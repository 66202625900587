import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useException } from "../../../contexts/exception.context";
import { useAppState } from "../../../contexts/appState.context";
import TableControl from "../../../controls/table.control";

import intervalUtil from "./interval.util";

const CalenderEventsPage = () => {
  const navigate = useNavigate();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();

  const [events, setEvents] = useState([]);

  const refresh = async () => {
    try {
      let events =
        await appStateCtx.apiServerClient.customer.calenderEvents.getMeetings();

      events.sort((a, b) => -a.startDate.localeCompare(b.startDate));

      setEvents(events);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleMeetingClicked = (meeting) => {
    navigate(`./${meeting._id}`);
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  return (
    <div className="container page">
      <h1>Møder</h1>
      <div className="mt-3">
        <TableControl
          columns={[
            {
              header: "Dato og tid",
              valueExtractor: (e) => intervalUtil.getDateTime(e),
            },
          ]}
          rows={events}
          rowClassNameExtractor={(e) =>
            e.finished ? "text-decoration-line-through" : ""
          }
          keyExtractor={(e) => e.orderNumber}
          onRowClicked={handleMeetingClicked}
        />
      </div>
    </div>
  );
};
export default CalenderEventsPage;
