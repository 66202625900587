import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { useException } from "../../contexts/exception.context";
import { useMessage } from "../../contexts/message.context";
import { useAppState } from "../../contexts/appState.context";
import MetadataControl from "../../controls/metadata.control";

const UnsubscribePage = () => {
  const params = useParams();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [state, setState] = useState("loading");
  const [subscriber, setSubscriber] = useState();

  const refresh = async () => {
    try {
      const subscriber =
        await appStateCtx.apiServerClient.visitor.newsletterSubscribers.getById(
          params.id
        );

      setSubscriber(subscriber);
      setState("exists");
    } catch (ex) {
      //handleException(ex);
      setState("not exists");
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, [params.id]);

  const handleUnsubscribeClicked = async () => {
    try {
      await appStateCtx.apiServerClient.visitor.newsletterSubscribers.unsubscribe(
        params.id
      );
      setState("unsubscribed");
      messageCtx.showSuccess("Du er afmeldt fra at modtage vores nyhedsbrev");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  if (state === "loading") return null;

  return (
    <div className="container page">
      <MetadataControl
        title="Capp2 - Afmeld nyhedsbrev"
        description="Afmeld dig Capp2's nyhedsbrev."
      />
      <h1>Afmeld dig vores nyhedsbrev</h1>
      {state === "exists" && (
        <div className="mt-3">
          <p>
            Vi er kede af at se dig forlade os, tryk paa afmeld for ikke at
            modtage flere nyhedsbreve.
          </p>
          <div className="mt-3">
            <label className="form-label">Email</label>
            <div>{subscriber.email}</div>
          </div>

          <div className="form-group mt-3">
            <button
              className="btn btn-secondary"
              onClick={handleUnsubscribeClicked}
            >
              Afmeld
            </button>
          </div>
        </div>
      )}
      {state === "not exists" && (
        <div className="mt-3">
          <p>Kan ikke finde abbonementet</p>
        </div>
      )}
      {state === "unsubscribed" && (
        <div className="mt-3">
          <p>
            {subscriber.email} er nu afmeldt fra at modtage vores nyhedsbrev
          </p>
        </div>
      )}
    </div>
  );
};
export default UnsubscribePage;
