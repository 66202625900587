import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import validateUtil from "../../../../../utils/validate.util";

import { useException } from "../../../../contexts/exception.context";
import { useMessage } from "../../../../contexts/message.context";
import { useAppState } from "../../../../contexts/appState.context";
import HtmlControl, { sanitizeHtml } from "../../../../controls/html.input";
import FileInput from "../../../../controls/file.input";
import TextInput from "../../../../controls/text.input";
import TextareaInput from "../../../../controls/textarea.input";
import SelectInput from "../../../../controls/select.input";

const AnnouncementPage = () => {
  const navigate = useNavigate();
  const params = useParams();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [oldAnnouncement, setOldAnnouncement] = useState();
  const [newAnnouncement, setNewAnnouncement] = useState();

  const isReadonly = !appStateCtx.user.permissionIds.includes(
    "admin.announcements.edit"
  );

  const refresh = async () => {
    try {
      if (params.announcementId) {
        const announcement =
          await appStateCtx.apiServerClient.employee.announcements.getById(
            params.announcementId
          );
        setOldAnnouncement(announcement);
        setNewAnnouncement(JSON.parse(JSON.stringify(announcement)));
      } else {
        setNewAnnouncement({
          title: "",
          banner: undefined,
          text: "",
          frontpagetext: "",
          enabled: false,
        });
      }
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, [params.announcementId]);

  const handleUpdateClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.announcements.update(
        newAnnouncement._id,
        newAnnouncement.banner,
        newAnnouncement.title,
        sanitizeHtml(newAnnouncement.text),
        newAnnouncement.frontpagetext,
        newAnnouncement.enabled
      );
      messageCtx.showSuccess("Nyhed opdateret");
      navigate("./..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleCreateClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.announcements.insert(
        newAnnouncement.banner,
        newAnnouncement.title,
        sanitizeHtml(newAnnouncement.text),
        newAnnouncement.frontpagetext,
        newAnnouncement.enabled
      );
      messageCtx.showSuccess("Nyhed gemt");
      navigate("./..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleDeleteClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.announcements.delete(
        params.announcementId
      );
      messageCtx.showSuccess("Nyhed slettet");
      navigate("./..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const isFormOk = () => {
    if (!newAnnouncement.banner) return false;
    if (!validateUtil.string.isValid(newAnnouncement.title)) return false;
    if (!sanitizeHtml(newAnnouncement.text)) return false;
    if (!newAnnouncement.frontpagetext) return false;

    return true;
  };

  const hasFormChanged = () => {
    return JSON.stringify(oldAnnouncement) !== JSON.stringify(newAnnouncement);
  };

  if (!newAnnouncement) return null;
  console.log(newAnnouncement);
  return (
    <div className="container page">
      <h1>Nyhed</h1>
      <div className="mt-3">
        <label className="form-label">Billede</label>
        <FileInput
          value={newAnnouncement.banner}
          accept=".png, .jpeg, .jpg"
          maxSize={1024 * 1024 * 5}
          onChange={(value) =>
            setNewAnnouncement({ ...newAnnouncement, banner: value })
          }
          readonly={isReadonly}
          showSeeImageButton
        />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Title</label>
        <TextInput
          value={newAnnouncement.title}
          readonly={isReadonly}
          onChange={(value) =>
            setNewAnnouncement({ ...newAnnouncement, title: value })
          }
        />
      </div>

      <div className="form-group mt-3">
        <label className="form-label">Forside tekst</label>

        <TextareaInput
          value={newAnnouncement.frontpagetext}
          rows={5}
          readonly={isReadonly}
          maxLength={200}
          onChange={(value) =>
            setNewAnnouncement({
              ...newAnnouncement,
              frontpagetext: value,
            })
          }
        />
      </div>

      <div className="form-group mt-3">
        <label className="form-label">Fuld tekst</label>
        <HtmlControl
          value={newAnnouncement.text}
          onChange={(value) =>
            setNewAnnouncement({
              ...newAnnouncement,
              text: value,
            })
          }
          readonly={isReadonly}
        />
      </div>

      <div className="form-group mt-3">
        <label className="form-label">Vis</label>
        <SelectInput
          items={["Ja", "Nej"]}
          valueExtractor={(i) => i}
          nameExtractor={(i) => i}
          value={newAnnouncement.enabled ? "Ja" : "Nej"}
          readonly={isReadonly}
          onChange={(value) =>
            setNewAnnouncement({
              ...newAnnouncement,
              enabled: value === "Ja",
            })
          }
        />
      </div>

      {!isReadonly && (
        <div className="form-group space-children mt-3">
          {!params.announcementId && (
            <button
              className="btn btn-primary"
              onClick={handleCreateClicked}
              disabled={!isFormOk()}
            >
              Gem
            </button>
          )}

          {params.announcementId && (
            <button
              className="btn btn-primary"
              onClick={handleUpdateClicked}
              disabled={!isFormOk() || !hasFormChanged()}
            >
              Opdater
            </button>
          )}

          {params.announcementId && (
            <button
              className="btn btn-secondary"
              onClick={handleDeleteClicked}
              disabled={!isFormOk()}
            >
              Slet
            </button>
          )}
        </div>
      )}
    </div>
  );
};
export default AnnouncementPage;
