import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  faCircleArrowDown,
  faCircleArrowUp,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, Dropdown } from "react-bootstrap";

import productGroupUtil from "../../../../utils/productGroup.util";

import { useException } from "../../../contexts/exception.context";
import { useMessage } from "../../../contexts/message.context";
import { useAppState } from "../../../contexts/appState.context";
import HtmlInput, { sanitizeHtml } from "../../../controls/html.input";
import OptionsControl from "../../../controls/options.control";

import getMenuItems from "./productMenuItems.data";
import TextInput from "../../../controls/text.input";
import SelectInput from "../../../controls/select.input";
import FileInput from "../../../controls/file.input";
import SelectproductModal from "../../../controls/selectproduct.modal";
import TableControl from "../../../controls/table.control";
import ChoiceModal from "../../../controls/choice.modal";

const hasFile = (document) => document?.url || document?.file;

const ProductPage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [showLoadFromProductModal, setShowLoadFromProductModal] =
    useState(false);
  const [products, setProducts] = useState([]);

  const [overlayTypes, setOverlayTypes] = useState([]);
  const [overlayPositions, setOverlayPositions] = useState([]);

  const [oldProduct, setOldProduct] = useState();
  const [newProduct, setNewProduct] = useState();

  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [oldDocument, setOldDocument] = useState();
  const [newDocument, setNewDocument] = useState();

  const [showMediaModal, setShowMediaModal] = useState(false);
  const [oldMedia, setOldMedia] = useState();
  const [newMedia, setNewMedia] = useState();

  const [showSubProductModal, setShowSubProductModal] = useState(false);
  const [oldSubProduct, setOldSubProduct] = useState();
  const [newSubProduct, setNewSubProduct] = useState();

  const [showGotoModal, setShowGotoModal] = useState(false);

  const isReadonly = !appStateCtx.user.permissionIds.includes(
    "admin.products.edit"
  );

  const refresh = async () => {
    try {
      const overlayTypes =
        await appStateCtx.apiServerClient.employee.products.getOverlayTypes();
      const overlayPositions =
        await appStateCtx.apiServerClient.employee.products.getOverlayPositions();

      setOverlayPositions(overlayPositions);
      setOverlayTypes(overlayTypes);

      await refreshProduct();

      const products =
        await appStateCtx.apiServerClient.employee.products.getAll();
      setProducts(products);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const refreshProduct = async () => {
    const product =
      await appStateCtx.apiServerClient.employee.products.getByName(
        params.productGroupName,
        params.productName
      );

    setOldProduct(product);
    setNewProduct(JSON.parse(JSON.stringify(product)));
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  const handleUpdateClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.products.update(
        newProduct._id,
        sanitizeHtml(newProduct.description),
        newProduct.tags,
        newProduct.overlay1.type.id,
        newProduct.overlay1.position.id,
        newProduct.overlay2.type.id,
        newProduct.overlay2.position.id,
        newProduct.medias,
        newProduct.documents,
        newProduct.subProducts
      );

      appStateCtx.refreshProductsAsync();
      messageCtx.showSuccess("Produkt opdateret");
      setShowGotoModal(true);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const hasFormChanged = () => {
    return JSON.stringify(oldProduct) !== JSON.stringify(newProduct);
  };

  const isFormOk = () => {
    return true;
  };

  const handleUpdateSubProductClicked = () => {
    const newProduct2 = { ...newProduct };
    const index = newProduct2.subProducts.indexOf(oldSubProduct);
    newProduct2.subProducts.splice(index, 1, newSubProduct);
    setNewProduct(newProduct2);
    setShowSubProductModal(false);
  };

  const handleSubProductClicked = (subProduct) => {
    //if (isReadonly) return;
    setOldSubProduct(subProduct);
    setNewSubProduct({ ...subProduct });
    setShowSubProductModal(true);
  };

  const isSubProductFormOk = () => {
    return true;
  };

  const hasSubProductFormChanged = () => {
    return JSON.stringify(oldSubProduct) !== JSON.stringify(newSubProduct);
  };

  const handleMediaClicked = (media) => {
    setOldMedia(media);
    setNewMedia(JSON.parse(JSON.stringify(media)));
    setShowMediaModal(true);
  };

  const handleUpdateMediaClicked = () => {
    const newProduct2 = { ...newProduct };
    const index = newProduct2.medias.indexOf(oldMedia);
    newProduct2.medias.splice(index, 1, newMedia);
    setNewProduct(newProduct2);
    setShowMediaModal(false);
  };

  const handleDeleteMediaClicked = () => {
    const newProduct2 = { ...newProduct };
    newProduct2.medias = newProduct2.medias.filter((m) => m !== oldMedia);
    setNewProduct(newProduct2);
    setShowMediaModal(false);
  };

  const handleCreateMediaClicked = () => {
    const newProduct2 = { ...newProduct };
    newProduct2.medias.push(newMedia);
    setNewProduct(newProduct2);
    setShowMediaModal(false);
  };

  const handleAddMediaClicked = () => {
    setOldMedia();
    setNewMedia();
    setShowMediaModal(true);
  };

  const isMediaFormOk = () => {
    if (!newMedia) return false;

    return true;
  };

  const hasMediaFormChanged = () => {
    return JSON.stringify(oldMedia) !== JSON.stringify(newMedia);
  };

  const handleDocumentClicked = (document) => {
    //if (isReadonly) return;
    setOldDocument(document);
    setNewDocument({ ...document });
    setShowDocumentModal(true);
  };

  const handleUpdateDocumentClicked = () => {
    const newProduct2 = { ...newProduct };
    const index = newProduct2.documents.indexOf(oldDocument);
    newProduct2.documents.splice(index, 1, newDocument);
    setNewProduct(newProduct2);
    setShowDocumentModal(false);
  };

  const handleDeleteDocumentClicked = () => {
    const newProduct2 = { ...newProduct };
    newProduct2.documents = newProduct2.documents.filter(
      (d) => d !== oldDocument
    );
    setNewProduct(newProduct2);
    setShowDocumentModal(false);
  };

  const handleCreateDocumentClicked = () => {
    const newProduct2 = { ...newProduct };
    newProduct2.documents.push(newDocument);
    setNewProduct(newProduct2);
    setShowDocumentModal(false);
  };

  const handleAddDocumentClicked = () => {
    const doc = { type: "Sikkerhedsdatablad" };
    setOldDocument(doc);
    setNewDocument(JSON.parse(JSON.stringify(doc)));

    setShowDocumentModal(true);
  };

  const isDocumentFormOk = () => {
    if (!newDocument) return false;
    if (!newDocument?.type) return false;
    //if (!newDocument?.file && !newDocument.url) return false;
    if (!newDocument?.file) return false;

    return true;
  };

  const hasDocumentFormChanged = () => {
    return JSON.stringify(oldDocument) !== JSON.stringify(newDocument);
  };

  const onCloseGotoModal = async (choice) => {
    setShowGotoModal(false);

    if (choice === "Vis gruppe") {
      navigate(
        productGroupUtil.getAdminAbsoluteUrl2(
          newProduct.productGroup.name,
          "produkter"
        )
      );
    } else {
      await refreshProduct();
    }
  };

  const loadFromProductClicked = () => {
    setShowLoadFromProductModal(true);
  };

  const handleSelectProductModalClose = (product) => {
    setShowLoadFromProductModal(false);
    if (!product) return;

    setOldProduct(product);
    setNewProduct(JSON.parse(JSON.stringify(product)));
  };

  const handleMoveMediaDown = (e, media) => {
    e.stopPropagation();

    const index = newProduct.medias.indexOf(media);
    if (index < newProduct.medias.length - 1) {
      const newProduct2 = { ...newProduct };
      newProduct2.medias = newProduct2.medias.filter((m) => m !== media);
      newProduct2.medias.splice(index + 1, 0, media);
      setNewProduct(newProduct2);
    }
  };

  const handleMoveMediaUp = (e, media) => {
    e.stopPropagation();

    const index = newProduct.medias.indexOf(media);
    if (index > 0) {
      const newProduct2 = { ...newProduct };
      newProduct2.medias = newProduct2.medias.filter((m) => m !== media);
      newProduct2.medias.splice(index - 1, 0, media);
      setNewProduct(newProduct2);
    }
  };

  if (!newProduct) return null;

  const getMediasColumns = () => {
    if (isReadonly) {
      return [{ header: "Navn", valueExtractor: (m) => m.file.name }];
    } else {
      return [
        { header: "Navn", valueExtractor: (m) => m.file.name },
        {
          header: "",
          valueExtractor: (m) => (
            <>
              <FontAwesomeIcon
                icon={faCircleArrowUp}
                onClick={(e) => handleMoveMediaUp(e, m)}
              />
              <FontAwesomeIcon
                icon={faCircleArrowDown}
                className="ms-2"
                onClick={(e) => handleMoveMediaDown(e, m)}
              />
            </>
          ),
          className: "text-end",
        },
      ];
    }
  };

  const menuItems = getMenuItems(appStateCtx.user, oldProduct, "Produkt");

  return (
    <div className="container page">
      <h1>Produkt: {params.productName}</h1>
      <div className="mt-4">
        <OptionsControl items={menuItems} />
        {!isReadonly && (
          <button
            className="btn btn-primary ms-1"
            onClick={loadFromProductClicked}
          >
            Hent
          </button>
        )}
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Beskrivelse</label>
        <HtmlInput
          value={newProduct.description}
          onChange={(value) =>
            setNewProduct({ ...newProduct, description: value })
          }
          type="product"
          disabled={isReadonly}
        />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Splash 1</label>
        <div className="input-group">
          <SelectInput
            items={overlayTypes}
            nameExtractor={(t) => t.name}
            valueExtractor={(t) => t.id}
            value={newProduct.overlay1.type.id}
            onChange={(value) =>
              setNewProduct({
                ...newProduct,
                overlay1: {
                  ...newProduct.overlay1,
                  type: { id: value },
                },
              })
            }
            readonly={isReadonly}
          />
          <SelectInput
            items={overlayPositions}
            nameExtractor={(p) => p.name}
            valueExtractor={(p) => p.id}
            value={newProduct.overlay1.position.id}
            onChange={(value) =>
              setNewProduct({
                ...newProduct,
                overlay1: {
                  ...newProduct.overlay1,
                  position: { id: value },
                },
              })
            }
            readonly={isReadonly}
          />
        </div>
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Splash 2</label>
        <div className="input-group">
          <SelectInput
            items={overlayTypes}
            nameExtractor={(t) => t.name}
            valueExtractor={(t) => t.id}
            value={newProduct.overlay2.type.id}
            onChange={(value) =>
              setNewProduct({
                ...newProduct,
                overlay2: {
                  ...newProduct.overlay2,
                  type: { id: value },
                },
              })
            }
            readonly={isReadonly}
          />
          <SelectInput
            items={overlayPositions}
            nameExtractor={(p) => p.name}
            valueExtractor={(p) => p.id}
            value={newProduct.overlay2.position.id}
            onChange={(value) =>
              setNewProduct({
                ...newProduct,
                overlay2: {
                  ...newProduct.overlay2,
                  position: { id: value },
                },
              })
            }
            readonly={isReadonly}
          />
        </div>
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Søgeord</label>
        <TextInput
          value={newProduct.tags}
          onChange={(value) =>
            setNewProduct({
              ...newProduct,
              tags: value,
            })
          }
          readonly={isReadonly}
        />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Billede og video</label>
        <div>
          {!isReadonly && (
            <button
              onClick={handleAddMediaClicked}
              className="btn btn-primary"
              disabled={newProduct.medias.length >= 6}
            >
              <FontAwesomeIcon icon={faPlus} className="clickable" />
            </button>
          )}
        </div>
        {newProduct.medias.length ? (
          <div className="mt-3">
            <TableControl
              columns={getMediasColumns()}
              rows={newProduct.medias}
              keyExtractor={(m, index) => index}
              onRowClicked={handleMediaClicked}
            />
          </div>
        ) : (
          <div className="mt-3">
            <i>Ingen billede og video</i>
          </div>
        )}
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Dokumenter</label>
        <div>
          {!isReadonly && (
            <button
              onClick={handleAddDocumentClicked}
              className="btn btn-primary"
            >
              <FontAwesomeIcon icon={faPlus} className="clickable" />
            </button>
          )}
        </div>
        {newProduct.documents.length ? (
          <div className="mt-3">
            <TableControl
              columns={[
                { header: "Type", valueExtractor: (d) => d.type },
                {
                  header: "Navn",
                  valueExtractor: (d) => d.file.name, //d.name || d.file.name,
                },
              ]}
              rows={newProduct.documents}
              keyExtractor={(d, index) => index}
              onRowClicked={handleDocumentClicked}
            />
          </div>
        ) : (
          <div className="mt-3">
            <i>Ingen dokumenter</i>
          </div>
        )}
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Sub produkter</label>
        <TableControl
          columns={[
            { header: "Nummer", valueExtractor: (s) => s.subProductNumber },
            { header: "Navn", valueExtractor: (s) => s.shortName },
            {
              header: "Vis på hjemmeside",
              valueExtractor: (s) => (s.showOnHomepage ? "Ja" : "Nej"),
            },
            {
              header: "Vejl. enhedspris",
              valueExtractor: (s) => (s.salesPrice || 0).toFixed(2),
              className: "text-end",
            },
          ]}
          keyExtractor={(s) => s.subProductNumber}
          rows={newProduct.subProducts}
          onRowClicked={handleSubProductClicked}
        />
      </div>
      {!isReadonly && (
        <div className="form-group mt-3">
          <button
            className="btn btn-primary"
            onClick={handleUpdateClicked}
            disabled={!isFormOk() || !hasFormChanged()}
          >
            Opdater
          </button>
        </div>
      )}

      <Modal
        show={showSubProductModal}
        onHide={() => setShowSubProductModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Subprodukt</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label className="form-label">Nummer</label>
            <TextInput value={newSubProduct?.subProductNumber} readonly />
          </div>
          <div className="form-group mt-3">
            <label className="form-label">Navn</label>
            <TextInput value={newSubProduct?.name} readonly />
          </div>
          <div className="form-group mt-3">
            <label className="form-label">Pris</label>
            <TextInput value={newSubProduct?.salesPrice || 0} readonly />
          </div>
          <div className="form-group mt-3">
            <label className="form-label">Vis på hjemeside</label>
            <SelectInput
              items={["Ja", "Nej"]}
              valueExtractor={(i) => i}
              nameExtractor={(i) => i}
              value={newSubProduct?.showOnHomepage ? "Ja" : "Nej"}
              onChange={(value) =>
                setNewSubProduct({
                  ...newSubProduct,
                  showOnHomepage: value === "Ja",
                })
              }
              readonly={isReadonly}
            />
          </div>
        </Modal.Body>
        {!isReadonly && (
          <Modal.Footer>
            <button
              className="btn btn-primary"
              onClick={handleUpdateSubProductClicked}
              disabled={!isSubProductFormOk() || !hasSubProductFormChanged()}
            >
              Opdater
            </button>
          </Modal.Footer>
        )}
      </Modal>

      <Modal show={showMediaModal} onHide={() => setShowMediaModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Billede/video</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label className="form-label">Fil</label>
            <FileInput
              accept=".png, .jpeg, .jpg, .mp4"
              allowEmpty={false}
              maxSize={1024 * 1024 * 5}
              showSeeImageButton={true}
              readonly={isReadonly}
              onChange={(value) => setNewMedia({ ...newMedia, file: value })}
              value={newMedia?.file}
              /* TODO */
            />
          </div>
        </Modal.Body>
        {!isReadonly && (
          <Modal.Footer>
            {!oldMedia && (
              <button
                className="btn btn-primary"
                onClick={handleCreateMediaClicked}
                disabled={!isMediaFormOk()}
              >
                Gem
              </button>
            )}
            {oldMedia && (
              <button
                className="btn btn-primary"
                onClick={handleUpdateMediaClicked}
                disabled={!isMediaFormOk() || !hasMediaFormChanged()}
              >
                Opdater
              </button>
            )}
            {oldMedia && (
              <button
                className="btn btn-secondary"
                onClick={handleDeleteMediaClicked}
              >
                Slet
              </button>
            )}
          </Modal.Footer>
        )}
      </Modal>

      <SelectproductModal
        products={products}
        show={showLoadFromProductModal}
        onClose={handleSelectProductModalClose}
      />

      <Modal
        show={showDocumentModal}
        onHide={() => setShowDocumentModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Dokument</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label className="form-label">Type</label>
            <SelectInput
              items={[
                "Arbejdspladsbrugsanvisning",
                "Brugsanvisning",
                "Oversigt",
                "Sikkerhedsdatablad",
              ]}
              nameExtractor={(i) => i}
              valueExtractor={(i) => i}
              value={newDocument?.type || ""}
              onChange={(value) =>
                setNewDocument({ ...newDocument, type: value })
              }
              readonly={isReadonly}
            />
          </div>
          <div className="form-group mt-3">
            <label className="form-label">Fil</label>
            <FileInput
              value={newDocument?.file}
              accept=".pdf"
              showSeeImageButton={true}
              allowEmpty={false}
              readonly={isReadonly}
              maxSize={1024 * 1024 * 5}
              onChange={(value) =>
                setNewDocument({ ...newDocument, file: value })
              }
            />
          </div>

          {!isReadonly && (
            <div className="mt-3 d-flex justify-content-end">
              <div className="space-children ">
                {!hasFile(oldDocument) && (
                  <button
                    className="btn btn-primary"
                    onClick={handleCreateDocumentClicked}
                    disabled={!isDocumentFormOk()}
                  >
                    Gem
                  </button>
                )}
                {hasFile(oldDocument) && (
                  <button
                    className="btn btn-primary"
                    onClick={handleUpdateDocumentClicked}
                    disabled={!isDocumentFormOk() || !hasDocumentFormChanged()}
                  >
                    Opdater
                  </button>
                )}
                {hasFile(oldDocument) && (
                  <button
                    className="btn btn-secondary"
                    onClick={handleDeleteDocumentClicked}
                  >
                    Slet
                  </button>
                )}
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>

      <ChoiceModal
        title="Produkt opdateret"
        text="Hvor vil du hen?"
        choices={["Vis gruppe", "Vis produkt"]}
        nameExtractor={(c) => c}
        show={showGotoModal}
        onClose={onCloseGotoModal}
      />
    </div>
  );
};

export default ProductPage;
