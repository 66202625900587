import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

import ErrorBoundaryFeature from "./components/features/errorboundary.feature";
import { AppStateProvider } from "./components/contexts/appState.context";
import { MessageProvider } from "./components/contexts/message.context";
import { ExceptionProvider } from "./components/contexts/exception.context";
import { OptionsProvider } from "./components/contexts/options.context";
import { CartProvider } from "./components/contexts/cart.context";
import { SearchProvider } from "./components/contexts/search.context";
import { SettingsProvider } from "./components/contexts/settings.context";
import { FavoritesProvider } from "./components/contexts/favorites.context";

import AppNav from "./components/pages/nav";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ErrorBoundaryFeature>
    <MessageProvider>
      <AppStateProvider>
        <ExceptionProvider>
          <SettingsProvider>
            <CartProvider>
              <BrowserRouter>
                <FavoritesProvider>
                  <SearchProvider>
                    <OptionsProvider>
                      <AppNav />
                    </OptionsProvider>
                  </SearchProvider>
                </FavoritesProvider>
              </BrowserRouter>
            </CartProvider>
          </SettingsProvider>
        </ExceptionProvider>
      </AppStateProvider>
    </MessageProvider>
  </ErrorBoundaryFeature>
);
