import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useException } from "../../../contexts/exception.context";
import { useAppState } from "../../../contexts/appState.context";
import TableControl from "../../../controls/table.control";

const EmployeesPage = () => {
  const navigate = useNavigate();
  const appStateCtx = useAppState();
  const exceptionCtx = useException();

  const [employees, setEmployees] = useState([]);

  const refresh = async () => {
    try {
      const employees =
        await appStateCtx.apiServerClient.employee.employees.getAll();
      setEmployees(employees);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleEmployeeClicked = (employee) => {
    navigate(`./${employee.employeeNumber}`);
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  return (
    <div className="container page">
      <h1>Medarbejdere</h1>
      <div className="mt-3">
        <TableControl
          columns={[{ header: "Navn", valueExtractor: (e) => e.name }]}
          rows={employees}
          keyExtractor={(e) => e._id}
          onRowClicked={handleEmployeeClicked}
        />
      </div>
    </div>
  );
};
export default EmployeesPage;
