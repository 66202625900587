import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { useException } from "../../../../contexts/exception.context";
import { useAppState } from "../../../../contexts/appState.context";
import TableControl from "../../../../controls/table.control";

const ReferencesPage = () => {
  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const navigate = useNavigate();

  const [references, setReferences] = useState([]);

  const isReadonly = !appStateCtx.user.permissionIds.includes(
    "admin.references.edit"
  );

  const refresh = async () => {
    try {
      const references =
        await appStateCtx.apiServerClient.employee.references.getAll();
      setReferences(references);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  const handleNewClicked = () => {
    navigate("./ny");
  };

  const handleReferenceClicked = (reference) => {
    navigate(`./${reference._id}`);
  };

  return (
    <div className="container page">
      <h1>Referencer</h1>
      {!isReadonly && (
        <div className="mt-4">
          <button className="btn btn-primary" onClick={handleNewClicked}>
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
      )}
      <div className="mt-3">
        <TableControl
          columns={[
            { header: "Titel", valueExtractor: (r) => r.title },
            {
              header: "Fra",
              valueExtractor: (r) => r.from,
            },
            {
              header: "Vis",
              valueExtractor: (r) => (r.enabled ? "Ja" : "Nej"),
            },
          ]}
          keyExtractor={(r) => r._id}
          onRowClicked={handleReferenceClicked}
          rows={references}
        />
      </div>
    </div>
  );
};

export default ReferencesPage;
