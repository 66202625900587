import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { useAppState } from "../../../../contexts/appState.context";
import { useException } from "../../../../contexts/exception.context";
import TableControl from "../../../../controls/table.control";

const VideosPage = () => {
  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const navigate = useNavigate();

  const [videos, setVideos] = useState([]);

  const isReadonly =
    !appStateCtx.user.permissionIds.includes("admin.videos.edit");

  const refresh = async () => {
    try {
      const videos = await appStateCtx.apiServerClient.employee.videos.getAll();

      setVideos(videos);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  const handleCreateClicked = () => {
    navigate("./ny");
  };

  const handleVideoClicked = (video) => {
    navigate(`./${video._id}`);
  };

  return (
    <div className="container page">
      <h1>Videoer</h1>
      {!isReadonly && (
        <div className="mt-4">
          <button className="btn btn-primary" onClick={handleCreateClicked}>
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
      )}

      <div className="mt-3">
        <TableControl
          columns={[
            { header: "Titel", valueExtractor: (v) => v.title },
            {
              header: "Produkt",
              valueExtractor: (v) => (v.productId ? "Ja" : "Nej"),
            },
            {
              header: "Vis",
              valueExtractor: (v) => (v.enabled ? "Ja" : "Nej"),
            },
          ]}
          keyExtractor={(v) => v._id}
          onRowClicked={handleVideoClicked}
          rows={videos}
        />
      </div>
    </div>
  );
};

export default VideosPage;
