import { Modal } from "react-bootstrap";

import TableControl from "./table.control";

export default ({ show, onClose, locations }) => {
  const handleCustomerClicked = (customer) => {
    onClose(customer);
  };

  return (
    <Modal show={show} onHide={() => onClose()} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Vælg adresse</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TableControl
          columns={[
            {
              header: "Navn",
              type: "string",
              valueExtractor: (l) => (
                <span>
                  {l.address}
                  <br />
                  {l.postalCode} {l.city}
                  <br />
                  {l.country}
                </span>
              ),
            },
          ]}
          keyExtractor={(l) => l.deliveryLocationNumber}
          rows={locations}
          onRowClicked={handleCustomerClicked}
        />
      </Modal.Body>
    </Modal>
  );
};
