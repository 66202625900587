import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faRemove } from "@fortawesome/free-solid-svg-icons";

import searchFilterUtil from "../../../../../utils/searchFilter.util";
import dateUtil from "../../../../../utils/date.util";

import { useAppState } from "../../../../contexts/appState.context";
import { useException } from "../../../../contexts/exception.context";
import InvoicesTable from "../../../../controls/invoices.table";
import MonthInput from "../../../../controls/month.input";

const getDefaultFilter = () => {
  return {
    show: false,
    month: dateUtil.todayAsISOMonth(),
  };
};

const InvoicesPage = () => {
  const appStateCtx = useAppState();
  const exceptionCtx = useException();

  const [invoices, setInvoices] = useState([]);
  const [filter, setFilter] = useState(getDefaultFilter());

  const saveAndSetFilter = (filter) => {
    searchFilterUtil.save("invoices", appStateCtx.user._id, filter);

    setFilter(filter);
  };

  const refresh = async () => {
    try {
      const filter =
        searchFilterUtil.load("invoices", appStateCtx.user._id) ||
        getDefaultFilter();

      setFilter(filter);

      const invoices =
        await appStateCtx.apiServerClient.employee.invoices.getByMonth(
          filter.month
        );

      // newest on top
      invoices.sort((a, b) => b.date.localeCompare(a.date));

      setInvoices(invoices);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, [filter.month]);

  return (
    <div className="container page">
      <h1>Fakturaer</h1>
      <div className="d-flex justify-content-end mt-4">
        <div className="btn-group">
          <button
            className="btn btn-primary"
            onClick={() => saveAndSetFilter({ ...filter, show: !filter.show })}
          >
            <FontAwesomeIcon icon={faFilter} />
          </button>
          {!searchFilterUtil.areFiltersEqual(getDefaultFilter(), filter) && (
            <button
              className="btn btn-secondary"
              onClick={() => saveAndSetFilter({ ...getDefaultFilter() })}
            >
              <FontAwesomeIcon icon={faRemove} />
            </button>
          )}
        </div>
      </div>
      <div style={{ display: filter.show ? "initial" : "none" }}>
        <div className="form-group mt-3">
          <label className="form-label">Måned</label>
          <MonthInput
            value={filter.month}
            onChange={(value) =>
              saveAndSetFilter({
                ...filter,
                month: value || dateUtil.todayAsISOMonth(),
              })
            }
            required
          />
        </div>
      </div>

      <div className="mt-3">
        <InvoicesTable invoices={invoices} showCustomer />
      </div>
    </div>
  );
};

export default InvoicesPage;
