import React, { useEffect, useState } from "react";
import { faCartArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useException } from "../../../contexts/exception.context";
import { useAppState } from "../../../contexts/appState.context";
import { useSettings } from "../../../contexts/settings.context";
import { useCart } from "../../../contexts/cart.context";
import { useMessage } from "../../../contexts/message.context";
import TableControl from "../../../controls/table.control";

const CustomerInvoiceLinesPage = () => {
  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const messageCtx = useMessage();
  const cartCtx = useCart();
  const settingsCtx = useSettings();

  const [lineProducts, setLineProducts] = useState([]);

  const refresh = async () => {
    try {
      const lineProducts =
        await appStateCtx.apiServerClient.customer.invoices.getLineProducts();

      lineProducts.sort((a, b) => b.quantity - a.quantity);

      setLineProducts(lineProducts);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleAddToCartClicked = (subProduct, product) => {
    cartCtx.cart.addToCart(subProduct, product);
    messageCtx.showSuccess("Produkt lagt i kurv");
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  const getColumns = () => {
    if (
      appStateCtx.user?.permissionIds?.includes("customer.cart.view") &&
      settingsCtx.settings?.cartState?.startsWith("Ja")
    ) {
      return [
        { header: "Nummer", valueExtractor: (p) => p.productNumber },
        {
          header: "Beskrivelse",
          valueExtractor: (p) => (
            <>
              <div
                style={{
                  textDecoration:
                    p.product && p.subProduct.name !== p.description
                      ? "line-through"
                      : "",
                }}
              >
                {p.description}
              </div>
              {p.product && p.subProduct.name !== p.description && (
                <div>{p.subProduct.name}</div>
              )}
            </>
          ),
        },
        { header: "Enhed", valueExtractor: (p) => p.unit },
        {
          header: "Antal",
          valueExtractor: (p) => p.quantity,
          className: "text-end",
        },
        {
          header: "Læg i kurv",
          valueExtractor: (p) =>
            p.product ? (
              <div
                onClick={() => handleAddToCartClicked(p.subProduct, p.product)}
              >
                <FontAwesomeIcon className="clickable" icon={faCartArrowDown} />
              </div>
            ) : (
              ""
            ),
          className: "text-end",
        },
      ];
    } else {
      return [
        { header: "Nummer", valueExtractor: (p) => p.productNumber },
        {
          header: "Beskrivelse",
          valueExtractor: (p) => (
            <>
              <div
                style={{
                  textDecoration:
                    p.product && p.subProduct.name !== p.description
                      ? "line-through"
                      : "",
                }}
              >
                {p.description}
              </div>
              {p.product && p.subProduct.name !== p.description && (
                <div>{p.subProduct.name}</div>
              )}
            </>
          ),
        },
        { header: "Enhed", valueExtractor: (p) => p.unit },
        {
          header: "Antal",
          valueExtractor: (p) => p.quantity,
          className: "text-end",
        },
      ];
    }
  };

  return (
    <div className="container page">
      <h1>Tidligere købt</h1>

      <div className="mt-3">
        <TableControl
          columns={getColumns()}
          rows={lineProducts}
          keyExtractor={(p) => p.productNumber}
        />
      </div>
    </div>
  );
};
export default CustomerInvoiceLinesPage;
