import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faRemove } from "@fortawesome/free-solid-svg-icons";
import { DateTime } from "luxon";

import settings from "../../../../../utils/settings";
import searchFilterUtil from "../../../../../utils/searchFilter.util";

import { useAppState } from "../../../../contexts/appState.context";
import { useException } from "../../../../contexts/exception.context";
import SelectInput from "../../../../controls/select.input";
import TableControl from "../../../../controls/table.control";

const defaultFilter = {
  show: false,
  type: "Køres snart",
};

const JobsPage = () => {
  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const navigate = useNavigate();
  const [filter, setFilter] = useState(defaultFilter);
  const [jobs, setJobs] = useState([]);

  const saveAndSetFilter = (filter) => {
    searchFilterUtil.save("jobs", appStateCtx.user._id, filter);

    setFilter(filter);
  };

  const refresh = async () => {
    try {
      const filter =
        searchFilterUtil.load("jobs", appStateCtx.user._id) || defaultFilter;

      setFilter(filter);

      const jobs = await appStateCtx.apiServerClient.employee.jobs.getLatest2(
        50
      );

      jobs.sort((a, b) => b.createdAt.localeCompare(a.createdAt));

      jobs.forEach((j) =>
        j.results.sort((a, b) => b.createdAt.localeCompare(a.createdAt))
      );

      setJobs(jobs);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  const getJobIconColor = (job) => {
    if (job.results.length === 0) return "gray";

    const lastResult = job.results[0];
    if (lastResult.result === "Fejl") return "red";
    else if (lastResult.result === "Success") return "green";
    else return "transparent";
  };

  const getJobIcon = (job) => {
    return (
      <span
        style={{
          width: 10,
          display: "inline-block",
          height: 10,
          marginRight: 10,
          backgroundColor: getJobIconColor(job),
          borderRadius: 10,
        }}
      ></span>
    );
  };

  const getNextRunDate = (job) => {
    if (!job.enabled) return "Deaktiveret";
    if (job.processAt) {
      return DateTime.fromISO(job.processAt).toFormat(
        settings.dateTimeShortFormat
      );
    }

    return "";
  };

  const getOverdueIcon = (job) => {
    if (!job.enabled) return "";
    if (job.processAt) {
      if (
        DateTime.fromISO(job.processAt) < DateTime.now().minus({ hours: 2 })
      ) {
        return (
          <span
            style={{
              width: 10,
              display: "inline-block",
              height: 10,
              marginRight: 10,
              backgroundColor: "red",
              borderRadius: 10,
            }}
          ></span>
        );
      }
    }

    return "";
  };

  const handleJobClicked = (job) => {
    navigate(`./${job._id}`);
  };

  const filteredJobs =
    filter.type === "Færdig"
      ? jobs.filter((j) => !j.processAt)
      : jobs.filter((j) => j.processAt);

  return (
    <div className="container page">
      <h1>Job</h1>
      <div className="d-flex justify-content-end mt-4">
        <div className="btn-group">
          <button
            className="btn btn-primary"
            onClick={() => saveAndSetFilter({ ...filter, show: !filter.show })}
          >
            <FontAwesomeIcon icon={faFilter} />
          </button>

          {!searchFilterUtil.areFiltersEqual(defaultFilter, filter) && (
            <button
              className="btn btn-secondary"
              onClick={() => saveAndSetFilter({ ...defaultFilter })}
            >
              <FontAwesomeIcon icon={faRemove} />
            </button>
          )}
        </div>
      </div>
      <div style={{ display: filter.show ? "initial" : "none" }}>
        <div className="form-group mt-3">
          <label className="form-label">Status</label>
          <div>
            <SelectInput
              items={["Køres snart", "Færdig"]}
              nameExtractor={(t) => t}
              valueExtractor={(t) => t}
              value={filter.type}
              onChange={(value) => saveAndSetFilter({ ...filter, type: value })}
            />
          </div>
        </div>
      </div>

      <TableControl
        columns={[
          {
            header: "Navn",
            valueExtractor: (j) => (
              <>
                {getJobIcon(j)} {j.name}
              </>
            ),
          },
          {
            header: "Køres",
            valueExtractor: (j) => (
              <>
                {getOverdueIcon(j)}
                {getNextRunDate(j)}
              </>
            ),
          },
        ]}
        rows={filteredJobs}
        keyExtractor={(j) => j._id}
        onRowClicked={handleJobClicked}
      />
    </div>
  );
};

export default JobsPage;
