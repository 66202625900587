import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUnlock,
  faRemove,
  faPlus,
  faMagnifyingGlass,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { DateTime } from "luxon";

import settings from "../../../../../utils/settings";
import stringUtil from "../../../../../utils/string.util";

import { MoneyCellContent } from "../../../../controls/table.controls";
import { useAppState } from "../../../../contexts/appState.context";
import { useException } from "../../../../contexts/exception.context";
import { useMessage } from "../../../../contexts/message.context";
import CustomerNotesModal from "../../../../controls/customerNotes.modal";
import CustomerInvoicesModal from "../../../../controls/customerInvoices.modal";
import SelectInput from "../../../../controls/select.input";
import TextareaInput from "../../../../controls/textarea.input";
import TextInput from "../../../../controls/text.input";
import SearchtextInput from "../../../../controls/searchtext.input";
import SelectCustomerModal from "../../../../controls/selectcustomer.modal";
import SelectCustomerDeliveryLocationModal from "../../../../controls/selectcustomerdeliverylocation.modal";
import SelectCustomerContactModal from "../../../../controls/selectcustomercontact.modal";
import TableControl from "../../../../controls/table.control";

const createLocked = (value, locked) => {
  return { value, locked };
};

const Input = ({ get, set, disabled }) => {
  return (
    <div className="input-group">
      <TextInput
        value={get.value}
        onChange={(value) => set(createLocked(value, false))}
        disabled={get.locked}
      />
      {get.locked && disabled && (
        <button
          className="btn btn-secondary py-0"
          type="button"
          onClick={() => set(createLocked(get.value, false))}
        >
          <FontAwesomeIcon icon={faUnlock} />
        </button>
      )}
      {get.value && !get.locked && (
        <button
          className="btn btn-secondary py-0"
          type="button"
          onClick={() => set(createLocked("", false))}
        >
          <FontAwesomeIcon icon={faRemove} />
        </button>
      )}
    </div>
  );
};

const Textarea = ({ get, set, disabled, rows }) => {
  return (
    <div className="input-group">
      <TextareaInput
        value={get.value}
        onChange={(value) => set(createLocked(value, false))}
        disabled={get.locked}
        rows={rows}
      />
      {get.locked && disabled && (
        <button
          className="btn btn-secondary py-0"
          type="button"
          onClick={() => set(createLocked(get.value, false))}
        >
          <FontAwesomeIcon icon={faUnlock} />
        </button>
      )}
      {get.value && !get.locked && (
        <button
          className="btn btn-secondary py-0"
          type="button"
          onClick={() => set(createLocked("", false))}
        >
          <FontAwesomeIcon icon={faRemove} />
        </button>
      )}
    </div>
  );
};

const getLineWithoutVAT = (line) =>
  ((line.subProduct.salesPrice || 0) * line.amount * (100 - line.discount)) /
  100;

const getLineVAT = (line) => getLineWithoutVAT(line) * 0.25;

const getLineWithVAT = (line) => getLineWithoutVAT(line) * 1.25;

const getContact = (contact) => {
  if (contact) {
    return {
      object: contact,
      name: createLocked(contact.name || "", true),
      phone: createLocked(contact.phone || "", true),
      email: createLocked(contact.email || "", true),
      note: createLocked(contact.note || "", true),
      receiveEmails: createLocked(contact.receiveEmails || "", true),
    };
  } else {
    return {
      name: createLocked("", false),
      phone: createLocked("", false),
      email: createLocked("", false),
      note: createLocked("", false),
      receiveEmails: createLocked(false, false),
    };
  }
};

const getDeliveryLocation = (deliveryLocation) => {
  if (deliveryLocation) {
    return {
      object: deliveryLocation,
      address: createLocked(deliveryLocation.address || "", true),
      zip: createLocked(deliveryLocation.zip || "", true),
      city: createLocked(deliveryLocation.city || "", true),
      country: createLocked(deliveryLocation.country || "", true),
      termsOfDelivery: createLocked(
        deliveryLocation.termsOfDelivery || "",
        true
      ),
    };
  } else {
    return {
      address: createLocked("", false),
      zip: createLocked("", false),
      city: createLocked("", false),
      country: createLocked("", false),
      termsOfDelivery: createLocked("", false),
    };
  }
};

const getCustomer = (customer) => {
  if (customer) {
    return {
      object: customer,
      name: createLocked(customer.name || "", true),
      cvr: createLocked(customer.cvr || "", true),
      ean: createLocked(customer.ean || "", true),
      address: createLocked(customer.address || "", true),
      zip: createLocked(customer.zip || "", true),
      city: createLocked(customer.city || "", true),
      country: createLocked(customer.country || "", true),
      email: createLocked(customer.email || "", true),
    };
  } else {
    return {
      name: createLocked("", false),
      cvr: createLocked("", false),
      ean: createLocked("", false),
      address: createLocked("", false),
      zip: createLocked("", false),
      city: createLocked("", false),
      country: createLocked("", false),
      email: createLocked("", false),
    };
  }
};

const TypeSection = ({ order, setOrder }) => {
  const types = ["Salg", "Tilbud"];

  return (
    <>
      <div className="form-group mt-3">
        <label className="form-label">Type</label>
        <SelectInput
          items={types}
          nameExtractor={(t) => t}
          valueExtractor={(t) => t}
          value={order.type}
          onChange={(value) => setOrder({ ...order, type: value })}
        />
      </div>
    </>
  );
};

const CustomerSection = ({ order, setOrder }) => {
  const appStateCtx = useAppState();
  const exceptionCtx = useException();

  const [isLoading, setIsLoading] = useState(true);

  const [showSelectCustomerModal, setShowSelectCustomerModal] = useState(false);
  const [customers, setCustomers] = useState([]);

  const [showCustomerNotesModal, setShowCustomerNotesModal] = useState(false);

  const [showCustomerInvoicesModal, setShowCustomerInvoicesModal] =
    useState(false);

  const refresh = async () => {
    try {
      setIsLoading(true);

      const customers =
        await appStateCtx.apiServerClient.employee.customers.getAll();

      customers.sort((a, b) => a.name.localeCompare(b.name));

      setCustomers(customers);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClearCustomerClicked = () => {
    setOrder({
      ...order,
      customer: getCustomer(),
      deliveryLocation: getDeliveryLocation(),
      contact: getContact(),
    });
  };

  const handleSelectCustomerClose = async (customer) => {
    setShowSelectCustomerModal(false);
    if (!customer) return;

    const deliveryLocation = customer.deliveryLocations.find(
      (c) =>
        c.deliveryLocationNumber ===
        customer.defaultDeliveryLocation?.deliveryLocationNumber
    );

    const contact = customer.contacts.find(
      (c) =>
        c.customerContactNumber ===
        customer.customerContact?.customerContactNumber
    );

    setOrder({
      ...order,
      customer: getCustomer(customer),
      deliveryLocation: getDeliveryLocation(deliveryLocation),
      contact: getContact(contact),
    });
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="mt-3">
        <b>
          <u>Kunde</u>
        </b>
      </div>
      <div className="mt-3">
        <div className="space-children">
          <div className="btn-group">
            <button
              className="btn btn-primary"
              onClick={() => setShowSelectCustomerModal(true)}
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} />{" "}
              {isLoading && <Spinner animation="border" size="sm" />}
              {!isLoading && <span>({customers.length})</span>}
            </button>
            {order.customer.object && (
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => handleClearCustomerClicked()}
              >
                <FontAwesomeIcon icon={faRemove} />
              </button>
            )}
          </div>
          {order.customer.object && (
            <>
              <button
                className="btn btn-primary"
                onClick={() => setShowCustomerNotesModal(true)}
              >
                Noter
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => setShowCustomerInvoicesModal(true)}
              >
                Fakturaer
              </button>
            </>
          )}
        </div>
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Navn *</label>
        <Input
          get={order.customer.name}
          set={(value) =>
            setOrder({
              ...order,
              customer: { ...order.customer, name: value },
            })
          }
          disabled={order.customer.object}
        />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">CVR</label>
        <Input
          get={order.customer.cvr}
          set={(value) =>
            setOrder({
              ...order,
              customer: { ...order.customer, cvr: value },
            })
          }
          disabled={order.customer.object}
        />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">EAN</label>
        <Input
          get={order.customer.ean}
          set={(value) =>
            setOrder({
              ...order,
              customer: { ...order.customer, ean: value },
            })
          }
          disabled={order.customer.object}
        />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Adresse *</label>
        <Input
          get={order.customer.address}
          set={(value) =>
            setOrder({
              ...order,
              customer: { ...order.customer, address: value },
            })
          }
          disabled={order.customer.object}
        />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Post nummer</label>
        <Input
          get={order.customer.zip}
          set={(value) =>
            setOrder({
              ...order,
              customer: { ...order.customer, zip: value },
            })
          }
          disabled={order.customer.object}
        />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">By</label>
        <Input
          get={order.customer.city}
          set={(value) =>
            setOrder({
              ...order,
              customer: { ...order.customer, city: value },
            })
          }
          disabled={order.customer.object}
        />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Land</label>
        <Input
          get={order.customer.country}
          set={(value) =>
            setOrder({
              ...order,
              customer: { ...order.customer, country: value },
            })
          }
          disabled={order.customer.object}
        />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Email</label>
        <Input
          get={order.customer.email}
          set={(value) =>
            setOrder({
              ...order,
              customer: { ...order.customer, email: value },
            })
          }
          disabled={order.customer.object}
        />
      </div>
      <SelectCustomerModal
        customers={customers}
        show={showSelectCustomerModal}
        onClose={handleSelectCustomerClose}
      />
      {order.customer.object && (
        <CustomerNotesModal
          show={showCustomerNotesModal}
          setShow={setShowCustomerNotesModal}
          customerId={order.customer.object._id}
        />
      )}
      {order.customer.object && (
        <CustomerInvoicesModal
          show={showCustomerInvoicesModal}
          setShow={setShowCustomerInvoicesModal}
          customerNumber={order.customer.object.customerNumber}
        />
      )}
    </>
  );
};

const DeliveryLocationSection = ({ order, setOrder }) => {
  const [showDeliveryLocationModal, setShowDeliveryLocationModal] =
    useState(false);

  const handleClearDeliveryLocationClicked = () => {
    setOrder({
      ...order,
      deliveryLocation: getDeliveryLocation(),
    });
  };

  const handleDeliveryLocationModalClose = (deliveryLocation) => {
    setShowDeliveryLocationModal(false);
    if (!deliveryLocation) return;

    setOrder({
      ...order,
      deliveryLocation: getDeliveryLocation(deliveryLocation),
    });
  };

  return (
    <>
      <div className="mt-3">
        <b>
          <u>Leverings sted</u>
        </b>
      </div>
      {order.customer.object && (
        <div className="mt-3">
          <div className="btn-group">
            <button
              className="btn btn-primary"
              onClick={() => setShowDeliveryLocationModal(true)}
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} /> (
              {order.customer.object.deliveryLocations.length})
            </button>
            {order.deliveryLocation.object && (
              <button
                className="btn btn-secondary"
                onClick={() => handleClearDeliveryLocationClicked()}
              >
                <FontAwesomeIcon icon={faRemove} />
              </button>
            )}
          </div>
        </div>
      )}
      <div className="form-group mt-3">
        <label className="form-label">Adresse</label>
        <Textarea
          get={order.deliveryLocation.address}
          set={(value) =>
            setOrder({
              ...order,
              deliveryLocation: { ...order.deliveryLocation, address: value },
            })
          }
          disabled={order.deliveryLocation.object}
          rows={5}
        />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Post nummer</label>
        <Input
          get={order.deliveryLocation.zip}
          set={(value) =>
            setOrder({
              ...order,
              deliveryLocation: { ...order.deliveryLocation, zip: value },
            })
          }
          disabled={order.deliveryLocation.object}
        />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">By</label>
        <Input
          get={order.deliveryLocation.city}
          set={(value) =>
            setOrder({
              ...order,
              deliveryLocation: { ...order.deliveryLocation, city: value },
            })
          }
          disabled={order.deliveryLocation.object}
        />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Land</label>
        <Input
          get={order.deliveryLocation.country}
          set={(value) =>
            setOrder({
              ...order,
              deliveryLocation: { ...order.deliveryLocation, country: value },
            })
          }
          disabled={order.deliveryLocation.object}
        />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Betingelse</label>
        <Input
          get={order.deliveryLocation.termsOfDelivery}
          set={(value) =>
            setOrder({
              ...order,
              deliveryLocation: {
                ...order.deliveryLocation,
                termsOfDelivery: value,
              },
            })
          }
          disabled={order.deliveryLocation.object}
        />
      </div>
      <SelectCustomerDeliveryLocationModal
        show={showDeliveryLocationModal}
        onClose={handleDeliveryLocationModalClose}
        locations={order.customer.object?.deliveryLocations || []}
      />
    </>
  );
};

const ContactSection = ({ order, setOrder }) => {
  const [showSelectContactModal, setShowSelectContactModal] = useState(false);

  const handleClearContactClicked = () => {
    setOrder({
      ...order,
      contact: getContact(),
    });
  };

  const handleSelectContactModalClose = (contact) => {
    setShowSelectContactModal(false);
    if (!contact) return;

    setOrder({
      ...order,
      contact: getContact(contact),
    });
  };

  return (
    <>
      <div className="mt-3">
        <b>
          <u>Kontakt person</u>
        </b>{" "}
      </div>
      {order.customer.object && (
        <div className="mt-3">
          <div className="btn-group">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setShowSelectContactModal(true)}
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} /> (
              {order.customer.object.contacts.length})
            </button>
            {order.contact.object && (
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleClearContactClicked}
              >
                <FontAwesomeIcon icon={faRemove} />
              </button>
            )}
          </div>
        </div>
      )}
      <div className="form-group mt-3">
        <label className="form-label">Navn</label>
        <Input
          get={order.contact.name}
          set={(value) =>
            setOrder({
              ...order,
              contact: {
                ...order.contact,
                name: value,
              },
            })
          }
          disabled={order.contact.object}
        />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Telefon numre *</label>
        <Input
          get={order.contact.phone}
          set={(value) =>
            setOrder({
              ...order,
              contact: {
                ...order.contact,
                phone: value,
              },
            })
          }
          disabled={order.contact.object}
        />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Email</label>
        <Input
          get={order.contact.email}
          set={(value) =>
            setOrder({
              ...order,
              contact: {
                ...order.contact,
                email: value,
              },
            })
          }
          disabled={order.contact.object}
        />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Note</label>
        <Input
          get={order.contact.note}
          set={(value) =>
            setOrder({
              ...order,
              contact: {
                ...order.contact,
                note: value,
              },
            })
          }
          disabled={order.contact.object}
        />
      </div>
      {order.customer.object && (
        <SelectCustomerContactModal
          show={showSelectContactModal}
          onClose={handleSelectContactModalClose}
          contacts={order.customer.object.contacts}
        />
      )}
    </>
  );
};

const LinesSection = ({ order, setOrder }) => {
  const appStateCtx = useAppState();
  const exceptionCtx = useException();

  const [showLineModal, setShowLineModal] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [invoiceLines, setInvoiceLines] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [showProductModal, setShowProductModal] = useState(false);
  const [subProducts, setSubProducts] = useState([]);
  const [productQuery, setProductQuery] = useState("");
  const [selectProductType, setSelectProductType] = useState(1);

  const [oldLine, setOldLine] = useState();
  const [newLine, setNewLine] = useState();

  const refreshInvoices = async () => {
    if (order.customer.object) {
      const invoices =
        await appStateCtx.apiServerClient.employee.invoices.getByCustomerNumber(
          order.customer.object.customerNumber
        );

      invoices.sort((a, b) => b.date.localeCompare(a.date));

      const invoiceLines = [];

      invoices.forEach((invoice) => {
        invoice.lines.forEach((line) => {
          if (!line.product?.productNumber) return;

          let invoiceLine = invoiceLines.find(
            (l) => l.product?.productNumber === line.product?.productNumber
          );

          if (!invoiceLine) {
            invoiceLine = { ...line };
            invoiceLines.push(invoiceLine);
          } else {
            invoiceLine.quantity += line.quantity;
          }
        });
      });

      invoiceLines.sort((a, b) => a.description.localeCompare(b.description));

      setInvoices(invoices);
      setInvoiceLines(invoiceLines);
    } else {
      setInvoices([]);
      setInvoiceLines([]);
    }
  };

  const refresh = async () => {
    try {
      setIsLoading(true);

      const products =
        await appStateCtx.apiServerClient.employee.products.getAll();

      const subProducts = products
        .flatMap((product) =>
          product.subProducts.map((subProduct) => ({
            subProduct,
            product,
          }))
        )
        .sort((a, b) => a.subProduct.name.localeCompare(b.subProduct.name));

      setSubProducts(subProducts);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    } finally {
      setIsLoading(false);
    }
  };

  const calculateTotals = (line) => {
    line.totalVAT = getLineVAT(line);
    line.totalWithVAT = getLineWithVAT(line);
    line.totalWithoutVAT = getLineWithoutVAT(line);
  };

  const setNewLineAndCalculateTotals = (line) => {
    calculateTotals(line);
    setNewLine(line);
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    refreshInvoices();
    //eslint-disable-next-line
  }, [order.customer.object]);

  const handleDeleteLine2Clicked = (e, line) => {
    e.stopPropagation();
    setOrder({
      ...order,
      lines: order.lines.filter((l) => l !== line),
    });
  };

  const handleSubProductClicked = (subProduct) => {
    setOldLine();
    setNewLineAndCalculateTotals({ ...subProduct, amount: 1, discount: 0 });
    setShowProductModal(false);
    setShowLineModal(true);
  };

  const handleCancelLineClicked = () => {
    if (!oldLine) {
      setShowLineModal(false);
      setShowProductModal(true);
    } else {
      setShowLineModal(false);
    }
    setOldLine();
  };

  const handleOkLineClicked = () => {
    if (newLine.amount === 0) {
      // remove
      const lines2 = order.lines.filter((l) => l !== oldLine);

      setOrder({ ...order, lines: lines2 });
    } else {
      const lines2 = order.lines.filter((l) => l !== oldLine);

      lines2.push(newLine);

      setOrder({ ...order, lines: lines2 });
    }
    setOldLine();
    setShowLineModal(false);
  };

  const handleInvoiceLineClicked = (line) => {
    let subProduct = subProducts.find(
      (s) => s.subProduct.subProductNumber === line.product.productNumber
    );

    if (!subProduct) {
      alert("Produkt sælges ikke mere");
      return;
    }

    // TODO if name has changed, alert it
    setOldLine();
    setNewLineAndCalculateTotals({
      ...subProduct,
      amount: 1,
      discount: 0,
    });

    setShowProductModal(false);
    setShowLineModal(true);
  };

  const handleLineClicked = (line) => {
    setOldLine(line);
    setNewLine(JSON.parse(JSON.stringify(line)));
    setShowLineModal(true);
  };

  const handleNewLineClicked = () => {
    setShowProductModal(true);
  };

  const productQuery2 = productQuery.trim();

  const filteredLines = !!productQuery2
    ? subProducts.filter((s) =>
        stringUtil.includesCI(
          s.subProduct.subProductNumber +
            "|" +
            s.subProduct.name +
            "|" +
            s.tags,
          productQuery2
        )
      )
    : subProducts;

  const filteredInvoiceLines = !!productQuery2
    ? invoiceLines.filter((l) =>
        stringUtil.includesCI(
          (l.product?.productNumber || "") + "|" + l.description,
          productQuery2
        )
      )
    : invoiceLines;

  const filteredInvoices = !!productQuery2
    ? invoices.filter((i) =>
        i.lines.some((l) =>
          stringUtil.includesCI(
            (l.product?.productNumber || "") + "|" + l.description,
            productQuery2
          )
        )
      )
    : invoices;

  const isLineFormOk = () => {
    if (!newLine) return false;
    if (!/^\d+$/.test(newLine.amount)) return false;
    if (!/^\d+$/.test(newLine.discount)) return false;

    const d = parseInt(newLine.discount);
    if (d < 0 || d > 100) return false;

    return true;
  };

  return (
    <>
      <div className="mt-3"></div>
      <b>
        <u>Linjer *</u>
      </b>
      <div className="mt-3">
        <button className="btn btn-primary" onClick={handleNewLineClicked}>
          <FontAwesomeIcon icon={faPlus} />
          {isLoading && (
            <span>
              {" "}
              <Spinner animation="border" size="sm" />
            </span>
          )}
        </button>
      </div>
      <div className="mt-3">
        <TableControl
          columns={[
            {
              header: "Nummer",
              valueExtractor: (l) => l.subProduct.subProductNumber,
            },
            {
              header: "Beskrivelse",
              valueExtractor: (l) => l.subProduct.subProductNumber,
            },
            { header: "Enhed", valueExtractor: (l) => l.subProduct.name },
            {
              header: "Rabat",
              valueExtractor: (l) => `${l.discount}%`,
              className: "text-end",
            },
            {
              header: "Antal",
              valueExtractor: (l) => l.amount,
              className: "text-end",
            },
            {
              header: "Pris",
              valueExtractor: (l) => (
                <MoneyCellContent amount={l.subProduct.salesPrice || 0} />
              ),
              className: "text-end",
            },
            {
              header: "Total",
              valueExtractor: (l) => (
                <MoneyCellContent amount={l.totalWithoutVAT} />
              ),
              className: "text-end",
            },
            {
              header: "",
              valueExtractor: (l) => (
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  className="clickable ms-1"
                  color="red"
                  onClick={(e) => handleDeleteLine2Clicked(e, l)}
                />
              ),
              className: "text-end",
            },
          ]}
          onRowClicked={handleLineClicked}
          rows={order.lines}
          keyExtractor={(l, index) => index}
          footerColumns={[
            { colspan: 5, valueExtractor: (r) => "" },
            { valueExtractor: (r) => r.name, className: "text-end" },
            {
              valueExtractor: (r) => r.value.toFixed(2),
              className: "text-end",
            },
            { valueExtractor: (r) => "" },
          ]}
          footerRows={[
            { name: "Subtotal", value: order.totalWithoutVAT },
            { name: "Moms", value: order.totalVAT },
            { name: "Total", value: order.totalWithVAT },
          ]}
          footerKeyExtractor={(r) => r.name}
        />
      </div>
      <Modal show={showLineModal} onHide={() => handleCancelLineClicked()}>
        <Modal.Header closeButton>
          <Modal.Title>Linje</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {newLine && (
            <div>
              <div className="form-group">
                <label className="form-label mb-0">Nummer</label>
                <TextInput
                  value={newLine.subProduct.subProductNumber}
                  readonly
                />
              </div>
              <div className="form-group">
                <label className="form-label mb-0">Navn</label>
                <TextInput value={newLine.subProduct.name} readonly />
              </div>
              <div className="form-group">
                <label className="form-label mb-0">Enhed</label>
                <TextInput
                  value={newLine.subProduct.unit?.name || ""}
                  readonly
                />
              </div>
              <div className="form-group">
                <label className="form-label mb-0">Pris</label>
                <TextInput
                  value={(newLine.subProduct.salesPrice || 0).toFixed(2)}
                  readonly
                />
              </div>
              <div className="form-group">
                <label className="form-label mb-2">Antal</label>
                <TextInput
                  value={newLine.amount}
                  onChange={(value) =>
                    setNewLineAndCalculateTotals({ ...newLine, amount: value })
                  }
                />
              </div>

              <div className="form-group mt-2">
                <label className="form-label mb-2">Rabat</label>
                <div className="input-group">
                  <TextInput
                    value={newLine.discount}
                    onChange={(value) =>
                      setNewLineAndCalculateTotals({
                        ...newLine,
                        discount: value,
                      })
                    }
                  />
                  <span class="input-group-text">%</span>
                </div>
              </div>
              <div className="form-group mt-2">
                <label className="form-label mb-0">Total uden moms</label>
                <TextInput
                  value={newLine.totalWithoutVAT.toFixed(2)}
                  readonly
                />
              </div>
              <div className="form-group">
                <label className="form-label mb-0">Moms</label>
                <TextInput value={newLine.totalVAT.toFixed(2)} readonly />
              </div>
              <div className="form-group">
                <label className="form-label mb-0">Total</label>
                <TextInput value={newLine.totalWithVAT.toFixed(2)} readonly />
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={handleCancelLineClicked}
          >
            Fortryd
          </button>
          <button
            className="btn btn-primary"
            onClick={handleOkLineClicked}
            disabled={!isLineFormOk()}
          >
            Ok
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showProductModal}
        onHide={() => setShowProductModal(false)}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Vælg produkt</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="space-children">
            <button
              className={`btn btn-primary ${
                selectProductType === 1 ? "active" : ""
              }`}
              onClick={() => setSelectProductType(1)}
            >
              Produkter
            </button>
            <button
              className={`btn btn-primary ${
                selectProductType === 2 ? "active" : ""
              }`}
              onClick={() => setSelectProductType(2)}
            >
              Fakturaer ({invoices.length})
            </button>
            <button
              className={`btn btn-primary ${
                selectProductType === 3 ? "active" : ""
              }`}
              onClick={() => setSelectProductType(3)}
            >
              Faktura linjer ({invoiceLines.length})
            </button>
          </div>
          {selectProductType === 1 && (
            <div>
              <div className="mt-3">
                <SearchtextInput
                  name="productQuery"
                  value={productQuery}
                  onChange={setProductQuery}
                  placeholder="Søg - nummer, navn, tags"
                />
              </div>
              <div className="mt-3">
                <TableControl
                  columns={[
                    {
                      header: "Nummer",
                      valueExtractor: (l) => l.subProduct.subProductNumber,
                    },
                    {
                      header: "Nummer",
                      valueExtractor: (l) => l.subProduct.name,
                    },
                  ]}
                  rows={filteredLines}
                  keyExtractor={(l) => l.subProduct.subProductNumber}
                  onRowClicked={handleSubProductClicked}
                />
              </div>
            </div>
          )}
          {selectProductType === 2 && (
            <div>
              <div className="mt-3">
                <SearchtextInput
                  name="productQuery"
                  value={productQuery}
                  onChange={setProductQuery}
                  placeholder="Søg - nummer, beskrivelse"
                />
              </div>
              <div className="mt-3">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Nummer</th>
                      <th>Beskrivelse</th>
                      <th>Enhed</th>
                      <th className="text-end">Pris</th>
                      <th className="text-end">Antal</th>
                      <th className="text-end">Rabat</th>
                      <th className="text-end">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredInvoices.map((invoice) => {
                      const filteredLines = !!productQuery2
                        ? invoice.lines.filter((l) =>
                            stringUtil.includesCI(
                              (l.product?.productNumber || "") +
                                "|" +
                                l.description,
                              productQuery2
                            )
                          )
                        : invoice.lines;
                      return (
                        <>
                          <tr key={invoice._id}>
                            <th colSpan="7">{invoice.date}</th>
                          </tr>
                          {filteredLines.map((l, index) => (
                            <tr
                              key={invoice._id + "|" + index}
                              className="clickable"
                              onClick={() => handleInvoiceLineClicked(l)}
                            >
                              <td>{l.product?.productNumber}</td>
                              <td>{l.description}</td>
                              <td>{l.unit?.name}</td>
                              <td>
                                <MoneyCellContent amount={l.unitNetPrice} />
                              </td>
                              <td className="text-end">{l.quantity}</td>
                              <td className="text-end">
                                {l.discountPercentage}%
                              </td>
                              <td>
                                <MoneyCellContent amount={l.totalNetAmount} />
                              </td>
                            </tr>
                          ))}
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {selectProductType === 3 && (
            <div>
              <div className="mt-3">
                <SearchtextInput
                  name="productQuery"
                  value={productQuery}
                  onChange={setProductQuery}
                  placeholder="Søg - nummer, beskrivelse"
                />
              </div>

              <div className="mt-3">
                <TableControl
                  columns={[
                    {
                      header: "Nummer",
                      valueExtractor: (l) => l.product?.productNumber,
                    },
                    {
                      header: "Beskrivelse",
                      valueExtractor: (l) => l.product?.productNumber,
                    },
                    {
                      header: "Enhed",
                      valueExtractor: (l) => l.product?.productNumber,
                    },
                    {
                      header: "Antal",
                      valueExtractor: (l) => l.product?.productNumber,
                      className: "text-end",
                    },
                  ]}
                  rows={filteredInvoiceLines}
                  keyExtractor={(l, index) => index}
                  onRowClicked={handleInvoiceLineClicked}
                />
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

const NotesSection = ({ order, setOrder }) => {
  return (
    <>
      <div className="mt-3">
        <b>
          <u>Note</u>
        </b>
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Overskrift</label>
        <TextInput
          value={order.note.title}
          onChange={(value) =>
            setOrder({
              ...order,
              note: { ...order.note, title: value },
            })
          }
        />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Tekst 1</label>
        <TextareaInput
          rows="3"
          value={order.note.text1}
          onChange={(value) =>
            setOrder({
              ...order,
              note: { ...order.note, text1: value },
            })
          }
        />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Tekst 2</label>
        <TextareaInput
          rows="3"
          value={order.note.text2}
          onChange={(value) =>
            setOrder({
              ...order,
              note: { ...order.note, text2: value },
            })
          }
        />
      </div>
    </>
  );
};

const OrderPage = () => {
  const navigate = useNavigate();
  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [order, setOrder] = useState();

  const [showSavedModal, setShowSavedModal] = useState(false);
  const [savedOrders, setSavedOrders] = useState([]);

  const setOrderAndCalculateTotals = (order) => {
    order.totalWithoutVAT = 0;
    order.totalVAT = 0;
    order.totalWithVAT = 0;

    for (var line of order.lines) {
      line.totalWithoutVAT = getLineWithoutVAT(line);
      line.totalWithoutVAT = getLineWithoutVAT(line);
      line.totalVAT = getLineVAT(line);
      line.totalWithVAT = getLineWithVAT(line);

      order.totalWithoutVAT += line.totalWithoutVAT;
      order.totalVAT += line.totalVAT;
      order.totalWithVAT += line.totalWithVAT;
    }

    setOrder(order);
  };

  const refresh = () => {
    setOrder({
      employeeId: appStateCtx.user._id,
      type: "Salg",
      customer: getCustomer(),
      deliveryLocation: getDeliveryLocation(),
      contact: getContact(),
      note: {
        title: "",
        text1: "",
        text2: "",
      },
      lines: [],
      totalWithoutVAT: 0,
      totalVAT: 0,
      totalWithVAT: 0,
    });
  };

  const refreshSavedOrders = async () => {
    try {
      const orders =
        await appStateCtx.apiServerClient.employee.employeeOrders.getSaved();
      setSavedOrders(orders);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    refreshSavedOrders();
    //eslint-disable-next-line
  }, []);

  const handleDeleteSavedClicked = async (e, order) => {
    e.stopPropagation();
    try {
      await appStateCtx.apiServerClient.employee.employeeOrders.delete(
        order._id
      );
      messageCtx.showSuccess(`Gemt ordre (${order.type}) slettet`);
      setShowSavedModal(false);
      refreshSavedOrders();
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleLoadSavedOrderClicked = (order) => {
    setOrderAndCalculateTotals(order);
    setShowSavedModal(false);
  };

  const handleSendClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.employeeOrders.create(order);

      messageCtx.showSuccess(`Ordre (${order.type}) sendt`);

      navigate("./..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const isFormOk = () => {
    if (order.customer.name.value.trim().length === 0) return false;
    if (order.customer.address.value.trim().length === 0) return false;
    if (order.contact.phone.value.trim().length === 0) return false;
    if (order.lines.length === 0) return false;

    return true;
  };

  const isSaveFormOk = () => {
    if (order.customer.name.value.trim().length === 0) return false;
    return true;
  };

  const handleSaveClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.employeeOrders.save(order);
      messageCtx.showSuccess(`Ordre (${order.type}) gemt`);
      navigate("./..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleLoadClicked = () => {
    setShowSavedModal(true);
  };

  const handleRemoveLoadedClicked = () => {
    refresh();
  };

  if (!order) return null;

  return (
    <>
      <div className="container page">
        <h1>Ny ordre</h1>
        <div className="input-group mt-4">
          <button className="btn btn-primary" onClick={handleLoadClicked}>
            Hent gemt
          </button>
          {order.savedAt && (
            <button
              className="btn btn-secondary"
              onClick={handleRemoveLoadedClicked}
            >
              <FontAwesomeIcon icon={faRemove} />
            </button>
          )}
        </div>

        <TypeSection order={order} setOrder={setOrder} />
        <CustomerSection order={order} setOrder={setOrder} />
        <DeliveryLocationSection order={order} setOrder={setOrder} />
        <ContactSection order={order} setOrder={setOrder} />
        <LinesSection order={order} setOrder={setOrderAndCalculateTotals} />
        <NotesSection order={order} setOrder={setOrder} />

        <div className="mt-3 space-children">
          <button
            onClick={handleSendClicked}
            className="btn btn-primary"
            disabled={!isFormOk()}
          >
            Send
          </button>
          <button
            onClick={handleSaveClicked}
            className="btn btn-primary"
            disabled={!isSaveFormOk()}
          >
            Gem
          </button>
        </div>
      </div>

      <Modal
        show={showSavedModal}
        onHide={() => setShowSavedModal(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Hent gemt</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TableControl
            columns={[
              {
                header: "Dato",
                valueExtractor: (o) =>
                  DateTime.fromISO(o.createdAt).toFormat(
                    settings.dateShortFormat
                  ),
              },
              { header: "Type", valueExtractor: (o) => o.type },
              {
                header: "Kunde navn",
                valueExtractor: (o) => o.customer.name.value,
              },
              {
                header: "",
                valueExtractor: (o) => (
                  <FontAwesomeIcon
                    icon={faCircleXmark}
                    color="red"
                    onClick={(e) => handleDeleteSavedClicked(e, o)}
                  />
                ),
              },
            ]}
            rows={savedOrders}
            keyExtractor={(o) => o._id}
            onRowClicked={handleLoadSavedOrderClicked}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OrderPage;
