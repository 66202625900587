import dateUtil from "../../utils/date.util";

import { MoneyCellContent } from "./table.controls";
import TableControl from "./table.control";

const InvoiceControl = ({ invoice, showNote2 }) => {
  if (!invoice) return null;

  return (
    <div>
      <div className="form-group mt-3">
        <label className="form-label">Nummer</label>
        <div className="form-text">{invoice.bookedInvoiceNumber}</div>
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Dato</label>
        <div className="form-text">{dateUtil.isoDateToShort(invoice.date)}</div>
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Betalings dato</label>
        <div className="form-text">
          {dateUtil.isoDateToShort(invoice.dueDate)}
        </div>
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Linjer</label>

        <TableControl
          columns={[
            { header: "Beskrivelse", valueExtractor: (l) => l.description },
            { header: "Enhed", valueExtractor: (l) => l.unit?.name },
            {
              header: "Antal",
              valueExtractor: (l) => l.quantity,
              className: "text-end",
            },
            {
              header: "Pris",
              valueExtractor: (l) => (
                <MoneyCellContent amount={l.unitNetPrice} />
              ),
              className: "text-end",
            },
            {
              header: "Rabat",
              valueExtractor: (l) => `${l.discountPercentage}%`,
              className: "text-end",
            },
            {
              header: "Total",
              valueExtractor: (l) => (
                <MoneyCellContent amount={l.totalNetAmount} />
              ),
              className: "text-end",
            },
          ]}
          rows={invoice.lines}
          keyExtractor={(l, index) => index}
          footerColumns={[
            {
              valueExtractor: (r) => r.name,
              colspan: 5,
              className: "text-end",
            },
            {
              valueExtractor: (r) => <MoneyCellContent amount={r.value} />,
              className: "text-end",
            },
          ]}
          footerRows={[
            { name: "SubTotal", value: invoice.netAmount },
            { name: "Moms", value: invoice.vatAmount },
            { name: "Total", value: invoice.grossAmount },
          ]}
          footerKeyExtractor={(r) => r.name}
        />
      </div>
      {invoice.notes && (
        <div className="form-group mt-3">
          <label className="form-label">Noter</label>
          {invoice.notes.heading && (
            <div>
              <span>Titel: </span>
              {invoice.notes.heading}
            </div>
          )}
          {invoice.notes.textLine1 && (
            <div>
              <span>Note 1: </span>
              {invoice.notes.textLine1}
            </div>
          )}
          {invoice.notes.textLine2 && showNote2 && (
            <div>
              <span>Note 2: </span>
              {invoice.notes.textLine2}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default InvoiceControl;
