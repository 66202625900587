import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";

import productUtil from "../../../utils/product.util";

const VideoControl = ({ video }) => {
  const videoRef = useRef(null);
  const [playing, setPlaying] = useState(false);

  const toggleVideoState = () => {
    if (playing) {
      videoRef.current.pause();
      setPlaying(false);
    } else {
      videoRef.current.play();
      setPlaying(true);
    }
  };

  return (
    <div className="row align-items-center">
      <div className="col-12 video-block">
        <div className="video-wrapper text-center">
          <div className="position-relative">
            <video
              ref={videoRef}
              controls={false}
              src={video.file.url}
              poster={video.file.thumbUrl}
              style={{
                height: 400,
                width: "auto",
                maxWidth: "100%",
              }}
              loop
            ></video>
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
              }}
              onClick={toggleVideoState}
            >
              {!playing && (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    width: 100,
                    height: 100,
                    backgroundColor: "#e43f3f",
                    color: "white",
                    borderRadius: 100,
                  }}
                >
                  <FontAwesomeIcon icon={faPlay} size="3x" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="text-wrapper text-center mt-3">
          <h5 className="mb-2 display-5 display-bold">{video.title}</h5>
          <div>{video.text}</div>
          {video.product && (
            <Link
              to={productUtil.getAbsoluteUrl({
                productGroup: { name: video.product.productGroup.name },
                name: video.product.name,
              })}
              className="btn btn-secondary item-btn display-7 display-bold mt-3"
            >
              Gå til produkt
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoControl;
