import React from "react";
import { DateTime } from "luxon";

import settings from "../../utils/settings";

const OrdersTable = ({ orders, onOrderClicked }) => {
  return (
    <table className="table table-striped table-clickable">
      <thead>
        <tr>
          <th>Dato</th>
          <th>Type</th>
          <th>Kunde</th>
          <th className="text-end">Total</th>
        </tr>
      </thead>
      <tbody>
        {orders.map((o) => {
          if (o.type) {
            // employee
            return (
              <tr
                key={o._id}
                onClick={() => onOrderClicked(o)}
                className={o.processedAt ? "strike-through" : ""}
              >
                <td>
                  {DateTime.fromISO(o.createdAt).toFormat(
                    settings.dateShortFormat
                  )}
                </td>
                <td>{o.type}</td>
                <td>{o.customer?.name?.value}</td>
                <td className="text-end">{o.totalWithVAT.toFixed(2)}</td>
              </tr>
            );
          } else {
            // customer

            return (
              <tr
                key={o._id}
                onClick={() => onOrderClicked(o)}
                className={o.processedAt ? "strike-through" : ""}
              >
                <td>
                  {DateTime.fromISO(o.createdAt).toFormat(
                    settings.dateShortFormat
                  )}
                </td>
                <td>Kunde</td>
                <td>{o.customer.name}</td>
                <td className="text-end">{o.totalWithVAT.toFixed(2)}</td>
              </tr>
            );
          }
        })}
      </tbody>
    </table>
  );
};

export default OrdersTable;
