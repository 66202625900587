import React, { useState, useEffect } from "react";
import { DateTime } from "luxon";

import { useNavigate, useParams } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import settings from "../../../../utils/settings";
import productUtil from "../../../../utils/product.util";

import { useException } from "../../../contexts/exception.context";
import { useAppState } from "../../../contexts/appState.context";
import OptionsControl from "../../../controls/options.control";
import TableControl from "../../../controls/table.control";

import getMenuItems from "./productMenuItems.data";

const ProductReviewsPage = () => {
  const navigate = useNavigate();
  const params = useParams();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();

  const [product, setProduct] = useState();
  const [reviews, setReviews] = useState([]);

  const isReadonly = !appStateCtx.user.permissionIds.includes(
    "admin.products.reviews.edit"
  );

  const refresh = async () => {
    try {
      const product =
        await appStateCtx.apiServerClient.employee.products.getByName(
          params.productGroupName,
          params.productName
        );
      const reviews =
        await appStateCtx.apiServerClient.employee.productReviews.getByProductId(
          product._id
        );

      setProduct(product);
      setReviews(reviews);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  const handleCreateClicked = () => {
    navigate(productUtil.getAdminAbsoluteUrl(product, "anmeldelser/ny"));
  };

  const onReviewClicked = (review) => {
    navigate(
      productUtil.getAdminAbsoluteUrl(product, `anmeldelser/${review._id}`)
    );
  };

  if (!product) return null;

  const menuItems = getMenuItems(appStateCtx.user, product, "Anmeldelser");

  return (
    <div className="container page">
      <h1>{params.productName}: anmeldelser</h1>
      <div className="space-children mt-4">
        {!isReadonly && (
          <button className="btn btn-primary" onClick={handleCreateClicked}>
            <FontAwesomeIcon icon={faPlus} />
          </button>
        )}
        <OptionsControl items={menuItems} />
      </div>

      <div className="mt-3">
        <TableControl
          columns={[
            {
              header: "Dato",
              valueExtractor: (r) =>
                DateTime.fromISO(r.createdAt).toFormat(
                  settings.dateShortFormat
                ),
            },
            {
              header: "Titel",
              valueExtractor: (r) => r.title,
            },
          ]}
          keyExtractor={(r) => r._id}
          rows={reviews}
          onRowClicked={onReviewClicked}
        />
      </div>
    </div>
  );
};

export default ProductReviewsPage;
