import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faRemove } from "@fortawesome/free-solid-svg-icons";

import searchFilterUtil from "../../../../utils/searchFilter.util";
import reportsUtil from "../../../../utils/reports.util";
import stringUtil from "../../../../utils/string.util";

import { useException } from "../../../contexts/exception.context";
import { useAppState } from "../../../contexts/appState.context";
import TextInput from "../../../controls/text.input";
import TableControl from "../../../controls/table.control";

const defaultFilter = { query: "", show: true };

const CustomersPage = () => {
  const navigate = useNavigate();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();

  const [isLoading, setIsLoading] = useState(true);

  const [customers, setCustomers] = useState([]);

  const [filter, setFilter] = useState(defaultFilter);

  const queryRef = useRef();

  const refresh = async () => {
    try {
      setIsLoading(true);

      const filter =
        searchFilterUtil.load("customers", appStateCtx.user._id) ||
        defaultFilter;

      setFilter(filter);

      const customers =
        await appStateCtx.apiServerClient.employee.customers.getAll();

      customers.sort((a, b) => a.name.localeCompare(b.name));

      setCustomers(customers);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    } finally {
      setIsLoading(false);
    }
  };

  const saveAndSetFilter = (filter) => {
    searchFilterUtil.save("customers", appStateCtx.user._id, filter);

    setFilter(filter);
  };

  useEffect(() => {
    refresh();
    queryRef.current.focus();
    //eslint-disable-next-line
  }, []);

  const onCustomerClicked = (customer) => {
    navigate(`./${customer.customerNumber}`);
  };

  let filteredCustomers = customers;
  const query2 = filter.query.trim();
  if (query2) {
    filteredCustomers = filteredCustomers.filter((c) =>
      stringUtil.includesCI(c.name, query2)
    );
  }

  const handleDownloadXLSXClicked = async () => {
    const columns = [
      {
        header: "Gruppe",
        dataKey: "customer.customerGroup.customerGroupNumber",
        style: {
          halign: "left",
          width: 50,
        },
      },
      {
        header: "Nummer",
        dataKey: "customer.customerNumber",
        style: {
          halign: "left",
          width: 50,
        },
      },
      {
        header: "Navn",
        dataKey: "customer.name",
        style: {
          halign: "left",
          width: 50,
        },
      },
      {
        header: "Adresse",
        dataKey: "customer.address",
        style: {
          halign: "left",
          width: 50,
        },
      },
      {
        header: "Tlfs",
        dataKey: "customer.telephoneAndFaxNumber",
        style: {
          halign: "left",
          width: 50,
        },
      },
      {
        header: "PostNr",
        dataKey: "customer.zip",
        style: {
          halign: "left",
          width: 50,
        },
      },
      {
        header: "By",
        dataKey: "customer.city",
        style: {
          halign: "left",
          width: 50,
        },
      },
      {
        header: "Land",
        dataKey: "customer.country",
        style: {
          halign: "left",
          width: 50,
        },
      },
      {
        header: "Kontakt person nummer",
        dataKey: "contact.customerContactNumber",
        style: {
          halign: "left",
          width: 50,
        },
      },
      {
        header: "Kontakt person navn",
        dataKey: "contact.name",
        style: {
          halign: "left",
          width: 50,
        },
      },
      {
        header: "Kontakt person email",
        dataKey: "contact.email",
        style: {
          halign: "left",
          width: 50,
        },
      },
      {
        header: "Kontakt person tlfs",
        dataKey: "contact.phone",
        style: {
          halign: "left",
          width: 50,
        },
      },
      {
        header: "Kontakt person modtag fokus mail",
        dataKey: "contact.receiveEmails",
        style: {
          halign: "left",
          width: 50,
        },
      },
      {
        header: "Kontakt person er hoved person",
        dataKey: "isAttention",
        style: {
          halign: "left",
          width: 50,
        },
      },
    ];

    const { headerColumnsDef, bodyColumnsDef } =
      reportsUtil.getColumnsDefsFromColumns(columns);

    const headerRow = reportsUtil.getHeaderRowFromColumns(columns);

    const headerRows = [reportsUtil.createRow(headerRow, "header")];

    // flatten contacts
    const contacts = [];
    for (var customer of filteredCustomers) {
      if (customer.contacts.length > 0) {
        for (var contact of customer.contacts) {
          const contact2 = {
            customer,
            contact,
            isAttention:
              customer.attention?.customerContactNumber ===
              contact.customerContactNumber
                ? "Ja"
                : "",
          };

          contact2.contact.receiveEmails = contact2.contact.receiveEmails
            ? "Ja"
            : "";

          contacts.push(contact2);
        }
      } else {
        const contact2 = {
          customer,
          contact: {},
          isAttention: "",
        };

        contacts.push(contact2);
      }
    }

    const bodyRows = contacts.map((c) => reportsUtil.createRow(c, "body"));

    const report = {
      name: "Kunder",
      documents: [
        {
          type: "table",
          name: "Kunder",
          headerRows,
          bodyRows,
          footerRows: [],
          columnsDefs: {
            body: bodyColumnsDef,
            header: headerColumnsDef,
          },
        },
      ],
    };

    reportsUtil.generateXLSX(report);
  };

  return (
    <div className="container page">
      <h1>Kunder</h1>
      <div className="d-flex justify-content-between mt-4">
        {filteredCustomers.length > 0 ? (
          <button
            className="btn btn-primary"
            onClick={handleDownloadXLSXClicked}
          >
            XLSX
          </button>
        ) : (
          <div></div>
        )}
        <div className="btn-group">
          <button
            className="btn btn-primary"
            onClick={() => saveAndSetFilter({ ...filter, show: !filter.show })}
          >
            <FontAwesomeIcon icon={faFilter} />
          </button>
          {!searchFilterUtil.areFiltersEqual(defaultFilter, filter) && (
            <button
              className="btn btn-secondary"
              onClick={() => saveAndSetFilter({ ...defaultFilter })}
            >
              <FontAwesomeIcon icon={faRemove} />
            </button>
          )}
        </div>
      </div>
      <div style={{ display: filter.show ? "initial" : "none" }}>
        <div className="mt-3">
          <TextInput
            ref={queryRef}
            value={filter.query}
            placeholder="Søg - navn"
            onChange={(value) => saveAndSetFilter({ ...filter, query: value })}
          />
        </div>
      </div>
      {isLoading && <Spinner animation="border" className="mt-3" />}
      {!isLoading && (
        <div className="mt-3">
          <TableControl
            columns={[
              { header: "Navn", type: "string", valueExtractor: (c) => c.name },
            ]}
            keyExtractor={(c) => c._id}
            rows={filteredCustomers}
            onRowClicked={onCustomerClicked}
          />
        </div>
      )}
    </div>
  );
};
export default CustomersPage;
