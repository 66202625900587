import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import dateUtil from "../../../utils/date.util";

import { useException } from "../../contexts/exception.context";
import { useAppState } from "../../contexts/appState.context";
import MetadataControl from "../../controls/metadata.control";

const AnnouncementPage = () => {
  const params = useParams();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();

  const [announcement, setAnnouncement] = useState();

  const refresh = async () => {
    try {
      const announcement =
        await appStateCtx.apiServerClient.visitor.announcements.getById(
          params.announcementId
        );
      setAnnouncement(announcement);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  if (!announcement) return null;

  return (
    <div className="container page">
      <MetadataControl
        title={`Capp2 - ${announcement.title}`}
        description={announcement.title}
      />
      <div className="row">
        <div className="col-3">
          <img
            src={announcement.banner.url}
            style={{ width: "100%" }}
            alt="Nyhed"
          />
        </div>
        <div className="col-9">
          <h3 className="display-bold">{announcement.title}</h3>
          <div className="pb-2 display-9">
            {dateUtil.isoDateToShort(announcement.createdAt)} af Capp2
          </div>
          <div dangerouslySetInnerHTML={{ __html: announcement.text }}></div>
        </div>
      </div>
    </div>
  );
};

export default AnnouncementPage;
