import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import settings from "../../../../utils/settings";
import stringUtil from "../../../../utils/string.util";

import { useException } from "../../../contexts/exception.context";
import { useAppState } from "../../../contexts/appState.context";
import OptionsControl from "../../../controls/options.control";
import TableControl from "../../../controls/table.control";

import getMenuItems from "./customerMenuItems.data";

const CustomerNotesPage = () => {
  const navigate = useNavigate();
  const params = useParams();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();

  const [notes, setNotes] = useState([]);
  const [customer, setCustomer] = useState();

  const isReadonly = !appStateCtx.user.permissionIds.includes(
    "admin.customers.notes.edit"
  );

  const refresh = async () => {
    try {
      const customer =
        await appStateCtx.apiServerClient.employee.customers.getByNumber(
          params.customerNumber
        );

      const notes =
        await appStateCtx.apiServerClient.employee.customerNotes.getByCustomerId(
          customer._id
        );

      notes.sort((a, b) => b.createdAt.localeCompare(a.createdAt));

      setCustomer(customer);
      setNotes(notes);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleNoteClicked = (note) => {
    navigate(`./${note._id}`);
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line
  }, []);

  if (!customer) return;

  const menuItems = getMenuItems(appStateCtx.user, customer, "Noter");

  return (
    <div className="container page">
      <h1>Kunde noter</h1>
      <div className="space-children mt-4">
        {!isReadonly && (
          <Link to="ny" className="btn btn-primary">
            <FontAwesomeIcon icon={faPlus} />
          </Link>
        )}
        <OptionsControl items={menuItems} />
      </div>
      <div className="mt-3">
        <TableControl
          columns={[
            {
              header: "Dato",
              valueExtractor: (n) =>
                DateTime.fromISO(n.createdAt).toFormat(
                  settings.dateShortFormat
                ),
            },
            {
              header: "Note",
              valueExtractor: (n) => (
                <div
                  dangerouslySetInnerHTML={{
                    __html: stringUtil.textToHtml(n.text),
                  }}
                ></div>
              ),
            },
          ]}
          rowClassNameExtractor={(n) =>
            n.finished ? "text-decoration-line-through" : ""
          }
          rows={notes}
          keyExtractor={(n) => n._id}
          onRowClicked={handleNoteClicked}
        />
      </div>
    </div>
  );
};
export default CustomerNotesPage;
