import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const OptionsControl = ({ items }) => {
  const navigate = useNavigate();

  return (
    <Dropdown style={{ display: "inline" }}>
      <Dropdown.Toggle variant="primary">
        <FontAwesomeIcon icon={faEllipsisV} />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {items.map((i) => (
          <Dropdown.Item
            onClick={() => {
              navigate(i.url);
            }}
            key={i.url}
            className={i.selected ? "active" : ""}
          >
            {i.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default OptionsControl;
