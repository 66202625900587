import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faRemove } from "@fortawesome/free-solid-svg-icons";

import searchFilterUtil from "../../../../../utils/searchFilter.util";

import { useAppState } from "../../../../contexts/appState.context";
import { useException } from "../../../../contexts/exception.context";
import OrdersTable from "../../../../controls/orders.table";
import SelectInput from "../../../../controls/select.input";

//import Table from "../../../table";

const defaultFilter = { type: "Åbne", show: false };

// const columns = [
//   { header: "Test1", dataKey: "test1", type: "number" },
//   { header: "Test2", dataKey: "test2", type: "number" },
// ];

// const filterAndSort = (rows, filter, sorting) => {
//   const filteredRows = [...rows];
//   if (sorting.column.type === "number") {
//   }
//   const multiplier = sorting.ascending ? 1 : -1;
//   filteredRows.sort(
//     (a, b) =>
//       (a[sorting.column.dataKey] - b[sorting.column.dataKey]) * multiplier
//   );

//   return filteredRows;
// };

const OrdersPage = () => {
  const appStateCtx = useAppState();
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [filter, setFilter] = useState(defaultFilter);

  const exceptionCtx = useException();

  const saveAndSetFilter = (filter) => {
    searchFilterUtil.save("orders", appStateCtx.user._id, filter);

    setFilter(filter);
  };

  const refresh = async () => {
    try {
      const filter =
        searchFilterUtil.load("orders", appStateCtx.user._id) || defaultFilter;

      setFilter(filter);

      const employeeOrdersPromise =
        appStateCtx.apiServerClient.employee.employeeOrders.getByProcessed(
          filter.type !== "Åbne"
        );

      const customerOrdersPromise =
        appStateCtx.apiServerClient.employee.customerOrders.getByProcessed(
          filter.type !== "Åbne"
        );

      const [employeeOrders, customerOrders] = await Promise.all([
        employeeOrdersPromise,
        customerOrdersPromise,
      ]);

      const orders = employeeOrders.concat(customerOrders);

      orders.sort((a, b) => b.createdAt.localeCompare(a.createdAt));

      setOrders(orders);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, [filter.type]);

  // const rows = [
  //   { test1: "1", test2: "2" },
  //   { test1: "2", test2: "1" },
  // ];

  // const [sorting, setSorting] = useState({
  //   column: columns[0],
  //   ascending: true,
  // });

  // const onColumnClicked = (column) => {
  //   if (sorting.column === column) {
  //     setSorting({ column, ascending: !sorting.ascending });
  //   } else {
  //     setSorting({ column, ascending: true });
  //   }
  // };

  // const onRowClicked = (row) => {
  //   alert(row);
  // };

  // const filteredAndSortedRows = filterAndSort(rows, undefined, sorting);

  return (
    <div className="container page order">
      <h1>Ordrer</h1>
      <div className="d-flex justify-content-end mt-3">
        <div className="btn-group">
          <button
            className="btn btn-primary"
            onClick={() => saveAndSetFilter({ ...filter, show: !filter.show })}
          >
            <FontAwesomeIcon icon={faFilter} />
          </button>
          {!searchFilterUtil.areFiltersEqual(defaultFilter, filter) && (
            <button
              className="btn btn-secondary"
              onClick={() => saveAndSetFilter({ ...defaultFilter })}
            >
              <FontAwesomeIcon icon={faRemove} />
            </button>
          )}
        </div>
      </div>
      <div style={{ display: filter.show ? "initial" : "none" }}>
        <div className="form-group mt-3">
          <label className="form-label">Type</label>
          <div>
            <SelectInput
              items={["Åbne", "Lukkede (30 dage)"]}
              valueExtractor={(t) => t}
              nameExtractor={(t) => t}
              value={filter.type}
              onChange={(value) => saveAndSetFilter({ ...filter, type: value })}
            />
          </div>
        </div>
      </div>
      <div className="mt-3">
        <OrdersTable
          orders={orders}
          onOrderClicked={(order) => {
            if (order.type) {
              navigate(`./medarbejder/${order._id}`);
            } else {
              navigate(`./kunde/${order._id}`);
            }
          }}
        />
      </div>
    </div>
  );
};

export default OrdersPage;
