import stringUtil from "../../../utils/string.util";
import dateUtil from "../../../utils/date.util";

const getInitials = (text) => {
  text = text.replaceAll("  ", "");
  const data = text.split(" ");
  if (data.length === 1) return data[0][0];
  if (data.length > 1)
    return `${data[0][0].toUpperCase()}${data[1][0].toUpperCase()}`;
  if (data.length === 0) return "";
};

export default ({ reference }) => {
  return (
    <div className="col-12 col-lg-6">
      {reference._id && (
        <div className="card backgroundcolor-gray p-4 h-100 d-flex flex-column border-radius-10">
          <>
            <h3 className="display-bold">{reference.title}</h3>
            <p
              className="mb-4"
              dangerouslySetInnerHTML={{
                __html: stringUtil.textToHtml(reference.text),
              }}
            ></p>
            <div className="d-flex mb-md-0 mb-4 flex-grow-1 align-items-end">
              <div className="d-flex align-items-center">
                <div
                  className="image-wrapper d-flex align-items-center justify-content-center"
                  style={{
                    backgroundColor: "#e43f3f",
                    borderRadius: 60,
                    width: 60,
                    height: 60,
                    color: "white",
                    fontSize: 30,
                  }}
                >
                  {getInitials(reference.from)}
                </div>
                <div className="text-wrapper ms-2">
                  <div className="display-bold">{reference.from}</div>
                  <div className="display-9 mt-1">
                    {dateUtil.isoDateToShort(reference.createdAt)} af{" "}
                    {reference.type}
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      )}
    </div>
  );
};
