const getDataKey = (name, userId) => {
  return `data.${userId}.${name}`;
};

const util = {
  loadData: (name, userId) => {
    try {
      const json = localStorage.getItem(getDataKey(name, userId));
      if (!json) throw Error("Data not found");
      const data = JSON.parse(json);
      return data;
    } catch (ex) {
      return null;
    }
  },
  saveData: (name, userId, data) => {
    const json = JSON.stringify(data);
    localStorage.setItem(getDataKey(name, userId), json);
  },
  set: (name, value) => {
    localStorage.setItem(name, value);
  },
  get: (name) => {
    return localStorage.getItem(name);
  },
};

export default util;
