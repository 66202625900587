import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useAppState } from "../../../../contexts/appState.context";
import { useException } from "../../../../contexts/exception.context";
import { useMessage } from "../../../../contexts/message.context";
import TextInput from "../../../../controls/text.input";

const SubscriberPage = () => {
  const navigate = useNavigate();
  const params = useParams();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [subscriber, setSubscriber] = useState();

  const isReadonly = !appStateCtx.user.permissionIds.includes(
    "admin.newsletter.edit"
  );

  const refresh = async () => {
    try {
      const subscriber =
        await appStateCtx.apiServerClient.employee.newsletterSubscribers.getById(
          params.subscriberId
        );

      setSubscriber(subscriber);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  if (!subscriber) return null;

  const handleDeleteClicked = async (p) => {
    try {
      await appStateCtx.apiServerClient.employee.newsletterSubscribers.delete(
        params.subscriberId
      );
      navigate("./..");
      messageCtx.showSuccess("Nyhedsbrev abonnement slettet");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  return (
    <div className="container page">
      <h1>Nyhedsbrev abonnement</h1>

      <div className="form-group mt-3">
        <label className="form-label">Firmanavn</label>
        <div>
          <TextInput value={subscriber.companyName} readonly />
        </div>
      </div>

      <div className="form-group mt-3">
        <label className="form-label">Navn</label>
        <div>
          <TextInput value={subscriber.name} readonly />
        </div>
      </div>

      <div className="form-group mt-3">
        <label className="form-label">Email</label>
        <div>
          <TextInput value={subscriber.email} readonly />
        </div>
      </div>

      <div className="form-group mt-3">
        <label className="form-label">Telefon</label>
        <div>
          <TextInput value={subscriber.phone} readonly />
        </div>
      </div>

      {!isReadonly && (
        <div className="space-children mt-3">
          <button className="btn btn-secondary" onClick={handleDeleteClicked}>
            Slet
          </button>
        </div>
      )}
    </div>
  );
};

export default SubscriberPage;
