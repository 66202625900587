import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { useException } from "../../../../contexts/exception.context";
import { useAppState } from "../../../../contexts/appState.context";
import TableControl from "../../../../controls/table.control";

const BannersPage = () => {
  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const navigate = useNavigate();
  const [banners, setBanners] = useState([]);

  const isReadonly =
    !appStateCtx.user.permissionIds.includes("admin.banners.edit");

  const refresh = async () => {
    try {
      const banners =
        await appStateCtx.apiServerClient.employee.banners.getAll();
      setBanners(banners);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  const handleNewClicked = () => {
    navigate("./ny");
  };

  const handleBannerClicked = (banner) => {
    navigate(`./${banner._id}`);
  };

  return (
    <div className="container page">
      <h1>Banners</h1>
      {!isReadonly && (
        <div className="mt-4">
          <button className="btn btn-primary" onClick={handleNewClicked}>
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
      )}
      <div className="mt-3">
        <TableControl
          columns={[
            { header: "Navn", valueExtractor: (b) => b.name },
            {
              header: "Vis",
              valueExtractor: (b) => (b.enabled ? "Ja" : "Nej"),
            },
          ]}
          rows={banners}
          keyExtractor={(b) => b._id}
          onRowClicked={handleBannerClicked}
        />
      </div>
    </div>
  );
};
export default BannersPage;
