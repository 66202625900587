import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { faEllipsisH, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useException } from "../../../../contexts/exception.context";
import { useMessage } from "../../../../contexts/message.context";
import { useAppState } from "../../../../contexts/appState.context";
import TextInput from "../../../../controls/text.input";
import FileInput from "../../../../controls/file.input";
import SelectInput from "../../../../controls/select.input";
import SelectProductModal from "../../../../controls/selectproduct.modal";

const VideoPage = () => {
  const navigate = useNavigate();
  const params = useParams();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [products, setProducts] = useState([]);
  const [showSelectProductModal, setShowSelectProductModal] = useState(false);

  const [oldVideo, setOldVideo] = useState();
  const [newVideo, setNewVideo] = useState();

  const isReadonly =
    !appStateCtx.user.permissionIds.includes("admin.videos.edit");

  const refresh = async () => {
    try {
      const products =
        await appStateCtx.apiServerClient.employee.products.getAll();
      setProducts(products);

      if (params.videoId) {
        const video = await appStateCtx.apiServerClient.employee.videos.getById(
          params.videoId
        );

        setOldVideo(video);
        setNewVideo(JSON.parse(JSON.stringify(video)));
      } else {
        setNewVideo({
          title: "",
          text: "",
          file: undefined,
          enabled: false,
          product: undefined,
        });
      }
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, [params.videoId]);

  const hasFormChanged = () => {
    return JSON.stringify(oldVideo) !== JSON.stringify(newVideo);
  };

  const isFormOk = () => {
    if (!newVideo.title) return false;
    if (!newVideo.text) return false;
    if (!newVideo.file) return false;

    return true;
  };

  const handleSelectProductModalClose = (product) => {
    setShowSelectProductModal(false);
    if (!product) return;
    setNewVideo({ ...newVideo, product });
    setShowSelectProductModal(false);
  };

  const handleCreateClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.videos.insert(
        newVideo.title,
        newVideo.text,
        newVideo.file,
        newVideo.product?._id,
        newVideo.enabled
      );
      messageCtx.showSuccess("Video gemt");
      navigate("./..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleUpdateClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.videos.update(
        newVideo._id,
        newVideo.title,
        newVideo.text,
        newVideo.file,
        newVideo.product?._id,
        newVideo.enabled
      );
      messageCtx.showSuccess("Video opdateret");
      navigate("./..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleDeleteClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.videos.delete(params.videoId);
      messageCtx.showSuccess("Video slettet");
      navigate("./..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleSelectProductClicked = () => {
    setShowSelectProductModal(true);
  };

  const handleDeleteProductClicked = () => {
    setNewVideo({ ...newVideo, product: undefined });
  };

  if (!newVideo) return null;
  console.log("video", newVideo);
  return (
    <div className="container page">
      <h1>Video</h1>
      <div className="form-group mt-3">
        <label className="form-label">Titel</label>
        <TextInput
          value={newVideo.title}
          readonly={isReadonly}
          onChange={(value) => setNewVideo({ ...newVideo, title: value })}
        />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Text</label>
        <TextInput
          value={newVideo.text}
          readonly={isReadonly}
          onChange={(value) => setNewVideo({ ...newVideo, text: value })}
        />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Video</label>
        <FileInput
          value={newVideo.file}
          onChange={(value) => setNewVideo({ ...newVideo, file: value })}
          readonly={isReadonly}
          accept=".mp4"
          allowEmpty={false}
          maxSize={1024 * 1024 * 50}
          showSeeImageButton
        />
      </div>

      <div className="form-group mt-3">
        <label className="form-label">Produkt</label>

        <div className="input-group">
          <TextInput
            value={
              newVideo.product
                ? `${newVideo.product.productGroup.name} > ${newVideo.product.name}`
                : ""
            }
            disabled
            readonly={isReadonly}
          />
          {!isReadonly && (
            <button
              className="btn btn-primary py-0"
              onClick={handleSelectProductClicked}
            >
              <FontAwesomeIcon icon={faEllipsisH} />
            </button>
          )}
          {!isReadonly && newVideo.product && (
            <button
              className="btn btn-secondary py-0"
              onClick={handleDeleteProductClicked}
            >
              <FontAwesomeIcon icon={faXmark} />
            </button>
          )}
        </div>
      </div>

      <div className="form-group mt-3">
        <label className="form-label">Vis</label>
        <SelectInput
          items={["Ja", "Nej"]}
          valueExtractor={(i) => i}
          nameExtractor={(i) => i}
          value={newVideo.enabled ? "Ja" : "Nej"}
          readonly={isReadonly}
          onChange={(value) =>
            setNewVideo({ ...newVideo, enabled: value === "Ja" })
          }
        />
      </div>

      {!isReadonly && (
        <div className="space-children mt-3">
          {!params.videoId && (
            <button
              className="btn btn-primary"
              onClick={handleCreateClicked}
              disabled={!isFormOk()}
            >
              Gem
            </button>
          )}
          {params.videoId && (
            <button
              className="btn btn-primary"
              onClick={handleUpdateClicked}
              disabled={!isFormOk() || !hasFormChanged()}
            >
              Opdater
            </button>
          )}
          {params.videoId && (
            <button className="btn btn-secondary" onClick={handleDeleteClicked}>
              Slet
            </button>
          )}
        </div>
      )}

      <SelectProductModal
        show={showSelectProductModal}
        onClose={handleSelectProductModalClose}
        products={products}
      />
    </div>
  );
};

export default VideoPage;
