import React, { useState, useEffect, Fragment, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // a plugin!
import daLocale from "@fullcalendar/core/locales/da";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faPlus, faRemove } from "@fortawesome/free-solid-svg-icons";

import settings from "../../../../../utils/settings";
import stringUtil from "../../../../../utils/string.util";
import searchFilterUtil from "../../../../../utils/searchFilter.util";

import { useException } from "../../../../contexts/exception.context";
import { useAppState } from "../../../../contexts/appState.context";
import MonthInput from "../../../../controls/month.input";
import TextInput from "../../../../controls/text.input";
import SelectInput from "../../../../controls/select.input";
import CheckboxesInput from "../../../../controls/checkboxes.input";

const CalenderEventsPage = () => {
  const getDefaultFilter = (types, user) => {
    return {
      show: false,
      calenders: [user._id.toString()],
      types: types.map((t) => t.id.toString()),
      query: "",
      mode: "calender",
      month: DateTime.now().toISODate().substring(0, 7),
    };
  };

  const navigate = useNavigate();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();

  const [events, setEvents] = useState([]);
  const [calenders, setCalenders] = useState([]);
  const [types, setTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState(getDefaultFilter([], appStateCtx.user));

  const calenderRef = useRef();

  const saveAndSetFilter = (filter) => {
    searchFilterUtil.save("calenderEvents", appStateCtx.user._id, filter);

    setFilter(filter);
  };

  const refresh = async () => {
    try {
      const types =
        await appStateCtx.apiServerClient.employee.calenderEvents.getTypes();

      const filter =
        searchFilterUtil.load("calenderEvents", appStateCtx.user._id) ||
        getDefaultFilter(types, appStateCtx.user);

      setFilter(filter);

      const events =
        await appStateCtx.apiServerClient.employee.calenderEvents.getByMonth(
          filter.month
        );

      const calenders =
        await appStateCtx.apiServerClient.employee.calenderEvents.getCalenders();

      setTypes(types);
      setCalenders(calenders);
      setEvents(events);
      setIsLoading(false);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const refreshEvents = async () => {
    try {
      const events =
        await appStateCtx.apiServerClient.employee.calenderEvents.getByMonth(
          filter.month
        );

      setEvents(events);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refreshEvents();
    if (calenderRef.current)
      calenderRef.current.getApi().gotoDate(filter.month + "-01");
    //eslint-disable-next-line
  }, [filter.month]);

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  const handleCreateClicked = () => {
    navigate("./ny");
  };

  const query2 = filter.query.trim();

  let filteredEvents = events.filter(
    (e) =>
      filter.calenders.includes(e.employeeId.toString()) &&
      filter.types.includes(e.type.id)
  );

  if (query2) {
    filteredEvents = filteredEvents.filter((e) =>
      stringUtil.includesCI(
        (e.header || "") +
          "|" +
          (e.place || "") +
          "|" +
          (e.note || "") +
          "|" +
          (e.customer?.name || ""),
        query2
      )
    );
  }

  const handleEventClicked2 = (info) => {
    navigate(`./${info.event.extendedProps._id}`);
  };

  const handleEventClicked = (event) => {
    navigate(`./${event._id}`);
  };

  const toggleSelectedType = (id, checked) => {
    if (checked) {
      saveAndSetFilter({ ...filter, types: [...filter.types, id] });
    } else {
      saveAndSetFilter({
        ...filter,
        types: filter.types.filter((t) => t !== id),
      });
    }
  };

  const toggleSelectedCalenders = (id, checked) => {
    if (checked) {
      saveAndSetFilter({ ...filter, calenders: [...filter.calenders, id] });
    } else {
      saveAndSetFilter({
        ...filter,
        calenders: filter.calenders.filter((c) => c !== id),
      });
    }
  };

  if (isLoading) return null;

  const renderList = () => {
    const getDateTimeInterval = (date, startTime, endTime) => {
      if (date && startTime && endTime) {
        return `${DateTime.fromISO(date).toFormat(
          settings.dateShortFormat
        )} ${startTime}-${endTime}`;
      } else if (date && startTime) {
        return `${DateTime.fromISO(date).toFormat(
          settings.dateShortFormat
        )} ${startTime}`;
      } else if (date) {
        return DateTime.fromISO(date).toFormat(settings.dateShortFormat);
      }
      return "";
    };

    const getDateTime = (date, time) => {
      if (date && time) {
        return `${DateTime.fromISO(date).toFormat(
          settings.dateShortFormat
        )} ${time}`;
      } else if (date) {
        return DateTime.fromISO(date).toFormat(settings.dateShortFormat);
      }
      return "";
    };

    const monthStartDate = DateTime.fromISO(filter.month + "-01");
    const monthEndDate = DateTime.fromISO(filter.month + "-01").plus({
      month: 1,
    });

    const weeks = {};

    const todayDate = DateTime.now().startOf("day");

    for (var filteredEvent of filteredEvents) {
      const eventStartDate = DateTime.fromISO(filteredEvent.startDate);

      if (filteredEvent.endDate) {
        const eventEndDate = DateTime.fromISO(filteredEvent.endDate);

        // event same date
        if (eventStartDate === eventEndDate) {
          // singleday
          const dateStr = getDateTimeInterval(
            filteredEvent.startDate,
            filteredEvent.startTime,
            filteredEvent.endTime
          );

          if (!weeks[eventStartDate.weekNumber]) {
            weeks[eventStartDate.weekNumber] = {
              number: eventStartDate.weekNumber,
              events: [],
            };
          }

          weeks[eventStartDate.weekNumber].events.push({
            ...filteredEvent,
            isToday: todayDate === eventStartDate,
            date: dateStr,
            backgroundColor: filteredEvent.type.color,
            weekNumber: eventStartDate.weekNumber,
            title: (filteredEvent.important ? "* " : "") + filteredEvent.header,
          });
        } else {
          // multidays
          let currentDate = eventStartDate;
          let dayNumber = 1;

          const diff = eventEndDate.diff(eventStartDate, ["days"]);
          const totalDays = Math.round(diff.days) + 1;

          while (currentDate <= eventEndDate) {
            if (currentDate >= monthStartDate && currentDate < monthEndDate) {
              let dateStr =
                eventStartDate === currentDate
                  ? getDateTime(
                      filteredEvent.startDate,
                      filteredEvent.startTime
                    )
                  : currentDate.toISODate();

              dateStr += ` (Dag ${dayNumber}/${totalDays})`;

              if (!weeks[currentDate.weekNumber]) {
                weeks[currentDate.weekNumber] = {
                  number: currentDate.weekNumber,
                  events: [],
                };
              }

              weeks[currentDate.weekNumber].events.push({
                ...filteredEvent,
                isToday: todayDate === currentDate,
                date: dateStr,
                backgroundColor: filteredEvent.type.color,
                weekNumber: currentDate.weekNumber,
                title:
                  (filteredEvent.important ? "* " : "") + filteredEvent.header,
              });
            }

            dayNumber++;
            currentDate = currentDate.plus({ day: 1 });
          }
        }
      } else {
        const dateStr = getDateTime(
          filteredEvent.startDate,
          filteredEvent.startTime
        );

        if (!weeks[eventStartDate.weekNumber]) {
          weeks[eventStartDate.weekNumber] = {
            number: eventStartDate.weekNumber,
            events: [],
          };
        }

        weeks[eventStartDate.weekNumber].events.push({
          ...filteredEvent,
          isToday: todayDate === filteredEvent.startDate,
          date: dateStr,
          backgroundColor: filteredEvent.type.color,
          weekNumber: eventStartDate.weekNumber,
          title: (filteredEvent.important ? "* " : "") + filteredEvent.header,
        });
      }
    }

    const weekNumbers = Object.keys(weeks).sort((a, b) => a - b);

    return (
      <div className="mt-3">
        <table className="table table-striped table-clickable">
          <thead>
            <tr>
              <th>Dato</th>
              <th>Type</th>
              <th>Overskrift</th>
            </tr>
          </thead>
          <tbody>
            {weekNumbers.map((weekNumber) => {
              const week = weeks[weekNumber];
              return (
                <Fragment key={week.number}>
                  <tr>
                    <th colSpan={4}>Uge {week.number}</th>
                  </tr>
                  {week.events.map((e) => {
                    return (
                      <tr
                        key={e._id}
                        onClick={() => handleEventClicked(e)}
                        style={{
                          textDecoration: e.finished ? "line-through" : "none",
                        }}
                      >
                        <td style={{ whiteSpace: "nowrap" }}>
                          {e.isToday && (
                            <span
                              style={{
                                width: "8px",
                                height: "8px",
                                borderRadius: "50%",
                                marginRight: "4px",
                                backgroundColor: "green",
                                display: "inline-block",
                              }}
                            ></span>
                          )}
                          {e.date}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          <span
                            style={{
                              width: "8px",
                              height: "8px",
                              borderRadius: "50%",
                              marginRight: "4px",
                              backgroundColor: e.backgroundColor,
                              display: "inline-block",
                            }}
                          ></span>
                          <span>{e.type.name}</span>
                        </td>
                        <td>{e.title}</td>
                      </tr>
                    );
                  })}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const renderCalender = () => {
    const handleDayClicked = (info) => {
      if (info.jsEvent.target.className !== "fc-daygrid-day-number") return;

      navigate("./ny", {
        //replace: true,
        state: {
          startDate: info.dateStr,
        },
      });
    };

    const calenderEvents = filteredEvents.map((e) => {
      const start = e.startTime
        ? `${e.startDate}T${e.startTime}:00`
        : e.startDate;
      const end = e.endDate
        ? e.endTime
          ? `${e.endDate}T${e.endTime}`
          : DateTime.fromISO(e.endDate).plus({ days: 1 }).toISODate()
        : undefined;

      const classNames = ["clickable"];

      if (e.finished) {
        classNames.push("strikethrough");
      }

      return {
        _id: e._id,
        title: (e.important ? "* " : "") + e.header,
        start,
        important: e.important,
        finished: e.finished,
        end,
        type: e.type,
        note: e.note,
        backgroundColor: e.type.color,
        classNames,
      };
    });

    return (
      <div className="mt-3">
        <FullCalendar
          locale={daLocale}
          ref={calenderRef}
          headerToolbar={false}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          firstDay={1}
          weekNumbers
          contentHeight={"auto"}
          events={calenderEvents}
          dateClick={handleDayClicked}
          eventClick={handleEventClicked2}
        />
      </div>
    );
  };

  return (
    <div className="container page">
      <h1>Kalender</h1>
      <div className="d-flex justify-content-between mt-4">
        <button className="btn btn-primary" onClick={handleCreateClicked}>
          <FontAwesomeIcon icon={faPlus} />
        </button>

        <div style={{ fontSize: 24, fontWeight: "bold", fontStyle: "italic" }}>
          {stringUtil.capitalizeFirstLetter(
            DateTime.fromISO(filter.month).toFormat(settings.monthLongFormat, {
              locale: "da",
            })
          )}
        </div>

        <div className="space-children">
          <div className="btn-group">
            <button
              className="btn btn-primary"
              onClick={() =>
                saveAndSetFilter({ ...filter, show: !filter.show })
              }
            >
              <FontAwesomeIcon icon={faFilter} />
            </button>
            {!searchFilterUtil.areFiltersEqual(
              getDefaultFilter(types, appStateCtx.user),
              filter
            ) && (
              <button
                className="btn btn-secondary"
                onClick={() =>
                  saveAndSetFilter({
                    ...getDefaultFilter(types, appStateCtx.user),
                  })
                }
              >
                <FontAwesomeIcon icon={faRemove} />
              </button>
            )}
          </div>
        </div>
      </div>
      <div style={{ display: filter.show ? "initial" : "none" }}>
        <div className="form-group mt-3">
          <label className="form-label">Måned</label>
          <MonthInput
            value={filter.month}
            onChange={(value) =>
              saveAndSetFilter({
                ...filter,
                month: value || DateTime.now().toISODate().substring(0, 7),
              })
            }
            required
          />
        </div>
        <div className="form-group mt-3">
          <label className="form-label">Vis</label>
          <SelectInput
            items={[
              { name: "Kalender", id: "calender" },
              { name: "Liste", id: "list" },
            ]}
            nameExtractor={(i) => i.name}
            valueExtractor={(i) => i.id}
            value={filter.mode}
            onChange={(value) => saveAndSetFilter({ ...filter, mode: value })}
          />
        </div>
        <div className="form-group mt-3">
          <label className="form-label">Typer</label>
          <CheckboxesInput
            items={types}
            nameExtractor={(t) => t.name}
            valueExtractor={(t) => t.id.toString()}
            values={filter.types}
            onChange={(values) =>
              saveAndSetFilter({ ...filter, types: values })
            }
          />
        </div>
        <div className="form-group mt-3">
          <label className="form-label">Kalendere</label>
          <CheckboxesInput
            items={calenders}
            nameExtractor={(c) => c.employee.name}
            valueExtractor={(c) => c.employee._id.toString()}
            values={filter.calenders}
            onChange={(values) =>
              saveAndSetFilter({ ...filter, calenders: values })
            }
          />
        </div>
        <div className="form-group mt-3">
          <label className="form-label">Fri søgning</label>
          <TextInput
            value={filter.query}
            onChange={(value) => saveAndSetFilter({ ...filter, query: value })}
          />
        </div>
      </div>
      {filter.mode === "calender" && renderCalender()}
      {filter.mode === "list" && renderList()}
    </div>
  );
};
export default CalenderEventsPage;
