import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useException } from "../../../contexts/exception.context";
import { useAppState } from "../../../contexts/appState.context";
import OptionsControl from "../../../controls/options.control";
import TableControl from "../../../controls/table.control";

import getMenuItems from "./customerMenuItems.data";

const CustomerInvoiceLinesPage = () => {
  const params = useParams();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();

  const [customer, setCustomer] = useState();
  const [invoiceLines, setInvoiceLines] = useState([]);

  const refresh = async () => {
    try {
      const customer =
        await appStateCtx.apiServerClient.employee.customers.getByNumber(
          params.customerNumber
        );

      const invoices =
        appStateCtx.user.type === "Employee"
          ? await appStateCtx.apiServerClient.employee.invoices.getByCustomerNumber(
              params.customerNumber
            )
          : await appStateCtx.apiServerClient.customer.invoices.getAll();

      const invoiceLines = [];

      invoices.forEach((invoice) => {
        invoice.lines.forEach((line) => {
          if (!line.product?.productNumber) return;

          let invoiceLine = invoiceLines.find(
            (l) => l.product?.productNumber === line.product?.productNumber
          );

          if (!invoiceLine) {
            // invoiceLine = { ...line };
            // invoiceLines.push(invoiceLine);
            invoiceLines.push(line);
          } else {
            invoiceLine.quantity += line.quantity;
          }
        });
      });

      invoiceLines.sort((a, b) => a.description.localeCompare(b.description));

      setCustomer(customer);
      setInvoiceLines(invoiceLines);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  if (!customer) return;

  const menuItems = getMenuItems(appStateCtx.user, customer, "Faktura linjer");

  return (
    <div className="container page">
      <h1>Kunde faktura linjer</h1>

      <div className="space-children mt-4">
        <OptionsControl items={menuItems} />
      </div>

      <div className="mt-3">
        <TableControl
          columns={[
            {
              header: "Nummer",
              valueExtractor: (l) => l.product?.productNumber,
            },
            {
              header: "Beskrivelse",
              valueExtractor: (l) => l.description,
            },
            {
              header: "Enhed",
              valueExtractor: (l) => l.unit?.name,
            },
            {
              header: "Enhed",
              valueExtractor: (l) => l.quantity,
              className: "text-end",
            },
          ]}
          keyExtractor={(l) => l.product.productNumber}
          rows={invoiceLines}
        />
      </div>
    </div>
  );
};
export default CustomerInvoiceLinesPage;
