import React, { createContext, useContext, useState } from "react";
import { faCircleXmark, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-bootstrap";

import { useMessage } from "./message.context";
import { useAppState } from "./appState.context";
import { useException } from "./exception.context";
import { useSettings } from "./settings.context";
import TextareaInput from "../controls/textarea.input";
import TextInput from "../controls/text.input";
import TableControl from "../controls/table.control";

const CartContext = createContext(null);

export const useCart = () => {
  return useContext(CartContext);
};

export const CartProvider = ({ children }) => {
  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const messageCtx = useMessage();
  const settingsCtx = useSettings();

  const [showCartModal, setShowCartModal] = useState(false);
  const [showAmountModal, setShowAmountModal] = useState(false);
  const [lines, setLines] = useState([]);
  const [remarks, setRemarks] = useState("");
  const [amount, setAmount] = useState("0");
  const [line, setLine] = useState();
  const [requisitionNumber, setRequisitionNumber] = useState("");

  const addToCart = (subProduct, product) => {
    const line = lines.find(
      (l) => l.subProduct.subProductNumber === subProduct.subProductNumber
    );

    if (!line) {
      setLines([...lines, { subProduct, product, amount: 1 }]);
    } else {
      addToLine(subProduct, 1);
    }
  };

  const removeFromCart = (subProduct) => {
    setLines(
      lines.filter(
        (l) => l.subProduct.subProductNumber !== subProduct.subProductNumber
      )
    );
  };

  const addToLine = (subProduct, amount) => {
    const line = lines.find(
      (l) => l.subProduct.subProductNumber === subProduct.subProductNumber
    );

    if (line) {
      line.amount += amount;
      setLines([...lines]);
    }
  };

  const removeFromLine = (subProduct, amount) => {
    const line = lines.find(
      (l) => l.subProduct.subProductNumber === subProduct.subProductNumber
    );

    if (line) {
      line.amount -= amount;
      setLines([...lines]);
      if (line.amount <= 0) {
        removeFromCart(line.subProduct);
      }
    }
  };

  const changeLine = (subProduct, amount) => {
    const line = lines.find(
      (l) => l.subProduct.subProductNumber === subProduct.subProductNumber
    );

    if (line) {
      line.amount = amount;
      setLines([...lines]);
      if (line.amount <= 0) {
        removeFromCart(line.subProduct);
      }
    }
  };

  const clearCart = () => {
    setLines([]);
    setRemarks("");
    setRequisitionNumber("");
  };

  const cart = {
    lines,
    addToCart,
    removeFromCart,
    addToLine,
    removeFromLine,
    clearCart,
  };

  const totalWithoutVAT = lines.reduce(
    (a, b) => a + (b.subProduct.salesPrice || 0) * b.amount,
    0
  );

  const totalVAT = totalWithoutVAT * 0.25;
  const totalWithVAT = totalWithoutVAT + totalVAT;

  const handleCheckoutClicked = async () => {
    try {
      const order = {
        lines,
        remarks,
        requisitionNumber,
        totalWithoutVAT,
        totalVAT,
        totalWithVAT,
      };
      await appStateCtx.apiServerClient.customer.customerOrders.create(order);

      clearCart();
      messageCtx.showSuccess(
        "Bestilling sendt! Capp2 vil i de kommende dage kontakte dig med yderligere information."
      );
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleEditAmountClicked = (line) => {
    setLine(line);
    if (line.amount === 0) {
      removeFromLine(line.subProduct, line.amount);
    }
    setAmount(line.amount.toString());
    setShowAmountModal(true);
  };

  const handleSaveLineAmountClicked = () => {
    changeLine(line.subProduct, parseInt(amount));
    setShowAmountModal(false);
  };

  const isAmountFormOk = () => {
    if (!/^\d+$/.test(amount)) return false;
    return true;
  };

  const getColumns = () => {
    if (settingsCtx.settings?.cartState === "Ja - Med priser") {
      return [
        {
          header: "Nummer",
          type: "string",
          valueExtractor: (l) => l.subProduct.subProductNumber,
        },
        {
          header: "Navn",
          type: "string",
          valueExtractor: (l) => l.subProduct.name,
        },
        {
          header: "Enhed",
          type: "string",
          valueExtractor: (l) => l.subProduct.unit?.name,
        },
        {
          header: "Vejl. enhedspris",
          type: "string",
          valueExtractor: (l) => (l.subProduct.salesPrice || 0).toFixed(2),
          className: "text-end",
        },
        {
          header: "Antal",
          type: "string",
          valueExtractor: (l) => (
            <>
              {l.amount}
              <FontAwesomeIcon
                icon={faEdit}
                className="clickable ms-1"
                onClick={() => handleEditAmountClicked(l)}
              />
              <FontAwesomeIcon
                icon={faCircleXmark}
                className="clickable ms-1"
                color="red"
                onClick={() => removeFromCart(l.subProduct)}
              />
            </>
          ),
          className: "text-end",
        },
        {
          header: "Total",
          type: "string",
          valueExtractor: (l) =>
            ((l.subProduct.salesPrice || 0) * l.amount).toFixed(2),
          className: "text-end",
        },
      ];
    } else {
      return [
        {
          header: "Nummer",
          type: "string",
          valueExtractor: (l) => l.subProduct.subProductNumber,
        },
        {
          header: "Navn",
          type: "string",
          valueExtractor: (l) => l.subProduct.name,
        },
        {
          header: "Enhed",
          type: "string",
          valueExtractor: (l) => l.subProduct.unit?.name,
        },
        {
          header: "Antal",
          type: "string",
          valueExtractor: (l) => (
            <>
              {l.amount}
              <FontAwesomeIcon
                icon={faEdit}
                className="clickable ms-1"
                onClick={() => handleEditAmountClicked(l)}
              />
              <FontAwesomeIcon
                icon={faCircleXmark}
                className="clickable ms-1"
                color="red"
                onClick={() => removeFromCart(l.subProduct)}
              />
            </>
          ),
          className: "text-end",
        },
      ];
    }
  };

  const getFooterColumns = () => {
    if (settingsCtx.settings?.cartState === "Ja - Med priser") {
      return [
        {
          colspan: 5,
          valueExtractor: (r) => r.name,
          className: "text-end",
        },
        {
          valueExtractor: (r) => r.value.toFixed(2),
          className: "text-end",
        },
      ];
    } else {
      return [];
    }
  };

  return (
    <CartContext.Provider value={{ cart, show: setShowCartModal }}>
      {children}

      <Modal
        show={showCartModal}
        onHide={() => setShowCartModal(false)}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Kurv</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TableControl
            columns={getColumns()}
            rows={lines}
            footerRows={[
              { name: "SubTotal", value: totalWithoutVAT },
              { name: "Moms", value: totalVAT },
              { name: "Total", value: totalWithVAT },
            ]}
            footerColumns={getFooterColumns()}
            footerKeyExtractor={(r) => r.name}
            keyExtractor={(l) => l.subProduct.subProductNumber}
          />

          {settingsCtx.settings?.cartState === "Ja - Med priser" && (
            <div>
              Alle priser er ekskl. moms. Ved samlet køb på mere end kr. 2.500,-
              ekskl. moms, leveres fragtfrit!
            </div>
          )}
          {settingsCtx.settings?.cartState === "Ja - Uden priser" && (
            <div>
              Ved samlet køb på mere end kr. 2.500,- ekskl. moms, leveres
              fragtfrit!
            </div>
          )}
          <div className="form-group mt-3">
            <label className="form-label">Bemærkninger</label>
            <TextareaInput value={remarks} onChange={setRemarks} rows={7} />
          </div>
          <div className="form-group mt-3">
            <label className="form-label">Rekvisitions nr.</label>
            <TextInput
              className="form-control"
              value={requisitionNumber}
              onChange={setRequisitionNumber}
            />
          </div>

          <div className="text-end mt-3">
            <button
              className="btn btn-secondary"
              onClick={() => clearCart()}
              disabled={lines.length === 0}
            >
              Tøm kurv
            </button>
            <button
              className="btn btn-success ms-2"
              onClick={handleCheckoutClicked}
              disabled={lines.length === 0}
            >
              Send bestilling
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showAmountModal} onHide={() => setShowAmountModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Antal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextInput value={amount} onChange={setAmount} />
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => setShowAmountModal(false)}
          >
            Fortryd
          </button>
          <button
            className="btn btn-primary"
            disabled={!isAmountFormOk()}
            onClick={handleSaveLineAmountClicked}
          >
            Gem
          </button>
        </Modal.Footer>
      </Modal>
    </CartContext.Provider>
  );
};
