import localStorageUtil from "./localStorage.util";

const util = {
  save: (name, userId, data) => {
    localStorageUtil.saveData("search." + name, userId, data);
  },
  load: (name, userId) => {
    try {
      const params = localStorageUtil.loadData("search." + name, userId);
      console.log("load", params);
      return params;
    } catch (ex) {
      return undefined;
    }
  },
  areFiltersEqual: (filter1, filter2, skipParameters = ["show"]) => {
    // we remove show when comparing, since it is not really part of the filter
    const filter1Altered = { ...filter1 };
    const filter2Altered = { ...filter2 };
    for (var parameter of skipParameters) {
      filter1Altered[parameter] = undefined;
      filter2Altered[parameter] = undefined;
    }
    return JSON.stringify(filter1Altered) === JSON.stringify(filter2Altered);
  },
};

export default util;
