import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { DateTime } from "luxon";

import settings from "../../../../utils/settings";

import { useException } from "../../../contexts/exception.context";
import { useAppState } from "../../../contexts/appState.context";
import OptionsControl from "../../../controls/options.control";
import TableControl from "../../../controls/table.control";

import getMenuItems from "./productMenuItems.data";

const ProductCustomersPage = () => {
  const params = useParams();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();

  const [product, setProduct] = useState();
  const [customers, setCustomers] = useState([]);

  const refresh = async () => {
    try {
      const customers =
        await appStateCtx.apiServerClient.employee.customers.getByProductName(
          params.productGroupName,
          params.productName
        );

      const product =
        await appStateCtx.apiServerClient.employee.products.getByName(
          params.productGroupName,
          params.productName
        );
      setProduct(product);
      setCustomers(customers);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line
  }, []);

  if (!product) return null;

  const menuItems = getMenuItems(appStateCtx.user, product, "Kunder");

  return (
    <div className="container page">
      <h1>{params.productName}: kunder</h1>

      <div className="mt-4">
        <OptionsControl items={menuItems} />
      </div>
      <div className="mt-3">
        <TableControl
          columns={[
            {
              header: "Sidst købt",
              valueExtractor: (c) =>
                DateTime.fromISO(c.lastDate).toFormat(settings.dateShortFormat),
            },
            {
              header: "Kunde",
              valueExtractor: (c) => c.name,
            },
          ]}
          rows={customers}
          keyExtractor={(c) => c._id}
        />
      </div>
    </div>
  );
};
export default ProductCustomersPage;
