import Modal from "react-bootstrap/Modal";

const isPdf = (fileName) => fileName && fileName.toLowerCase().endsWith(".pdf");
const isVideo = (fileName) =>
  fileName && fileName.toLowerCase().endsWith(".mp4");
const isImage = (fileName) =>
  fileName &&
  (fileName.toLowerCase().endsWith(".png") ||
    fileName.toLowerCase().endsWith(".jpg") ||
    fileName.toLowerCase().endsWith(".jpeg"));

export default ({ show, setShow, images, index = 0 }) => {
  const getFileViewer = () => {
    if (show && images.length && images[index]) {
      const file = images[index];

      //return <iframe className="w-100 h-100" src={file.url}></iframe>;

      if (isPdf(file.name)) {
        return <iframe className="w-100 h-100" src={file.url}></iframe>;
      } else if (isImage(file.name)) {
        return (
          <img
            src={file.url}
            alt={file.name}
            className="w-100 h-100"
            style={{ objectFit: "contain" }}
          />
        );
      } else if (isVideo(file.name)) {
        return (
          <video
            src={file.url}
            poster={file.thumbUrl}
            alt={file.name}
            controls
            className="w-100 h-100"
            style={{ objectFit: "contain" }}
          />
        );
      }
    }

    return null;
  };
  return (
    <Modal fullscreen show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Se</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflow: "hidden" }}>{getFileViewer()}</Modal.Body>
    </Modal>
  );
};
