import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { useNavigate, useParams } from "react-router-dom";

import settings from "../../../../../utils/settings";

import { useAppState } from "../../../../contexts/appState.context";
import { useException } from "../../../../contexts/exception.context";
import { useMessage } from "../../../../contexts/message.context";
import TableControl from "../../../../controls/table.control";
import SelectInput from "../../../../controls/select.input";
import HtmlInput from "../../../../controls/html.input";
import TextInput from "../../../../controls/text.input";

const JobPage = () => {
  const navigate = useNavigate();
  const params = useParams();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [oldJob, setOldJob] = useState();
  const [newJob, setNewJob] = useState();

  const refresh = async () => {
    try {
      const job = await appStateCtx.apiServerClient.employee.jobs.getById(
        params.jobId
      );
      job.results.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
      setOldJob(job);
      setNewJob({ ...job });
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleRunNowClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.jobs.runNowById(oldJob._id);

      messageCtx.showSuccess("Job vil blive kørt hurtigst muligt");

      await refresh();
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  const getResultIconColor = (result) => {
    if (result.result === "Fejl") return "red";
    else if (result.result === "Success") return "green";
    else return "white";
  };

  const getJobIconColor = (job) => {
    if (job.results.length === 0) return "gray";

    const lastResult = job.results[0];
    if (lastResult.result === "Fejl") return "red";
    else if (lastResult.result === "Success") return "green";
    else return "transparent";
  };

  const getStatus = (job) => {
    if (!job.enabled) return "Deaktiveret";
    else {
      if (job.processAt) {
        return `Næste kørsel ${DateTime.fromISO(job.processAt).toFormat(
          settings.dateTimeShortFormat
        )}`;
      } else {
        return "Færdig";
      }
    }
  };

  const getResultIcon = (result) => {
    return (
      <span
        style={{
          width: 10,
          display: "inline-block",
          height: 10,
          marginRight: 10,
          backgroundColor: getResultIconColor(result),
          borderRadius: 10,
        }}
      ></span>
    );
  };

  const getJobIcon = (job) => {
    const backgroundColor = getJobIconColor(job);

    if (backgroundColor === "transparent") return null;

    return (
      <span
        style={{
          width: 10,
          display: "inline-block",
          height: 10,
          marginRight: 10,
          backgroundColor,
          borderRadius: 10,
        }}
      ></span>
    );
  };

  const handleUpdateClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.jobs.update(
        newJob._id,
        newJob.enabled
      );
      messageCtx.showSuccess("Job opdateret");
      navigate("./..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const hasFormChanged = () => {
    return JSON.stringify(oldJob) !== JSON.stringify(newJob);
  };

  if (!oldJob) return null;

  return (
    <div className="container page">
      <h1>Job</h1>
      <div className="form-group mt-3">
        <label className="form-label">Navn</label>
        <TextInput
          value={
            <span>
              {getJobIcon(newJob)} {newJob.name}
            </span>
          }
          readonly={true}
        />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Beskrivelse</label>
        <TextInput value={newJob.description} readonly={true} />
      </div>

      <div className="form-group mt-3">
        <label className="form-label">Type</label>
        <TextInput
          value={newJob.runAt ? "Service" : "En gangs"}
          readonly={true}
        />
      </div>

      <div className="form-group mt-3">
        <label className="form-label">Slået til</label>
        <SelectInput
          items={["Ja", "Nej"]}
          valueExtractor={(i) => i}
          nameExtractor={(i) => i}
          value={newJob.enabled ? "Ja" : "Nej"}
          onChange={(value) =>
            setNewJob({ ...newJob, enabled: value === "Ja" })
          }
        />
      </div>

      <div className="form-group mt-3">
        <label className="form-label">Status</label>
        <TextInput value={getStatus(oldJob)} readonly={true} />
      </div>

      {oldJob.results.length > 0 && (
        <div className="form-group mt-3">
          <label className="form-label">Resultater</label>
          <TableControl
            columns={[
              {
                header: "Dato",
                valueExtractor: (r) => (
                  <>
                    {getResultIcon(r)}
                    {DateTime.fromISO(r.createdAt).toFormat(
                      settings.dateTimeShortFormat
                    )}
                  </>
                ),
              },
              { header: "Resultat", valueExtractor: (r) => r.note },
            ]}
            rows={newJob.results}
            keyExtractor={(r) => r}
          />
        </div>
      )}
      {oldJob.processAt && (
        <div className="mt-3 space-children">
          <button
            className="btn btn-primary"
            onClick={handleUpdateClicked}
            disabled={!hasFormChanged()}
          >
            Opdater
          </button>
          <button className="btn btn-primary" onClick={handleRunNowClicked}>
            Kør nu
          </button>
        </div>
      )}
    </div>
  );
};

export default JobPage;
