import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faRemove } from "@fortawesome/free-solid-svg-icons";

import searchFilterUtil from "../../../../../utils/searchFilter.util";
import stringUtil from "../../../../../utils/string.util";

import { useAppState } from "../../../../contexts/appState.context";
import { useException } from "../../../../contexts/exception.context";
import TableControl from "../../../../controls/table.control";
import TextInput from "../../../../controls/text.input";

const defaultFilter = {
  query: "",
  show: false,
};

export default () => {
  const navigate = useNavigate();

  const appStateCtx = useAppState();
  const expceptionCtx = useException();

  const [templates, setTemplates] = useState([]);
  const queryRef = useRef();
  const [filter, setFilter] = useState(defaultFilter);

  const refresh = async () => {
    try {
      const filter =
        searchFilterUtil.load("emailskabeloner", appStateCtx.user._id) ||
        defaultFilter;

      setFilter(filter);

      const templates =
        await appStateCtx.apiServerClient.employee.emailTemplates.getAll();
      templates.sort((a, b) => a.type.localeCompare(b.type));
      setTemplates(templates);
    } catch (ex) {
      expceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    queryRef.current.focus();
  }, []);

  const saveAndSetFilter = (filter) => {
    searchFilterUtil.save("emailskabeloner", appStateCtx.user._id, filter);

    setFilter(filter);
  };

  const handleEmailClicked = (email) => {
    navigate(`./${email._id}`);
  };

  const query2 = filter.query.trim();

  const filteredTemplates =
    query2.length > 0
      ? templates.filter((t) =>
          stringUtil.includesCI(t.type + "|" + t.to, query2)
        )
      : templates;

  return (
    <div className="container page">
      <h1>Email skabeloner</h1>

      <div className="d-flex justify-content-end mt-4">
        <div className="btn-group">
          <button
            className="btn btn-primary"
            onClick={() => saveAndSetFilter({ ...filter, show: !filter.show })}
          >
            <FontAwesomeIcon icon={faFilter} />
          </button>
          {!searchFilterUtil.areFiltersEqual(defaultFilter, filter) && (
            <button
              className="btn btn-secondary"
              onClick={() => saveAndSetFilter({ ...defaultFilter })}
            >
              <FontAwesomeIcon icon={faRemove} />
            </button>
          )}
        </div>
      </div>
      <div style={{ display: filter.show ? "initial" : "none" }}>
        <div className="mt-3">
          <TextInput
            ref={queryRef}
            value={filter.query}
            name="productQuery"
            placeholder="Søg - type, modtager"
            onChange={(value) => saveAndSetFilter({ ...filter, query: value })}
          />
        </div>
      </div>
      <div className="mt-3">
        <TableControl
          columns={[
            { header: "Type", valueExtractor: (e) => e.type },
            { header: "Modtager", valueExtractor: (e) => e.to },
            {
              header: "Slået til",
              valueExtractor: (e) => (e.enabled ? "Ja" : "Nej"),
            },
          ]}
          rows={filteredTemplates}
          keyExtractor={(e) => e._id}
          onRowClicked={handleEmailClicked}
        />
      </div>
    </div>
  );
};
