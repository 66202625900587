import React, { createContext, useContext } from "react";

import { useMessage } from "./message.context";
import { useAppState } from "./appState.context";

import errorUtil from "../../utils/error.util";
import errorbaseUtil from "../../utils/errorbase.util";

const ExceptionContext = createContext(null);

export const useException = () => {
  return useContext(ExceptionContext);
};

export const messageStates = {
  success: 1,
  error: 2,
  info: 3,
  warning: 4,
};

export const ExceptionProvider = ({ children }) => {
  const messageCtx = useMessage();
  const appStateCtx = useAppState();

  const handleException = (ex) => {
    errorbaseUtil.insertException(ex);

    if (errorUtil.isServerError(ex)) {
      if (ex.message === "Bruger ikke logget ind") {
        // force goto login page
        appStateCtx.signout();
      }

      messageCtx.showError(ex.message);
    } else {
      messageCtx.showError(ex.message);
    }
  };

  return (
    <ExceptionContext.Provider value={{ handleException }}>
      {children}
    </ExceptionContext.Provider>
  );
};
