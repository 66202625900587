import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DateTime } from "luxon";

import reportsUtil from "../../../../utils/reports.util";
import dateUtil from "../../../../utils/date.util";

import { useException } from "../../../contexts/exception.context";
import { useAppState } from "../../../contexts/appState.context";
import { useMessage } from "../../../contexts/message.context";
import OptionsControl from "../../../controls/options.control";
import MonthInput from "../../../controls/month.input";
import DateInput from "../../../controls/date.input";
import SelectInput from "../../../controls/select.input";

import getMenuItems from "./employeeMenuItems.data";

const EmployeeReportsPage = () => {
  const params = useParams();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [employee, setEmployee] = useState();
  const [types, setTypes] = useState([]);
  const [typeId, setTypeId] = useState("");
  const [month, setMonth] = useState(
    DateTime.now().toISODate().substring(0, 7)
  );
  const [startDate, setStartDate] = useState(
    DateTime.now().minus({ days: 7 }).toISODate()
  );
  const [endDate, setEndDate] = useState(DateTime.now().toISODate());
  const [report, setReport] = useState();

  const refresh = async () => {
    try {
      const employee =
        await appStateCtx.apiServerClient.employee.employees.getByNumber(
          parseInt(params.employeeNumber)
        );

      const types =
        await appStateCtx.apiServerClient.employee.employees.getReportTypes();

      setEmployee(employee);
      if (types.length) setTypeId(types[0].id.toString());
      setTypes(types);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  const handleCreateClicked = async () => {
    try {
      let startDate2, endDate2;

      if (type.hasMonth) {
        startDate2 = DateTime.fromISO(month + "-01")
          .minus({ month: 1 })
          .plus({ days: 20 })
          .toISODate();
        endDate2 = DateTime.fromISO(startDate2)
          .plus({ months: 1 })
          .minus({ days: 1 })
          .toISODate();
      } else if (type.hasInterval) {
        startDate2 = startDate;
        endDate2 = endDate;
      }

      const report =
        await appStateCtx.apiServerClient.employee.employees.generateReport(
          typeId,
          startDate2,
          endDate2,
          params.employeeNumber
        );

      messageCtx.showSuccess("Rapport genereret");
      setReport(report);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  if (!employee || !typeId) return null;

  const type = types.find((t) => t.id === parseInt(typeId));

  const menuItems = getMenuItems(appStateCtx.user, employee, "Rapporter");

  return (
    <div className="container page">
      <h1>Medarbejder rapporter</h1>
      <div className="mt-4">
        <OptionsControl items={menuItems} />
      </div>

      <div className="form-group mt-3">
        <label className="form-label">Type</label>
        <SelectInput
          items={types}
          valueExtractor={(t) => t.id}
          nameExtractor={(t) => t.name}
          value={typeId}
          onChange={setTypeId}
        />
      </div>

      {type.hasMonth && (
        <div className="form-group mt-3">
          <label className="form-label">Måned</label>
          <MonthInput
            value={month}
            onChange={(value) => setMonth(value || dateUtil.todayAsISOMonth())}
          />
        </div>
      )}
      {type.hasInterval && (
        <div>
          <div className="form-group mt-3">
            <label className="form-label">Start</label>
            <DateInput value={startDate} onChange={setStartDate} />
          </div>
          <div className="form-group mt-3">
            <label className="form-label">Slut</label>
            <DateInput value={endDate} onChange={setEndDate} />
          </div>
        </div>
      )}
      <div className="form-group mt-3 space-children">
        <button className="btn btn-primary" onClick={handleCreateClicked}>
          Generer
        </button>
        {report && (
          <>
            <button
              className="btn btn-success"
              onClick={() => reportsUtil.generatePDF(report)}
            >
              PDF
            </button>
            <button
              className="btn btn-success"
              onClick={() => reportsUtil.generateXLSX(report)}
            >
              XLSX
            </button>
          </>
        )}
      </div>
      {report && <div>{reportsUtil.generateHtml(report)}</div>}
    </div>
  );
};

export default EmployeeReportsPage;
