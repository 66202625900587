import urlUtil from "./url.util";

const util = {
  getAdminAbsoluteUrl: (product, path) => {
    let url = `/admin/produktGrupper/${urlUtil.safeEncodeComponent(
      product.productGroup.name
    )}/produkter/${urlUtil.safeEncodeComponent(product.name)}`;

    if (path) {
      url += "/" + path;
    }

    return url;
  },
  getAbsoluteUrl: (product, path) => {
    let url = `/produktGrupper/${urlUtil.safeEncodeComponent(
      product.productGroup.name
    )}/${urlUtil.safeEncodeComponent(product.name)}`;

    if (path) {
      url += "/" + path;
    }

    return url;
  },
  getUrlSegments: (product) => {
    let url = `/${urlUtil.safeEncodeComponent(
      product.productGroup.name
    )}/${urlUtil.safeEncodeComponent(product.name)}`;

    return url;
  },
};

export default util;
