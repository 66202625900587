import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { DateTime } from "luxon";

import settings from "../../utils/settings";
import stringUtil from "../../utils/string.util";

import { useAppState } from "../contexts/appState.context";
import { useException } from "../contexts/exception.context";
import SearchtextInput from "../controls/searchtext.input";
import TableControl from "../controls/table.control";

const CustomerNotesModal = ({ customerId, show, setShow }) => {
  const appStateCtx = useAppState();
  const exceptionCtx = useException();

  const [query, setQuery] = useState("");
  const [notes, setNotes] = useState([]);

  const refresh = async () => {
    try {
      const notes =
        await appStateCtx.apiServerClient.employee.customerNotes.getByCustomerId(
          customerId
        );

      setNotes(notes);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, [customerId]);

  let filteredNotes = notes;

  if (query) {
    const q = query;
    filteredNotes = filteredNotes.filter((n) =>
      stringUtil.includesCI(n.text, q)
    );
  }

  return (
    <Modal show={show} onHide={() => setShow(false)} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Noter</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <SearchtextInput
            value={query}
            placeholder="Søg - tekst"
            onChange={setQuery}
          />
          <div className="mt-3">
            <TableControl
              columns={[
                {
                  header: "Note",
                  valueExtractor: (n) => (
                    <>
                      <div className="display-bold">
                        {DateTime.fromISO(n.createdAt).toFormat(
                          settings.dateShortFormat
                        )}
                      </div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: stringUtil.textToHtml(n.text),
                        }}
                      ></div>
                    </>
                  ),
                },
              ]}
              rows={filteredNotes}
              keyExtractor={(n) => n._id}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default CustomerNotesModal;
