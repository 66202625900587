export default ({
  readonly,
  values,
  disabled,
  onChange,
  items,
  valueExtractor,
  nameExtractor,
}) => {
  const toggle = (value, checked) => {
    if (checked) {
      values.push(value);
      onChange(values);
    } else {
      values = values.filter((v) => v !== value);
      onChange(values);
    }
  };
  if (readonly) {
    return (
      <label className="form-control-plaintext">
        {values.map((v) => (
          <div key={v}>
            {nameExtractor(items.find((i) => valueExtractor(i) === v))}
          </div>
        ))}
      </label>
    );
  } else {
    return (
      <div className="form-control">
        {items.map((i) => (
          // form-check form-check-inline
          <div className="form-check" key={valueExtractor(i)}>
            <input
              disabled={disabled}
              className="form-check-input"
              type="checkbox"
              checked={values.includes(valueExtractor(i))}
              onChange={(e) => toggle(valueExtractor(i), e.target.checked)}
            />
            <label className="form-check-label">{nameExtractor(i)}</label>
          </div>
        ))}
      </div>
    );
  }
};
