import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { DateTime } from "luxon";

import settings from "../../../../../utils/settings";

import { useException } from "../../../../contexts/exception.context";
import { useAppState } from "../../../../contexts/appState.context";
import TableControl from "../../../../controls/table.control";

const QRCodesPage = () => {
  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const navigate = useNavigate();
  const [qrCodes, setQRCodes] = useState([]);

  const isReadonly =
    !appStateCtx.user.permissionIds.includes("admin.qrcodes.edit");

  const refresh = async () => {
    try {
      const qrcodes =
        await appStateCtx.apiServerClient.employee.qrCodes.getAll();

      qrcodes.sort((a, b) => -a.createdAt.localeCompare(b.createdAt));

      setQRCodes(qrcodes);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  const handleNewClicked = () => {
    navigate("./ny");
  };

  const handleCodeClicked = (qrCode) => {
    navigate(`./${qrCode._id}`);
  };

  return (
    <div className="container page">
      <h1>QR codes</h1>
      {!isReadonly && (
        <div className="mt-4">
          <button className="btn btn-primary" onClick={handleNewClicked}>
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
      )}
      <div className="mt-3">
        <TableControl
          columns={[
            {
              header: "Oprettet",
              valueExtractor: (c) =>
                DateTime.fromISO(c.createdAt).toFormat(
                  settings.dateShortFormat
                ),
            },
            {
              header: "Produkt",
              valueExtractor: (c) => c.productName,
            },
            { header: "Beskrivelse", valueExtractor: (c) => c.description },
          ]}
          rows={qrCodes}
          keyExtractor={(c) => c._id}
          onRowClicked={handleCodeClicked}
        />
      </div>
    </div>
  );
};
export default QRCodesPage;
