import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faUnlock,
  faRemove,
} from "@fortawesome/free-solid-svg-icons";

import settings from "../../../../../utils/settings";
import validateUtil from "../../../../../utils/validate.util";

import { useAppState } from "../../../../contexts/appState.context";
import { useException } from "../../../../contexts/exception.context";
import { useMessage } from "../../../../contexts/message.context";
import TextInput from "../../../../controls/text.input";
import DateInput from "../../../../controls/date.input";
import SelectInput from "../../../../controls/select.input";

const ReportEventPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [types, setTypes] = useState([]);
  const [visitTypes, setVisitTypes] = useState([]);

  const [oldEvent, setOldEvent] = useState();
  const [newEvent, setNewEvent] = useState();

  const refresh = async () => {
    try {
      const types =
        await appStateCtx.apiServerClient.employee.reportEvents.getTypes();
      const visitTypes =
        await appStateCtx.apiServerClient.employee.reportEvents.getVisitTypes();

      setVisitTypes(visitTypes);
      setTypes(types);

      if (params.reportEventId) {
        const event =
          await appStateCtx.apiServerClient.employee.reportEvents.getById(
            params.reportEventId
          );

        event.street = event.street || "";
        event.city = event.city || "";
        event.zip = event.zip || "";
        event.km = event.km?.toString() || "";

        event.demo = event.demo || false;
        event.visitType = event.visitType || "Planlagt";

        event.revenue = event.revenue?.toString() || "";

        setOldEvent(event);
        setNewEvent(JSON.parse(JSON.stringify(event)));
      } else {
        const { position, values } = await getGpsPosition2();

        messageCtx.showSuccess("Adresse hentet via GPS");

        setNewEvent({
          date: searchParams.get("dato") || DateTime.now().toISODate(),
          description: "",
          type: "Gammel kunde",
          coordinate: position.coords,
          unlockAddress: false,
          street: values.street_number + " " + values.route,
          zip: values.postal_code,
          city: values.locality,
          km: "",
          visitType: "Planlagt",
          demo: false,
          revenue: "",
        });
      }
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const getGpsPosition2 = () => {
    return new Promise((resolve, reject) => {
      const success = async (position) => {
        // https://maps.googleapis.com/maps/api/geocode/json?latlng=40.714224,-73.961452 // us
        // https://maps.googleapis.com/maps/api/geocode/json?latlng=25.78030038625002,-100.28668949380517 // mex
        // https://maps.googleapis.com/maps/api/geocode/json?latlng=55.731438,12.481078&key=AIzaSyDd84GYdkREq3ErNcMx-kXpCbhCJnQx9uI // dk

        try {
          var googleMapsUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${settings.google.maps.key}`;

          const response = await fetch(googleMapsUrl);

          if (!response.ok) {
            reject("GPS dekodning fejl. Har du internet?");
            return;
          }

          const json = await response.json();

          const values = {};

          if (json.status !== "OK" || json.results.length === 0) {
            reject("GPS dekodning fejl");
            return;
          }

          const result = json.results[0];

          for (var component of result.address_components) {
            if (component.types && component.types.length > 0) {
              values[component.types[0]] = component.long_name;
            }
          }

          resolve({ position, values });
        } catch (ex) {
          reject("Kan ikke hente adresse fra koordinater på internettet");
        }
      };

      const error = (msg) => {
        reject("GPS fejl. Har du GPS net?");
      };

      if (navigator.geolocation) {
        var options = {
          enableHighAccuracy: true,
          //maximumAge: 30000, //default 0
          //timeout: 27000 // default infinite
        };

        navigator.geolocation.getCurrentPosition(success, error, options);
      } else {
        reject("GPS ikke fundet. Bruger du korrekt browser og har du GPS net?");
      }
    });
  };

  const getGpsPosition = async () => {
    setNewEvent({
      ...newEvent,
      unlockAddress: true,
      coordinate: undefined,
      street: "",
      zip: "",
      city: "",
    });
    try {
      const { position, values } = await getGpsPosition2();

      messageCtx.showSuccess("Adresse hentet via GPS");

      setNewEvent({
        ...newEvent,
        unlockAddress: false,
        coordinate: position.coords,
        street: values.street_number + " " + values.route,
        zip: values.postal_code,
        city: values.locality,
      });
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();

    //eslint-disable-next-line
  }, []);

  const isFormOk = () => {
    if (!types) return false;
    if (!newEvent.description) return false;

    const type = types.find((t) => t.name === newEvent.type);

    if (type.hasAddress && !validateUtil.string.isInteger(newEvent.km, 0))
      return false;
    if (type.hasRevenue && !validateUtil.string.isInteger(newEvent.revenue, 0))
      return false;

    return true;
  };

  const hasFormChanged = () => {
    return JSON.stringify(oldEvent) !== JSON.stringify(newEvent);
  };

  const handleCreateClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.reportEvents.insert(
        newEvent.date,
        newEvent.type,
        newEvent.demo,
        newEvent.visitType,
        newEvent.description,
        newEvent.street,
        newEvent.zip,
        newEvent.km,
        newEvent.city,
        {
          latitude: newEvent.coordinate?.latitude,
          longitude: newEvent.coordinate?.longitude,
        },
        newEvent.unlockAddress,
        newEvent.revenue
      );

      messageCtx.showSuccess("Registrering gemt");
      navigate(`./..?dato=${newEvent.date}`);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleUpdateClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.reportEvents.update(
        newEvent._id,
        newEvent.date,
        newEvent.type,
        newEvent.demo,
        newEvent.visitType,
        newEvent.description,
        newEvent.street,
        newEvent.zip,
        newEvent.km,
        newEvent.city,
        {
          latitude: newEvent.coordinate?.latitude,
          longitude: newEvent.coordinate?.longitude,
        },
        newEvent.unlockAddress,
        newEvent.revenue
      );
      messageCtx.showSuccess("Begivenhed opdateret");
      navigate(`./..?dato=${newEvent.date}`);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleDeleteClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.reportEvents.delete(
        params.reportEventId
      );
      messageCtx.showSuccess("Begivenhed slettet");
      navigate("./..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleUnlockCoordinateClicked = () => {
    setNewEvent({ ...newEvent, unlockAddress: true });
  };

  const handleClearClicked = () => {
    setNewEvent({ ...newEvent, city: "", street: "", zip: "" });
  };

  if (!newEvent) return null;

  const type = types.find((t) => t.name === newEvent.type);

  if (!type) return null;

  let isReadonly =
    DateTime.fromISO(newEvent.date) <
      DateTime.now().startOf("day").minus({ days: 7 }) ||
    DateTime.fromISO(newEvent.date) >
      DateTime.now().startOf("day").plus({ days: 7 });

  const dateProps = {};

  if (!isReadonly) {
    dateProps.min = DateTime.now().minus({ days: 7 }).toISODate();
    dateProps.max = DateTime.now().plus({ days: 7 }).toISODate();
  }

  return (
    <div className="container page">
      <h1>Registrering</h1>
      <div className="form-group mt-3">
        <label className="form-label">Dato</label>
        <div className="input-group">
          <DateInput
            value={newEvent.date}
            readonly={isReadonly}
            min={DateTime.now().minus({ days: 7 }).toISODate()}
            max={DateTime.now().plus({ days: 7 }).toISODate()}
            onChange={(value) => setNewEvent({ ...newEvent, date: value })}
          />
          {newEvent.date !== DateTime.now().toISODate() && !isReadonly && (
            <button
              className="btn btn-secondary py-0"
              onClick={() =>
                setNewEvent({ ...newEvent, date: DateTime.now().toISODate() })
              }
            >
              <FontAwesomeIcon icon={faRemove} />
            </button>
          )}
        </div>
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Type</label>
        <SelectInput
          items={types}
          nameExtractor={(t) => t.name}
          valueExtractor={(t) => t.name}
          value={newEvent.type}
          readonly={isReadonly}
          onChange={(value) => setNewEvent({ ...newEvent, type: value })}
        />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Beskrivelse</label>
        <TextInput
          name="description"
          readonly={isReadonly}
          value={newEvent.description}
          onChange={(value) => setNewEvent({ ...newEvent, description: value })}
        />
      </div>

      {type.hasAddress && (
        <div>
          <div className="form-group mt-3">
            <label className="form-label">Vej</label>
            <div className="input-group">
              <TextInput
                name="street"
                value={newEvent.street}
                disabled={!newEvent.unlockAddress}
                readonly={isReadonly}
                onChange={(value) =>
                  setNewEvent({ ...newEvent, street: value })
                }
              />
              {!newEvent.unlockAddress && (
                <button
                  className="btn btn-secondary py-0"
                  onClick={handleUnlockCoordinateClicked}
                >
                  <FontAwesomeIcon icon={faUnlock} />
                </button>
              )}
              {newEvent.unlockAddress && !isReadonly && (
                <button
                  className="btn btn-primary py-0"
                  onClick={getGpsPosition}
                >
                  <FontAwesomeIcon icon={faLocationDot} />
                </button>
              )}
              {newEvent.unlockAddress && !isReadonly && (
                <button
                  className="btn btn-secondary py-0"
                  onClick={handleClearClicked}
                >
                  <FontAwesomeIcon icon={faRemove} />
                </button>
              )}
            </div>
          </div>
          <div className="form-group mt-3">
            <label className="form-label">Postnummer</label>
            <TextInput
              name="zip"
              value={newEvent.zip}
              disabled={!newEvent.unlockAddress}
              readonly={isReadonly}
              onChange={(value) => setNewEvent({ ...newEvent, zip: value })}
            />
          </div>
          <div className="form-group mt-3">
            <label className="form-label">By</label>
            <TextInput
              name="city"
              value={newEvent.city}
              disabled={!newEvent.unlockAddress}
              readonly={isReadonly}
              onChange={(value) => setNewEvent({ ...newEvent, city: value })}
            />
          </div>
          <div className="form-group mt-3">
            <label className="form-label">Km</label>
            <TextInput
              className="form-control"
              value={newEvent.km}
              readonly={isReadonly}
              onChange={(value) => setNewEvent({ ...newEvent, km: value })}
            />
          </div>
        </div>
      )}
      {
        // ["Ny kunde", "Gammel kunde"].includes(typeName)
        type.hasVisit && (
          <div>
            <div className="form-group mt-3">
              <label className="form-label">Besøgs type</label>
              <SelectInput
                items={visitTypes}
                valueExtractor={(t) => t.name}
                nameExtractor={(t) => t.name}
                value={newEvent.visitType}
                readonly={isReadonly}
                onChange={(value) =>
                  setNewEvent({ ...newEvent, visitType: value })
                }
              />
            </div>
            <div className="form-group mt-3">
              <label className="form-label">Demo</label>
              <SelectInput
                items={["Ja", "Nej"]}
                valueExtractor={(i) => i}
                nameExtractor={(i) => i}
                value={newEvent.demo ? "1" : "0"}
                readonly={isReadonly}
                onChange={(value) =>
                  setNewEvent({ ...newEvent, demo: value === "1" })
                }
              />
            </div>
          </div>
        )
      }
      {
        // ["Ny kunde", "Gammel kunde", "Email", "Opkald"].includes(typeName)
        type.hasRevenue && (
          <div className="form-group mt-3">
            <label className="form-label">Omsætning</label>
            <TextInput
              value={newEvent.revenue}
              readonly={isReadonly}
              onChange={(value) => setNewEvent({ ...newEvent, revenue: value })}
            />
          </div>
        )
      }
      {!isReadonly && (
        <div className="space-children mt-3">
          {!params.reportEventId && (
            <button
              className="btn btn-primary"
              onClick={handleCreateClicked}
              disabled={!isFormOk()}
            >
              Gem
            </button>
          )}

          {params.reportEventId && (
            <button
              className="btn btn-primary"
              onClick={handleUpdateClicked}
              disabled={!isFormOk() || !hasFormChanged()}
            >
              Opdater
            </button>
          )}

          {params.reportEventId && (
            <button className="btn btn-secondary" onClick={handleDeleteClicked}>
              Slet
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default ReportEventPage;
