import { ApiServerError } from "./apiserver.client";

const util = {
  isServerError: (ex) => {
    //    if (!ex?.error) return false;
    //  return true;
    return ex instanceof ApiServerError;
  },
  isError: (ex) => {
    return ex instanceof Error;
  },
};

export default util;
