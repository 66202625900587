import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import stringUtil from "../../../../utils/string.util";

import { useException } from "../../../contexts/exception.context";
import { useAppState } from "../../../contexts/appState.context";

import EmployeeControl from "../../contact/employee.control";

import intervalUtil from "./interval.util";

const CalenderEventPage = () => {
  const params = useParams();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();

  const [calenderEvent, setCalenderEvent] = useState();

  const refresh = async () => {
    try {
      const order =
        await appStateCtx.apiServerClient.customer.calenderEvents.getMeetingById(
          params.calenderEventId
        );
      setCalenderEvent(order);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, [params.calenderEventId]);

  if (!calenderEvent) return null;

  const contact = {
    name: calenderEvent.contactName,
    email: calenderEvent.contactEmail,
    phone: calenderEvent.contactPhone,
    image: {
      name: "contact",
      url: "/assets/user3.png",
      thumbUrl: "/assets/user3.png",
      _id: "contact",
    },
  };

  return (
    <div className="container page">
      <h1>Møde</h1>
      <div className="form-group mt-3">
        <label className="form-label">Dato og tid</label>
        <div>
          {intervalUtil.getDateTime(calenderEvent)}{" "}
          {calenderEvent.finished ? "(Afsluttet)" : ""}
        </div>
      </div>
      {calenderEvent.place && (
        <div className="form-group mt-3">
          <label className="form-label">Sted</label>
          <div
            dangerouslySetInnerHTML={{
              __html: stringUtil.textToHtml(calenderEvent.place),
            }}
          ></div>
        </div>
      )}
      <div className="form-group mt-3">
        <label className="form-label">Note</label>
        <div>{calenderEvent.note}</div>
      </div>
      <div className="row mt-3">
        <div className="col-6">
          {contact && (
            <div className="h-100">
              <label className="form-label">Kunde kontakt</label>
              <EmployeeControl employee={contact} disableSaveOnPhone />
            </div>
          )}
        </div>
        <div className="col-6">
          {calenderEvent.employee && (
            <div className="h-100">
              <label className="form-label">Capp2 kontakt</label>
              <EmployeeControl employee={calenderEvent.employee} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CalenderEventPage;
