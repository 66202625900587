import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import {
  faCartArrowDown,
  faEnvelope,
  faHeart,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";

import stringUtil from "../../../utils/string.util";
import pdfLogo from "../../../assets/pdf.gif";

import { useCart } from "../../contexts/cart.context";
import { useException } from "../../contexts/exception.context";
import { useAppState } from "../../contexts/appState.context";
import { useSettings } from "../../contexts/settings.context";
import { useFavorites } from "../../contexts/favorites.context";
import { useMessage } from "../../contexts/message.context";
import TableControl from "../../controls/table.control";
import MetadataControl from "../../controls/metadata.control";

const DocumentTypesOrderMap = {
  Brugsanvisning: 1,
  Oversigt: 2,
  Sikkerhedsdatablad: 3,
  Arbejdspladsbrugsanvisning: 4,
};

const ProductPage = () => {
  const params = useParams();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const messageCtx = useMessage();
  const cartCtx = useCart();
  const favoriteCtx = useFavorites();
  const settingsCtx = useSettings();

  const [product, setProduct] = useState();
  const [reviews, setReviews] = useState([]);
  const [showMediasModal, setShowMediasModal] = useState(false);
  const [mediaIndex, setMediaIndex] = useState(-1);

  const refresh = async () => {
    try {
      const product =
        await appStateCtx.apiServerClient.visitor.products.getByName(
          params.productGroupName,
          params.productName
        );

      //product.documents.sort((a, b) => b.name.localeCompare(a.name));
      product.documents.sort(
        (a, b) => DocumentTypesOrderMap[a.type] - DocumentTypesOrderMap[b.type]
      );

      setProduct(product);
      const reviews =
        await appStateCtx.apiServerClient.visitor.productReviews.getByProductId(
          product._id
        );
      reviews.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
      setReviews(reviews);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, [params.productGroupName, params.productName]);

  if (!product) return null;
  if (!settingsCtx.settings) return null;

  const handleAddToCartClicked = (subProduct, product) => {
    cartCtx.cart.addToCart(subProduct, product);
    messageCtx.showSuccess("Produkt lagt i kurv");
  };

  const handleToggleFavorite = async () => {
    if (favoriteCtx.existFavorite(product)) {
      try {
        await favoriteCtx.removeFavorite(product);
        messageCtx.showSuccess("Produkt fjernet som favorit");
      } catch (ex) {
        exceptionCtx.handleException(ex);
      }
    } else {
      try {
        await favoriteCtx.addFavorite(product);
        messageCtx.showSuccess("Produkt gemt som favorit");
      } catch (ex) {
        exceptionCtx.handleException(ex);
      }
    }
  };

  const getMediaViewer = () => {
    if (mediaIndex === -1) return null;
    const media = product.medias[mediaIndex];

    const imageExtensions = {
      ".jpg": {},
      ".jpeg": {},
      ".png": {},
    };

    const videoExtensions = {
      ".mp4": {},
      ".mkv": {},
    };

    const url = media.file.url.toLowerCase();
    const extension = url.substring(url.lastIndexOf("."));

    const isImage = !!imageExtensions[extension];
    const isVideo = !!videoExtensions[extension];

    if (isImage) {
      return (
        <div className="d-flex w-100 h-100 p-3 justify-content-center align-items-center">
          <img
            src={media.file.url}
            alt="Billede"
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        </div>
      );
    }
    if (isVideo) {
      return (
        <div className="d-flex w-100 h-100 p-3 justify-content-center align-items-center">
          <video
            controls
            src={media.file.url}
            style={{
              height: "100%",
              width: "auto",
            }}
            loop
          />
        </div>
      );
    }

    return null;
  };

  const getMediaControls = () => {
    if (mediaIndex === -1) return null;
    return (
      <>
        {mediaIndex > 0 && (
          <div className="d-flex position-absolute h-100 align-items-center p-5">
            <img
              src="/assets/previous.svg"
              alt="Sidste"
              width="40"
              height="40"
              style={{ cursor: "pointer" }}
              onClick={() => setMediaIndex(mediaIndex - 1)}
            />
          </div>
        )}
        {mediaIndex < product.medias.length - 1 && (
          <div
            className="d-flex position-absolute justify-content-center align-items-center p-5"
            style={{ right: 0, height: "100%" }}
          >
            <img
              src="/assets/next.svg"
              alt="Næste"
              width="40"
              height="40"
              style={{ cursor: "pointer" }}
              onClick={() => setMediaIndex(mediaIndex + 1)}
            />
          </div>
        )}
      </>
    );
  };

  const getSubProductsColumns = () => {
    if (appStateCtx.user?.permissionIds?.includes("customer.cart.view")) {
      if (settingsCtx.settings?.cartState === "Ja - Med priser") {
        return [
          {
            header: "Nummer",
            type: "string",
            valueExtractor: (s) => s.subProductNumber,
          },
          {
            header: "Beskrivelse",
            type: "string",
            valueExtractor: (s) => s.shortName,
          },
          {
            header: "Vejl. enhedspris",
            type: "string",
            className: "text-end",
            valueExtractor: (s) => (s.salesPrice || 0).toFixed(2),
          },
          {
            header: "Læg i kurv",
            type: "string",
            className: "text-end",
            valueExtractor: (s) => (
              <FontAwesomeIcon
                className="clickable"
                icon={faCartArrowDown}
                onClick={() => handleAddToCartClicked(s, product)}
              />
            ),
          },
        ];
      } else if (
        settingsCtx.settings?.cartState.startsWith("Ja - Uden priser")
      ) {
        return [
          {
            header: "Nummer",
            type: "string",
            valueExtractor: (s) => s.subProductNumber,
          },
          {
            header: "Beskrivelse",
            type: "string",
            valueExtractor: (s) => s.shortName,
          },
          {
            header: "Læg i kurv",
            type: "string",
            className: "text-end",
            valueExtractor: (s) => (
              <FontAwesomeIcon
                className="clickable"
                icon={faCartArrowDown}
                onClick={() => handleAddToCartClicked(s, product)}
              />
            ),
          },
        ];
      } else if (settingsCtx.settings?.cartState.startsWith("Nej")) {
        return [
          {
            header: "Nummer",
            type: "string",
            valueExtractor: (s) => s.subProductNumber,
          },
          {
            header: "Beskrivelse",
            type: "string",
            valueExtractor: (s) => s.shortName,
          },
        ];
      }
    } else {
      return [
        {
          header: "Nummer",
          type: "string",
          valueExtractor: (s) => s.subProductNumber,
        },
        {
          header: "Beskrivelse",
          type: "string",
          valueExtractor: (s) => s.shortName,
        },
      ];
    }
  };

  return (
    <section className="container page product-page">
      <MetadataControl
        title={`Capp2 - Produkt: ${product.name}`}
        description={stringUtil.htmlToText(product.description)}
        keywords={product.tags}
      />
      <div className="row">
        <div className="col-3 d-none d-xl-block">
          {product.medias.length > 0 && (
            <div>
              <div className="row">
                <div>
                  <img
                    src={product.medias[0].file.thumbUrl}
                    alt="Medie"
                    style={{
                      width: "100%",
                      height: 300,
                      objectFit: "contain",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setMediaIndex(0);
                      setShowMediasModal(true);
                    }}
                  />
                </div>
              </div>
              <div className="row mt-4 mb-5">
                <div className="col">
                  {product.medias.map((m, index) => (
                    <img
                      key={m._id}
                      src={m.file.thumbUrl}
                      alt="Medie"
                      style={{
                        width: 40,
                        height: 40,
                        marginRight: 2,
                        objectFit: "contain",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setMediaIndex(index);
                        setShowMediasModal(true);
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
          <div>
            <h3>Dokumenter</h3>
            <img src={pdfLogo} alt="Pdf" />{" "}
            <a
              rel="noreferrer"
              href={`/api/v1/filer/produkter/produktblad/${product._id}`}
              target="_blank"
            >
              Produktblad
            </a>
            {product.documents.map((d) => (
              <div key={d._id}>
                <img src={pdfLogo} alt="Pdf" />{" "}
                <a href={d.indirectUrl} rel="noreferrer" target="_blank">
                  {d.type}
                </a>
              </div>
            ))}
          </div>
        </div>
        <div className="col-xl-9 col-12">
          <div className="d-flex justify-content-between">
            <h1>{product.name}</h1>
            <div>
              <a
                href={`mailto:test@test.com?subject=Capp2: Se dette produkt!&body=${window.location.href}`}
                className="btn btn-secondary btn-sm m-0 btn-round"
              >
                <FontAwesomeIcon icon={faEnvelope} />
              </a>
              {appStateCtx.user?.permissionIds?.includes(
                "customer.favorites.view"
              ) && (
                <button
                  className={`btn btn-${
                    favoriteCtx.existFavorite(product) ? "success" : "secondary"
                  } btn-sm m-0 btn-round ms-2`}
                  onClick={handleToggleFavorite}
                >
                  <FontAwesomeIcon icon={faHeart} />
                </button>
              )}
            </div>
          </div>
          <div className="d-xl-none mt-4">
            {product.medias.length > 0 && (
              <div className="mt-4">
                <div className="row">
                  <div>
                    <img
                      src={product.medias[0].file.thumbUrl}
                      alt="Medie"
                      style={{
                        height: 300,
                        objectFit: "contain",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setMediaIndex(0);
                        setShowMediasModal(true);
                      }}
                    />
                  </div>
                </div>
                <div className="row mt-4 mb-5">
                  <div className="col">
                    {product.medias.map((m, index) => (
                      <img
                        key={m._id}
                        src={m.file.thumbUrl}
                        alt="Medie"
                        style={{
                          width: 40,
                          height: 40,
                          objectFit: "contain",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setMediaIndex(index);
                          setShowMediasModal(true);
                        }}
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}

            <div>
              <h3>Dokumenter</h3>
              <div>
                <img src={pdfLogo} alt="Pdf" />{" "}
                <a
                  rel="noreferrer"
                  href={`/api/v1/filer/produkter/produktblad/${product._id}`}
                  target="_blank"
                >
                  Produktblad
                </a>
              </div>
              {product.documents.map((d) => (
                <div key={d._id}>
                  <img src={pdfLogo} alt="Pdf" />{" "}
                  <a href={d.indirectUrl} rel="noreferrer" target="_blank">
                    {d.type}
                  </a>
                </div>
              ))}
            </div>
          </div>
          <div className="row mt-3">
            <div
              dangerouslySetInnerHTML={{ __html: product.description }}
            ></div>
          </div>
          <div className="row">
            <div className="col">
              <h3 className="my-4">Sub produkter</h3>
              <TableControl
                columns={getSubProductsColumns()}
                rows={product.subProducts}
                keyExtractor={(s) => s.subProductNumber}
              />
            </div>
          </div>
          {reviews.length > 0 && (
            <div className="row">
              <div className="col">
                <h3 className="my-4">Anmeldelser</h3>
                <TableControl
                  columns={[
                    {
                      header: "",
                      valueExtractor: (r) => (
                        <>
                          <div>
                            <h5 className="display-bold">{r.title}</h5>
                          </div>
                          <div className="display-9">
                            <span className="display-bold">{r.reviewer}</span>{" "}
                            {DateTime.fromISO(r.createdAt).toISODate()}
                          </div>
                          <div
                            className="mt-4"
                            dangerouslySetInnerHTML={{
                              __html: stringUtil.textToHtml(r.text),
                            }}
                          ></div>
                        </>
                      ),
                    },
                  ]}
                  keyExtractor={(r) => r._id}
                  rows={reviews}
                  showColumns={false}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal
        fullscreen
        show={showMediasModal}
        onHide={() => setShowMediasModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Billede og video ({mediaIndex + 1} / {product.medias.length})
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ overflow: "hidden", padding: 0 }}
          className="d-flex"
        >
          {getMediaViewer()}
          {getMediaControls()}
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default ProductPage;
