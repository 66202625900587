const mappings = [
  { char: "%", encoded: "%25" },
  { char: "/", encoded: "%2F" },
  { char: "?", encoded: "%3F" },
  { char: "#", encoded: "%23" },
  // Luksus Krop & Hår med 70% aloe vera
  //{ char: "&", encoded: "%26" },
];
const util = {
  safeEncodeComponent: (component) => {
    mappings.forEach(
      (m) => (component = component.replaceAll(m.char, m.encoded))
    );
    return component;
  },
  safeEncodeURL: (url) => {
    const components = url.split("/");
    for (var i = 0; i < components.length; i++) {
      components[i] = util.safeEncodeComponent(components[i]);
    }

    return components.join("/");
  },
  safeEncodeComponentsToURL: (components) => {
    const components2 = [];
    for (var i = 0; i < components.length; i++) {
      components2.push(util.safeEncodeComponent(components[i]));
    }

    return components2.join("/");
  },
  safeDecodeURLToComponents: (url) => {
    const components = url.split("/");
    for (var i = 0; i < components.length; i++) {
      components[i] = decodeURIComponent(
        util.safeDecodeComponent(components[i])
      );
    }
    return components;
  },
  safeDecodeComponent: (component) => {
    mappings.forEach(
      (m) => (component = component.replaceAll(m.encoded, m.char))
    );

    return component;
  },
  safeDecodeURL: (url) => {
    const components = url.split("/");
    for (var i = 0; i < components.length; i++) {
      components[i] = util.safeDecodeComponent(components[i]);
    }

    return components.join("/");
  },
  getQueryParams: (query) => {
    if (query.startsWith("?")) query = query.substring(1);
    const keyValues = query.split("&");

    const params = {};

    for (var keyValue of keyValues) {
      const [key, value] = keyValue.split("=");
      params[key] = value;
    }
    return params;
  },
};

export default util;
