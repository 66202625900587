import { faFilter, faRemove } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import searchFilterUtil from "../../../../utils/searchFilter.util";
import productGroupUtil from "../../../../utils/productGroup.util";
import stringUtil from "../../../../utils/string.util";

import { useException } from "../../../contexts/exception.context";
import { useAppState } from "../../../contexts/appState.context";
import OptionsControl from "../../../controls/options.control";
import TextInput from "../../../controls/text.input";
import TableControl from "../../../controls/table.control";

const menuItems = [
  {
    name: "Produkter",
    url: "../produkter",
  },
  {
    name: "Grupper",
    url: "../produktGrupper",
    selected: true,
  },
];

const defaultFilter = { query: "", show: true };

const ProductGroupsPage = () => {
  const navigate = useNavigate();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();

  const [groups, setGroups] = useState([]);
  const [filter, setFilter] = useState(defaultFilter);
  const queryRef = useRef();

  const saveAndSetFilter = (filter) => {
    searchFilterUtil.save("productGroups", appStateCtx.user._id, filter);

    setFilter(filter);
  };

  const refresh = async () => {
    try {
      const filter =
        searchFilterUtil.load("productGroups", appStateCtx.user._id) ||
        defaultFilter;

      setFilter(filter);

      const groups =
        await appStateCtx.apiServerClient.employee.productGroups.getAll();

      groups.sort((a, b) => a.name.localeCompare(b.name));

      setGroups(groups);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    queryRef.current.focus();
    // eslint-disable-next-line
  }, []);

  const handleGroupClicked = (group) => {
    navigate(productGroupUtil.getAdminAbsoluteUrl(group, "produkter"));
  };

  const query2 = filter.query.trim();

  const filteredGroups = query2
    ? groups.filter((g) =>
        stringUtil.includesCI(g.productGroupNumber + "|" + g.name, query2)
      )
    : groups;

  return (
    <div className="container page">
      <h1>Grupper</h1>

      <div className="d-flex justify-content-between mt-4">
        <OptionsControl items={menuItems} />

        <div className="btn-group">
          <button
            className="btn btn-primary"
            onClick={() => saveAndSetFilter({ ...filter, show: !filter.show })}
          >
            <FontAwesomeIcon icon={faFilter} />
          </button>
          {!searchFilterUtil.areFiltersEqual(defaultFilter, filter) && (
            <button
              className="btn btn-secondary"
              onClick={() => saveAndSetFilter({ ...defaultFilter })}
            >
              <FontAwesomeIcon icon={faRemove} />
            </button>
          )}
        </div>
      </div>

      <div style={{ display: filter.show ? "initial" : "none" }}>
        <div className="mt-3">
          <TextInput
            ref={queryRef}
            name="productGroupQuery"
            value={filter.query}
            placeholder="Søg - nummer, navn"
            onChange={(value) => saveAndSetFilter({ ...filter, query: value })}
          />
        </div>
      </div>
      <TableControl
        columns={[
          {
            header: "Nummer",
            type: "string",
            valueExtractor: (g) => g.productGroupNumber,
          },
          {
            header: "Gruppe",
            type: "string",
            valueExtractor: (g) => g.name,
          },
          {
            header: "Billede",
            type: "string",
            valueExtractor: (g) => (g.image ? "Ja" : "Nej"),
          },
        ]}
        rows={filteredGroups}
        keyExtractor={(g) => g.productGroupNumber}
        onRowClicked={handleGroupClicked}
      />
    </div>
  );
};

export default ProductGroupsPage;
