import React from "react";
import { DateTime } from "luxon";

import settings from "../../utils/settings";

import TableControl from "../controls/table.control";

const Locked = ({ value }) => {
  return value && <span>(Låst)</span>;
};

const OrderControl = ({ order, showEmployee }) => {
  if (!order) return null;

  return (
    <div className="order">
      <div className="mt-3">
        <h4>Generalt</h4>
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Dato</label>
        <div className="form-text">
          {DateTime.fromISO(order.createdAt).toFormat(settings.dateShortFormat)}
        </div>
      </div>

      <div className="form-group mt-3">
        <label className="form-label">Type</label>
        <div className="form-text">{order.type}</div>
      </div>

      {showEmployee && (
        <div className="form-group mt-3">
          <label className="form-label">Medarbejder</label>
          <div className="form-text">{order.employee.name}</div>
        </div>
      )}

      <div className="mt-3">
        <h4>Kunde</h4>
      </div>
      <div className="form-group mt-3">
        <label className="form-label">
          Navn <Locked value={order.customer?.name?.locked} />
        </label>
        <div className="form-text">{order.customer?.name?.value}</div>
      </div>
      <div className="form-group mt-3">
        <label className="form-label">
          CVR <Locked value={order.customer?.cvr?.locked} />
        </label>
        <div className="form-text">{order.customer?.cvr?.value}</div>
      </div>
      <div className="form-group mt-3">
        <label className="form-label">
          EAN <Locked value={order.customer?.ean?.locked} />
        </label>
        <div className="form-text">{order.customer?.ean?.value}</div>
      </div>
      <div className="form-group mt-3">
        <label className="form-label">
          Adresse <Locked value={order.customer?.address?.locked} />
        </label>
        <div className="form-text">{order.customer?.address?.value}</div>
      </div>
      <div className="form-group mt-3">
        <label className="form-label">
          Post nummer <Locked value={order.customer?.zip?.locked} />
        </label>
        <div className="form-text">{order.customer?.zip?.value}</div>
      </div>
      <div className="form-group mt-3">
        <label className="form-label">
          By <Locked value={order.customer?.city?.locked} />
        </label>
        <div className="form-text">{order.customer?.city?.value}</div>
      </div>
      <div className="form-group mt-3">
        <label className="form-label">
          Land <Locked value={order.customer?.country?.locked} />
        </label>
        <div className="form-text">{order.customer?.country?.value}</div>
      </div>
      <div className="form-group mt-3">
        <label className="form-label">
          Email <Locked value={order.customer?.email?.locked} />
        </label>
        <div className="form-text">{order.customer?.email?.value}</div>
      </div>

      <div className="mt-3">
        <h4>Leverings sted</h4>
      </div>
      <div className="form-group mt-3">
        <label className="form-label">
          Adresse <Locked value={order.deliveryLocation?.address?.locked} />
        </label>
        <div className="form-text">
          {order.deliveryLocation?.address?.value}
        </div>
      </div>
      <div className="form-group mt-3">
        <label className="form-label">
          Post nummer <Locked value={order.deliveryLocation?.zip?.locked} />
        </label>
        <div className="form-text">{order.deliveryLocation?.zip?.value}</div>
      </div>
      <div className="form-group mt-3">
        <label className="form-label">
          By <Locked value={order.deliveryLocation?.city?.locked} />
        </label>
        <div className="form-text">{order.deliveryLocation?.city?.value}</div>
      </div>
      <div className="form-group mt-3">
        <label className="form-label">
          Land <Locked value={order.deliveryLocation?.country?.locked} />
        </label>
        <div className="form-text">
          {order.deliveryLocation?.country?.value}
        </div>
      </div>
      <div className="form-group mt-3">
        <label className="form-label">
          Betingelse{" "}
          <Locked value={order.deliveryLocation?.termsOfDelivery?.locked} />
        </label>
        <div className="form-text">
          {order.deliveryLocation?.termsOfDelivery?.value}
        </div>
      </div>

      <div className="mt-3">
        <h4>Kontakt person</h4>
      </div>
      <div className="form-group mt-3">
        <label className="form-label">
          Navn <Locked value={order.contact?.name?.locked} />
        </label>
        <div className="form-text">{order.contact?.name?.value}</div>
      </div>
      <div className="form-group mt-3">
        <label className="form-label">
          Telefon numre <Locked value={order.contact?.phone?.locked} />
        </label>
        <div className="form-text">{order.contact?.phone?.value}</div>
      </div>
      <div className="form-group mt-3">
        <label className="form-label">
          Email <Locked value={order.contact?.email?.locked} />
        </label>
        <div className="form-text">{order.contact?.email?.value}</div>
      </div>
      <div className="form-group mt-3">
        <label className="form-label">
          Note <Locked value={order.contact?.note?.locked} />
        </label>
        <div className="form-text">{order.contact?.note?.value}</div>
      </div>
      <div className="form-group mt-3">
        <label className="form-label">
          Modtag email <Locked value={order.contact?.receiveEmail?.locked} />
        </label>
        <div className="form-text">
          {order.contact?.receiveEmail?.value ? "Ja" : ""}
        </div>
      </div>

      <div className="mt-3">
        <h4>Linjer</h4>
      </div>

      <div className="mt-3">
        <TableControl
          columns={[
            {
              header: "Nummer",
              valueExtractor: (l) => l.subProduct.subProductNumber,
            },
            {
              header: "Beskrivelse",
              valueExtractor: (l) => l.subProduct.name,
            },
            {
              header: "Enhed",
              valueExtractor: (l) => l.subProduct.unit?.name,
            },
            {
              header: "Rabat",
              valueExtractor: (l) => l.discount,
              className: "text-end",
            },
            {
              header: "Antal",
              valueExtractor: (l) => l.amount,
              className: "text-end",
            },
            {
              header: "Pris",
              valueExtractor: (l) => (l.subProduct.salesPrice || 0).toFixed(2),
              className: "text-end",
            },
            {
              header: "Total",
              valueExtractor: (l) => l.totalWithoutVAT.toFixed(2),
              className: "text-end",
            },
          ]}
          rows={order.lines}
          keyExtractor={(l) => l.subProduct.subProductNumber}
          footerColumns={[
            {
              colspan: 6,
              className: "text-end",
              valueExtractor: (r) => r.name,
            },
            {
              className: "text-end",
              valueExtractor: (r) => r.value.toFixed(2),
            },
          ]}
          footerRows={[
            { name: "Subtotal", value: order.totalWithoutVAT },
            { name: "Moms", value: order.totalVAT },
            { name: "Total", value: order.totalWithVAT },
          ]}
          footerKeyExtractor={(r) => r.name}
        />
      </div>

      <div className="mt-3">
        <h4>Noter</h4>
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Overskrift</label>
        <div className="form-text">{order.note?.title}</div>
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Tekst 1</label>
        <div className="form-text">{order.note?.text1}</div>
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Tekst 2</label>
        <div className="form-text">{order.note?.text2}</div>
      </div>
    </div>
  );
};
export default OrderControl;
