import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

import GoogleMapReact from "google-map-react";
import QRCode from "react-qr-code";

import office from "../../../assets/kontor2.jpg";
import settings from "../../../utils/settings";

import { useAppState } from "../../contexts/appState.context";
import { useException } from "../../contexts/exception.context";
import MetadataControl from "../../controls/metadata.control";

import MeetingPage from "./meeting.page";
import EmployeeControl from "./employee.control";

const ContactPage = () => {
  const appStateCtx = useAppState();
  const exceptionCtx = useException();

  const [employees, setEmployees] = useState([]);
  const [employee, setEmployee] = useState();
  const [showQrCodeModal, setShowQrCodeModal] = useState(false);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [showGetContactedModal, setShowGetContactedModal] = useState(false);

  const handleShowQrCodeClicked = (employee) => {
    setEmployee(employee);
    setShowQrCodeModal(true);
  };

  const handleGetContactedClicked = () => {
    setShowGetContactedModal(true);
  };

  const handleAddressClicked = () => {
    setShowAddressModal(true);
  };

  const refresh = async () => {
    try {
      const employees =
        await appStateCtx.apiServerClient.visitor.employees.getAll();

      setEmployees(employees);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  const center = settings.company.gps;
  const zoom = 13;

  const handleApiLoaded = (map, maps) => {
    const image = "/assets/marker11.png";

    new maps.Marker({
      position: center,
      map,
      icon: image,
    });
  };

  const generateVCard = (employee) => {
    const lines = [];
    lines.push("BEGIN:VCARD");
    lines.push("VERSION:3.0");
    if (employee.name) lines.push(`N:;${employee.name}`);
    if (employee.name) lines.push(`FN:${employee.name}`);
    if (employee.position) lines.push(`TITLE:${employee.position}`);
    lines.push("ORG:Capp2");
    lines.push("URL:www.capp2.dk");
    lines.push(`EMAIL;TYPE=INTERNET:${employee.email}`);
    lines.push(`TEL;TYPE=voice,cell,pref:${employee.phone}`);
    lines.push("ADR:;;Fabriksparken 10A;Glostrup;;2800;");
    lines.push("END:VCARD");
    return lines.join("\n");
  };

  return (
    <section className="page">
      <MetadataControl
        title="Capp2 - Kontakt"
        description="Ring, email eller bed om møde med Capp2. Se medarbejdere."
      />
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <h1 className="mb-5">Kontakt os</h1>
          </div>
          <div className="row justify-content-center g-4 px-2 m-0">
            <div className="card col-12 col-md-6">
              <div
                className="card-wrapper backgroundcolor-gray d-flex flex-row p-4 center"
                style={{ borderRadius: 5 }}
              >
                <div className="image-wrapper d-flex align-items-center">
                  <FontAwesomeIcon icon={faPhone} size={"2x"} />
                </div>
                <div className="text-wrapper ms-4 justify-content-center">
                  <h6 className="card-title mb-0 display-bold">Telefon</h6>
                  <div>
                    {/* eslint-disable-next-line */}
                    <a href={`tel:${settings.company.phone}`}>
                      {settings.company.phone}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="card col-12 col-md-6">
              <div
                className="card-wrapper backgroundcolor-gray d-flex flex-row p-4"
                style={{ borderRadius: 5 }}
              >
                <div className="image-wrapper d-flex align-items-center">
                  <FontAwesomeIcon icon={faEnvelope} size={"2x"} />
                </div>
                <div className="text-wrapper ms-4">
                  <h6 className="card-title mb-0 display-bold">Email</h6>
                  <div>
                    <a
                      href={`mailto:${settings.company.emails.contact}?body=Hej Capp2,%0A%0AMit navn er %0A%0AMit telefon nummer er %0A%0AJeg skriver til jer fordi %0A%0AMvh,%0A%0A%0A%0A`}
                    >
                      {settings.company.emails.contact}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="card col-12 col-md-6">
              <div
                className="card-wrapper backgroundcolor-gray d-flex flex-row p-4"
                style={{ borderRadius: 5 }}
              >
                <div className="image-wrapper d-flex align-items-center">
                  <FontAwesomeIcon icon={faLocationDot} size={"2x"} />
                </div>
                <div className="text-wrapper ms-4">
                  <h6 className="card-title mb-0 display-bold">Addresse</h6>
                  <div>
                    {/* eslint-disable-next-line */}
                    <a
                      href="#"
                      onClick={(event) => {
                        event.preventDefault();
                        handleAddressClicked();
                      }}
                    >
                      {`${settings.company.street}, ${settings.company.zip} ${settings.company.city}, ${settings.company.countryCode}`}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="card col-12 col-md-6 ">
              <div
                className="card-wrapper backgroundcolor-gray d-flex flex-row p-4"
                style={{ borderRadius: 5 }}
              >
                <div className="image-wrapper d-flex align-items-center">
                  <FontAwesomeIcon icon={faClock} size={"2x"} />
                </div>
                <div className="text-wrapper ms-4">
                  <h6 className="card-title mb-0 display-bold">Åbningstider</h6>
                  <div>{settings.company.openingHours}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-5 text-center">
        <button
          className="btn btn-secondary btn-action"
          onClick={handleGetContactedClicked}
        >
          ...eller bliv kontaktet af {settings.company.name}
        </button>
      </div>
      <div className="w-100 mt-5" style={{ height: 500 }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: settings.google.maps.key,
          }}
          defaultCenter={center}
          defaultZoom={zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        ></GoogleMapReact>
      </div>
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-12">
            <h1 className="mb-5">Vores team</h1>
          </div>
          <div className="row justify-content-center mt-4">
            {employees.map((e) => (
              <div className="col-md-6 col-xl-4 mb-5" key={e._id}>
                <EmployeeControl
                  employee={e}
                  key={e._id}
                  handleShowQrCodeClicked={handleShowQrCodeClicked}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      <Modal
        show={showQrCodeModal}
        onHide={() => setShowQrCodeModal(false)}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Gem på telefon</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mb-3 mt-2">
          {employee && (
            <div className="d-flex flex-column align-items-center">
              <div className="display-4 mb-3">
                Scan koden med din telefons kamera
              </div>

              <QRCode
                value={generateVCard(employee)}
                fgColor={settings.colors.primary}
              />
            </div>
          )}
        </Modal.Body>
      </Modal>
      <Modal
        show={showAddressModal}
        onHide={() => setShowAddressModal(false)}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Kontor</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mb-3 mt-2">
          <div className="d-flex flex-column align-items-center">
            <img src={office} className="w-100" alt="" />
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showGetContactedModal}
        onHide={() => setShowGetContactedModal(false)}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Bliv kontaktet af {settings.company.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mb-3 mt-2">
          <MeetingPage />
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default ContactPage;
