import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import validateUtil from "../../../utils/validate.util";
import randomUtil from "../../../utils/random.util";

import { useException } from "../../contexts/exception.context";
import { useAppState } from "../../contexts/appState.context";
import { useMessage } from "../../contexts/message.context";
import { useSettings } from "../../contexts/settings.context";
import MetadataControl from "../../controls/metadata.control";

import VideoControl from "../videos/video.control";
import AnnouncementControl from "../announcements/announcement.control";
import FocusProductControl from "../focusproducts/focusproduct.control";
import ReferenceControl from "../references/reference.control";

const MainPage = () => {
  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const messageCtx = useMessage();
  const settingsCtx = useSettings();

  const [announcements, setAnnouncements] = useState([]);
  const [references, setReferences] = useState([]);
  const [focusProducts, setFocusProducts] = useState([]);
  const [banners, setBanners] = useState([]);
  const [videos, setVideos] = useState([]);

  const [companyName, setCompanyName] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(true);

  const refresh = async () => {
    try {
      const announcementsPromise =
        appStateCtx.apiServerClient.visitor.announcements.getAll();
      const referencesPromise =
        appStateCtx.apiServerClient.visitor.references.getAll();
      const productsPromise =
        appStateCtx.apiServerClient.visitor.products.getAllFocusProducts();
      const videosPromise = appStateCtx.apiServerClient.visitor.videos.getAll();
      const bannersPromise =
        appStateCtx.apiServerClient.visitor.banners.getAll();
      let [announcements, references, products, videos, banners] =
        await Promise.all([
          announcementsPromise,
          referencesPromise,
          productsPromise,
          videosPromise,
          bannersPromise,
        ]);

      announcements = randomUtil.getMultipleRandom(announcements, 2);
      references = randomUtil.getMultipleRandom(references, 2);
      products = randomUtil.getMultipleRandom(products, 3);
      videos = randomUtil.getMultipleRandom(videos, 1);

      setAnnouncements(announcements);
      setReferences(references);
      setFocusProducts(products);
      setVideos(videos);
      setBanners(banners);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, [appStateCtx.user]);

  const handleSubscribeClicked = async () => {
    try {
      await appStateCtx.apiServerClient.visitor.newsletterSubscribers.subscribe(
        companyName,
        name,
        email,
        phone
      );
      setEmail("");
      setName("");
      setCompanyName("");
      setPhone("");
      messageCtx.showSuccess("Du har tilmeldt dig Capp2's nyhedsbrev");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const isFormOk = () => {
    if (!validateUtil.string.isValid(companyName)) return false;
    if (!validateUtil.string.isValid(name)) return false;
    if (!validateUtil.email.isValid(email)) return false;

    return true;
  };

  if (loading) return null;

  if (!settingsCtx.settings) return null;

  return (
    <>
      <MetadataControl
        title="Capp2 - For sikkerhed og miljø"
        description="Velkommen til Capp2"
      />

      {banners.length > 0 && (
        <ImageGallery
          slideInterval={10000}
          slideDuration={1000}
          autoPlay
          items={banners}
          showThumbnails={false}
          showFullscreenButton={false}
          showPlayButton={false}
          showIndex={false}
          showBullets={banners.length > 1}
          showNav={false}
          renderItem={(item) => (
            <div style={{ overflow: "hidden", position: "relative" }}>
              <img
                src={item.image?.url}
                className="banner"
                alt={item.image?.name}
              />
              <div className="banner-text d-flex justify-content-center align-items-center flex-column">
                {item.title && (
                  <h1 className="mb-3 display-1 display-bold text-center color-white">
                    {item.title}
                  </h1>
                )}
                {item.subTitle && (
                  <p
                    className="display-7 display-bold text-center color-white"
                    style={{ wordBreak: "break-word" }}
                  >
                    {item.subTitle}
                  </p>
                )}
                {item.showButtons && (
                  <div
                    className="mt-3 banner-buttons"
                    style={{ textAlign: "center" }}
                  >
                    <Link
                      className="btn btn-primary btn-action mt-1 banner-button"
                      to="/produktGrupper"
                    >
                      Produkter
                    </Link>
                    {settingsCtx.settings?.productCatalog && (
                      <a
                        className="btn btn-secondary btn-action mt-1 banner-button"
                        href={settingsCtx.settings?.productCatalog?.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Hent katalog
                      </a>
                    )}
                    <Link
                      className="btn btn-tertiary btn-action mt-1 banner-button"
                      to="/om"
                    >
                      Se mere
                    </Link>
                  </div>
                )}
              </div>
            </div>
          )}
        />
      )}

      {focusProducts.length > 0 && (
        <section
          className="backgroundcolor-gray"
          style={{
            paddingTop: "6rem",
            paddingBottom: "6rem",
          }}
        >
          <div className="container">
            <div className="row justify-content-center text-center">
              <h1 className="mb-2">Produkter i fokus</h1>
              <h2 className="mb-5">
                Se vores produkter der er fokus på i denne måned
              </h2>
            </div>
            <div className="row row-cols-1 row-cols-lg-3 g-4 justify-content-center">
              {focusProducts.map((p) => (
                <FocusProductControl product={p} key={p._id} />
              ))}
            </div>
            <div className="text-center mt-5">
              <Link
                className="btn btn-secondary btn-action"
                to="produkterifokus"
              >
                ... se alle vores produkter i fokus
              </Link>
            </div>
          </div>
        </section>
      )}

      {announcements.length > 0 && (
        <section
          style={{
            paddingTop: "6rem",
            paddingBottom: "6rem",
          }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="text-center">
                <h1 className="mb-2">Nyheder</h1>
                <h2 className="mb-5">Seneste nyt fra Capp2</h2>
              </div>
            </div>
            <div className="row g-4 justify-content-center">
              {announcements.map((a) => (
                <AnnouncementControl announcement={a} key={a._id} />
              ))}
            </div>

            <div className="text-center mt-5">
              <Link
                className="btn btn-secondary btn-action"
                style={{ display: "inline" }}
                to="nyheder"
              >
                ... se alle vores nyheder
              </Link>
            </div>
          </div>
        </section>
      )}

      {videos.length > 0 && (
        <section
          className="backgroundcolor-gray"
          style={{
            paddingTop: "6rem",
            paddingBottom: "6rem",
          }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <h1 className="text-center mb-5">Video</h1>
            </div>
            {videos.map((video) => (
              <VideoControl video={video} key={video._id} />
            ))}
          </div>

          <div className="text-center mt-5">
            <Link
              className="btn btn-secondary btn-action d-inline"
              to="videoer"
            >
              ... se alle vores videoer
            </Link>
          </div>
        </section>
      )}

      {references.length > 0 && (
        <section
          className="backgroundcolor-white"
          style={{
            paddingTop: "6rem",
            paddingBottom: "6rem",
          }}
        >
          <div className="container">
            <div className="row justify-content-center text-center">
              <h1 className="mb-2">Hvad siger vores kunder</h1>
              <h2 className="mb-5">Se de flotte udtalelser</h2>
            </div>
            <div className="row g-4 justify-content-center">
              {references.map((r) => (
                <ReferenceControl reference={r} key={r._id} />
              ))}
            </div>
            <div className="text-center mt-5">
              <Link
                className="btn btn-secondary btn-action d-inline"
                to="referencer"
              >
                ... se alle vores referencer
              </Link>
            </div>
          </div>
        </section>
      )}

      <section
        className="backgroundcolor-gray"
        style={{
          paddingTop: "6rem",
          paddingBottom: "6rem",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <h1 className="text-center mb-2">Tilmeld dig vores nyhedsbrev</h1>
          </div>
          <div className="row justify-content-center mt-4">
            <div className="col-lg-8 mx-auto">
              <div
                className="row"
                style={{ alignItems: "center", justifyContent: "center" }}
              >
                <div className="col-lg-4 col-md-12 col-sm-12 form-group my-2">
                  <input
                    type="text"
                    name="companyName"
                    placeholder="Firmanavn*"
                    className="form-control display-4"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12 form-group my-2">
                  <input
                    type="text"
                    name="name"
                    placeholder="Navn*"
                    className="form-control display-4"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12 form-group my-2">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email*"
                    className="form-control display-4"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12 form-group my-2">
                  <input
                    type="text"
                    name="phone"
                    placeholder="Telefon"
                    className="form-control display-4"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12 text-center my-2">
                  <button
                    className="btn btn-secondary btn-action w-100 m-0"
                    disabled={!isFormOk()}
                    onClick={handleSubscribeClicked}
                  >
                    Tilmeld
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MainPage;
