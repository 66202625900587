import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import InputGroup from "react-bootstrap/InputGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";

import { useAppState } from "../../../../contexts/appState.context";
import { useException } from "../../../../contexts/exception.context";
import TextInput from "../../../../controls/text.input";
import HtmlInput from "../../../../controls/html.input";
import { useMessage } from "../../../../contexts/message.context";
import SelectInput from "../../../../controls/select.input";

export default () => {
  const navigate = useNavigate();
  const params = useParams();

  const appStateCtx = useAppState();
  const expceptionCtx = useException();
  const messageCtx = useMessage();

  const subjectRef = useRef();
  const [oldEmailTemplate, setOldEmailTemplate] = useState();
  const [newEmailTemplate, setNewEmailTemplate] = useState();

  const isReadonly =
    !appStateCtx.user.permissionIds.includes("admin.emails.edit");

  const refresh = async () => {
    try {
      const template =
        await appStateCtx.apiServerClient.employee.emailTemplates.getById(
          params.emailTemplateId
        );
      setOldEmailTemplate(template);
      setNewEmailTemplate({ ...template });
    } catch (ex) {
      expceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  const hasFormChanged = () => {
    return (
      JSON.stringify(oldEmailTemplate) !== JSON.stringify(newEmailTemplate)
    );
  };

  const isFormOk = () => {
    if (newEmailTemplate.subject.trim().length === 0) return false;
    if (newEmailTemplate.body.trim().length === 0) return false;

    return true;
  };

  const handleSendTestClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.emailTemplates.sendTest(
        newEmailTemplate.type
      );
      messageCtx.showSuccess("Test email sendt");
    } catch (ex) {
      expceptionCtx.handleException(ex);
    }
  };

  const handleUpdateClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.emailTemplates.update(
        params.emailTemplateId,
        newEmailTemplate.enabled,
        newEmailTemplate.subject,
        newEmailTemplate.body
      );
      messageCtx.showSuccess("Email skabelon gemt");
      navigate("./..");
    } catch (ex) {
      expceptionCtx.handleException(ex);
    }
  };

  const handleSubjectFieldClicked = (field) => {
    const tag = `[${field.id}]`;
    const cursorPos = subjectRef.current.selectionStart;
    setNewEmailTemplate({
      ...newEmailTemplate,
      subject:
        newEmailTemplate.subject.slice(0, cursorPos) +
        tag +
        newEmailTemplate.subject.slice(cursorPos),
    });
    subjectRef.current.focus();
    subjectRef.current.setSelectionRange(
      cursorPos + tag.length,
      cursorPos + tag.length
    );
  };

  if (!newEmailTemplate) return null;

  return (
    <div className="container page">
      <h1>Email skabelon</h1>

      <div className="mt-3">
        <div className="form-group mt-3">
          <label className="form-label">Type</label>
          <TextInput value={newEmailTemplate.type} readonly={true} />
        </div>
        <div className="form-group mt-3">
          <label className="form-label">Modtager</label>
          <TextInput value={newEmailTemplate.to} readonly={true} />
        </div>
        <div className="form-group mt-3">
          <label className="form-label">Slået til</label>
          <SelectInput
            items={["Ja", "Nej"]}
            valueExtractor={(i) => i}
            nameExtractor={(i) => i}
            value={newEmailTemplate.enabled ? "Ja" : "Nej"}
            readonly={isReadonly}
            onChange={(value) =>
              setNewEmailTemplate({
                ...newEmailTemplate,
                enabled: value === "Ja",
              })
            }
          />
        </div>
        <div className="form-group mt-3">
          <label className="form-label">Overskrift</label>
          <InputGroup>
            <TextInput
              ref={subjectRef}
              value={newEmailTemplate.subject}
              readonly={isReadonly}
              onChange={(value) =>
                setNewEmailTemplate({ ...newEmailTemplate, subject: value })
              }
            />
            {!isReadonly && (
              <Dropdown>
                <Dropdown.Toggle variant="primary" className="py-0">
                  <FontAwesomeIcon icon={faEllipsisH} />
                </Dropdown.Toggle>
                <Dropdown.Menu drop="end">
                  {newEmailTemplate.fields
                    .filter((f) => f.useInSubject)
                    .map((field) => (
                      <Dropdown.Item
                        onClick={() => handleSubjectFieldClicked(field)}
                        key={field.id}
                      >
                        {field.id}
                      </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </InputGroup>
        </div>

        <div className="form-group mt-3">
          <label className="form-label">Meddelelse</label>
          <HtmlInput
            type="emailTemplate"
            value={newEmailTemplate.body}
            readonly={isReadonly}
            fields={newEmailTemplate.fields.filter((f) => f.useInHtml)}
            onChange={(value) =>
              setNewEmailTemplate({ ...newEmailTemplate, body: value })
            }
          />
        </div>
        {!isReadonly && (
          <div className="space-children mt-3">
            <button
              className="btn btn-primary"
              onClick={handleUpdateClicked}
              disabled={!isFormOk() || !hasFormChanged()}
            >
              Opdater
            </button>
            <button
              className="btn btn-primary"
              onClick={handleSendTestClicked}
              disabled={hasFormChanged()}
            >
              Send test
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
