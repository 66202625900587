import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forwardRef, useState } from "react";

export default forwardRef(
  ({ readonly, value, disabled, onChange, ...rest }, ref) => {
    const [viewPassword, setViewPassword] = useState(false);

    const handleShowPasswordClicked = (e) => {
      setViewPassword(!viewPassword);
    };

    if (readonly) {
      return (
        <label className="form-control-plaintext">
          {"*".repeat(value.length)}
        </label>
      );
    } else {
      return (
        <div className="input-group">
          <input
            type={viewPassword ? "text" : "password"}
            ref={ref}
            {...rest}
            className="form-control"
            value={value}
            disabled={disabled}
            onChange={(e) => onChange(e.target.value, e)}
          />
          <button
            className="btn btn-primary py-0"
            onClick={handleShowPasswordClicked}
          >
            <FontAwesomeIcon icon={viewPassword ? faEyeSlash : faEye} />
          </button>
        </div>
      );
    }
  }
);
