import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { faFilter, faRemove } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import productUtil from "../../../../utils/product.util";
import searchFilterUtil from "../../../../utils/searchFilter.util";
import stringUtil from "../../../../utils/string.util";

import { useException } from "../../../contexts/exception.context";
import { useAppState } from "../../../contexts/appState.context";
import OptionsControl from "../../../controls/options.control";
import TextInput from "../../../controls/text.input";
import TableControl from "../../../controls/table.control";

const menuItems = [
  {
    name: "Gruppe",
    url: "..",
  },
  {
    name: "Produkter",
    url: ".",
    selected: true,
  },
];

const defaultFilter = {
  query: "",
  show: true,
};

const ProductGroupProductsPage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();

  const [products, setProducts] = useState([]);
  const [filter, setFilter] = useState(defaultFilter);

  const queryRef = useRef();

  useEffect(() => {
    refresh();
    queryRef.current.focus();
    //eslint-disable-next-line
  }, []);

  const saveAndSetFilter = (filter) => {
    searchFilterUtil.save("productGroupProducts", appStateCtx.user._id, filter);

    setFilter(filter);
  };

  const refresh = async () => {
    try {
      const filter =
        searchFilterUtil.load("productGroupProducts", appStateCtx.user._id) ||
        defaultFilter;

      setFilter(filter);

      const products =
        await appStateCtx.apiServerClient.employee.products.getByProductGroupName(
          params.productGroupName
        );

      products.sort((a, b) => a.name.localeCompare(b.name));

      setProducts(products);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleProductClicked = (product) => {
    navigate(productUtil.getAdminAbsoluteUrl(product));
  };

  const query2 = filter.query.trim();

  const filteredProducts = query2
    ? products.filter((p) =>
        p.subProducts.some((s) =>
          stringUtil.includesCI(
            s.subProductNumber + "|" + s.name + "|" + p.tags,
            query2
          )
        )
      )
    : products;

  return (
    <div className="container page">
      <h1>Gruppe: {params.productGroupName}</h1>

      <div className="d-flex justify-content-between mt-4">
        <OptionsControl items={menuItems} />
        <div className="btn-group">
          <button
            className="btn btn-primary"
            onClick={() => saveAndSetFilter({ ...filter, show: !filter.show })}
          >
            <FontAwesomeIcon icon={faFilter} />
          </button>

          {!searchFilterUtil.areFiltersEqual(defaultFilter, filter) && (
            <button
              className="btn btn-secondary"
              onClick={() => saveAndSetFilter({ ...defaultFilter })}
            >
              <FontAwesomeIcon icon={faRemove} />
            </button>
          )}
        </div>
      </div>

      <div style={{ display: filter.show ? "initial" : "none" }}>
        <div className="mt-3">
          <TextInput
            ref={queryRef}
            name="productQuery"
            value={filter.query}
            placeholder="Søg - nummer, navn, tags"
            onChange={(value) => saveAndSetFilter({ ...filter, query: value })}
          />
        </div>
      </div>

      <div className="form-group mt-3">
        <label className="form-label">Produkter</label>
        <TableControl
          columns={[
            { header: "Navn", type: "string", valueExtractor: (p) => p.name },
            {
              header: "Sub produkter",
              type: "string",
              valueExtractor: (p) => p.subProducts.length,
            },
            {
              header: "Antal på www",
              type: "string",
              valueExtractor: (p) =>
                p.subProducts.filter((s) => s.showOnHomepage).length,
            },
            {
              header: "Antal billeder",
              type: "string",
              valueExtractor: (p) => p.medias.length,
            },
            {
              header: "Antal dokumenter",
              type: "string",
              valueExtractor: (p) => p.documents.length,
            },
          ]}
          rows={filteredProducts}
          keyExtractor={(p) => p._id}
          onRowClicked={handleProductClicked}
        />
      </div>
    </div>
  );
};

export default ProductGroupProductsPage;
