import { Modal } from "react-bootstrap";

import TableControl from "./table.control";

export default ({ show, onClose, documents }) => {
  const handleDocumentClicked = (document) => {
    onClose(document);
  };

  return (
    <Modal show={show} onHide={() => onClose()} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Vælg dokument</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TableControl
          columns={[
            { header: "Navn", type: "string", valueExtractor: (d) => d.name },
          ]}
          rows={documents}
          onRowClicked={handleDocumentClicked}
          keyExtractor={(d) => d._id}
        />
      </Modal.Body>
    </Modal>
  );
};
