import React, { useEffect, useState } from "react";

import { useException } from "../../../../contexts/exception.context";
import { useMessage } from "../../../../contexts/message.context";
import { useAppState } from "../../../../contexts/appState.context";
import SelectInput from "../../../../controls/select.input";

const SettingsPage = () => {
  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [oldSettings, setOldSettings] = useState();
  const [newSettings, setNewSettings] = useState();

  const isReadonly = !appStateCtx.user.permissionIds.includes(
    "admin.settings.edit"
  );

  const refresh = async () => {
    try {
      const settings =
        await appStateCtx.apiServerClient.employee.settings.get();
      setOldSettings(settings);
      setNewSettings(JSON.parse(JSON.stringify(settings)));
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  const isFormOk = () => {
    return true;
  };

  const hasFormChanged = () => {
    return JSON.stringify(oldSettings) !== JSON.stringify(newSettings);
  };

  const handleUpdateClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.settings.update(
        newSettings.cartState
      );
      refresh();
      messageCtx.showSuccess("Indstillinger opdateret");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  if (!oldSettings) return null;

  return (
    <div className="container page">
      <h1>Indstillinger</h1>
      <div className="form-group mt-3">
        <label className="form-label">Vis kurv</label>
        <div>
          <SelectInput
            items={["Ja - Med priser", "Ja - Uden priser", "Nej"]}
            nameExtractor={(t) => t}
            valueExtractor={(t) => t}
            value={newSettings.cartState}
            readonly={isReadonly}
            onChange={(value) =>
              setNewSettings({ ...newSettings, cartState: value })
            }
          />
        </div>
      </div>
      {!isReadonly && (
        <div className="mt-3">
          <button
            className="btn btn-primary"
            onClick={handleUpdateClicked}
            disabled={!isFormOk() || !hasFormChanged()}
          >
            Opdater
          </button>
        </div>
      )}
    </div>
  );
};

export default SettingsPage;
