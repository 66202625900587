import React, { createContext, useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import productUtil from "../../utils/product.util";

import { useAppState } from "./appState.context";
import TableControl from "../controls/table.control";

const FavoritesContext = createContext(null);

export const useFavorites = () => {
  return useContext(FavoritesContext);
};

export const FavoritesProvider = ({ children }) => {
  const navigate = useNavigate();

  const appStateCtx = useAppState();

  const [showModal, setShowModal] = useState(false);
  const [favorites, setFavorites] = useState([]);

  useEffect(() => {
    setFavorites(appStateCtx.user?.favorites || []);
  }, [appStateCtx.user]);

  const existFavorite = (product) => {
    return favorites.some(
      (f) =>
        f.productGroup.name === product.productGroup.name &&
        f.name === product.name
    );
  };

  const addFavorite = async (product) => {
    const favorite =
      await appStateCtx.apiServerClient.customer.customers.insertCustomerContactFavorite(
        product.productGroup.name,
        product.name
      );
    const favorites2 = [...favorites, favorite];
    setFavorites(favorites2);
  };

  const removeFavorite = async (product) => {
    await appStateCtx.apiServerClient.customer.customers.deleteCustomerContactFavorite(
      product._id
    );
    const favorites2 = favorites.filter(
      (f) =>
        !(
          f.productGroup.name === product.productGroup.name &&
          f.name === product.name
        )
    );
    setFavorites(favorites2);
  };

  const handleProductClicked = (product) => {
    navigate(productUtil.getAbsoluteUrl(product));
  };

  return (
    <FavoritesContext.Provider
      value={{
        show: setShowModal,
        addFavorite,
        removeFavorite,
        favorites,
        existFavorite,
      }}
    >
      {children}
      {/* 
      {appStateCtx.user?.type === "CustomerContact" && ( */}
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Favoritter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TableControl
            rows={favorites}
            columns={[
              {
                header: "Navn",
                type: "string",
                valueExtractor: (p) => p.name,
              },
            ]}
            keyExtractor={(p) => p._id}
            onRowClicked={handleProductClicked}
          />
        </Modal.Body>
      </Modal>
      {/* )} */}
    </FavoritesContext.Provider>
  );
};
