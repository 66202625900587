import React from "react";
// check index.css for styling (search for fr-view)

// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";

// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

// Require Font Awesome.
//import "font-awesome/css/font-awesome.css";

import "froala-editor/js/languages/da.js";
import "froala-editor/js/plugins/font_size.min.js";
import "froala-editor/js/plugins/colors.min.js";
import "froala-editor/js/plugins/lists.min.js";
import "froala-editor/js/plugins/paragraph_format.min.js";
import "froala-editor/js/plugins/image.min.js";
import "froala-editor/js/plugins/link.min.js";
import "froala-editor/js/plugins/font_family.min.js";

import Froalaeditor from "froala-editor";
import FroalaEditor from "react-froala-wysiwyg";

Froalaeditor.DefineIcon("defaultText", { NAME: "plus", SVG_KEY: "insertMore" });
Froalaeditor.RegisterCommand("defaultText", {
  title: "Standard tekst",
  focus: true,
  undo: true,
  refreshAfterCallback: true,
  callback: function () {
    this.html.set(
      "<h3>Anvendelse</h3><h3>Produktbeskrivelse</h3><h3>Brugsanvisning</h3>"
    );
    //this.events.focus();
  },
});

Froalaeditor.DefineIcon("clear", { NAME: "clear", SVG_KEY: "remove" });
Froalaeditor.RegisterCommand("clear", {
  title: "Slet alt",
  focus: true,
  undo: true,
  refreshAfterCallback: true,
  callback: function () {
    this.html.set("");
  },
});

// todo pick product
// alert(JSON.stringify(Froalaeditor.ICON_TEMPLATES));
// alert(Froalaeditor.ICON_DEFAULT_TEMPLATE);
// alert(JSON.stringify(Froalaeditor.SVG));
//console.log(Froalaeditor.ICONS);
//console.log(Froalaeditor.SVG);

Froalaeditor.DefineIcon("insertField", {
  NAME: "tag",
  SVG_KEY: "tags",
}); // uses fontawesome

Froalaeditor.RegisterCommand("insertField", {
  title: "Indsæt felt",
  type: "dropdown",
  focus: true,
  undo: true,
  options: {},
  refreshAfterCallback: true,
  callback: function (cmd, val, params) {
    this.html.insert(val);
  },
  refreshOnShow: function ($btn, $dropdown) {
    var editorInstance = this,
      list = $dropdown.find("ul.fr-dropdown-list"),
      fields = editorInstance.opts.custom.getFields();

    list.empty();

    fields.forEach((field) => {
      const li = document.createElement("li");
      const a = document.createElement("a");
      a.setAttribute("class", "fr-command");
      a.setAttribute("title", field.id);
      a.onclick = () => {
        editorInstance.html.insert(field.html);
      };
      a.innerText = field.id;
      li.appendChild(a);
      list.append(li);
    });

    // if (!editorInstance.selection.inEditor()) {
    //   // Move cursor position to end.
    //   editorInstance.selection.setAtEnd(editorInstance.$el.get(0));
    //   editorInstance.selection.restore();
    // }
  },
});

const HtmlInput = ({ value, onChange, type, disabled, readonly, fields }) => {
  if (readonly) {
    return (
      <div
        className="form-control-plaintext"
        dangerouslySetInnerHTML={{ __html: value }}
      ></div>
    );
  } else {
    let buttons, config;

    if (type === "product") {
      buttons = [
        ["bold", "italic", "underline"],
        ["formatOL", "formatUL"],
        ["paragraphFormat", "textColor", "backgroundColor"],
        ["insertLink"],
        ["undo", "redo", "clearFormatting", "clear"],
        ["defaultText", "pick"],
      ];
      config = {
        events: {
          contentChanged: function () {
            removeDummyStyles(this.$el[0]);
          },
        },
        language: "da",
        toolbarButtons: buttons,
        pasteAllowedStyleProps: ["color"],
        htmlAllowedStyleProps: [
          "color",
          "width",
          "text-align",
          "vertical-align",
          "background-color",
        ],
        // 'font-family', 'font-size',
      };
    } else if (type === "email") {
      buttons = [
        ["bold", "italic", "underline"],
        ["formatOL", "formatUL"],
        [
          "fontFamily",
          "fontSize",
          // "paragraphFormat",
          "textColor",
          "backgroundColor",
        ],
        ["insertLink", "insertImage"],
        ["undo", "redo", "clearFormatting", "clear"],
      ];
      config = {
        events: {},
        fontFamilyDefaultSelection: "Arial",
        fontFamilySelection: true,
        language: "da",
        toolbarButtons: buttons,
        // Set the image upload URL.
        imageUploadURL: "/api/v1/files/uploadEmailImage",

        // Additional upload params.
        imageUploadParams: { id: "my_editor" },

        // Set request type.
        imageUploadMethod: "POST",

        // Set max image size to 5MB.
        imageMaxSize: 5 * 1024 * 1024,

        // Allow to upload PNG and JPG.
        imageAllowedTypes: ["jpeg", "jpg", "png"],

        pasteAllowedStyleProps: ["color"],
      };
    } else if (type === "emailTemplate") {
      buttons = [
        ["bold", "italic", "underline"],
        ["formatOL", "formatUL"],
        ["paragraphFormat", "textColor", "backgroundColor"],
        ["insertLink"],
        ["undo", "redo", "clearFormatting", "clear"],
        ["insertField"],
      ];
      config = {
        custom: { getFields: () => fields },
        events: {},
        language: "da",
        toolbarButtons: buttons,
        pasteAllowedStyleProps: ["color"],
      };
    } else {
      buttons = [
        ["bold", "italic", "underline"],
        ["formatOL", "formatUL"],
        ["paragraphFormat", "textColor", "backgroundColor"],
        ["insertLink"],
        ["undo", "redo", "clearFormatting", "clear"],
      ];
      config = {
        events: {},
        language: "da",
        toolbarButtons: buttons,
        pasteAllowedStyleProps: ["color"],
      };
    }

    if (disabled) {
      config.events = {
        initialized: function () {
          this.edit.off();
        },
      };
    }

    return (
      <FroalaEditor model={value} onModelChange={onChange} config={config} />
    );
  }
};

const removeDummyStyles = (elm) => {
  // we remove froala styles that are not used, but destroys datasheets
  if (elm.style?.backgroundColor === "transparent") {
    elm.style.removeProperty("background-color");
    elm.style.removeProperty("font-size");
    elm.style.removeProperty("font-weight");
  }

  var node;
  // Handle child elements
  for (node = elm.firstChild; node; node = node.nextSibling) {
    if (node.nodeType === 1) {
      // 1 == Element
      removeDummyStyles(node);
    }
  }
};

// Kick it off starting with the `body` element

export const sanitizeHtml = (html) => {
  if (!html) return "<div></div>";

  var el = document.createElement("div");

  el.innerHTML = html;

  removeDummyStyles(el);

  if (el.lastChild) {
    if (el.lastChild.innerHTML.includes("Powered by")) {
      el.removeChild(el.lastChild);
    }
  }

  return el.innerHTML;
};

export default HtmlInput;
