const getMenuItems = (user, employee, selectedName) => {
  const menuItems = [
    {
      name: "Medarbejder",
      url: `/admin/medarbejdere/${employee.employeeNumber}`,
      hasPermissions: (user) =>
        user.permissionIds.includes("admin.employees.view"),
      selected: selectedName === "Medarbejder",
    },
    {
      name: "Rapporter",
      url: `/admin/medarbejdere/${employee.employeeNumber}/rapporter`,
      hasPermissions: (user) =>
        user.permissionIds.includes("admin.employees.reports.view"),
      selected: selectedName === "Rapporter",
    },
  ];
  return menuItems.filter((i) => i.hasPermissions(user));
};

export default getMenuItems;
