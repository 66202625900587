import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { useException } from "../../contexts/exception.context";
import { useAppState } from "../../contexts/appState.context";
import ProductControl from "../../controls/product.control";
import MetadataControl from "../../controls/metadata.control";

const ProductGroupPage = () => {
  const params = useParams();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();

  const [products, setProducts] = useState([]);

  const refresh = async () => {
    try {
      const products =
        await appStateCtx.apiServerClient.visitor.products.getByProductGroupName(
          params.productGroupName
        );
      products.sort((a, b) => a.name.localeCompare(b.name));
      setProducts(products);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  return (
    <section className="container page">
      <MetadataControl
        title={`Capp2 - Produkt gruppe: ${params.productGroupName}`}
        description={params.productGroupName}
      />
      <div className="row">
        <div className="col-12">
          <h1 className="mb-5">{params.productGroupName}</h1>
        </div>
      </div>
      <div className="row">
        {products.map((p) => (
          <ProductControl product={p} key={p._id} />
        ))}
      </div>
    </section>
  );
};

export default ProductGroupPage;
