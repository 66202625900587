import { useRef, useState } from "react";
import { Modal } from "react-bootstrap";

import { useAppState } from "../../../contexts/appState.context";
import { useException } from "../../../contexts/exception.context";
import { useMessage } from "../../../contexts/message.context";
import PasswordInput from "../../../controls/password.input";

const WelcomePage = () => {
  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const password1Ref = useRef();
  const password2Ref = useRef();

  const handleChangePasswordClicked = () => {
    setShowModal(true);
  };

  const handleChangePasswordConfirmClicked = async () => {
    try {
      await appStateCtx.apiServerClient.customer.customers.changePassword(
        password1
      );
      setPassword1("");
      setPassword2("");
      setViewPassword(false);
      setShowModal(false);
      messageCtx.showSuccess("Kodeord ændret");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const isFormOk = () => {
    if (password1.length < 6) return false;
    if (password1 !== password2) return false;

    return true;
  };

  const setPassword1Proxy = (txt) => {
    setPassword1(txt.trim());
  };

  const setPassword2Proxy = (txt) => {
    setPassword2(txt.trim());
  };

  const handleViewPasswordClicked = () => {
    const newViewPassword = !viewPassword;

    password1Ref.current.type = newViewPassword ? "text" : "password";
    password2Ref.current.type = newViewPassword ? "text" : "password";

    setViewPassword(newViewPassword);
  };

  return (
    <div className="container page">
      <h1>Profil</h1>
      <div className="form-group mt-3">
        <label className="form-label">Kunde</label>
        <div>{appStateCtx.user.customerName}</div>
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Kontakt</label>
        <div>{appStateCtx.user.name}</div>
      </div>

      <div className="mt-3">
        <button
          className="btn btn-primary"
          onClick={handleChangePasswordClicked}
        >
          Skift kodeord
        </button>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Nyt kodeord</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group ">
            <label className="form-label">Skriv nyt kodeord</label>
            <PasswordInput
              value={password1}
              ref={password1Ref}
              onChange={(value) => setPassword1Proxy(value)}
            />
          </div>
          <div className="form-group mt-2">
            <label className="form-label">Skriv nyt kodeord igen</label>
            <PasswordInput
              value={password2}
              ref={password2Ref}
              onChange={(value) => setPassword2Proxy(value)}
            />
          </div>
          <div className="form-group mt-2">
            Kodeord skal bestå af mindst 6 tal eller bogstaver
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            onClick={handleChangePasswordConfirmClicked}
            disabled={!isFormOk()}
          >
            Skift kodeord
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default WelcomePage;
