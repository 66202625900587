import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import QRCode from "react-qr-code";
import toImg from "react-svg-to-image";
import { DateTime } from "luxon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH, faImage } from "@fortawesome/free-solid-svg-icons";

import settings from "../../../../../utils/settings";
import validateUtil from "../../../../../utils/validate.util";

import { useException } from "../../../../contexts/exception.context";
import { useMessage } from "../../../../contexts/message.context";
import { useAppState } from "../../../../contexts/appState.context";
import TextInput from "../../../../controls/text.input";
import SelectProductModal from "../../../../controls/selectproduct.modal";
import SelectProductDocumentModal from "../../../../controls/selectproductdocument.modal";
import FileViewerModal from "../../../../controls/fileViewer.modal";

const QRCodePage = () => {
  const navigate = useNavigate();
  const params = useParams();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [showSelectProductModal, setShowSelectProductModal] = useState(false);
  const [products, setProducts] = useState([]);

  const [showSelectDocumentModal, setShowDocumentModal] = useState(false);
  const [showFileViewerModal, setShowFileViewerModal] = useState(false);

  const [oldCode, setOldCode] = useState();
  const [newCode, setNewCode] = useState();

  const isReadonly =
    !appStateCtx.user.permissionIds.includes("admin.qrcodes.edit") || !!oldCode;

  const refresh = async () => {
    try {
      if (params.qrCodeId) {
        const code = await appStateCtx.apiServerClient.employee.qrCodes.getById(
          params.qrCodeId
        );

        code.product = {
          productGroup: { code: code.productGroupName },
          name: code.productName,
        };
        setOldCode(code);
        setNewCode(JSON.parse(JSON.stringify(code)));
      } else {
        let products =
          await appStateCtx.apiServerClient.employee.products.getAll();

        products = products.filter((p) =>
          p.documents.some((d) => d.type === "Sikkerhedsdatablad")
        );
        setProducts(products);
        setNewCode({
          description: "",
          product: undefined,
          document: undefined,
        });
      }
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, [params.qrCodeId]);

  const handleSaveQRClicked = () => {
    toImg("#qrcode", "qrkode", {
      scale: 3,
    });
  };

  const handleCreateClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.qrCodes.insert(
        newCode.description,
        newCode.product.productGroup.name,
        newCode.product.name,
        newCode.document
      );
      messageCtx.showSuccess("QR kode gemt");
      navigate("./..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleSelectProductClicked = () => {
    setShowSelectProductModal(true);
  };

  const handleSelectProductModalClose = (product) => {
    setShowSelectProductModal(false);
    if (!product) return;

    setNewCode({ ...newCode, product });
  };

  const handleSelectDocumentClicked = () => {
    setShowDocumentModal(true);
  };

  const handleSelectProductDocumentModalClose = (document) => {
    setShowDocumentModal(false);
    if (!document) return;

    setNewCode({ ...newCode, document });
  };

  const isFormOk = () => {
    if (!validateUtil.string.isValid(newCode.description)) return false;
    if (!newCode.document) return false;

    return true;
  };

  const handleShowPdfClicked = () => {
    setShowFileViewerModal(true);
  };

  if (!newCode) return null;

  return (
    <div className="container page">
      <h1>QR kode</h1>
      <div className="mt-3">
        <div className="form-group mt-3">
          <label className="form-label">Beskrivelse</label>
          <TextInput
            value={newCode.description}
            readonly={isReadonly}
            onChange={(value) => setNewCode({ ...newCode, description: value })}
          />
        </div>

        {oldCode && (
          <div className="form-group mt-3">
            <label className="form-label">Beskrivelse</label>
            <TextInput
              value={DateTime.fromISO(oldCode.createdAt).toFormat(
                settings.dateShortFormat
              )}
              readonly
            />
          </div>
        )}
        <div className="form-group mt-3">
          <label className="form-label">Produkt</label>
          <div className="input-group">
            <TextInput value={newCode.product?.name || ""} disabled />

            {!isReadonly && (
              <button
                className="btn btn-primary py-0"
                onClick={handleSelectProductClicked}
              >
                <FontAwesomeIcon icon={faEllipsisH} />
              </button>
            )}
          </div>
        </div>
        {newCode.product && (
          <div className="form-group mt-3">
            <label className="form-label">Sikkerhedsdatablad</label>
            <div className="input-group">
              <TextInput
                value={newCode?.name || newCode.document?.name || ""}
                disabled
              />

              {!isReadonly && (
                <button
                  className="btn btn-primary py-0"
                  onClick={handleSelectDocumentClicked}
                >
                  <FontAwesomeIcon icon={faEllipsisH} />
                </button>
              )}

              {newCode.document && (
                <button
                  className="btn btn-success py-0"
                  onClick={handleShowPdfClicked}
                >
                  <FontAwesomeIcon icon={faImage} />
                </button>
              )}
            </div>
          </div>
        )}

        {oldCode && (
          <div className="form-group mt-3">
            <label className="form-label">QR til etikette</label>
            <div className="text-center">
              <QRCode
                id="qrcode"
                size={256}
                style={{ height: "256", width: "256" }}
                value={oldCode.document.indirectUrl}
                viewBox={`0 0 256 256`}
              />
            </div>
          </div>
        )}
        {oldCode && (
          <div className="form-group space-children mt-3">
            <button className="btn btn-primary" onClick={handleSaveQRClicked}>
              Opdater
            </button>
          </div>
        )}
        {!isReadonly && (
          <div className="form-group space-children mt-3">
            <button
              className="btn btn-primary"
              onClick={handleCreateClicked}
              disabled={!isFormOk()}
            >
              Gem
            </button>
          </div>
        )}
      </div>

      <SelectProductModal
        show={showSelectProductModal}
        onClose={handleSelectProductModalClose}
        products={products}
      />

      <SelectProductDocumentModal
        documents={newCode.product?.documents?.filter(
          (d) => d.type === "Sikkerhedsdatablad"
        )}
        show={showSelectDocumentModal}
        onClose={handleSelectProductDocumentModalClose}
      />
      <FileViewerModal
        show={showFileViewerModal}
        setShow={setShowFileViewerModal}
        images={[newCode.document]}
      />
    </div>
  );
};
export default QRCodePage;
