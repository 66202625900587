import { useEffect, useState } from "react";
import { Dropdown, InputGroup } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import { useAppState } from "../../../../contexts/appState.context";
import { useException } from "../../../../contexts/exception.context";
import { useMessage } from "../../../../contexts/message.context";
import TextInput from "../../../../controls/text.input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import TextareaInput from "../../../../controls/textarea.input";
import SelectInput from "../../../../controls/select.input";
import SelectproductModal from "../../../../controls/selectproduct.modal";

const reasons = ["Bestseller", "Nyhed", "Udsalg"];

const FocusProductPage = () => {
  const navigate = useNavigate();
  const params = useParams(); // productGroupName, productName

  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [showSelectProductModal, setShowSelectProductModal] = useState(false);
  const [products, setProducts] = useState([]);

  const [oldProduct, setOldProduct] = useState();
  const [newProduct, setNewProduct] = useState();

  const isReadonly = !appStateCtx.user.permissionIds.includes(
    "admin.focusproducts.edit"
  );

  const refresh = async () => {
    try {
      if (params.productGroupName) {
        const product =
          await appStateCtx.apiServerClient.employee.products.getByName(
            params.productGroupName,
            params.productName
          );

        product.focus.reason = product.focus.reason || "";

        setOldProduct(product);
        setNewProduct(JSON.parse(JSON.stringify(product)));
      } else {
        const products =
          await appStateCtx.apiServerClient.employee.products.getAll(true);

        setProducts(products);
        setNewProduct({
          name: "",
          productGroup: { name: "" },
          focus: { text: "", reason: "", enabled: false },
        });
      }
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleSelectProductModalClose = (product) => {
    setShowSelectProductModal(false);
    if (!product) return;

    const product2 = { ...product };

    if (!product2.focus) {
      product2.focus = { text: "", reason: "", enabled: false };
    }
    setNewProduct(product2);
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  const handleDeleteClicked = async (p) => {
    try {
      await appStateCtx.apiServerClient.employee.products.deleteFocusProduct(
        params.productGroupName,
        params.productName
      );
      navigate("../..");
      messageCtx.showSuccess("Produkt i fokus slettet");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleSaveClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.products.insertOrUpdateFocusProduct(
        newProduct.productGroup.name,
        newProduct.name,
        newProduct.focus.text,
        newProduct.focus.reason.trim(),
        newProduct.focus.enabled
      );
      if (window.location.href.endsWith("ny")) {
        navigate("./..");
      } else {
        navigate("../..");
      }
      messageCtx.showSuccess("Produkt i fokus gemt");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const isFormOk = () => {
    if (!newProduct.focus?.text) return false;

    return true;
  };

  const hasFormChanged = () => {
    return JSON.stringify(oldProduct) !== JSON.stringify(newProduct);
  };

  const handleSelectProductClicked = () => {
    setShowSelectProductModal(true);
  };

  if (!newProduct) return null;

  return (
    <div className="container page">
      <h1>Produkt i fokus</h1>

      <div className="form-group mt-3">
        <label className="form-label">Produkt</label>
        <div className="input-group">
          <TextInput
            value={newProduct.name}
            readonly={isReadonly || oldProduct}
          />
          {!isReadonly && !params.productGroupName && (
            <button
              className="btn btn-primary py-0"
              onClick={handleSelectProductClicked}
            >
              <FontAwesomeIcon icon={faEllipsisH} />
            </button>
          )}
        </div>
      </div>

      <div className="form-group mt-3">
        <label className="form-label">Tekst</label>
        <TextareaInput
          rows={7}
          value={newProduct.focus?.text || ""}
          readonly={isReadonly}
          onChange={(value) =>
            setNewProduct({
              ...newProduct,
              focus: { ...newProduct.focus, text: value },
            })
          }
        />
      </div>

      <div className="form-group mt-3">
        <label className="form-label">Hvorfor i fokus</label>
        <InputGroup>
          <TextInput
            value={newProduct.focus?.reason || ""}
            readonly={isReadonly}
            onChange={(value) =>
              setNewProduct({
                ...newProduct,
                focus: { ...newProduct.focus, reason: value },
              })
            }
          />
          {!isReadonly && (
            <Dropdown>
              <Dropdown.Toggle variant="primary" className="py-0">
                <FontAwesomeIcon icon={faEllipsisH} />
              </Dropdown.Toggle>
              <Dropdown.Menu drop="end">
                {reasons.map((reason) => (
                  <Dropdown.Item
                    onClick={() =>
                      setNewProduct({
                        ...newProduct,
                        focus: { ...newProduct.focus, reason },
                      })
                    }
                    key={reason}
                  >
                    {reason}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </InputGroup>
      </div>

      <div className="form-group mt-3">
        <label className="form-label">Vis</label>
        <SelectInput
          items={["Ja", "Nej"]}
          valueExtractor={(i) => i}
          nameExtractor={(i) => i}
          value={newProduct.focus?.enabled ? "Ja" : "Nej"}
          readonly={isReadonly}
          onChange={(value) =>
            setNewProduct({
              ...newProduct,
              focus: {
                ...newProduct.focus,
                enabled: value === "Ja",
              },
            })
          }
        />
      </div>

      {!isReadonly && (
        <div className="space-children mt-3">
          <button
            className="btn btn-primary"
            onClick={handleSaveClicked}
            disabled={!isFormOk() || !hasFormChanged()}
          >
            Gem
          </button>
          {params.productGroupName && (
            <button className="btn btn-secondary" onClick={handleDeleteClicked}>
              Slet
            </button>
          )}
        </div>
      )}

      <SelectproductModal
        show={showSelectProductModal}
        onClose={handleSelectProductModalClose}
        products={products}
      />
    </div>
  );
};

export default FocusProductPage;
