import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import validateUtil from "../../../../../utils/validate.util";

import { useException } from "../../../../contexts/exception.context";
import { useMessage } from "../../../../contexts/message.context";
import { useAppState } from "../../../../contexts/appState.context";
import TextInput from "../../../../controls/text.input";
import SelectInput from "../../../../controls/select.input";
import FileInput from "../../../../controls/file.input";

const BannerPage = () => {
  const navigate = useNavigate();
  const params = useParams();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [oldBanner, setOldBanner] = useState();
  const [newBanner, setNewBanner] = useState();

  const isReadonly =
    !appStateCtx.user.permissionIds.includes("admin.banners.edit");

  const refresh = async () => {
    try {
      if (params.bannerId) {
        const banner =
          await appStateCtx.apiServerClient.employee.banners.getById(
            params.bannerId
          );
        setOldBanner(banner);
        setNewBanner(JSON.parse(JSON.stringify(banner)));
      } else {
        setNewBanner({
          name: "",
          title: "",
          subTitle: "",
          image: undefined,
          showButtons: false,
          enabled: false,
        });
      }
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, [params.bannerId]);

  const handleUpdateClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.banners.update(
        newBanner._id,
        newBanner.name,
        newBanner.title,
        newBanner.subTitle,
        newBanner.image,
        newBanner.showButtons,
        newBanner.enabled
      );
      messageCtx.showSuccess("Banner opdateret");
      navigate("./..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleCreateClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.banners.insert(
        newBanner.name,
        newBanner.title,
        newBanner.subTitle,
        newBanner.image,
        newBanner.showButtons,
        newBanner.enabled
      );
      messageCtx.showSuccess("Banner gemt");
      navigate("./..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleDeleteClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.banners.delete(
        params.bannerId
      );
      messageCtx.showSuccess("Banner slettet");
      navigate("./..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const isFormOk = () => {
    if (!validateUtil.string.isValid(newBanner.name)) return false;
    if (!newBanner.image) return false;

    return true;
  };

  const hasFormChanged = () => {
    return JSON.stringify(oldBanner) !== JSON.stringify(newBanner);
  };

  if (!newBanner) return null;

  return (
    <div className="container page">
      <h1>Banner</h1>
      <div className="mt-3">
        <div className="form-group mt-3">
          <label className="form-label">Navn</label>
          <TextInput
            value={newBanner.name}
            readonly={isReadonly}
            onChange={(value) => setNewBanner({ ...newBanner, name: value })}
          />
        </div>

        <div className="form-group mt-3">
          <label className="form-label">Titel</label>
          <TextInput
            value={newBanner.title}
            readonly={isReadonly}
            onChange={(value) => setNewBanner({ ...newBanner, title: value })}
          />
        </div>

        <div className="form-group mt-3">
          <label className="form-label">Undertitel</label>
          <TextInput
            value={newBanner.subTitle}
            readonly={isReadonly}
            onChange={(value) =>
              setNewBanner({ ...newBanner, subTitle: value })
            }
          />
        </div>

        <div className="form-group mt-3">
          <label className="form-label">Vis knapper</label>
          <SelectInput
            items={["Ja", "Nej"]}
            valueExtractor={(i) => i}
            nameExtractor={(i) => i}
            value={newBanner.showButtons ? "1" : "0"}
            readonly={isReadonly}
            onChange={(value) =>
              setNewBanner({
                ...newBanner,
                showButtons: value === "1",
              })
            }
          />
        </div>

        <div className="form-group mt-3">
          <label className="form-label">Billede</label>
          <FileInput
            value={newBanner.image}
            onChange={(value) =>
              setNewBanner({
                ...newBanner,
                image: value,
              })
            }
            accept=".png, .jpeg, .jpg"
            allowEmpty={false}
            maxSize={1024 * 1024 * 5}
            showSeeImageButton
            readonly={isReadonly}
          />
        </div>

        <div className="form-group mt-3">
          <label className="form-label">Vis</label>
          <SelectInput
            items={["Ja", "Nej"]}
            valueExtractor={(i) => i}
            nameExtractor={(i) => i}
            value={newBanner.enabled ? "Ja" : "Nej"}
            readonly={isReadonly}
            onChange={(value) =>
              setNewBanner({
                ...newBanner,
                enabled: value === "Ja",
              })
            }
          />
        </div>

        {!isReadonly && (
          <div className="form-group space-children mt-3">
            {!params.bannerId && (
              <button
                className="btn btn-primary"
                onClick={handleCreateClicked}
                disabled={!isFormOk()}
              >
                Gem
              </button>
            )}

            {params.bannerId && (
              <button
                className="btn btn-primary"
                onClick={handleUpdateClicked}
                disabled={!isFormOk() || !hasFormChanged()}
              >
                Opdater
              </button>
            )}

            {params.bannerId && (
              <button
                className="btn btn-secondary"
                onClick={handleDeleteClicked}
              >
                Slet
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default BannerPage;
