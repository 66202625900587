import { convert } from "html-to-text";

const util = {
  textToHtml: (text) => {
    if (!text) return "";
    return text.replaceAll("\n", "<br/>");
  },
  htmlToText: (html) => {
    if (!html) return "";
    return convert(html);
  },
  capitalizeFirstLetter: (txt) => {
    if (!txt) return "";
    return txt[0].toUpperCase() + txt.substring(1).toLowerCase();
  },
  /**
   *
   * @param {String} txt
   * @param {String} query
   * @param {Boolean} caseInsensitive
   */
  includesCI: (txt, query) => {
    //if (!query) return false;
    return txt.toLowerCase().includes(query.toLowerCase());
  },
};

export default util;
