import React from "react";
import { DateTime } from "luxon";

import settings from "../../utils/settings";
import stringUtil from "../../utils/string.util";

import TableControl from "../controls/table.control";

const OrderControl = ({ order, showType }) => {
  if (!order) return null;

  return (
    <div className="order">
      <div className="mt-3">
        <h4>Generalt</h4>
      </div>

      <div className="form-group mt-3">
        <label className="form-label">Dato</label>
        <div className="form-text">
          {DateTime.fromISO(order.createdAt).toFormat(settings.dateShortFormat)}
        </div>
      </div>

      {showType && (
        <div className="form-group mt-3">
          <label className="form-label">Type</label>
          <div className="form-text">Kunde</div>
        </div>
      )}

      <div className="form-group mt-3">
        <label className="form-label">Indkøbskurv</label>
        <div className="form-text">{order.cartState}</div>
      </div>

      <div className="form-group mt-3">
        <label className="form-label">Bemærkninger</label>
        <div className="form-text">{stringUtil.textToHtml(order.remarks)}</div>
      </div>

      <div className="form-group mt-3">
        <label className="form-label">Rekvisitions nr.</label>
        <div className="form-text">{order.requisitionNumber}</div>
      </div>

      <div className="mt-3">
        <h4>Kunde</h4>
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Navn</label>
        <div className="form-text">{order.customer.name}</div>
      </div>

      <div className="mt-3">
        <h4>Kontakt person</h4>
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Navn</label>
        <div className="form-text">{order.contact.name}</div>
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Telefon numre</label>
        <div className="form-text">{order.contact.phone}</div>
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Email</label>
        <div className="form-text">{order.contact.email}</div>
      </div>

      <div className="mt-3">
        <h4>Linjer</h4>
      </div>

      <div className="mt-3">
        <TableControl
          columns={[
            {
              header: "Nummer",
              valueExtractor: (l) => l.subProduct.subProductNumber,
            },
            {
              header: "Beskrivelse",
              valueExtractor: (l) => l.subProduct.name,
            },
            {
              header: "Enhed",
              valueExtractor: (l) => l.subProduct.unit?.name,
            },
            {
              header: "Antal",
              valueExtractor: (l) => l.amount,
              className: "text-end",
            },
            {
              header: "Pris",
              valueExtractor: (l) => (l.subProduct.salesPrice || 0).toFixed(2),
              className: "text-end",
            },
            {
              header: "Total",
              valueExtractor: (l) =>
                (l.amount * (l.subProduct.salesPrice || 0)).toFixed(2),
              className: "text-end",
            },
          ]}
          rows={order.lines}
          keyExtractor={(l) => l.subProduct.subProductNumber}
          footerColumns={[
            {
              colspan: 5,
              className: "text-end",
              valueExtractor: (r) => r.name,
            },
            {
              className: "text-end",
              valueExtractor: (r) => r.value.toFixed(2),
            },
          ]}
          footerRows={[
            { name: "Subtotal", value: order.totalWithoutVAT },
            { name: "Moms", value: order.totalVAT },
            { name: "Total", value: order.totalWithVAT },
          ]}
          footerKeyExtractor={(r) => r.name}
        />
      </div>
    </div>
  );
};
export default OrderControl;
