export default ({ readonly, value, disabled, onChange, min, max, ...rest }) => {
  if (readonly) {
    return <label className="form-control-plaintext">{value}</label>;
  } else {
    return (
      <input
        type="month"
        className="form-control"
        value={value}
        min={min}
        max={max}
        disabled={disabled}
        onChange={(e) => onChange(e.target.value)}
        {...rest}
      />
    );
  }
};
