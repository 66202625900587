import dateUtil from "../../../../utils/date.util";

const util = {
  getDateTime: (meeting) => {
    const { startDate, startTime, endDate, endTime } = meeting;

    //let result = startDate;
    let result = dateUtil.isoDateToShort(startDate);

    if (startTime) {
      result += " " + startTime;
    }

    if (endDate) {
      if (startDate !== endDate) {
        //result += " - " + endDate;
        result += " - " + dateUtil.isoDateToShort(endDate);
      }
    }

    if (endTime) {
      result += " " + endTime;
    }

    return result;
  },
};

export default util;
