import productUtil from "../../../../utils/product.util";

// we create absolute urls due to limitation/error in react router dom with special chars
const getMenuItems = (user, customer, selectedName) => {
  const getUrl = (name) => {
    if (name) {
      return `/admin/kunder/${customer.customerNumber}/${name}`;
    } else {
      return `/admin/kunder/${customer.customerNumber}`;
    }
  };
  const menuItems = [
    {
      name: "Kunde",
      url: getUrl(),
      hasPermissions: (user) =>
        user.permissionIds.includes("admin.customers.view"),
      selected: selectedName === "Kunde",
    },
    {
      name: "Info",
      url: getUrl("info"),
      hasPermissions: (user) =>
        user.permissionIds.includes("admin.customers.info.view"),
      selected: selectedName === "Info",
    },
    {
      name: "Noter",
      url: getUrl("noter"),
      hasPermissions: (user) =>
        user.permissionIds.includes("admin.customers.notes.view"),
      selected: selectedName === "Noter",
    },
    {
      name: "Fakturaer",
      url: getUrl("fakturaer"),
      hasPermissions: (user) =>
        user.permissionIds.includes("admin.customers.invoices.view"),
      selected: selectedName === "Fakturaer",
    },
    {
      name: "Faktura linjer",
      url: getUrl("fakturalinjer"),
      hasPermissions: (user) =>
        user.permissionIds.includes("admin.customers.invoicelines.view"),
      selected: selectedName === "Faktura linjer",
    },
    {
      name: "Kalender",
      url: getUrl("kalender"),
      hasPermissions: (user) =>
        user.permissionIds.includes("admin.customers.calender.view"),
      selected: selectedName === "Kalender",
    },
    {
      name: "Rapporter",
      url: getUrl("rapporter"),
      hasPermissions: (user) =>
        user.permissionIds.includes("admin.customers.reports.view"),
      selected: selectedName === "Rapporter",
    },
  ];

  return menuItems.filter((i) => i.hasPermissions(user));
};

export default getMenuItems;
