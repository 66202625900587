import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import productGroupUtil from "../../../../utils/productGroup.util";

import { useException } from "../../../contexts/exception.context";
import { useAppState } from "../../../contexts/appState.context";
import { useMessage } from "../../../contexts/message.context";
import OptionsControl from "../../../controls/options.control";
import TextInput from "../../../controls/text.input";
import FileInput from "../../../controls/file.input";

const ProductGroupPage = () => {
  const navigate = useNavigate();
  const params = useParams();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [oldProductGroup, setOldProductGroup] = useState();
  const [newProductGroup, setNewProductGroup] = useState();

  const isReadonly = !appStateCtx.user.permissionIds.includes(
    "admin.products.edit"
  );

  useEffect(() => {
    refresh();
    // eslint-disable-next-line
  }, []);

  const refresh = async () => {
    try {
      const group =
        await appStateCtx.apiServerClient.employee.productGroups.getByName(
          params.productGroupName
        );

      setOldProductGroup(group);
      setNewProductGroup(JSON.parse(JSON.stringify(group)));
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleFileChanged = (file) => {
    if (file) {
      file.url = URL.createObjectURL(file);
      setNewProductGroup({ ...newProductGroup, image: file });
    }
  };

  const handleUpdateClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.productGroups.update(
        newProductGroup._id,
        newProductGroup.image
      );
      navigate(productGroupUtil.getAdminAbsoluteUrl(oldProductGroup));
      messageCtx.showSuccess("Produkt gruppe opdateret");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleDeleteImageClicked = async () => {
    setNewProductGroup({ ...newProductGroup, image: undefined });
  };

  const hasFormChanged = () => {
    return JSON.stringify(oldProductGroup) !== JSON.stringify(newProductGroup);
  };

  const isFormOk = () => {
    return true;
  };

  if (!newProductGroup) return;

  const menuItems = [
    {
      name: "Gruppe",
      url: ".",
      selected: true,
    },
    {
      name: "Produkter",
      url: "produkter",
    },
  ];

  return (
    <div className="container page">
      <h1>Gruppe: {params.productGroupName}</h1>

      <div className="mt-4">
        <OptionsControl items={menuItems} />
      </div>

      <div className="form-group mt-3">
        <label className="form-label">Navn</label>
        <TextInput value={newProductGroup.name} readonly />
      </div>

      <div className="form-group mt-3">
        <label className="form-label">Billede</label>
        <FileInput
          maxSize={1024 * 1024 * 5}
          accept=".png, .jpeg, .jpg"
          value={newProductGroup.image}
          readonly={isReadonly}
          showSeeImageButton={true}
          onChange={handleFileChanged}
        />
      </div>

      {!isReadonly && (
        <div className="form-group mt-3">
          <button
            className="btn btn-primary"
            onClick={handleUpdateClicked}
            disabled={!isFormOk() || !hasFormChanged()}
          >
            Opdater
          </button>
        </div>
      )}
    </div>
  );
};

export default ProductGroupPage;
