import React, { createContext, useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";

const OptionsContext = createContext(null);

export const useOptions = () => {
  return useContext(OptionsContext);
};

export const OptionsProvider = ({ children }) => {
  const navigate = useNavigate();

  const [options, setOptions] = useState();

  const showOptions = (options) => {
    setOptions(options);
  };

  const handleOptionClicked = (option) => {
    navigate(option.url);
  };

  return (
    <OptionsContext.Provider value={{ showOptions }}>
      {children}
      <Modal show={!!options} onHide={() => setOptions()}>
        <Modal.Header closeButton>
          <Modal.Title>Valg</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {options &&
            options.map((option) => (
              <p
                key={option.url}
                onClick={() => handleOptionClicked(option)}
                style={{ cursor: "pointer" }}
              >
                {option.name}
              </p>
            ))}
        </Modal.Body>
      </Modal>
    </OptionsContext.Provider>
  );
};
