import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import reportsUtil from "../../../../../utils/reports.util";

import { useAppState } from "../../../../contexts/appState.context";
import { useException } from "../../../../contexts/exception.context";
import TableControl from "../../../../controls/table.control";

const SubscribersPage = () => {
  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const navigate = useNavigate();

  const [subscribers, setSubscribers] = useState([]);

  const refresh = async () => {
    try {
      const subscribers =
        await appStateCtx.apiServerClient.employee.newsletterSubscribers.getAll();

      setSubscribers(subscribers);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  const handleDownloadXLSXClicked = async () => {
    const columns = [
      {
        header: "Firma navn",
        dataKey: "companyName",
        style: {
          halign: "left",
          width: 50,
        },
      },
      {
        header: "Navn",
        dataKey: "name",
        style: {
          halign: "left",
          width: 50,
        },
      },
      {
        header: "Email",
        dataKey: "email",
        style: {
          halign: "left",
          width: 50,
        },
      },
      {
        header: "Telefon",
        dataKey: "phone",
        style: {
          halign: "left",
          width: 50,
        },
      },
    ];

    const { headerColumnsDef, bodyColumnsDef } =
      reportsUtil.getColumnsDefsFromColumns(columns);

    const headerRow = reportsUtil.getHeaderRowFromColumns(columns);

    const headerRows = [reportsUtil.createRow(headerRow, "header")];

    const bodyRows = subscribers.map((s) => reportsUtil.createRow(s, "body"));

    const report = {
      name: "Nyhedsbrev abonnementer",
      documents: [
        {
          type: "table",
          name: "Nyhedsbrev abonnementer",
          headerRows,
          bodyRows,
          footerRows: [],
          columnsDefs: {
            body: bodyColumnsDef,
            header: headerColumnsDef,
          },
        },
      ],
    };

    reportsUtil.generateXLSX(report);
  };

  const handleSubscriberClicked = (subscriber) => {
    navigate(`./${subscriber._id}`);
  };

  return (
    <div className="container page">
      <h1>Nyhedsbrev abonnementer</h1>
      <div className="d-flex justify-content-between mt-4">
        {subscribers.length > 0 ? (
          <button
            className="btn btn-primary"
            onClick={handleDownloadXLSXClicked}
          >
            XLSX
          </button>
        ) : (
          <div></div>
        )}
      </div>
      <div className="mt-3">
        <TableControl
          columns={[
            { header: "Firmanavn", valueExtractor: (s) => s.companyName },
            { header: "Navn", valueExtractor: (s) => s.name },
            { header: "Email", valueExtractor: (s) => s.email },
            { header: "Telefon", valueExtractor: (s) => s.phone },
          ]}
          rows={subscribers}
          keyExtractor={(s) => s._id}
          onRowClicked={handleSubscriberClicked}
        />
      </div>
    </div>
  );
};

export default SubscribersPage;
