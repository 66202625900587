import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { useAppState } from "../../../../contexts/appState.context";
import { useException } from "../../../../contexts/exception.context";
import TableControl from "../../../../controls/table.control";

const RolesPage = () => {
  const navigate = useNavigate();
  const appStateCtx = useAppState();
  const exceptionCtx = useException();

  const [roles, setRoles] = useState([]);

  const refresh = async () => {
    try {
      const roles = await appStateCtx.apiServerClient.roles.getAll();

      roles.sort((a, b) => a.name.localeCompare(b.name));

      setRoles(roles);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  const handleRoleClicked = (role) => {
    navigate(`./${role._id}`);
  };

  const handleNewClicked = () => {
    navigate(`./ny`);
  };

  const isReadonly =
    !appStateCtx.user.permissionIds.includes("admin.roles.edit");

  return (
    <div className="container page">
      <h1>Roller</h1>
      {!isReadonly && (
        <div className="mt-4">
          <button className="btn btn-primary" onClick={handleNewClicked}>
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
      )}
      <div className="mt-3">
        <TableControl
          columns={[
            {
              header: "Navn",
              valueExtractor: (r) => r.name,
            },
          ]}
          rows={roles}
          keyExtractor={(r) => r._id}
          onRowClicked={handleRoleClicked}
        />
      </div>
    </div>
  );
};

export default RolesPage;
