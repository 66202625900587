import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import { useRef } from "react";

// export const isDateTime = (datetime) => {
//   const datas = datetime.split("T");
//   return datas.length === 2;
// };

// export const getDate = (datetime) => {
//   if (datetime === "") return "";
//   const datas = datetime.split("T");
//   if (datas.length > 0) return datas[0];
//   return "";
// };

// export const getTime = (datetime) => {
//   if (datetime === "") return "";
//   const datas = datetime.split("T");
//   if (datas.length > 1) return datas[1];
//   return "";
// };

export default ({ readonly, disabled, value, onChange }) => {
  const dateRef = useRef();
  const timeRef = useRef();

  const handleDeleteStartClicked = () => {
    onChange({ date: dateRef.current.value, time: "" });
  };

  const handleChange = () => {
    onChange({ date: dateRef.current.value, time: timeRef.current.value });
  };

  if (readonly) {
    return <label className="form-control-plaintext">{value}</label>;
  } else {
    return (
      <div className="input-group">
        <input
          ref={dateRef}
          disabled={disabled}
          type="date"
          className="form-control"
          value={value.date}
          min={DateTime.now().toISODate()}
          onChange={handleChange}
        ></input>
        <input
          ref={timeRef}
          disabled={disabled}
          type="time"
          className="form-control"
          value={value.time}
          onChange={handleChange}
        ></input>
        <button
          className="btn btn-secondary py-0"
          onClick={handleDeleteStartClicked}
        >
          <FontAwesomeIcon icon={faXmark} />
        </button>
      </div>
    );
  }
};
