import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import InvoicesTable from "../../../controls/invoices.table";
import OptionsControl from "../../../controls/options.control";
import { useException } from "../../../contexts/exception.context";
import { useAppState } from "../../../contexts/appState.context";

import getMenuItems from "./customerMenuItems.data";

const CustomerInvoicesPage = () => {
  const params = useParams();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();

  const [customer, setCustomer] = useState();
  const [invoices, setInvoices] = useState([]);

  const refresh = async () => {
    try {
      const customer =
        await appStateCtx.apiServerClient.employee.customers.getByNumber(
          params.customerNumber
        );

      const invoices =
        await appStateCtx.apiServerClient.employee.invoices.getByCustomerNumber(
          params.customerNumber
        );

      invoices.sort((a, b) => b.date.localeCompare(a.date));

      setCustomer(customer);
      setInvoices(invoices);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  if (!customer) return;

  const menuItems = getMenuItems(appStateCtx.user, customer, "Fakturaer");

  return (
    <div className="container page">
      <h1>Kunde fakturaer</h1>
      <div className="mt-4">
        <OptionsControl items={menuItems} />
      </div>
      <InvoicesTable invoices={invoices} />
    </div>
  );
};

export default CustomerInvoicesPage;
