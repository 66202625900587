import { Link } from "react-router-dom";

import dateUtil from "../../../utils/date.util";
import stringUtil from "../../../utils/string.util";

const AnnouncementControl = ({ announcement }) => {
  return (
    <div className="col-12 col-lg-6">
      <div className="card h-100 backgroundcolor-gray p-4">
        <div className="row h-100">
          <div className="col-sm-6 d-none d-lg-flex d-flex flex-column">
            <h3 className="card-title display-bold">{announcement.title}</h3>
            <div className="pb-2 display-9">
              {dateUtil.isoDateToShort(announcement.createdAt)} af Capp2
            </div>
            <p
              className="card-text"
              dangerouslySetInnerHTML={{
                __html: stringUtil.textToHtml(announcement.frontpagetext),
              }}
            ></p>
            <div className="d-flex flex-grow-1 align-items-end">
              <Link
                to={`/nyheder/${announcement._id}`}
                className="btn btn-secondary btn-action"
              >
                Se mere
              </Link>
            </div>
          </div>
          <div className="col-sm-6 d-none d-lg-block backgroundcolor-white">
            <img
              className="w-100 h-100"
              src={announcement.banner.url}
              alt=""
              style={{ objectFit: "contain", maxHeight: 400, maxWidth: "100%" }}
            />
          </div>
          <div className="col-12 d-lg-none text-center">
            <h3 className="card-title display-bold">{announcement.title}</h3>
            <div className="backgroundcolor-white">
              <img
                className="mt-3"
                src={announcement.banner.url}
                alt=""
                style={{
                  objectFit: "contain",
                  maxHeight: 400,
                  maxWidth: "100%",
                }}
              />
            </div>
            <p
              className="card-text mt-3"
              dangerouslySetInnerHTML={{ __html: announcement.frontpagetext }}
            ></p>
            <Link
              to={`/nyheder/${announcement._id}`}
              className="btn btn-secondary btn-action"
            >
              Se mere
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnnouncementControl;
