import { useEffect, useState } from "react";

import { useAppState } from "../../contexts/appState.context";
import { useException } from "../../contexts/exception.context";
import MetadataControl from "../../controls/metadata.control";

import ReferenceControl from "./reference.control";

export default () => {
  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const [references, setReferences] = useState([]);

  const refresh = async () => {
    try {
      const references =
        await appStateCtx.apiServerClient.visitor.references.getAll();

      references.sort((a, b) => a.createdAt.localeCompare(b.createdAt) * -1);

      setReferences(references);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  return (
    <section
      className="backgroundcolor-white"
      style={{
        paddingTop: "6rem",
        paddingBottom: "6rem",
      }}
    >
      <MetadataControl
        title="Capp2 - Referencer"
        description="Se Capp2's referencer."
      />
      <div className="container">
        <div className="row justify-content-center text-center ">
          <h1 className="mb-2">Hvad siger vores kunder</h1>
          <h2 className="mb-5">Se alle de flotte udtalelser</h2>
        </div>
        <div className="row g-4 justify-content-start">
          {references.map((r) => (
            <ReferenceControl reference={r} key={r._id} />
          ))}
        </div>
      </div>
    </section>
  );
};
