import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faPlus, faRemove } from "@fortawesome/free-solid-svg-icons";

import searchFilterUtil from "../../../../../utils/searchFilter.util";
import dateUtil from "../../../../../utils/date.util";

import { useAppState } from "../../../../contexts/appState.context";
import { useException } from "../../../../contexts/exception.context";
import OrdersTableControl from "../../../../controls/orders.table";
import MonthInput from "../../../../controls/month.input";

const getDefaultFilter = () => {
  return {
    show: false,
    month: dateUtil.todayAsISOMonth(),
  };
};

const OrdersPage = () => {
  const navigate = useNavigate();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();

  const [employeeOrders, setEmployeeOrders] = useState([]);
  const [filter, setFilter] = useState(getDefaultFilter());

  const saveAndSetFilter = (filter) => {
    searchFilterUtil.save("employeeOrders", appStateCtx.user._id, filter);

    setFilter(filter);
  };

  const refresh = async () => {
    try {
      const filter =
        searchFilterUtil.load("employeeOrders", appStateCtx.user._id) ||
        getDefaultFilter();

      setFilter(filter);

      const employeeOrders =
        await appStateCtx.apiServerClient.employee.employeeOrders.getByMonth(
          filter.month
        );

      employeeOrders.sort((a, b) => b.createdAt.localeCompare(a.createdAt));

      setEmployeeOrders(employeeOrders);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, [filter.month]);

  return (
    <div className="container page">
      <h1>Ordrer</h1>
      <div className="d-flex justify-content-between mt-4">
        <Link to="ny" className="btn btn-primary">
          <FontAwesomeIcon icon={faPlus} />
        </Link>
        <div className="btn-group">
          <button
            className="btn btn-primary"
            onClick={() => saveAndSetFilter({ ...filter, show: !filter.show })}
          >
            <FontAwesomeIcon icon={faFilter} />
          </button>
          {!searchFilterUtil.areFiltersEqual(getDefaultFilter(), filter) && (
            <button
              className="btn btn-secondary"
              onClick={() => saveAndSetFilter({ ...getDefaultFilter() })}
            >
              <FontAwesomeIcon icon={faRemove} />
            </button>
          )}
        </div>
      </div>
      <div style={{ display: filter.show ? "initial" : "none" }}>
        <div className="form-group mt-3">
          <label className="form-label">Måned</label>
          <MonthInput
            value={filter.month}
            onChange={(value) =>
              saveAndSetFilter({
                ...filter,
                month: value || dateUtil.todayAsISOMonth(),
              })
            }
          />
        </div>
      </div>

      <div className="mt-3">
        <OrdersTableControl
          orders={employeeOrders}
          onOrderClicked={(order) => {
            navigate(`./${order._id}`);
          }}
        />
      </div>
    </div>
  );
};

export default OrdersPage;
