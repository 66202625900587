import stringUtil from "../../../utils/string.util";
import { useSettings } from "../../contexts/settings.context";
import office from "../../../assets/kontor3.jpg";
import MetadataControl from "../../controls/metadata.control";

const AboutPage = () => {
  const settingsCtx = useSettings();

  if (!settingsCtx.settings) return null;

  return (
    <>
      <MetadataControl title="Capp2 - Om os" description="Hvem er Capp2" />

      <section>
        <img
          src={office}
          style={{ objectFit: "contain", width: "100%" }}
          alt="Capp2"
        />
      </section>

      <section
        className="backgroundcolor-white"
        style={{
          paddingTop: "6rem",
          paddingBottom: "6rem",
        }}
        id="about"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12">
              <h1 className="text-center mb-5">Om os</h1>
              <p
                className="text-center"
                dangerouslySetInnerHTML={{
                  __html: stringUtil.textToHtml(settingsCtx.settings.about),
                }}
              ></p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutPage;
