import React, { useState } from "react";

import validateUtil from "../../../utils/validate.util";

import { useAppState } from "../../contexts/appState.context";
import { useException } from "../../contexts/exception.context";
import { useMessage } from "../../contexts/message.context";
import TextInput from "../../controls/text.input";
import TextareaInput from "../../controls/textarea.input";

const MeetingPage = () => {
  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [company, setCompany] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [about, setAbout] = useState("");

  const handleSendClicked = async () => {
    try {
      await appStateCtx.apiServerClient.visitor.contact.send(
        company,
        name,
        email,
        phone,
        about
      );
      setCompany("");
      setName("");
      setEmail("");
      setPhone("");
      setAbout("");
      messageCtx.showSuccess("Meddelelse om kontakt sendt");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const isFormOk = () => {
    if (!validateUtil.string.isValid(name)) return false;
    if (!validateUtil.email.isValid(email)) return false;
    if (!validateUtil.string.isPhone(phone)) return false;

    return true;
  };

  return (
    <div>
      <div className="form-group mb-3">
        <label className="form-label">Firma navn</label>
        <TextInput value={company} onChange={setCompany} />
      </div>
      <div className="form-group mb-3">
        <label className="form-label">Navn *</label>
        <TextInput className="form-control" value={name} onChange={setName} />
      </div>
      <div className="form-group mb-3">
        <label className="form-label">Email *</label>
        <TextInput value={email} onChange={setEmail} />
      </div>
      <div className="form-group mb-3">
        <label className="form-label">Telefon *</label>
        <TextInput value={phone} onChange={setPhone} />
      </div>
      <div className="form-group mb-3">
        <label className="form-label">Angående</label>
        <TextareaInput value={about} rows={7} onChange={setAbout} />
      </div>
      <div className="mt-2">
        <button
          className="btn btn-secondary w-100"
          onClick={handleSendClicked}
          disabled={!isFormOk()}
        >
          Send anmodning
        </button>
      </div>
    </div>
  );
};

export default MeetingPage;
