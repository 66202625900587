import React, { createContext, useContext, useEffect, useState } from "react";

import ApiServerClient from "../../utils/apiserver.client";
import localStorageUtil from "../../utils/localStorage.util";

import { useMessage } from "./message.context";

const apiServerClient = new ApiServerClient();

const AppStateContext = createContext(null);

export const useAppState = () => {
  return useContext(AppStateContext);
};

export const AppStateProvider = ({ children }) => {
  const messageCtx = useMessage();

  const [user, setUser] = useState(null);
  const [products, setProducts] = useState([]);
  const [loadingByToken, setLoadingByToken] = useState(true);

  const saveToken = (token) => {
    localStorageUtil.set("user", token);
  };

  const loadToken = () => {
    try {
      const token = localStorageUtil.get("user");
      return token;
    } catch (ex) {
      return "";
    }
  };

  const redirect = (user) => {};
  const refreshAsync = async () => {
    const token = loadToken();

    try {
      if (!token) {
        await loginVisitorAsync();
        return;
      }

      const auth = await apiServerClient.auth.loginByToken(token);

      saveToken(auth.token);
      setUser(auth.user);
      redirect(auth.user);
      messageCtx.showSuccess(`Velkommen ${auth.user.name}`);
    } catch (ex) {
      await loginVisitorAsync();
    } finally {
      setLoadingByToken(false);
    }
  };

  useEffect(() => {
    refreshAsync().then(() => {
      refreshProductsAsync();
    });
    //eslint-disable-next-line
  }, []);

  const loginVisitorAsync = async () => {
    try {
      const auth = await apiServerClient.auth.loginVisitor();
      saveToken(auth.token);
      setUser(auth.user);
    } catch (ex) {}
  };

  const refreshProductsAsync = async () => {
    const products = await apiServerClient.visitor.products.getAll();

    products.sort((a, b) =>
      a.productGroup.name.localeCompare(b.productGroup.name)
    );

    setProducts(products);
  };

  const loginByCredentialsAsync = async (username, password) => {
    const auth = await apiServerClient.auth.loginByCredentials(
      username,
      password
    );

    saveToken(auth.token);
    setUser(auth.user);
    return auth.user;
  };

  const logout = async () => {
    //await apiServerClient.auth.logout();
    saveToken();
    setUser();

    await loginVisitorAsync();
  };

  if (loadingByToken) {
    return null;
  }

  const initialValues = {
    user,
    loginByCredentialsAsync,
    logout,
    apiServerClient,
    products,
    refreshProductsAsync,
  };

  return (
    <AppStateContext.Provider value={initialValues}>
      {children}
    </AppStateContext.Provider>
  );
};
