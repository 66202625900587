import { useState } from "react";
import { Modal } from "react-bootstrap";

import stringUtil from "../../utils/string.util";

import SearchtextInput from "./searchtext.input";
import TableControl from "./table.control";

export default ({ show, onClose, products }) => {
  const [query, setQuery] = useState("");

  const query2 = query.trim();

  const filteredProducts =
    query2.length >= 2
      ? products.filter((p) =>
          p.subProducts.some((s) =>
            stringUtil.includesCI(
              s.subProductNumber + "|" + s.name + "|" + p.tags,
              query2
            )
          )
        )
      : [];

  const handleProductClicked = (product) => {
    onClose(product);
  };

  return (
    <Modal show={show} onHide={() => onClose()} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Vælg produkt</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SearchtextInput
          name="productQuery"
          placeholder="Søg - nummer, navn, tags"
          value={query}
          onChange={setQuery}
        />
        <div className="mt-3">
          <TableControl
            columns={[
              { header: "Navn", type: "string", valueExtractor: (p) => p.name },
            ]}
            keyExtractor={(p) => p._id}
            onRowClicked={handleProductClicked}
            rows={filteredProducts}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
