import React from "react";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";

import settings from "../../utils/settings";

import { MoneyCell, MoneyCellContent } from "./table.controls";
import TableControl from "./table.control";

const InvoicesTableControl = ({ invoices, showCustomer }) => {
  const navigate = useNavigate();

  const handleInvoiceClicked = (invoice) => {
    navigate(`./${invoice.bookedInvoiceNumber}`);
  };

  const getColumns = () => {
    if (showCustomer) {
      return [
        {
          header: "Dato",
          valueExtractor: (i) =>
            DateTime.fromISO(i.date).toFormat(settings.dateShortFormat),
        },
        { header: "Kunde", valueExtractor: (i) => i.recipient?.name },
        {
          header: "Total",
          valueExtractor: (i) => <MoneyCellContent amount={i.grossAmount} />,
          className: "text-end",
        },
      ];
    } else {
      return [
        {
          header: "Dato",
          valueExtractor: (i) =>
            DateTime.fromISO(i.date).toFormat(settings.dateShortFormat),
        },
        {
          header: "Total",
          valueExtractor: (i) => <MoneyCellContent amount={i.grossAmount} />,
          className: "text-end",
        },
      ];
    }
  };

  return (
    <TableControl
      columns={getColumns()}
      rows={invoices}
      keyExtractor={(i) => i.bookedInvoiceNumber}
      onRowClicked={handleInvoiceClicked}
    />
  );
};
export default InvoicesTableControl;
