import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { useException } from "../../../../contexts/exception.context";
import { useAppState } from "../../../../contexts/appState.context";
import TableControl from "../../../../controls/table.control";

const AnnouncementsPage = () => {
  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const navigate = useNavigate();
  const [announcements, setAnnouncements] = useState([]);

  const isReadonly = !appStateCtx.user.permissionIds.includes(
    "admin.announcements.edit"
  );

  const refresh = async () => {
    try {
      const announcements =
        await appStateCtx.apiServerClient.employee.announcements.getAll();
      setAnnouncements(announcements);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  const handleNewClicked = () => {
    navigate("./ny");
  };

  const handleAnnouncementClicked = (reference) => {
    navigate(`./${reference._id}`);
  };

  return (
    <div className="container page">
      <h1>Nyheder</h1>
      {!isReadonly && (
        <div className="mt-4">
          <button className="btn btn-primary" onClick={handleNewClicked}>
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
      )}
      <div className="mt-3">
        <TableControl
          columns={[
            { header: "Titel", valueExtractor: (a) => a.title },
            {
              header: "Vis",
              valueExtractor: (a) => (a.enabled ? "Ja" : "Nej"),
            },
          ]}
          rows={announcements}
          keyExtractor={(a) => a._id}
          onRowClicked={handleAnnouncementClicked}
        />
      </div>
    </div>
  );
};
export default AnnouncementsPage;
