import { faRemove, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forwardRef } from "react";

import TextInput from "./text.input";

export default forwardRef(
  ({ readonly, value, disabled, onChange, ...rest }, ref) => {
    const handleResetClicked = () => {
      onChange("");
    };

    if (readonly) {
      return <label className="form-control-plaintext">{value}</label>;
    } else {
      return (
        <div className="input-group">
          <TextInput
            placeholder="Søg - nummer, navn, tags"
            name="productQuery"
            ref={ref}
            readonly={readonly}
            value={value}
            disabled={disabled}
            onChange={onChange}
            {...rest}
          />
          {value && (
            <button
              className="btn btn-secondary btn-noshadow py-0"
              onClick={handleResetClicked}
            >
              <FontAwesomeIcon icon={faXmark} />
            </button>
          )}
        </div>
      );
    }
  }
);
