import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

import productUtil from "../../../../../utils/product.util";

import { useAppState } from "../../../../contexts/appState.context";
import { useException } from "../../../../contexts/exception.context";
import TableControl from "../../../../controls/table.control";

const FocusProductsPage = () => {
  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const navigate = useNavigate();
  const [focusProducts, setFocusProducts] = useState([]);

  const isReadonly = !appStateCtx.user.permissionIds.includes(
    "admin.focusproducts.edit"
  );

  const refresh = async () => {
    try {
      const products =
        await appStateCtx.apiServerClient.employee.products.getAllFocusProducts();

      setFocusProducts(products);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  const handleNewClicked = async () => {
    navigate("./ny");
  };

  const handleFocusProductClicked = async (p) => {
    const url = "." + productUtil.getUrlSegments(p);
    navigate(url);
  };

  return (
    <div className="container page">
      <h1>Produkter i fokus</h1>
      {!isReadonly && (
        <div className="mt-4">
          <button className="btn btn-primary" onClick={handleNewClicked}>
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
      )}
      <div className="mt-3">
        <TableControl
          columns={[
            { header: "Navn", valueExtractor: (p) => p.name },
            {
              header: "Vis",
              valueExtractor: (p) => (p.focus.enabled ? "Ja" : "Nej"),
            },
          ]}
          rows={focusProducts}
          keyExtractor={(p) => p._id}
          onRowClicked={handleFocusProductClicked}
        />
      </div>
    </div>
  );
};
export default FocusProductsPage;
