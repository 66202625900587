export const MoneyCell = ({ className, amount }) => {
  return (
    <td className={`text-end ${className} ${amount < 0 ? "color-red" : ""}`}>
      {amount?.toFixed(2)}
    </td>
  );
};

export const MoneyCellContent = ({ amount }) => {
  return (
    <span className={`${amount < 0 ? "color-red" : ""}`}>
      {amount?.toFixed(2)}
    </span>
  );
};
