import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";

import stringUtil from "../../../../utils/string.util";

import { useException } from "../../../contexts/exception.context";
import { useAppState } from "../../../contexts/appState.context";
import TextInput from "../../../controls/text.input";

import EmployeeControl from "../../contact/employee.control";

import intervalUtil from "../calenderEvents/interval.util";

const NextMeetingControl = ({ event }) => {
  if (!event)
    return (
      <div className="form-group mt-3">
        <label className="form-label">Næste møde</label>
        <div className="form-control-plaintext">
          <i>Der er ingen fremtidige møder</i>
        </div>
      </div>
    );

  const contact = {
    name: event.contactName,
    email: event.contactEmail,
    phone: event.contactPhone,
    image: {
      name: "contact",
      url: "/assets/user3.png",
      thumbUrl: "/assets/user3.png",
      _id: "contact",
    },
  };

  return (
    <>
      <div className="form-group mt-3">
        <label className="form-label">Næste møde</label>
        <div>
          {intervalUtil.getDateTime(event)}{" "}
          {event.finished ? "(Afsluttet)" : ""}
        </div>
      </div>
      {event.place && (
        <div className="form-group mt-3">
          <label className="form-label">Sted</label>
          <div
            dangerouslySetInnerHTML={{
              __html: stringUtil.textToHtml(event.place),
            }}
          ></div>
        </div>
      )}

      <div className="row mt-3">
        <div className="col-6">
          {contact && (
            <div className="h-100">
              <label className="form-label">Kunde kontakt</label>
              <EmployeeControl employee={contact} disableSaveOnPhone />
            </div>
          )}
        </div>
        <div className="col-6">
          {event.employee && (
            <div className="h-100">
              <label className="form-label">Capp2 kontakt</label>
              <EmployeeControl employee={event.employee} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const WelcomePage = () => {
  const appStateCtx = useAppState();
  const exceptionCtx = useException();

  const [nextCalenderEvent, setNextCalenderEvent] = useState();

  const refresh = async () => {
    try {
      let events =
        await appStateCtx.apiServerClient.customer.calenderEvents.getMeetings();

      events.sort((a, b) => -a.startDate.localeCompare(b.startDate));

      const nextCalenderEvent = events.find(
        (e) => e.startDate >= DateTime.now().toISODate()
      );

      setNextCalenderEvent(nextCalenderEvent);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  return (
    <div className="container page">
      <h1>Velkommen</h1>
      <div className="form-group mt-3">
        <label className="form-label">Kunde</label>
        <TextInput value={appStateCtx.user.customerName} readonly />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Kontakt</label>
        <TextInput value={appStateCtx.user.name} readonly />
      </div>
      <NextMeetingControl event={nextCalenderEvent} />
      <div className="mt-3 space-children">
        {appStateCtx.user?.permissionIds?.includes(
          "customer.invoices.view"
        ) && (
          <Link to="../fakturaer" className="btn btn-primary">
            Se fakturaer
          </Link>
        )}
        {appStateCtx.user?.permissionIds?.includes(
          "customer.meetings.view"
        ) && (
          <Link to="../møder" className="btn btn-primary">
            Se møder
          </Link>
        )}
        {appStateCtx.user?.permissionIds?.includes(
          "customer.previouslybought.view"
        ) && (
          <Link to="../tidligerekoeb" className="btn btn-primary">
            Se tidligere købt
          </Link>
        )}
      </div>
    </div>
  );
};

export default WelcomePage;
