import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EmployeeControl = ({ employee }) => {
  const handleSaveClicked = () => {
    const lines = [];
    lines.push("BEGIN:VCARD");
    lines.push("VERSION:3.0");
    if (employee.name) lines.push(`N:;${employee.name}`);
    if (employee.name) lines.push(`FN:${employee.name}`);
    if (employee.position) lines.push(`TITLE:${employee.position}`);
    lines.push("ORG:Capp2");
    lines.push("URL:www.capp2.dk");
    lines.push(`EMAIL;TYPE=INTERNET:${employee.email}`);
    lines.push(`TEL;TYPE=voice,cell,pref:${employee.phone}`);
    lines.push("ADR:;;Fabriksparken 10A;Glostrup;;2800;");
    lines.push("END:VCARD");
    const vcard = lines.join("\n");

    var blob = new Blob([vcard], { type: "text/vcard" });
    var url = URL.createObjectURL(blob);

    const name = employee.name || "kontakt";

    const newLink = document.createElement("a");
    newLink.download = name + ".vcf";
    newLink.textContent = name;
    newLink.href = url;

    newLink.click();
  };
  return (
    <div className="card text-center backgroundcolor-gray h-100 align-items-center border-radius-10">
      <div className="card-body d-flex flex-column">
        <h5 className="card-title display-bold">{employee.name || "?"}</h5>

        <div className="mt-2">
          <img
            className="backgroundcolor-white"
            src={employee.image?.url}
            style={{
              width: 200,
              height: 200,
              objectFit: "contain",
              border: "#bbb 1px solid",
            }}
            alt={employee.name || "-"}
          />
        </div>
        <div className="mt-2 d-flex flex-column justify-content-end flex-grow-1">
          {employee.position && (
            <h6 className="card-subtitle mb-3 display-bold">
              {employee.position}
            </h6>
          )}
          {employee.phone && (
            <div className="line-height-1-3">
              <FontAwesomeIcon icon={faPhone} />{" "}
              <span>
                <a href={`tel:${employee.phone}`}>{employee.phone}</a>
              </span>
            </div>
          )}
          {employee.email && (
            <div className="line-height-1-3">
              <FontAwesomeIcon icon={faEnvelope} />{" "}
              <a href={`mailto:${employee.email}`}>{employee.email}</a>
            </div>
          )}
          <div className="mt-3">
            <button className="btn btn-secondary" onClick={handleSaveClicked}>
              Gem
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeControl;
