import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faFilter, faRemove } from "@fortawesome/free-solid-svg-icons";

import settings from "../../../../../utils/settings";
import searchFilterUtil from "../../../../../utils/searchFilter.util";
import dateUtil from "../../../../../utils/date.util";
import { useAppState } from "../../../../contexts/appState.context";
import { useException } from "../../../../contexts/exception.context";
import DateInput from "../../../../controls/date.input";
import TableControl from "../../../../controls/table.control";

const getDefaultFilter = () => {
  return {
    show: true,
    date: DateTime.now().toISODate(),
  };
};

const ReportEventsPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();

  const [events, setEvents] = useState([]);
  const [filter, setFilter] = useState(getDefaultFilter());

  const saveAndSetFilter = (filter) => {
    searchFilterUtil.save("reportEvents", appStateCtx.user._id, filter);

    setFilter(filter);
  };

  const refreshFilter = () => {
    try {
      const filter =
        searchFilterUtil.load("reportEvents", appStateCtx.user._id) ||
        getDefaultFilter();

      if (searchParams.get("dato")) {
        filter.date = searchParams.get("dato");
      }

      setFilter(filter);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const refreshEvents = async (date) => {
    const events =
      await appStateCtx.apiServerClient.employee.reportEvents.getByDate(date);

    setEvents(events);
  };

  useEffect(() => {
    refreshFilter();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    refreshEvents(filter.date);
  }, [filter.date]);

  const handleCreateClicked = () => {
    navigate(`./ny?dato=${filter.date}`);
  };

  const handleEventClicked = (event) => {
    navigate(`./${event._id}`);
  };

  return (
    <div className="container page">
      <h1>Registreringer</h1>
      <div className="d-flex justify-content-between mt-4">
        <button className="btn btn-primary" onClick={handleCreateClicked}>
          <FontAwesomeIcon icon={faPlus} />
        </button>
        <div className="btn-group">
          <button
            className="btn btn-primary"
            onClick={() => saveAndSetFilter({ ...filter, show: !filter.show })}
          >
            <FontAwesomeIcon icon={faFilter} />
          </button>
          {!searchFilterUtil.areFiltersEqual(getDefaultFilter(), filter) && (
            <button
              className="btn btn-secondary"
              onClick={() => saveAndSetFilter({ ...getDefaultFilter() })}
            >
              <FontAwesomeIcon icon={faRemove} />
            </button>
          )}
        </div>
      </div>

      <div style={{ display: filter.show ? "initial" : "none" }}>
        <div className="mt-3">
          <label className="form-label">Dato</label>
          <DateInput
            value={filter.date}
            onChange={(value) =>
              saveAndSetFilter({
                ...filter,
                date: value || dateUtil.todayAsISOMonth(),
              })
            }
          />
        </div>
      </div>
      <div className="mt-3">
        <TableControl
          columns={[
            { header: "Type", valueExtractor: (e) => e.type },
            { header: "Beskrivlse", valueExtractor: (e) => e.description },
            { header: "Vej", valueExtractor: (e) => e.street },
            { header: "Postnummer", valueExtractor: (e) => e.zip },
            { header: "By", valueExtractor: (e) => e.city },
            {
              header: "Km",
              valueExtractor: (e) => e.km,
              className: "text-end",
            },
            {
              header: "Omsætning",
              valueExtractor: (e) => e.revenue,
              className: "text-end",
            },
            {
              header: "Stemplet",
              valueExtractor: (e) =>
                DateTime.fromISO(e.date).toFormat(settings.dateLongFormat),
            },
          ]}
          rows={events}
          keyExtractor={(e) => e._id}
          onRowClicked={handleEventClicked}
        />
      </div>
    </div>
  );
};

export default ReportEventsPage;
