import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import InvoiceControl from "../../../../controls/invoice.control";
import { useAppState } from "../../../../contexts/appState.context";
import { useException } from "../../../../contexts/exception.context";

const InvoicePage = () => {
  const params = useParams();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();

  const [invoice, setInvoice] = useState();

  const refresh = async () => {
    try {
      const invoice =
        await appStateCtx.apiServerClient.employee.invoices.getByBookedInvoiceNumber(
          params.bookedInvoiceNumber
        );

      setInvoice(invoice);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, [params.bookedInvoiceNumber]);

  return (
    <div className="container page">
      <h1>Faktura</h1>
      <InvoiceControl invoice={invoice} showNote2 />
    </div>
  );
};
export default InvoicePage;
