import React from "react";
import { Link } from "react-router-dom";

import settings from "../../utils/settings";
import productGroupUtil from "../../utils/productGroup.util";

const ProductGroupControl = ({ group }) => {
  if (!group) return <div className="col" />;

  return (
    <div className="col-md-6 col-xl-4 mb-5" key={group.productGroupNumber}>
      <div
        className="card text-center backgroundcolor-gray h-100"
        style={{ alignItems: "center" }}
      >
        <div className="card-body d-flex flex-column align-items-center">
          <h5 className="card-title display-bold text-center">{group.name}</h5>

          <div
            className="d-flex mt-2 align-items-end justify-content-center"
            style={{
              flexGrow: 1,
            }}
          >
            {group ? (
              <Link to={productGroupUtil.getAbsoluteUrl(group)}>
                <img
                  className="backgroundcolor-white"
                  src={group.image?.thumbUrl || settings.noImage.thumbUrl}
                  style={{
                    width: 200,
                    height: 200,
                    objectFit: "contain",
                    padding: 20,
                    border: "#bbb 1px solid",
                  }}
                  alt={group.name}
                />
              </Link>
            ) : (
              <div></div>
            )}
          </div>
          <Link
            to={productGroupUtil.getAbsoluteUrl(group)}
            className="btn btn-secondary btn-action mt-4"
          >
            Se mere
          </Link>
        </div>
      </div>
    </div>
  );
};
export default ProductGroupControl;
