import React, { Fragment, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import stringUtil from "../../utils/string.util";

import { useAppState } from "../contexts/appState.context";
import { useException } from "../contexts/exception.context";

import { MoneyCell, MoneyCellContent } from "./table.controls";
import SearchtextInput from "../controls/searchtext.input";

const CustomerInvoicesModal = ({ customerNumber, show, setShow }) => {
  const appStateCtx = useAppState();
  const exceptionCtx = useException();

  const [query, setQuery] = useState("");
  const [invoices, setInvoices] = useState([]);

  const refresh = async () => {
    try {
      const invoices =
        await appStateCtx.apiServerClient.employee.invoices.getByCustomerNumber(
          customerNumber
        );

      invoices.sort((a, b) => b.date.localeCompare(a.date));

      setInvoices(invoices);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, [customerNumber]);

  let filteredInvoices = invoices;
  const q = query.trim();

  if (q) {
    filteredInvoices = filteredInvoices.filter((i) =>
      i.lines.some((l) => stringUtil.includesCI(l.description, q))
    );
  }

  return (
    <Modal show={show} onHide={() => setShow(false)} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Fakturaer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <SearchtextInput
            value={query}
            placeholder="Søg - nummer, beskrivelse"
            onChange={setQuery}
          />
          <div className="mt-3">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Nummer</th>
                  <th>Beskrivelse</th>
                  <th>Enhed</th>
                  <th className="text-end">Pris</th>
                  <th className="text-end">Antal</th>
                  <th className="text-end">Rabat</th>
                  <th className="text-end">Total</th>
                </tr>
              </thead>
              <tbody>
                {filteredInvoices.map((i) => {
                  const filteredLines = q
                    ? i.lines.filter((l) =>
                        stringUtil.includesCI(l.description, q)
                      )
                    : i.lines;
                  return (
                    <Fragment key={i.bookedInvoiceNumber}>
                      <tr>
                        <td colSpan="7" style={{ fontWeight: "bold" }}>
                          {i.date}
                        </td>
                      </tr>

                      {filteredLines.map((l) => (
                        <tr key={l.lineNumber}>
                          <td>{l.product?.productNumber}</td>
                          <td>{l.description}</td>
                          <td>{l.unit?.name}</td>
                          <td className="text-end">
                            <MoneyCellContent amount={l.unitNetPrice} />
                          </td>
                          <td className="text-end">{l.quantity}</td>
                          <td className="text-end">{l.discountPercentage}%</td>
                          <td className="text-end">
                            <MoneyCellContent amount={l.totalNetAmount} />
                          </td>
                        </tr>
                      ))}
                    </Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default CustomerInvoicesModal;
