import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useAppState } from "../../../contexts/appState.context";
import { useException } from "../../../contexts/exception.context";
import { useMessage } from "../../../contexts/message.context";
import OptionsControl from "../../../controls/options.control";
import TextInput from "../../../controls/text.input";
import TextareaInput from "../../../controls/textarea.input";

import getMenuItems from "./customerMenuItems.data";

const CustomerInfoPage = () => {
  const params = useParams();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [oldCustomer, setOldCustomer] = useState();
  const [newCustomer, setNewCustomer] = useState();

  const isReadonly = !appStateCtx.user.permissionIds.includes(
    "admin.customers.info.edit"
  );

  const refresh = async () => {
    try {
      const customer =
        await appStateCtx.apiServerClient.employee.customers.getByNumber(
          params.customerNumber
        );

      setOldCustomer(customer);
      setNewCustomer(JSON.parse(JSON.stringify(customer)));
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line
  }, [params.customerNumber]);

  const handleUpdateClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.customers.updateGeneralNote(
        newCustomer.customerNumber,
        newCustomer.generalNote.buyer,
        newCustomer.generalNote.employees,
        newCustomer.generalNote.departments,
        newCustomer.generalNote.employeeCount,
        newCustomer.generalNote.typeOfWork,
        newCustomer.generalNote.providers
      );
      setOldCustomer(JSON.parse(JSON.stringify(newCustomer)));
      messageCtx.showSuccess("Info opdateret");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const hasFormChanged = () => {
    return JSON.stringify(oldCustomer) !== JSON.stringify(newCustomer);
  };

  const isFormOk = () => {
    return true;
  };

  if (!newCustomer) return null;

  const menuItems = getMenuItems(appStateCtx.user, newCustomer, "Info");

  return (
    <div className="container page">
      <h1>Kunde info</h1>

      <div className="mt-4">
        <OptionsControl items={menuItems} />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Indkøber</label>
        <TextareaInput
          rows={3}
          readonly={isReadonly}
          value={newCustomer.generalNote.buyer}
          onChange={(value) =>
            setNewCustomer({
              ...newCustomer,
              generalNote: {
                ...newCustomer.generalNote,
                buyer: value,
              },
            })
          }
        />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Medarbejdere</label>
        <TextareaInput
          rows={3}
          readonly={isReadonly}
          value={newCustomer.generalNote.employees}
          onChange={(value) =>
            setNewCustomer({
              ...newCustomer,
              generalNote: {
                ...newCustomer.generalNote,
                employees: value,
              },
            })
          }
        />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Afdelinger</label>
        <TextareaInput
          rows={3}
          readonly={isReadonly}
          value={newCustomer.generalNote.departments}
          onChange={(value) =>
            setNewCustomer({
              ...newCustomer,
              generalNote: {
                ...newCustomer.generalNote,
                departments: value,
              },
            })
          }
        />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Antal ansatte</label>
        <TextInput
          value={newCustomer.generalNote.employeeCount}
          readonly={isReadonly}
          onChange={(value) => {
            setNewCustomer({
              ...newCustomer,
              generalNote: {
                ...newCustomer.generalNote,
                employeeCount: value,
              },
            });
          }}
        />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Opgaver</label>
        <TextareaInput
          rows={3}
          readonly={isReadonly}
          value={newCustomer.generalNote.typeOfWork}
          onChange={(value) =>
            setNewCustomer({
              ...newCustomer,
              generalNote: {
                ...newCustomer.generalNote,
                typeOfWork: value,
              },
            })
          }
        />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Andre leverandøre</label>
        <TextareaInput
          rows={3}
          readonly={isReadonly}
          value={newCustomer.generalNote.providers}
          onChange={(value) =>
            setNewCustomer({
              ...newCustomer,
              generalNote: {
                ...newCustomer.generalNote,
                providers: value,
              },
            })
          }
        />
      </div>

      {!isReadonly && (
        <div className="space-children mt-3">
          <button
            className="btn btn-primary"
            onClick={handleUpdateClicked}
            disabled={!isFormOk() || !hasFormChanged()}
          >
            Opdater
          </button>
        </div>
      )}
    </div>
  );
};

export default CustomerInfoPage;
