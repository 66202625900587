import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import { useException } from "../contexts/exception.context";
import { useAppState } from "../contexts/appState.context";
import { useMessage } from "../contexts/message.context";

import TextInput from "./text.input";
import SelectInput from "./select.input";

export default ({
  show,
  setShow,
  oldCustomer,
  newCustomer,
  setNewCustomer,
  contact,
  readonly,
}) => {
  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [oldContact, setOldContact] = useState();
  const [newContact, setNewContact] = useState();

  useEffect(() => {
    if (!contact) return;

    setOldContact(contact);
    setNewContact(JSON.parse(JSON.stringify(contact)));
  }, [contact]);

  const hasCustomerChanged = () => {
    return JSON.stringify(oldCustomer) !== JSON.stringify(newCustomer);
  };

  const hasContactFormChanged = () => {
    return JSON.stringify(oldContact) !== JSON.stringify(newContact);
  };

  const isContactFormOk = () => {
    return true;
  };

  const handleContactUpdateClicked = async () => {
    const newCustomer2 = { ...newCustomer };
    const index = newCustomer2.contacts.indexOf(oldContact);
    newCustomer2.contacts.splice(index, 1, newContact);
    setNewCustomer(newCustomer2);
    setShow(false);
  };

  const handleContactSendPasswordClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.customers.sendContactPassword(
        newCustomer.customerNumber,
        contact.customerContactNumber
      );

      messageCtx.showSuccess("Kunde kontakt kodeord sendt");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  if (!newContact) return null;

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Kontakt</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="form-group">
            <label className="form-label">Nummer</label>
            <TextInput value={newContact.customerContactNumber} readonly />
          </div>
          <div className="form-group mt-3">
            <label className="form-label">Navn</label>
            <TextInput value={newContact.name} readonly />
          </div>
          <div className="form-group mt-3">
            <label className="form-label">Email</label>
            <TextInput value={newContact.email} readonly />
          </div>
          <div className="form-group mt-3">
            <label className="form-label">Telefon</label>
            <TextInput value={newContact.phone} readonly />
          </div>
          <div className="form-group mt-3">
            <label className="form-label">Kan logge ind</label>
            <SelectInput
              items={[{ name: "Ja" }, { name: "Nej" }]}
              nameExtractor={(i) => i.name}
              valueExtractor={(i) => i.name}
              value={newContact.loginEnabled ? "Ja" : "Nej"}
              readonly={readonly}
              onChange={(value) =>
                setNewContact({
                  ...newContact,
                  loginEnabled: value === "Ja",
                })
              }
            />
          </div>
          <div className="form-group mt-3">
            <label className="form-label">Modtag emails</label>
            <SelectInput
              items={[{ name: "Ja" }, { name: "Nej" }]}
              nameExtractor={(i) => i.name}
              valueExtractor={(i) => i.name}
              value={newContact.receiveEmails ? "Ja" : "Nej"}
              readonly={readonly}
              onChange={(value) =>
                setNewContact({
                  ...newContact,
                  receiveEmails: value === "Ja",
                })
              }
            />
          </div>
        </div>
      </Modal.Body>
      {!readonly && (
        <Modal.Footer>
          <button
            disabled={
              hasCustomerChanged() ||
              hasContactFormChanged() ||
              !newContact?.email ||
              !newContact?.loginEnabled
            }
            className="btn btn-primary"
            onClick={handleContactSendPasswordClicked}
          >
            Send nyt kodeord
          </button>
          <button
            className="btn btn-primary"
            onClick={handleContactUpdateClicked}
            disabled={!isContactFormOk() || !hasContactFormChanged()}
          >
            Opdater
          </button>
        </Modal.Footer>
      )}
    </Modal>
  );
};
