import urlUtil from "./url.util";

const util = {
  getAdminAbsoluteUrl: (group, path) => {
    let url = `/admin/produktGrupper/${urlUtil.safeEncodeComponent(
      group.name
    )}`;

    if (path) {
      url += "/" + path;
    }

    return url;
  },
  getAdminAbsoluteUrl2: (groupName, path) => {
    let url = `/admin/produktGrupper/${urlUtil.safeEncodeComponent(groupName)}`;

    if (path) {
      url += "/" + path;
    }

    return url;
  },
  getAbsoluteUrl: (group, path) => {
    let url = `/produktGrupper/${urlUtil.safeEncodeComponent(group.name)}`;

    if (path) {
      url += "/" + path;
    }

    return url;
  },
};

export default util;
