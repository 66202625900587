import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { useException } from "../../../contexts/exception.context";
import { useMessage } from "../../../contexts/message.context";
import { useAppState } from "../../../contexts/appState.context";
import OptionsControl from "../../../controls/options.control";
import TextInput from "../../../controls/text.input";
import TextareaInput from "../../../controls/textarea.input";
import CustomercontactModal from "../../../controls/customercontact.modal";
import ChoiceModal from "../../../controls/choice.modal";
import TableControl from "../../../controls/table.control";

import getMenuItems from "./customerMenuItems.data";

const getContactAttributes = (contact, customer) => {
  const attributes = [];

  if (
    contact.customerContactNumber ===
    customer.customerContact?.customerContactNumber
  )
    attributes.push("Kontakt");

  if (
    contact.customerContactNumber === customer.attention?.customerContactNumber
  )
    attributes.push("Attention");

  if (attributes.length > 0) return `(${attributes.join(", ")})`;
  else return "";
};

const getDeliveryLocationAttributes = (deliverLocation, customer) => {
  const attributes = [];

  if (
    deliverLocation.deliverLocationNumber ===
    customer.defaultDeliverLocation?.deliverLocationNumber
  )
    attributes.push("Standard");

  if (attributes.length > 0) return `(${attributes.join(", ")})`;
  else return "";
};

const CustomerPage = () => {
  const navigate = useNavigate();
  const params = useParams();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  // customer
  const [oldCustomer, setOldCustomer] = useState();
  const [newCustomer, setNewCustomer] = useState();

  // contact
  const [showContactModal, setShowContactModal] = useState(false);
  const [contact, setContact] = useState();

  const [showGotoModal, setShowGotoModal] = useState(false);

  const isReadonly = !appStateCtx.user.permissionIds.includes(
    "admin.customers.edit"
  );

  const refresh = async () => {
    try {
      const customer =
        await appStateCtx.apiServerClient.employee.customers.getByNumber(
          params.customerNumber
        );
      setOldCustomer(customer);
      setNewCustomer(JSON.parse(JSON.stringify(customer)));
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line
  }, [params.customerNumber]);

  if (!oldCustomer) return null;

  const handleContactClicked = (contact) => {
    setContact(contact);
    setShowContactModal(true);
  };

  const hasFormChanged = () => {
    return JSON.stringify(oldCustomer) !== JSON.stringify(newCustomer);
  };

  const isFormOk = () => {
    return true;
  };

  const handleUpdateClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.customers.update(
        newCustomer.customerNumber,
        newCustomer.contacts
      );
      messageCtx.showSuccess("Kunde opdateret");
      setShowGotoModal(true);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const menuItems = getMenuItems(appStateCtx.user, newCustomer, "Kunde");

  const onCloseGotoModal = async (choice) => {
    setShowGotoModal(false);

    if (choice === "Vis kunder") {
      navigate("./..");
    } else {
      await refresh();
    }
  };

  return (
    <div className="container page">
      <h1>Kunde</h1>

      <div className="mt-4">
        <OptionsControl items={menuItems} />
      </div>

      <div className="form-group mt-3">
        <label className="form-label">Nummer</label>
        <TextInput value={newCustomer.customerNumber} readonly />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Navn</label>
        <TextInput value={newCustomer.name} readonly />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Adresse</label>
        <TextareaInput
          value={[
            newCustomer.address,
            newCustomer.zip + " " + newCustomer.city,
          ].join("\n")}
          readonly
        />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Telefon/fax</label>
        <TextInput value={newCustomer.telephoneAndFaxNumber} readonly />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Email</label>
        <TextInput value={newCustomer.email} readonly />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Kontakter</label>
        <TableControl
          columns={[
            {
              header: "Navn",
              type: "string",
              valueExtractor: (c) =>
                `${c.name} ${getContactAttributes(c, newCustomer)}`,
            },
            { header: "Email", type: "string", valueExtractor: (c) => c.email },
            {
              header: "Telefon",
              type: "string",
              valueExtractor: (c) => c.phone,
            },
            {
              header: "Kan logge ind",
              type: "string",
              valueExtractor: (c) => (c.loginEnabled ? "Ja" : ""),
            },
            {
              header: "Modtag emails",
              type: "string",
              valueExtractor: (c) => (c.receiveEmails ? "Ja" : ""),
            },
            {
              header: "Kodeord",
              type: "string",
              valueExtractor: (c) => (c.password ? "Ja" : ""),
            },
          ]}
          keyExtractor={(c) => c.customerContactNumber}
          rows={newCustomer.contacts}
          onRowClicked={(c) => handleContactClicked(c)}
        />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Leverings adresser</label>
        <TableControl
          columns={[
            {
              header: "Adresse",
              type: "string",
              valueExtractor: (l) =>
                `${(l.address || "").replaceAll("\n", ",")}, ${l.postalCode}, ${
                  l.city
                } ${getDeliveryLocationAttributes(l, newCustomer)}`,
            },
            {
              header: "Leverings betingelser",
              type: "string",
              valueExtractor: (l) => l.termsOfDelivery,
            },
          ]}
          keyExtractor={(l) => l.deliveryLocationNumber}
          rows={newCustomer.deliveryLocations}
        />
      </div>

      {!isReadonly && (
        <div className="space-children mt-3">
          <button
            className="btn btn-primary"
            onClick={handleUpdateClicked}
            disabled={!isFormOk() || !hasFormChanged()}
          >
            Opdater
          </button>
        </div>
      )}

      <CustomercontactModal
        show={showContactModal}
        setShow={setShowContactModal}
        oldCustomer={oldCustomer}
        newCustomer={newCustomer}
        setNewCustomer={setNewCustomer}
        contact={contact}
        readonly={isReadonly}
      />
      <ChoiceModal
        title="Kunde opdateret"
        text="Hvor vil du hen?"
        choices={["Vis kunder", "Vis kunde"]}
        nameExtractor={(c) => c}
        show={showGotoModal}
        onClose={onCloseGotoModal}
      />
    </div>
  );
};
export default CustomerPage;
