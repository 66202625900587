import productUtil from "../../../../utils/product.util";

// we create absolute urls due to limitation/error in react router dom with special chars
const getMenuItems = (user, product, selectedName) => {
  const menuItems = [
    {
      name: "Produkt",
      url: productUtil.getAdminAbsoluteUrl(product),
      hasPermissions: (user) =>
        user.permissionIds.includes("admin.products.view"),
      selected: selectedName === "Produkt",
    },
    {
      name: "Fakturaer",
      url: productUtil.getAdminAbsoluteUrl(product, "fakturaer"),
      hasPermissions: (user) =>
        user.permissionIds.includes("admin.products.invoices.view"),
      selected: selectedName === "Fakturaer",
    },
    {
      name: "Kunder",
      url: productUtil.getAdminAbsoluteUrl(product, "kunder"),
      hasPermissions: (user) =>
        user.permissionIds.includes("admin.products.customers.view"),
      selected: selectedName === "Kunder",
    },
    {
      name: "Anmeldelser",
      url: productUtil.getAdminAbsoluteUrl(product, "anmeldelser"),
      hasPermissions: (user) =>
        user.permissionIds.includes("admin.products.reviews.view"),
      selected: selectedName === "Anmeldelser",
    },
    {
      name: "Rapporter",
      url: productUtil.getAdminAbsoluteUrl(product, "rapporter"),
      hasPermissions: (user) =>
        user.permissionIds.includes("admin.products.reports.view"),
      selected: selectedName === "Rapporter",
    },
  ];
  return menuItems.filter((i) => i.hasPermissions(user));
};

export default getMenuItems;
