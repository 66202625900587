import { DateTime } from "luxon";
import settings from "./settings";

const util = {
  isoDateToShort: (date) => {
    return DateTime.fromISO(date).toFormat(settings.dateShortFormat, {
      locale: "da",
    });
  },
  isoDateToLong: (date) => {
    return DateTime.fromISO(date).toFormat(settings.dateLongFormat, {
      locale: "da",
    });
  },
  luxonToISOMonth: (date) => {
    return date.toFormat("yyyy-MM");
  },
  todayAsISOMonth: () => {
    return DateTime.now().toFormat("yyyy-MM");
  },
};

export default util;
