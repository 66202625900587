import React, { createContext, useContext, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { faRemove } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import stringUtil from "../../utils/string.util";

import ProductControl from "../controls/product.control";

import { useAppState } from "./appState.context";
import TextInput from "../controls/text.input";
import SearchTextInput from "../controls/searchtext.input";

const SearchContext = createContext(null);

export const useSearch = () => {
  return useContext(SearchContext);
};

export const SearchProvider = ({ children }) => {
  const appStateCtx = useAppState();

  const [showModal, setShowModal] = useState(false);
  const queryRef = useRef();
  const [query, setQuery] = useState("");

  const query2 = query.trim();

  const filteredProducts =
    query2.length >= 2
      ? appStateCtx.products.filter((p) =>
          p.subProducts.some((s) =>
            stringUtil.includesCI(
              s.subProductNumber + "|" + s.name + "|" + p.tags,
              query2
            )
          )
        )
      : [];

  return (
    <SearchContext.Provider value={{ show: setShowModal }}>
      {children}

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="xl"
        onShow={() => queryRef.current?.focus()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Søg produkter</Modal.Title>
        </Modal.Header>
        <Modal.Body className="container">
          <SearchTextInput
            placeholder="Søg - nummer, navn, tags"
            name="productQuery"
            ref={queryRef}
            value={query}
            onChange={setQuery}
          />
          <div className="mt-3">
            <div className="row">
              {filteredProducts.map((p) => (
                <ProductControl
                  key={p._id}
                  product={p}
                  onClick={() => setShowModal(false)}
                />
              ))}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </SearchContext.Provider>
  );
};
