import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import reportsUtil from "../../../../utils/reports.util";

import { useException } from "../../../contexts/exception.context";
import { useAppState } from "../../../contexts/appState.context";
import { useMessage } from "../../../contexts/message.context";
import OptionsControl from "../../../controls/options.control";
import SearchtextInput from "../../../controls/searchtext.input";
import SelectInput from "../../../controls/select.input";

import getMenuItems from "./customerMenuItems.data";

const CustomerReportsPage = () => {
  const params = useParams();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [customer, setCustomer] = useState();
  const [types, setTypes] = useState([]);
  const [typeId, setTypeId] = useState("");
  const [query, setQuery] = useState("");
  const [report, setReport] = useState();

  const refresh = async () => {
    try {
      const customer =
        await appStateCtx.apiServerClient.employee.customers.getByNumber(
          params.customerNumber
        );

      const types =
        await appStateCtx.apiServerClient.employee.customers.getReportTypes();

      setTypes(types);
      if (types.length) setTypeId(types[0].id.toString());

      setCustomer(customer);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  const handleCreateClicked = async () => {
    try {
      const report =
        await appStateCtx.apiServerClient.employee.customers.generateReport(
          typeId,
          params.customerNumber,
          query
        );

      messageCtx.showSuccess("Rapport genereret");
      setReport(report);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  if (!customer) return;

  const menuItems = getMenuItems(appStateCtx.user, customer, "Rapporter");

  const reportType = types.find((t) => t.id.toString() === typeId);

  return (
    <div className="container page">
      <h1>Kunde rapporter</h1>

      <div className="mt-4">
        <OptionsControl items={menuItems} />
      </div>

      <div className="form-group mt-3">
        <label className="form-label">Type</label>
        <SelectInput
          value={typeId}
          items={types}
          onChange={setTypeId}
          nameExtractor={(t) => t.name}
          valueExtractor={(t) => t.id}
        />
      </div>
      {reportType && (
        <div className="form-group mt-3">
          <label className="form-label">Søg</label>
          <SearchtextInput
            value={query}
            onChange={setQuery}
            placeholder={reportType.queryPlaceholder}
          />
        </div>
      )}
      <div className="mt-3 space-children">
        <button className="btn btn-primary" onClick={handleCreateClicked}>
          Generer
        </button>
        {report && (
          <>
            <button
              className="btn btn-success"
              onClick={() => reportsUtil.generatePDF(report)}
            >
              PDF
            </button>
            <button
              className="btn btn-success ms-2"
              onClick={() => reportsUtil.generateXLSX(report)}
            >
              XLSX
            </button>
          </>
        )}
      </div>
      {report && <div>{reportsUtil.generateHtml(report)}</div>}
    </div>
  );
};
export default CustomerReportsPage;
