const settings = {
  company: {
    name: "Capp2 ApS",
    street: "Fabriksparken 10A",
    zip: "2600",
    city: "Glostrup",
    country: "Danmark",
    countryCode: "DK",
    cvr: "30494199",
    emails: {
      contact: "kontakt@capp2.dk",
      order: "ordre@capp2.dk",
    },
    phone: "+45 24 428 428",
    openingHours: "Man - Tor: 8 - 16, Fre: 8 - 15",
    gps: { lat: 55.68669, lng: 12.392707 },
  },
  google: {
    maps: {
      key: "AIzaSyBe_7G3xsv0QeJw_KC80JjKZg-6Vdr8rGc",
    },
  },
  dateTimeShortFormat: "dd-MM-yyyy HH:mm",
  dateShortFormat: "dd-MM-yyyy",
  dateLongFormat: "dd MMM yyyy",
  monthLongFormat: "MMM yyyy",
  monthShortFormat: "MM-yyyy",
  colors: {
    primary: "#05386b",
    secondary: "#e43f3f",
    yellow: "#ffe161",
  },
  noImage: {
    url: "/filer/generalt/empty2.jpg",
    thumbUrl: "/filer/generalt/empty2.jpg",
    name: "Mangler",
  },
};

export default settings;
