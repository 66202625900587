const serverUrl = "/api/v1"; // http://localhost:3000

export class ApiServerError extends Error {
  isServerError = true;

  constructor(message) {
    super(message);
  }
}

class ApiServerClient {
  #token = undefined;

  #checkStatus = async (res) => {
    if (res.ok) {
      // res.status >= 200 && res.status < 300
      const json = await res.json();
      return json;
    } else if (res.status === 500) {
      const json = await res.json();
      throw new ApiServerError(json.error);
    } else {
      throw new Error(res.statusText);
    }
  };

  #post = async (path, authorization, data) => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (authorization) {
      //options.headers.Authorization = "Bearer " + this.#token;
      options.headers.Capp2Authorization = "Bearer " + this.#token;
    }

    if (data) {
      options.body = JSON.stringify(data);
    }

    const endpointUrl = `${serverUrl}${path}`;

    //console.log(endpointUrl);

    const res = await fetch(endpointUrl, options);

    const result = await this.#checkStatus(res);

    //console.log("result", result);

    return result;
  };

  #postMultiparts = async (path, authorization, fileDatas, data) => {
    const options = {
      method: "POST",
      headers: {},
    };

    if (authorization) {
      //options.headers.Authorization = "Bearer " + this.#token;
      options.headers.Capp2Authorization = "Bearer " + this.#token;
    }

    const payload = new FormData();

    if (data) {
      payload.append("data", JSON.stringify(data));
    }

    for (var fileData of fileDatas) {
      payload.append(fileData.type, fileData.file);
    }

    options.body = payload;

    const res = await fetch(`${serverUrl}${path}`, options);

    const result = await this.#checkStatus(res);

    return result;
  };

  #postMultipart = async (path, authorization, file, data) => {
    const options = {
      method: "POST",
      headers: {},
    };

    if (authorization) {
      //options.headers.Authorization = "Bearer " + this.#token;
      options.headers.Capp2Authorization = "Bearer " + this.#token;
    }

    const payload = new FormData();

    if (data) {
      payload.append("data", JSON.stringify(data));
    }

    if (file) {
      payload.append("file", file);
    }

    options.body = payload;

    const res = await fetch(`${serverUrl}${path}`, options);

    const result = await this.#checkStatus(res);

    return result;
  };

  visitor = {
    announcements: {
      getAll: async () => {
        const announcements = await this.#post(
          "/announcements/getAllAsVisitor",
          true
        );

        return announcements;
      },
      getById: async (announcementId) => {
        const announcement = await this.#post(
          "/announcements/getByIdAsVisitor",
          true,
          {
            announcementId,
          }
        );

        return announcement;
      },
    },
    contact: {
      send: async (company, name, email, phone, about) => {
        await this.#post("/contact/sendAsVisitor", true, {
          company,
          name,
          email,
          phone,
          about,
        });
      },
    },
    employees: {
      getAll: async () => {
        const employees = await this.#post("/employees/getAllAsVisitor", true);

        return employees;
      },
    },
    newsletterSubscribers: {
      subscribe: async (companyName, name, email, phone) => {
        await this.#post("/newsletterSubscribers/subscribeAsVisitor", false, {
          companyName,
          name,
          email,
          phone,
        });
      },
      getById: async (id) => {
        const subscriber = await this.#post(
          "/newsletterSubscribers/getByIdAsVisitor",
          false,
          {
            id,
          }
        );

        return subscriber;
      },
      unsubscribe: async (id) => {
        await this.#post("/newsletterSubscribers/unsubscribeAsVisitor", false, {
          id,
        });
      },
    },
    references: {
      getAll: async () => {
        const references = await this.#post(
          "/references/getAllAsVisitor",
          true
        );

        return references;
      },
    },
    banners: {
      getAll: async () => {
        const references = await this.#post("/banners/getAllAsVisitor", true);

        return references;
      },
    },
    products: {
      getAllWithDataSheet: async () => {
        const products = await this.#post(
          "/products/getAllWithDataSheetAsVisitor",
          true
        );

        return products;
      },
      getByName: async (productGroupName, productName) => {
        const product = await this.#post("/products/getByNameAsVisitor", true, {
          productGroupName,
          productName,
        });

        return product;
      },
      getAll: async (skipDoNotShowOnHomepage) => {
        const products = await this.#post("/products/getAllAsVisitor", true, {
          skipDoNotShowOnHomepage,
        });

        return products;
      },
      getByProductGroupName: async (productGroupName) => {
        const products = await this.#post(
          "/products/getByProductGroupNameAsVisitor",
          true,
          {
            productGroupName,
          }
        );

        return products;
      },
      getAllFocusProducts: async () => {
        const products = await this.#post(
          "/products/getAllFocusProductsAsVisitor",
          true
        );

        return products;
      },
    },
    productReviews: {
      getByProductId: async (productId) => {
        const reviews = await this.#post(
          "/productReviews/getByProductIdAsVisitor",
          true,
          {
            productId,
          }
        );

        return reviews;
      },
    },
    productGroups: {
      getAll: async () => {
        const groups = await this.#post("/productGroups/getAllAsVisitor", true);

        return groups;
      },
    },
    settings: {
      get: async () => {
        const settings = await this.#post("/settings/getAsVisitor", false);

        return settings;
      },
    },
    videos: {
      getAll: async () => {
        const videos = await this.#post("/videos/getAllAsVisitor", true);

        return videos;
      },
    },
  };

  customer = {
    calenderEvents: {
      getMeetingById: async (calenderEventId) => {
        const event = await this.#post(
          "/calenderEvents/getMeetingByIdAsCustomer",
          true,
          {
            calenderEventId,
          }
        );

        return event;
      },
      getMeetings: async () => {
        const events = await this.#post(
          "/calenderEvents/getMeetingsAsCustomer",
          true
        );

        return events;
      },
    },
    customers: {
      insertCustomerContactFavorite: async (productGroupName, productName) => {
        const favorite = await this.#post(
          "/customers/insertCustomerContactFavoriteAsCustomer",
          true,
          {
            productGroupName,
            productName,
          }
        );
        return favorite;
      },
      deleteCustomerContactFavorite: async (productId) => {
        await this.#post(
          "/customers/deleteCustomerContactFavoriteAsCustomer",
          true,
          {
            productId,
          }
        );
      },
      changePassword: async (password) => {
        await this.#post("/customers/changePasswordAsCustomer", true, {
          password,
        });
      },
    },
    invoices: {
      getAll: async () => {
        const invoices = await this.#post("/invoices/getAllAsCustomer", true);

        return invoices;
      },
      getByBookedInvoiceNumber: async (bookedInvoiceNumber) => {
        const invoice = await this.#post(
          "/invoices/getByBookedInvoiceNumberAsCustomer",
          true,
          {
            bookedInvoiceNumber,
          }
        );

        return invoice;
      },
      getLineProducts: async () => {
        const lineProducts = await this.#post(
          "/invoices/getLineProductsAsCustomer",
          true
        );

        return lineProducts;
      },
    },
    customerOrders: {
      create: async (order) => {
        await this.#post("/customerOrders/createAsCustomer", true, {
          order,
        });
      },
    },
  };

  employee = {
    announcements: {
      getAll: async () => {
        const announcements = await this.#post(
          "/announcements/getAllAsEmployee",
          true
        );

        return announcements;
      },
      getById: async (announcementId) => {
        const announcement = await this.#post(
          "/announcements/getByIdAsEmployee",
          true,
          {
            announcementId,
          }
        );

        return announcement;
      },
      insert: async (banner, title, text, frontpagetext, enabled) => {
        const announcement = await this.#postMultipart(
          "/announcements/insertAsEmployee",
          true,
          banner,
          {
            title,
            text,
            frontpagetext,
            enabled,
          }
        );

        return announcement;
      },
      update: async (
        announcementId,
        banner,
        title,
        text,
        frontpagetext,
        enabled
      ) => {
        await this.#postMultipart(
          "/announcements/updateAsEmployee",
          true,
          banner,
          {
            announcementId,
            title,
            text,
            frontpagetext,
            enabled,
          }
        );
      },
      delete: async (announcementId) => {
        await this.#post("/announcements/deleteAsEmployee", true, {
          announcementId,
        });
      },
    },
    banners: {
      getAll: async () => {
        const banners = await this.#post("/banners/getAllAsEmployee", true);

        return banners;
      },
      getById: async (bannerId) => {
        const banner = await this.#post("/banners/getByIdAsEmployee", true, {
          bannerId,
        });

        return banner;
      },
      insert: async (name, title, subTitle, image, showButtons, enabled) => {
        const event = await this.#postMultipart(
          "/banners/insertAsEmployee",
          true,
          image,
          {
            name,
            title,
            subTitle,
            showButtons,
            enabled,
          }
        );

        return event;
      },
      update: async (
        bannerId,
        name,
        title,
        subTitle,
        image,
        showButtons,
        enabled
      ) => {
        await this.#postMultipart("/banners/updateAsEmployee", true, image, {
          bannerId,
          name,
          title,
          subTitle,
          showButtons,
          enabled,
        });
      },
      delete: async (bannerId) => {
        await this.#post("/banners/deleteAsEmployee", true, {
          bannerId,
        });
      },
    },
    calenderEvents: {
      getTypes: async () => {
        const types = await this.#post(
          "/calenderEvents/getTypesAsEmployee",
          true
        );

        return types;
      },
      getByMonth: async (month) => {
        const events = await this.#post(
          "/calenderEvents/getByMonthAsEmployee",
          true,
          {
            month,
          }
        );

        return events;
      },
      getCalenders: async () => {
        return await this.#post("/calenderEvents/getCalendersAsEmployee", true);
      },
      getByCustomerId: async (customerId) => {
        const events = await this.#post(
          "/calenderEvents/getByCustomerIdAsEmployee",
          true,
          {
            customerId,
          }
        );

        return events;
      },
      getById: async (calenderEventId) => {
        const event = await this.#post(
          "/calenderEvents/getByIdAsEmployee",
          true,
          {
            calenderEventId,
          }
        );

        return event;
      },
      insert: async (
        employeeId,
        header,
        startDate,
        startTime,
        endDate,
        endTime,
        typeId,
        customerId,
        contactName,
        contactEmail,
        contactPhone,
        place,
        note,
        important
      ) => {
        const event = await this.#post(
          "/calenderEvents/insertAsEmployee",
          true,
          {
            employeeId,
            header,
            startDate,
            startTime,
            endDate,
            endTime,
            typeId,
            customerId,
            contactName,
            contactEmail,
            contactPhone,
            place,
            note,
            important,
          }
        );

        return event;
      },
      update: async (
        calenderEventId,
        employeeId,
        header,
        startDate,
        startTime,
        endDate,
        endTime,
        typeId,
        customerId,
        contactName,
        contactEmail,
        contactPhone,
        place,
        note,
        important
      ) => {
        await this.#post("/calenderEvents/updateAsEmployee", true, {
          calenderEventId,
          employeeId,
          header,
          startDate,
          startTime,
          endDate,
          endTime,
          typeId,
          customerId,
          contactName,
          contactEmail,
          contactPhone,
          place,
          note,
          important,
        });
      },
      delete: async (calenderEventId) => {
        await this.#post("/calenderEvents/deleteAsEmployee", true, {
          calenderEventId,
        });
      },
      updateFinished: async (calenderEventId, finished) => {
        await this.#post("/calenderEvents/updateFinishedAsEmployee", true, {
          calenderEventId,
          finished,
        });
      },
      sendMeetingConfirmationEmail: async (calenderEventId) => {
        const event = await this.#post(
          "/calenderEvents/sendMeetingConfirmationEmailAsEmployee",
          true,
          {
            calenderEventId,
          }
        );

        return event;
      },
    },
    customers: {
      getReportTypes: async () => {
        const types = await this.#post(
          "/customers/getReportTypesAsEmployee",
          true
        );

        return types;
      },
      generateReport: async (typeId, customerNumber, query) => {
        const report = await this.#post(
          "/customers/generateReportAsEmployee",
          true,
          {
            typeId,
            customerNumber,
            query,
          }
        );

        return report;
      },
      getAll: async () => {
        const customers = await this.#post("/customers/getAllAsEmployee", true);

        return customers;
      },
      getByNumber: async (customerNumber) => {
        const customer = await this.#post(
          "/customers/getByNumberAsEmployee",
          true,
          {
            customerNumber,
          }
        );

        return customer;
      },
      getByProductName: async (productGroupName, productName) => {
        const customers = await this.#post(
          "/customers/getByProductNameAsEmployee",
          true,
          {
            productGroupName,
            productName,
          }
        );

        return customers;
      },
      update: async (customerNumber, contacts) => {
        await this.#post("/customers/updateAsEmployee", true, {
          customerNumber,
          contacts,
        });
      },
      updateGeneralNote: async (
        customerNumber,
        buyer,
        employees,
        departments,
        employeeCount,
        typeOfWork,
        providers
      ) => {
        await this.#post("/customers/updateGeneralNoteAsEmployee", true, {
          customerNumber,
          buyer,
          employees,
          departments,
          employeeCount,
          typeOfWork,
          providers,
        });
      },
      sendContactPassword: async (customerNumber, customerContactNumber) => {
        await this.#post("/customers/sendContactPasswordAsEmployee", true, {
          customerNumber,
          customerContactNumber,
        });
      },
    },
    customerGroups: {
      getAll: async () => {
        const groups = await this.#post(
          "/customerGroups/getAllAsEmployee",
          true
        );

        return groups;
      },
    },
    customerNotes: {
      getByCustomerId: async (customerId) => {
        const notes = await this.#post(
          "/customerNotes/getByCustomerIdAsEmployee",
          true,
          {
            customerId,
          }
        );

        return notes;
      },
      getById: async (customerNoteId) => {
        const note = await this.#post(
          "/customerNotes/getByIdAsEmployee",
          true,
          {
            customerNoteId,
          }
        );

        return note;
      },
      updateFinished: async (customerNoteId, finished) => {
        await this.#post("/customerNotes/updateFinishedAsEmployee", true, {
          customerNoteId,
          finished,
        });
      },
      insert: async (customerId, text, file) => {
        const event = await this.#postMultipart(
          "/customerNotes/insertAsEmployee",
          true,
          file,
          {
            text,
            customerId,
          }
        );

        return event;
      },
      update: async (customerNoteId, text, file) => {
        await this.#postMultipart(
          "/customerNotes/updateAsEmployee",
          true,
          file,
          {
            customerNoteId,
            text,
            fileId: file?._id,
          }
        );
      },
      delete: async (customerNoteId) => {
        await this.#post("/customerNotes/deleteAsEmployee", true, {
          customerNoteId,
        });
      },
    },
    employees: {
      getReportTypes: async () => {
        const types = await this.#post(
          "/employees/getReportTypesAsEmployee",
          true
        );

        return types;
      },
      generateReport: async (typeId, startDate, endDate, employeeNumber) => {
        const report = await this.#post(
          "/employees/generateReportAsEmployee",
          true,
          {
            typeId,
            startDate,
            endDate,
            employeeNumber,
          }
        );

        return report;
      },
      getAll: async () => {
        const employees = await this.#post("/employees/getAllAsEmployee", true);

        return employees;
      },
      getRoles: async () => {
        const roles = await this.#post("/employees/getRolesAsEmployee", true);

        return roles;
      },
      getByNumber: async (employeeNumber) => {
        const employee = await this.#post(
          "/employees/getByNumberAsEmployee",
          true,
          {
            employeeNumber,
          }
        );

        return employee;
      },
      sendNewPassword: async (employeeId) => {
        await this.#post("/employees/sendNewPasswordAsEmployee", true, {
          employeeId,
        });
      },
      changePassword: async (password) => {
        await this.#post("/employees/changePasswordAsEmployee", true, {
          password,
        });
      },
      update: async (
        employeeId,
        position,
        customerGroups,
        roleIds,
        calenders,
        image
      ) => {
        const employee = await this.#postMultipart(
          "/employees/updateAsEmployee",
          true,
          image,
          {
            employeeId,
            position,
            customerGroups,
            roleIds,
            calenders,
            imageId: image?._id,
          }
        );

        return employee;
      },
    },
    emailTemplates: {
      getAll: async () => {
        const emails = await this.#post(
          "/emailTemplates/getAllAsEmployee",
          true
        );

        return emails;
      },
      getById: async (emailTemplateId) => {
        const email = await this.#post(
          "/emailTemplates/getByIdAsEmployee",
          true,
          {
            emailTemplateId,
          }
        );

        return email;
      },
      sendTest: async (type) => {
        await this.#post("/emailTemplates/sendTestAsEmployee", true, {
          type,
        });
      },
      update: async (emailTemplateId, enabled, subject, body) => {
        const email = await this.#post(
          "/emailTemplates/updateAsEmployee",
          true,
          {
            emailTemplateId,
            enabled,
            subject,
            body,
          }
        );

        return email;
      },
    },
    emails: {
      getEmailLists: async () => {
        const groups = await this.#post("/email/getEmailListsAsEmployee", true);

        return groups;
      },
      send: async (emailListId, subject, html, file) => {
        const data = {
          emailListId,
          subject,
          html,
        };

        if (!(file instanceof File)) {
          data.file = file;
        }

        await this.#postMultipart("/email/sendAsEmployee", true, file, data);
      },
      getSavedEmails: async () => {
        const emails = await this.#post(
          "/email/getSavedEmailsAsEmployee",
          true
        );
        return emails;
      },
      getSavedEmailById: async (savedEmailId) => {
        const email = await this.#post(
          "/email/getSavedEmailByIdAsEmployee",
          true,
          {
            savedEmailId,
          }
        );
        return email;
      },
      insertSavedEmail: async (name, emailListId, subject, html, file) => {
        const data = {
          name,
          emailListId,
          subject,
          html,
        };

        let file2;

        if (file instanceof File) {
          file2 = file;
        } else {
          data.file = file;
        }

        const email = await this.#postMultipart(
          "/email/insertSavedEmailAsEmployee",
          true,
          file2,
          data
        );

        return email;
      },
      updateSavedEmail: async (
        savedEmailId,
        emailListId,
        subject,
        html,
        file
      ) => {
        const data = {
          savedEmailId,
          emailListId,
          subject,
          html,
        };

        let file2;

        if (file instanceof File) {
          file2 = file;
        } else {
          data.file = file;
        }

        const email = await this.#postMultipart(
          "/email/updateSavedEmailAsEmployee",
          true,
          file2,
          data
        );
        return email;
      },
      deleteSavedEmail: async (savedEmailId) => {
        await this.#post("/email/deleteSavedEmailAsEmployee", true, {
          savedEmailId,
        });
      },
    },
    invoices: {
      getByMonth: async (month) => {
        const invoices = await this.#post(
          "/invoices/getByMonthAsEmployee",
          true,
          {
            month,
          }
        );

        return invoices;
      },
      getByBookedInvoiceNumber: async (bookedInvoiceNumber) => {
        const invoice = await this.#post(
          "/invoices/getByBookedInvoiceNumberAsEmployee",
          true,
          {
            bookedInvoiceNumber,
          }
        );

        return invoice;
      },
      getByCustomerNumber: async (customerNumber) => {
        const invoices = await this.#post(
          "/invoices/getByCustomerNumberAsEmployee",
          true,
          {
            customerNumber,
          }
        );

        return invoices;
      },
      getByProductName: async (productGroupName, productName) => {
        const invoices = await this.#post(
          "/invoices/getByProductNameAsEmployee",
          true,
          {
            productGroupName,
            productName,
          }
        );

        return invoices;
      },
    },
    jobs: {
      getById: async (jobId) => {
        const job = await this.#post("/jobs/getByIdAsEmployee", true, {
          jobId,
        });

        return job;
      },
      getLatest2: async (amount) => {
        const jobs = await this.#post("/jobs/getLatest2AsEmployee", true, {
          amount,
        });

        return jobs;
      },
      getLatest: async (amount) => {
        const jobs = await this.#post("/jobs/getLatestAsEmployee", true, {
          amount,
        });

        return jobs;
      },
      runNowById: async (jobId) => {
        const job = await this.#post("/jobs/runNowByIdAsEmployee", true, {
          jobId,
        });

        return job;
      },
      update: async (jobId, enabled) => {
        const job = await this.#post("/jobs/updateByIdAsEmployee", true, {
          jobId,
          enabled,
        });

        return job;
      },
    },
    newsletterSubscribers: {
      getAll: async () => {
        const subscribers = await this.#post(
          "/newsletterSubscribers/getAllAsEmployee",
          true
        );

        return subscribers;
      },
      getById: async (subscriberId) => {
        const subscriber = await this.#post(
          "/newsletterSubscribers/getByIdAsEmployee",
          true,
          { subscriberId }
        );

        return subscriber;
      },
      delete: async (subscriberId) => {
        const subscriber = await this.#post(
          "/newsletterSubscribers/deleteAsEmployee",
          true,
          { subscriberId }
        );

        return subscriber;
      },
    },
    employeeOrders: {
      getSaved: async () => {
        const orders = await this.#post(
          "/employeeOrders/getSavedAsEmployee",
          true
        );

        return orders;
      },
      save: async (order) => {
        const order2 = await this.#post(
          "/employeeOrders/saveAsEmployee",
          true,
          {
            order,
          }
        );

        return order2;
      },
      load: async (orderId) => {
        const order = await this.#post("/employeeOrders/loadAsEmployee", true, {
          orderId,
        });

        return order;
      },
      delete: async (orderId) => {
        await this.#post("/employeeOrders/deleteAsEmployee", true, {
          orderId,
        });
      },
      getByMonth: async (month) => {
        const orders = await this.#post(
          "/employeeOrders/getByMonthAsEmployee",
          true,
          {
            month,
          }
        );

        return orders;
      },
      getByProcessed: async (processed) => {
        const orders = await this.#post(
          "/employeeOrders/getByProcessedAsEmployee",
          true,
          {
            processed,
          }
        );

        return orders;
      },
      getById: async (orderId) => {
        const order = await this.#post(
          "/employeeOrders/getByIdAsEmployee",
          true,
          {
            orderId,
          }
        );

        return order;
      },
      create: async (order) => {
        await this.#post("/employeeOrders/createAsEmployee", true, {
          order,
        });
      },
      updateProcessed: async (orderId, processed) => {
        const order = await this.#post(
          "/employeeOrders/updateProcessedAsEmployee",
          true,
          {
            orderId,
            processed,
          }
        );

        return order;
      },
    },
    customerOrders: {
      getByProcessed: async (processed) => {
        const orders = await this.#post(
          "/customerOrders/getByProcessedAsEmployee",
          true,
          {
            processed,
          }
        );

        return orders;
      },
      getById: async (orderId) => {
        const order = await this.#post(
          "/customerOrders/getByIdAsEmployee",
          true,
          {
            orderId,
          }
        );

        return order;
      },

      updateProcessed: async (orderId, processed) => {
        const order = await this.#post(
          "/customerOrders/updateProcessedAsEmployee",
          true,
          {
            orderId,
            processed,
          }
        );

        return order;
      },
    },
    products: {
      getOverlayTypes: async () => {
        const overlayTypes = await this.#post(
          "/products/getOverlayTypesAsEmployee",
          true
        );

        return overlayTypes;
      },
      getOverlayPositions: async () => {
        const overlayPositions = await this.#post(
          "/products/getOverlayPositionsAsEmployee",
          true
        );

        return overlayPositions;
      },
      getReportTypes: async () => {
        const types = await this.#post(
          "/products/getReportTypesAsEmployee",
          true
        );

        return types;
      },
      generateReport: async (typeId, productGroupName, productName) => {
        const report = await this.#post(
          "/products/generateReportAsEmployee",
          true,
          {
            typeId,
            productGroupName,
            productName,
          }
        );

        return report;
      },
      getAll: async () => {
        const products = await this.#post("/products/getAllAsEmployee", true);

        return products;
      },
      update: async (
        productId,
        description,
        tags,
        overlay1TypeId,
        overlay1PositionId,
        overlay2TypeId,
        overlay2PositionId,
        medias,
        documents,
        subProducts
      ) => {
        const mediaDatas = medias
          .filter((m) => m.file instanceof File)
          .map((m) => ({ type: "mediaFiles", file: m.file }));

        let i = 0;
        const medias2 = medias.map((m) =>
          m.file instanceof File ? { index: i++ } : m
        );

        const documentDatas = documents
          .filter((d) => d.file instanceof File)
          .map((d) => ({ type: "documentFiles", file: d.file }));

        i = 0;
        const documents2 = documents.map((d) =>
          d.file instanceof File ? { index: i++, type: d.type } : d
        );

        await this.#postMultiparts(
          "/products/updateAsEmployee",
          true,
          [...mediaDatas, ...documentDatas],
          {
            productId,
            description,
            tags,
            overlay1TypeId,
            overlay1PositionId,
            overlay2TypeId,
            overlay2PositionId,
            medias: medias2,
            documents: documents2,
            subProducts,
          }
        );
      },
      getByProductGroupName: async (productGroupName) => {
        const products = await this.#post(
          "/products/getByProductGroupNameAsEmployee",
          true,
          {
            productGroupName,
          }
        );

        return products;
      },
      getByName: async (productGroupName, productName) => {
        const product = await this.#post(
          "/products/getByNameAsEmployee",
          true,
          {
            productGroupName,
            productName,
          }
        );

        return product;
      },
      getById: async (productId) => {
        const product = await this.#post("/products/getByIdAsEmployee", true, {
          productId,
        });

        return product;
      },
      getAllFocusProducts: async () => {
        const products = await this.#post(
          "/products/getAllFocusProductsAsEmployee",
          true
        );

        return products;
      },
      insertOrUpdateFocusProduct: async (
        productGroupName,
        productName,
        text,
        reason,
        enabled
      ) => {
        await this.#post(
          "/products/insertOrUpdateFocusProductAsEmployee",
          true,
          {
            productGroupName,
            productName,
            text,
            reason,
            enabled,
          }
        );
      },
      deleteFocusProduct: async (productGroupName, productName) => {
        await this.#post("/products/deleteFocusProductAsEmployee", true, {
          productGroupName,
          productName,
        });
      },
    },
    productReviews: {
      getByProductId: async (productId) => {
        const reviews = await this.#post(
          "/productReviews/getByProductIdAsEmployee",
          true,
          {
            productId,
          }
        );

        return reviews;
      },
      getById: async (productReviewId) => {
        const review = await this.#post(
          "/productReviews/getByIdAsEmployee",
          true,
          {
            productReviewId,
          }
        );

        return review;
      },
      insert: async (productId, title, text, reviewer) => {
        const review = await this.#post(
          "/productReviews/insertAsEmployee",
          true,
          {
            // productGroupName,
            // productName,
            productId,
            title,
            text,
            reviewer,
          }
        );

        return review;
      },
      update: async (productReviewId, title, text, reviewer) => {
        await this.#post("/productReviews/updateAsEmployee", true, {
          productReviewId,
          title,
          text,
          reviewer,
        });
      },
      delete: async (productReviewId) => {
        await this.#post("/productReviews/deleteAsEmployee", true, {
          productReviewId,
        });
      },
    },
    productGroups: {
      getAll: async () => {
        const groups = await this.#post(
          "/productGroups/getAllAsEmployee",
          true
        );

        return groups;
      },
      getByName: async (productGroupName) => {
        const group = await this.#post(
          "/productGroups/getByNameAsEmployee",
          true,
          {
            productGroupName,
          }
        );

        return group;
      },
      update: async (productGroupId, image) => {
        const group = await this.#postMultipart(
          "/productGroups/updateAsEmployee",
          true,
          image,
          { productGroupId, imageId: image?._id }
        );

        return group;
      },
    },
    qrCodes: {
      getAll: async () => {
        const banners = await this.#post("/qrCodes/getAllAsEmployee", true);

        return banners;
      },
      getById: async (qrCodeId) => {
        const banner = await this.#post("/qrCodes/getByIdAsEmployee", true, {
          qrCodeId,
        });

        return banner;
      },
      insert: async (description, productGroupName, productName, document) => {
        const code = await this.#post("/qrCodes/insertAsEmployee", true, {
          description,
          productGroupName,
          productName,
          document,
        });

        return code;
      },
    },
    reportEvents: {
      getTypes: async () => {
        const events = await this.#post(
          "/reportEvents/getTypesAsEmployee",
          true
        );

        return events;
      },
      getVisitTypes: async () => {
        const events = await this.#post(
          "/reportEvents/getVisitTypesAsEmployee",
          true
        );

        return events;
      },
      getByDate: async (date) => {
        const events = await this.#post(
          "/reportEvents/getByDateAsEmployee",
          true,
          {
            date,
          }
        );

        return events;
      },
      getById: async (reportEventId) => {
        const event = await this.#post(
          "/reportEvents/getByIdAsEmployee",
          true,
          {
            reportEventId,
          }
        );

        return event;
      },
      insert: async (
        date,
        type,
        demo,
        visitType,
        description,
        street,
        zip,
        km,
        city,
        coordinate,
        unlockAddress,
        revenue
      ) => {
        const event = await this.#post("/reportEvents/insertAsEmployee", true, {
          date,
          type,
          demo,
          visitType,
          description,
          street,
          zip,
          km,
          city,
          coordinate,
          unlockAddress,
          revenue,
        });

        return event;
      },
      update: async (
        reportEventId,
        date,
        type,
        demo,
        visitType,
        description,
        street,
        zip,
        km,
        city,
        coordinate,
        unlockAddress,
        revenue
      ) => {
        const event = await this.#post("/reportEvents/updateAsEmployee", true, {
          reportEventId,
          date,
          type,
          demo,
          visitType,
          description,
          street,
          zip,
          km,
          city,
          coordinate,
          unlockAddress,
          revenue,
        });

        return event;
      },
      delete: async (reportEventId) => {
        await this.#post("/reportEvents/deleteAsEmployee", true, {
          reportEventId,
        });
      },
    },
    references: {
      getAll: async () => {
        const references = await this.#post(
          "/references/getAllAsEmployee",
          true
        );

        return references;
      },
      getById: async (referenceId) => {
        const reference = await this.#post(
          "/references/getByIdAsEmployee",
          true,
          {
            referenceId,
          }
        );

        return reference;
      },
      insert: async (title, text, from, type, enabled) => {
        const event = await this.#post("/references/insertAsEmployee", true, {
          title,
          text,
          from,
          type,
          enabled,
        });

        return event;
      },
      update: async (referenceId, title, text, from, type, enabled) => {
        await this.#post("/references/updateAsEmployee", true, {
          referenceId,
          title,
          text,
          from,
          type,
          enabled,
        });
      },
      delete: async (referenceId) => {
        await this.#post("/references/deleteAsEmployee", true, {
          referenceId,
        });
      },
    },
    settings: {
      get: async () => {
        const settings = await this.#post("/settings/getAsEmployee", true);

        return settings;
      },
      update: async (cartState) => {
        await this.#post("/settings/updateAsEmployee", true, {
          cartState,
        });
      },
      updateProductCatalog: async (productCatalog) => {
        await this.#postMultipart(
          "/settings/updateProductCatalogAsEmployee",
          true,
          productCatalog,
          {
            removeProductCatalog: !productCatalog,
          }
        );
      },
      updateAbout: async (about) => {
        await this.#post("/settings/updateAboutAsEmployee", true, {
          about,
        });
      },
    },
    videos: {
      getAll: async () => {
        const videos = await this.#post("/videos/getAllAsEmployee", true);

        return videos;
      },
      getById: async (videoId) => {
        const review = await this.#post("/videos/getByIdAsEmployee", true, {
          videoId,
        });

        return review;
      },
      insert: async (title, text, video, productId, enabled) => {
        const event = await this.#postMultipart(
          "/videos/insertAsEmployee",
          true,
          video,
          {
            title,
            text,
            productId,
            enabled,
          }
        );

        return event;
      },
      update: async (videoId, title, text, video, productId, enabled) => {
        await this.#postMultipart("/videos/updateAsEmployee", true, video, {
          videoId,
          title,
          text,
          productId,
          enabled,
        });
      },
      delete: async (videoId) => {
        await this.#post("/videos/deleteAsEmployee", true, {
          videoId,
        });
      },
    },
  };

  auth = {
    loginByCredentials: async (email, password) => {
      const auth = await this.#post("/auth/loginByCredentials", false, {
        email,
        password,
      });

      this.#token = auth.token;

      return auth;
    },
    loginVisitor: async () => {
      const auth = await this.#post("/auth/loginVisitor", false);

      this.#token = auth.token;

      return auth;
    },
    loginByToken: async (token) => {
      const auth = await this.#post("/auth/loginByToken", false, {
        token,
      });

      this.#token = auth.token;

      return auth;
    },
  };

  roles = {
    getPermissions: async () => {
      const permissions = await this.#post("/roles/getPermissions", true);

      return permissions;
    },
    getAll: async () => {
      const roles = await this.#post("/roles/getAll", true);

      return roles;
    },
    getById: async (roleId) => {
      const role = await this.#post("/roles/getById", true, { roleId });

      return role;
    },
    insert: async (name, permissionIds) => {
      const role = await this.#post("/roles/insert", true, {
        name,
        permissionIds,
      });

      return role;
    },
    update: async (roleId, name, permissionIds) => {
      const role = await this.#post("/roles/update", true, {
        roleId,
        name,
        permissionIds,
      });

      return role;
    },
    delete: async (roleId) => {
      await this.#post("/roles/delete", true, {
        roleId,
      });
    },
  };
}

export default ApiServerClient;
