import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faRemove } from "@fortawesome/free-solid-svg-icons";
import Spinner from "react-bootstrap/Spinner";

import productUtil from "../../../../utils/product.util";
import searchFilterUtil from "../../../../utils/searchFilter.util";
import stringUtil from "../../../../utils/string.util";

import { useException } from "../../../contexts/exception.context";
import { useAppState } from "../../../contexts/appState.context";
import OptionsControl from "../../../controls/options.control";
import TextInput from "../../../controls/text.input";
import TableControl from "../../../controls/table.control";

const defaultFilter = {
  query: "",
  show: true,
};

const ProductsPage = () => {
  const navigate = useNavigate();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();

  const [isLoading, setIsLoading] = useState(true);

  const [products, setProducts] = useState([]);
  const queryRef = useRef();
  const [filter, setFilter] = useState(defaultFilter);

  useEffect(() => {
    refresh();
    queryRef.current.focus();
    //eslint-disable-next-line
  }, []);

  const saveAndSetFilter = (filter) => {
    searchFilterUtil.save("products", appStateCtx.user._id, filter);

    setFilter(filter);
  };

  const refresh = async () => {
    try {
      setIsLoading(true);

      const filter =
        searchFilterUtil.load("products", appStateCtx.user._id) ||
        defaultFilter;

      setFilter(filter);

      const products =
        await appStateCtx.apiServerClient.employee.products.getAll();

      products.sort((a, b) => a.name.localeCompare(b.name));

      setProducts(products);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    } finally {
      setIsLoading(false);
    }
  };

  const onProductClicked = (product) => {
    navigate(productUtil.getAdminAbsoluteUrl(product));
  };

  const menuItems = [
    {
      name: "Produkter",
      url: "../produkter",
      selected: true,
    },
    {
      name: "Grupper",
      url: "../produktGrupper",
    },
  ];

  const query2 = filter.query.trim();

  const filteredProducts =
    query2.length >= 2
      ? products.filter((p) =>
          p.subProducts.some((s) =>
            stringUtil.includesCI(
              s.subProductNumber + "|" + s.name + "|" + p.tags,
              query2
            )
          )
        )
      : [];

  return (
    <div className="container page">
      <h1>Produkter</h1>

      <div className="d-flex justify-content-between mt-4">
        <OptionsControl items={menuItems} />

        <div className="btn-group">
          <button
            className="btn btn-primary"
            onClick={() => saveAndSetFilter({ ...filter, show: !filter.show })}
          >
            <FontAwesomeIcon icon={faFilter} />
          </button>
          {!searchFilterUtil.areFiltersEqual(defaultFilter, filter) && (
            <button
              className="btn btn-secondary"
              onClick={() => saveAndSetFilter({ ...defaultFilter })}
            >
              <FontAwesomeIcon icon={faRemove} />
            </button>
          )}
        </div>
      </div>
      <div style={{ display: filter.show ? "initial" : "none" }}>
        <div className="mt-3">
          <TextInput
            ref={queryRef}
            value={filter.query}
            name="productQuery"
            placeholder="Søg - nummer, navn, tags"
            onChange={(value) => saveAndSetFilter({ ...filter, query: value })}
          />
        </div>
      </div>

      {isLoading && <Spinner animation="border" className="mt-3" />}
      {!isLoading && (
        <div className="mt-3">
          <TableControl
            columns={[
              { header: "Navn", valueExtractor: (p) => p.name },
              {
                header: "Sub produkter",
                valueExtractor: (p) => p.subProducts.length,
                className: "text-end",
              },
              {
                header: "www",
                valueExtractor: (p) =>
                  p.subProducts.filter((s) => s.showOnHomepage).length,
                className: "text-end",
              },
              {
                header: "Billeder",
                valueExtractor: (p) => p.medias.length,
                className: "text-end",
              },
              {
                header: "Dokumenter",
                valueExtractor: (p) => p.documents.length,
                className: "text-end",
              },
            ]}
            rows={filteredProducts}
            onRowClicked={onProductClicked}
            keyExtractor={(p) => p._id}
          />
        </div>
      )}
    </div>
  );
};

export default ProductsPage;
