import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { DateTime } from "luxon";

import settings from "../../../../utils/settings";

import { useException } from "../../../contexts/exception.context";
import { useAppState } from "../../../contexts/appState.context";
import OptionsControl from "../../../controls/options.control";
import TableControl from "../../../controls/table.control";

import getMenuItems from "./customerMenuItems.data";

const getDateTime = (date, time) => {
  if (date && time)
    return `${DateTime.fromISO(date).toFormat(
      settings.dateShortFormat
    )} ${time}`;
  else if (DateTime.fromISO(date).toFormat(settings.dateShortFormat))
    return date;
  return "";
};

const getInterval = (start, end) => {
  if (end) return `${start} - ${end}`;
  return start;
};

const CustomerCalenderPage = () => {
  const params = useParams();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();

  const [events, setEvents] = useState([]);
  const [customer, setCustomer] = useState();

  const refresh = async () => {
    try {
      const customer =
        await appStateCtx.apiServerClient.employee.customers.getByNumber(
          params.customerNumber
        );

      const orders =
        await appStateCtx.apiServerClient.employee.calenderEvents.getByCustomerId(
          customer._id
        );

      setCustomer(customer);
      setEvents(orders);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line
  }, []);

  if (!customer) return;

  const menuItems = getMenuItems(appStateCtx.user, customer, "Kalender");

  return (
    <div className="container page">
      <h1>Kunde kalender</h1>

      <div className="mt-4">
        <OptionsControl items={menuItems} />
      </div>

      <div className="mt-3">
        <TableControl
          columns={[
            {
              header: "Dato",
              valueExtractor: (e) => {
                const start = getDateTime(e.startDate, e.startTime);
                const end = getDateTime(e.endDate, e.endTime);
                return getInterval(start, end);
              },
            },
            {
              header: "Type",
              valueExtractor: (e) => e.type.name,
            },
            {
              header: "Sted",
              valueExtractor: (e) => e.place,
            },
            {
              header: "Note",
              valueExtractor: (e) => e.note,
            },
          ]}
          rowClassNameExtractor={(e) =>
            e.finished ? "text-decoration-line-through" : ""
          }
          keyExtractor={(e) => e._id}
          rows={events}
        />
      </div>
    </div>
  );
};

export default CustomerCalenderPage;
