import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import validateUtil from "../../../utils/validate.util";
import urlUtil from "../../../utils/url.util";

import { useAppState } from "../../contexts/appState.context";
import { useException } from "../../contexts/exception.context";
import { useMessage } from "../../contexts/message.context";
import TextControl from "../../controls/text.input";
import PasswordInput from "../../controls/password.input";
import MetadataControl from "../../controls/metadata.control";

const LoginPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const submitRef = useRef();
  const passwordRef = useRef();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLoginClicked = async (e) => {
    e.preventDefault();

    try {
      const user = await appStateCtx.loginByCredentialsAsync(email, password);
      messageCtx.showSuccess(`Velkommen ${user.name}`);

      if (location.search) {
        // this restores / in components and can therefore not be used
        // const queryParams = new URLSearchParams(location.search);
        // const url = queryParams.get("url");

        const params = urlUtil.getQueryParams(location.search);

        if (params.url) {
          const url = encodeURI(
            urlUtil.safeEncodeComponentsToURL(
              urlUtil.safeDecodeURLToComponents(decodeURI(params.url))
            )
          );
          navigate(url);
          return;
        }
      }

      if (user.type === "Employee") {
        navigate("/admin/medarbejder/kalender");
      } else if (user.type === "CustomerContact") {
        navigate("/kunde/velkommen");
      } else {
        navigate("/");
      }
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const isFormOk = () => {
    if (!validateUtil.string.isValid(email)) return false;
    if (!validateUtil.string.isValid(password)) return false;

    return true;
  };

  return (
    <section className="page">
      <MetadataControl title="Capp2 - Log ind" />
      <div className="container">
        <h1>Log ind</h1>
        <div className="form-group mt-5">
          <label className="form-label">Email</label>
          <TextControl
            name="email"
            value={email}
            onChange={setEmail}
            onKeyDown={(e) => {
              if (e.which === 13) {
                e.preventDefault();
                passwordRef.current.focus();
              }
            }}
          />
        </div>
        <div className="form-group mt-3">
          <label className="form-label">Kodeord</label>
          <PasswordInput
            ref={passwordRef}
            value={password}
            onChange={setPassword}
            onKeyDown={(e) => {
              if (e.which === 13) {
                e.preventDefault();
                submitRef.current.click();
              }
            }}
          />
        </div>
        <div className="mt-3">
          <button
            ref={submitRef}
            disabled={!isFormOk()}
            className="btn btn-secondary btn-action m-0 w-100"
            onClick={handleLoginClicked}
          >
            Log ind
          </button>
        </div>
      </div>
    </section>
  );
};

export default LoginPage;
