import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useAppState } from "../../../../contexts/appState.context";
import { useException } from "../../../../contexts/exception.context";
import CustomerOrderControl from "../../../../controls/customerOrder.control";

const OrderPage = () => {
  const navigate = useNavigate();
  const params = useParams();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();

  const [order, setOrder] = useState();

  const isReadonly =
    !appStateCtx.user.permissionIds.includes("admin.orders.edit");

  const refresh = async () => {
    try {
      const order =
        await appStateCtx.apiServerClient.employee.customerOrders.getById(
          params.customerOrderId
        );

      setOrder(order);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  const handleCloseClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.customerOrders.updateProcessed(
        params.customerOrderId,
        true
      );
      navigate("./../..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleOpenClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.customerOrders.updateProcessed(
        params.customerOrderId,
        false
      );
      navigate("./../..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  if (!order) return null;

  return (
    <div className="container page">
      <h1>Kunde ordre</h1>

      <CustomerOrderControl order={order} showType />

      {!isReadonly && (
        <div className="mt-3">
          {!order.processedAt && (
            <button className="btn btn-primary" onClick={handleCloseClicked}>
              Luk
            </button>
          )}
          {order.processedAt && (
            <button className="btn btn-primary" onClick={handleOpenClicked}>
              Åben
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default OrderPage;
