import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { DateTime } from "luxon";

import settings from "../../../../utils/settings";

import { useException } from "../../../contexts/exception.context";
import { useAppState } from "../../../contexts/appState.context";
import OptionsControl from "../../../controls/options.control";
import TableControl from "../../../controls/table.control";

import getMenuItems from "./productMenuItems.data";

const ProductInvoicesPage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();

  const [invoices, setInvoices] = useState([]);
  const [product, setProduct] = useState();

  const refresh = async () => {
    try {
      const invoices =
        await appStateCtx.apiServerClient.employee.invoices.getByProductName(
          params.productGroupName,
          params.productName
        );

      invoices.sort(
        (a, b) => DateTime.fromISO(b.date) - DateTime.fromISO(a.date)
      );

      const product =
        await appStateCtx.apiServerClient.employee.products.getByName(
          params.productGroupName,
          params.productName
        );
      setProduct(product);
      setInvoices(invoices);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleInvoiceClicked = (invoice) => {
    navigate(`./${invoice.bookedInvoiceNumber}`);
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  if (!product) return null;

  const menuItems = getMenuItems(appStateCtx.user, product, "Fakturaer");

  return (
    <div className="container page">
      <h1>{params.productName}: fakturaer</h1>

      <div className="mt-4">
        <OptionsControl items={menuItems} />
      </div>

      <div className="mt-3">
        <TableControl
          columns={[
            {
              header: "Dato",
              valueExtractor: (i) =>
                DateTime.fromISO(i.date).toFormat(settings.dateShortFormat),
            },
            {
              header: "Kunde",
              valueExtractor: (i) => i.recipient.name,
            },
          ]}
          keyExtractor={(i) => i._id}
          onRowClicked={handleInvoiceClicked}
          rows={invoices}
        />
      </div>
    </div>
  );
};

export default ProductInvoicesPage;
