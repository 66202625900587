import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { useAppState } from "../../../../contexts/appState.context";
import { useException } from "../../../../contexts/exception.context";
import OrderControl from "../../../../controls/employeeOrder.control";

const OrderPage = () => {
  const params = useParams();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();

  const [order, setOrder] = useState();

  const refresh = async () => {
    try {
      const order =
        await appStateCtx.apiServerClient.employee.employeeOrders.getById(
          params.orderId
        );

      setOrder(order);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  return (
    <div className="container page">
      <h1>Ordre</h1>

      <OrderControl order={order} showEmployee={false} />
    </div>
  );
};

export default OrderPage;
