import { useEffect, useState } from "react";

import { useAppState } from "../../contexts/appState.context";
import { useException } from "../../contexts/exception.context";
import MetadataControl from "../../controls/metadata.control";

import VideoControl from "./video.control";

const FocusProductsPage = () => {
  const appStateCtx = useAppState();
  const exceptionCtx = useException();

  const [videos, setVideos] = useState([]);

  const refresh = async () => {
    try {
      const videos = await appStateCtx.apiServerClient.visitor.videos.getAll();

      setVideos(videos);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  return (
    <section
      className="backgroundcolor-gray"
      style={{
        paddingTop: "6rem",
        paddingBottom: "6rem",
      }}
    >
      <MetadataControl
        title="Capp2 - Videoer"
        description="Se Capp2's videoer."
      />
      <div className="container">
        <div className="row justify-content-center text-center ">
          <h1 className="mb-2">Videoer</h1>
          <h2 className="mb-5">Se alle vores videoer</h2>
        </div>
      </div>
      {videos.map((video, index) => (
        <div
          key={video._id}
          className={`${
            index % 2 === 0 ? "backgroundcolor-white" : "backgroundcolor-gray"
          } py-4`}
        >
          <VideoControl video={video} />
        </div>
      ))}
    </section>
  );
};

export default FocusProductsPage;
