import React from "react";
import { DateTime } from "luxon";
import numeral from "numeral";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

const format = (value, numberFormat) => {
  if (value === null || value === undefined) return "";
  // (12345.67).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');  // 12,345.67
  if (numberFormat) {
    return numeral(value).format(numberFormat);
  }
  return value;
};

const getDataCell = (column, row) => {
  const path = column.dataKey;
  if (!path) return "";

  let cell;
  if (path instanceof Function) {
    cell = path();
  } else {
    const elements = path.split(".");

    cell = row;
    for (let element of elements) {
      if (cell === undefined) return "";
      cell = cell[element];
    }
  }

  return cell;
};

const util = {
  generatePDF: (report) => {
    if (!report) return;

    const getPdfCell = (dataColumn, dataCell) => {
      const value = dataColumn.cellHasStyle ? dataCell.value : dataCell;

      const cellStyle = dataColumn.cellHasStyle
        ? { ...dataColumn.style, ...dataCell.style }
        : { ...dataColumn.style };

      const styles = {};

      if (cellStyle?.halign !== undefined) {
        styles.halign = cellStyle.halign;
      }

      if (cellStyle?.fontStyle === "bold") {
        styles.fontStyle = "bold";
      }

      if (cellStyle?.textColor !== undefined) {
        styles.textColor = "red";
      }

      return {
        content: `${format(value, cellStyle?.numberFormat)}${
          cellStyle?.post || ""
        }`,
        colSpan: dataColumn.colspan || 1,
        styles,
      };
    };

    const getRows = (rows) => {
      //eslint-disable-next-line
      const rows2 = rows.map((dataRow) => {
        const dataColumns = document.columnsDefs[dataRow._columnDefId];

        return dataColumns.map((dataColumn) => {
          const dataCell = getDataCell(dataColumn, dataRow);

          const pdfCell = getPdfCell(dataColumn, dataCell);
          return pdfCell;
        });
      });
      return rows2;
    };

    const outputLine = (doc, txt, x, y) => {
      const maxWidth = doc.getPageWidth() - 2 * x;
      doc.text(txt, x, y, { maxWidth, baseline: "top" });

      var dim = doc.getTextDimensions(report.name, {
        maxWidth,
      });

      return y + dim.h;
    };

    try {
      const doc = new jsPDF(report.orientation);
      doc.setFontSize(16);

      let yOffset = 10;
      const xOffset = 10;

      yOffset = outputLine(doc, report.name, xOffset, yOffset);
      yOffset += 4;

      for (let index in report.documents) {
        var document = report.documents[index];

        if (index > 0) {
          if (document.forceNewPage) {
            doc.addPage();
            yOffset = 0;
          }
        }

        if (document.type === "table") {
          const head = getRows(document.headerRows);
          const body = getRows(document.bodyRows);
          const foot = getRows(document.footerRows);

          doc.setFontSize(14);

          if (report.name != document.name && report.documents.length > 1) {
            yOffset = outputLine(doc, document.name, xOffset, yOffset);
            yOffset += 4;
          }

          doc.autoTable({
            startY: yOffset,
            head,
            foot,
            body,
          });

          yOffset = doc.lastAutoTable.finalY;
          yOffset += 4;
        }
      }

      const name = `${DateTime.now().toFormat("yyyy-MM-dd")} - ${report.name}`;
      const fileName = `${name}.pdf`;

      doc.save(fileName);
      return true;
    } catch (ex) {
      return false;
    }
  },

  generateXLSX: (report) => {
    if (!report) return;

    const applyStyle = (
      xlCell,
      xlRowNumber,
      xlColumnNumber,
      xlStyle,
      dataColumn,
      dataCell
    ) => {
      const style = dataColumn.cellHasStyle
        ? { ...dataColumn.style, ...dataCell.style, ...xlStyle }
        : { ...dataColumn.style, ...xlStyle };

      if (!style) return xlColumnNumber + 1;

      if (style.numberFormat !== undefined) {
        xlCell.numFmt = style.numberFormat;
      }

      if (style.halign !== undefined) {
        xlCell.alignment = { horizontal: style.halign };
      }

      if (style.fontStyle === "bold" || style.textColor !== undefined) {
        xlCell.font = {};

        if (style.fontStyle === "bold") {
          xlCell.font.bold = true;
        }
        if (style.textColor !== undefined) {
          xlCell.font.color = {
            argb: "FF" + style.textColor.substring(1).toUpperCase(),
          };
        }
      }

      if (dataColumn.colspan !== undefined) {
        xlCell.worksheet.mergeCells(
          xlRowNumber,
          xlColumnNumber,
          xlRowNumber,
          xlColumnNumber + dataColumn.colspan - 1
        );
      }

      return xlColumnNumber + (dataColumn.colspan || 1);
    };

    const writeRows = (xlSheet, document, rows) => {
      // eslint-disable-next-line no-loop-func
      rows.forEach((dataRow) => {
        const dataColumns = document.columnsDefs[dataRow._columnDefId];

        let xlColumnNumber = 1;
        const xlRow = xlSheet.addRow();
        dataColumns.forEach((dataColumn) => {
          const xlCell = xlRow.getCell(xlColumnNumber);
          const dataCell = getDataCell(dataColumn, dataRow);
          xlCell.value = dataColumn.cellHasStyle ? dataCell.value : dataCell;
          xlColumnNumber = applyStyle(
            xlCell,
            xlRow.number,
            xlColumnNumber,
            undefined,
            dataColumn,
            dataCell
          );
        });
      });
    };

    const normalizeSheetname = (name) => {
      let name2 = name;
      name2 = name2.replaceAll("*", "");
      name2 = name2.replaceAll("?", "");
      name2 = name2.replaceAll(":", "");
      name2 = name2.replaceAll("\\", "");
      name2 = name2.replaceAll("/", "");
      name2 = name2.replaceAll("[", "");
      name2 = name2.replaceAll("]", "");
      return name2;
      //* ? : \ / [ ]
    };

    try {
      const name = `${DateTime.now().toFormat("yyyy-MM-dd")} - ${report.name}`;
      const fileName = `${name}.xlsx`;

      const xlBook = new ExcelJS.Workbook();
      xlBook.creator = "VecinosMX";
      xlBook.created = new Date();

      // added 2024-01-04
      const xlSheet = xlBook.addWorksheet(normalizeSheetname(name));

      for (let index in report.documents) {
        var dataDocument = report.documents[index];

        if (dataDocument.type === "table") {
          // delete 2024-01-04
          // const xlSheet = xlBook.addWorksheet(
          //   normalizeSheetname(
          //     `${parseInt(index) + 1} - ${dataDocument.name.substr(0, 25)}`
          //   )
          // );

          const xlRowReport = xlSheet.addRow();
          const xlCellReportName = xlRowReport.getCell(1);
          xlCellReportName.value = report.name;
          xlCellReportName.font = { bold: true, size: 14 };

          if (report.name != document.name && report.documents.length > 1) {
            const xlRowTable = xlSheet.addRow();
            const xlCellTableName = xlRowTable.getCell(1);
            xlCellTableName.value = dataDocument.name;
            xlCellTableName.font = { bold: true, size: 13 };
          }

          xlSheet.addRow();

          writeRows(xlSheet, dataDocument, dataDocument.headerRows);
          writeRows(xlSheet, dataDocument, dataDocument.bodyRows);
          writeRows(xlSheet, dataDocument, dataDocument.footerRows);

          xlSheet.addRow();
        }
      }

      async function saveFile(fileName, workbook) {
        const xls64 = await workbook.xlsx.writeBuffer({ base64: true });
        saveAs(
          new Blob([xls64], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          }),
          fileName
        );
      }

      saveFile(fileName, xlBook);
      return true;
    } catch (ex) {
      return false;
    }
  },

  generateHtml: (report) => {
    if (!report) return;

    const getFormattedValue = (dataColumn, dataCell) => {
      const value = dataColumn.cellHasStyle ? dataCell.value : dataCell;

      const cellStyle = {
        ...dataColumn.style,
        ...(dataColumn.cellHasStyle ? dataCell.style : {}),
      };

      return `${format(value, cellStyle?.numberFormat)}${
        cellStyle?.post || ""
      }`;
    };

    const getStyle = (dataColumn, dataCell, forceStyle) => {
      const cellStyle = {
        ...dataColumn.style,
        ...dataCell?.style,
        ...forceStyle,
      };

      const style = {};

      if (cellStyle.halign !== undefined) {
        style.textAlign = cellStyle.halign;
      }

      if (cellStyle.textColor !== undefined) {
        style.color = cellStyle.textColor;
      }

      if (cellStyle.fontStyle !== undefined) {
        style.fontWeight = cellStyle.fontStyle;
      }

      if (cellStyle.width !== undefined) {
        style.width = cellStyle.width;
      }

      if (cellStyle.borderLeft) {
        style.borderLeft = "1px solid rgb(222, 226, 230)";
      }

      if (cellStyle.borderRight) {
        style.borderRight = "1px solid rgb(222, 226, 230)";
      }

      return style;
    };

    const writeRows = (document, dataRows) => {
      return dataRows.map((dataRow, rowIndex) => {
        const columnDef = document.columnsDefs[dataRow._columnDefId];

        return (
          <tr key={rowIndex}>
            {columnDef.map((dataColumn, columnIndex) => {
              const dataCell = getDataCell(dataColumn, dataRow);

              const style = getStyle(dataColumn, dataCell);

              return (
                <td
                  colSpan={dataColumn.colspan || 1}
                  key={columnIndex}
                  style={style}
                >
                  {getFormattedValue(dataColumn, dataCell)}
                </td>
              );
            })}
          </tr>
        );
      });
    };

    const list = report.documents.map((document, tIndex) => {
      if (document.type === "table") {
        return (
          <div key={tIndex} className="mt-3">
            {report.name != document.name && report.documents.length > 1 && (
              <h4>{document.name}</h4>
            )}
            <table className="table table-striped">
              <thead>{writeRows(document, document.headerRows)}</thead>
              <tbody>{writeRows(document, document.bodyRows)}</tbody>
              <tfoot>{writeRows(document, document.footerRows)}</tfoot>
            </table>
          </div>
        );
      } else if (document.type === "lines") {
        return (
          <div className="mt-3">
            {document.lines.map((l) => (
              <div>{l}</div>
            ))}
          </div>
        );
      }

      return null;
    });

    return (
      <div className="mt-3">
        <h2>
          <strong>{report.name}</strong>
        </h2>
        {list}
      </div>
    );
  },

  createRow: (row, _columnDefId) => {
    const row2 = { ...row, _columnDefId };

    return row2;
  },

  createHeaderDef: (bodyDef) => {
    const headerDef = [];

    for (var column of bodyDef) {
      const column2 = {
        dataKey: column.dataKey,
        style: { ...column.style, numberFormat: undefined, post: undefined },
      };

      if (column2.dataKey) {
        if (!column2.style) column2.style = {};
        column2.style.fontStyle = "bold";
      }

      headerDef.push(column2);
    }

    return headerDef;
  },

  getColumnsDefsFromColumns: (columns) => {
    const headerColumnsDef = columns.map((column) => ({
      dataKey: column.dataKey,
      colspan: column.colspan,
      style: {
        ...column.style,

        numberFormat: undefined,
        post: undefined,
        fontStyle: "bold",
      },
    }));
    const bodyColumnsDef = columns.map((column) => ({
      dataKey: column.dataKey,
      colspan: column.colspan,
      cellHasStyle: column.cellHasStyle,
      style: column.style,
    }));

    return { headerColumnsDef, bodyColumnsDef };
  },
  getHeaderRowFromColumns: (columns) => {
    const row = {};

    columns.forEach((column) => {
      if (!column.dataKey) return;

      const elements = column.dataKey.split(".");

      let tmp = row;

      for (var i = 0; i < elements.length; i++) {
        const element = elements[i];
        if (i === elements.length - 1) {
          tmp[element] = column.header;
        } else {
          if (!tmp[element]) {
            tmp[element] = {};
          }
          tmp = tmp[element];
        }
      }
    });

    return row;
  },
};

export default util;
