import React, { createContext, useContext, useEffect, useState } from "react";

import { useAppState } from "./appState.context";

const SettingsContext = createContext(null);

export const useSettings = () => {
  return useContext(SettingsContext);
};

export const SettingsProvider = ({ children }) => {
  const appStateCtx = useAppState();

  const [settings, setSettings] = useState();

  const refresh = async () => {
    try {
      const settings = await appStateCtx.apiServerClient.visitor.settings.get();
      setSettings(settings);
    } catch (ex) {
      console.log("settings failed to load", ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);
  return (
    <SettingsContext.Provider value={{ settings }}>
      {children}
    </SettingsContext.Provider>
  );
};
