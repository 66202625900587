import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DateTime } from "luxon";
import { Modal, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH, faXmark } from "@fortawesome/free-solid-svg-icons";

import validateUtil from "../../../../../utils/validate.util";

import { useAppState } from "../../../../contexts/appState.context";
import { useMessage } from "../../../../contexts/message.context";
import { useException } from "../../../../contexts/exception.context";
import CustomerNotesModal from "../../../../controls/customerNotes.modal";
import CustomerInvoicesModal from "../../../../controls/customerInvoices.modal";
import SelectInput from "../../../../controls/select.input";
import TextInput from "../../../../controls/text.input";
import DateTimeInput from "../../../../controls/datetime.input";
import TextareaInput from "../../../../controls/textarea.input";
import SelectCustomerModal from "../../../../controls/selectcustomer.modal";
import SelectDeliveryLocationModal from "../../../../controls/selectcustomerdeliverylocation.modal";
import SelectContactModal from "../../../../controls/selectcustomercontact.modal";
import ChoiceModal from "../../../../controls/choice.modal";

const CalenderEventPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { state } = useLocation();

  const appStateCtx = useAppState();
  const messageCtx = useMessage();
  const exceptionCtx = useException();

  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [customers, setCustomers] = useState([]);

  const [showDeliveryLocationModal, setShowDeliveryLocationModal] =
    useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showCustomerNotesModal, setShowCustomerNotesModal] = useState(false);
  const [showCustomerInvoicesModal, setShowCustomerInvoicesModal] =
    useState(false);

  const [calenders, setCalenders] = useState([]);
  const [types, setTypes] = useState([]);

  const [oldEvent, setOldEvent] = useState();
  const [newEvent, setNewEvent] = useState();

  const [showGotoModal, setShowGotoModal] = useState(false);

  const isReadonly =
    !!newEvent &&
    (!calenders.some((c) => c.employee._id === newEvent.employeeId) ||
      newEvent.finished);

  const refreshEvent = async (eventId, types) => {
    const event =
      await appStateCtx.apiServerClient.employee.calenderEvents.getById(
        eventId
      );

    // make sure types has latest rules
    event.type = types.find((t) => t.id === event.type.id);
    event.contactName = event.contactName || "";
    event.contactEmail = event.contactEmail || "";
    event.contactPhone = event.contactPhone || "";

    setOldEvent(event);
    setNewEvent(JSON.parse(JSON.stringify(event)));
  };

  const refresh = async () => {
    try {
      const types =
        await appStateCtx.apiServerClient.employee.calenderEvents.getTypes();
      setTypes(types);
      const calenders =
        await appStateCtx.apiServerClient.employee.calenderEvents.getCalenders();
      setCalenders(calenders);

      appStateCtx.apiServerClient.employee.customers
        .getAll()
        .then((customers) => {
          customers.sort((a, b) => a.name.localeCompare(b.name));
          setCustomers(customers);
        });

      if (state?.event) {
        const event = state.event;
        event.type = types.find((t) => t.id === state.event.type.id);
        event.contactName = event.contactName || "";
        event.contactEmail = event.contactEmail || "";
        event.contactPhone = event.contactPhone || "";

        setOldEvent();
        setNewEvent(JSON.parse(JSON.stringify(event)));
        return;
      }

      if (params.calenderEventId) {
        await refreshEvent(params.calenderEventId, types);
      } else {
        setNewEvent({
          employeeId: appStateCtx.user._id,
          header: "",
          startDate: DateTime.now().toISODate(),
          startTime: "",
          endDate: "",
          endTime: "",
          important: false,
          finished: false,
          type: undefined,
          customer: undefined,
          contactName: "",
          contactEmail: "",
          contactPhone: "",
          email: "",
          place: "",
          note: "",
          notifications: {},
        });
      }
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line
  }, [params.calenderEventId, state]);

  const onCloseGotoModal = async (choice) => {
    setShowGotoModal(false);

    if (choice === "Til begivenheder") {
      navigate("./..");
    } else {
      if (oldEvent) {
        await refreshEvent(newEvent._id, types);
      } else {
        navigate(`../${newEvent._id}`);
      }
    }
  };

  const handleCreateClicked = async () => {
    try {
      const event =
        await appStateCtx.apiServerClient.employee.calenderEvents.insert(
          newEvent.employeeId,
          newEvent.header,
          newEvent.startDate,
          newEvent.startTime,
          newEvent.endDate,
          newEvent.endTime,
          newEvent.type.id,
          newEvent.customer?._id,
          newEvent.contactName,
          newEvent.contactEmail,
          newEvent.contactPhone,
          newEvent.place,
          newEvent.note,
          newEvent.important
        );
      messageCtx.showSuccess("Kalender begivenhed gemt");
      setNewEvent(event);
      setShowGotoModal(true);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleUpdateClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.calenderEvents.update(
        newEvent._id,
        newEvent.employeeId,
        newEvent.header,
        newEvent.startDate,
        newEvent.startTime,
        newEvent.endDate,
        newEvent.endTime,
        newEvent.type.id,
        newEvent.customer?._id,
        newEvent.contactName,
        newEvent.contactEmail,
        newEvent.contactPhone,
        newEvent.place,
        newEvent.note,
        newEvent.important
      );
      messageCtx.showSuccess("Kalender begivenhed opdateret");
      setShowGotoModal(true);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleDeleteClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.calenderEvents.delete(
        params.calenderEventId
      );
      messageCtx.showSuccess("Kalender begivenhed slettet");
      navigate("./..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleSelectCustomerClicked = () => {
    setShowCustomerModal(true);
  };

  const handleClearCustomerClicked = () => {
    const header = getHeader(undefined, "");
    setNewEvent({
      ...newEvent,
      customer: undefined,
      header,
      contactName: "",
      contactEmail: "",
      contactPhone: "",
      place: "",
    });
  };

  const handleCreateFromThisClicked = () => {
    // https://stackoverflow.com/questions/46820682/how-do-i-reload-a-page-with-react-router
    navigate("../ny", {
      //replace: true,
      state: {
        event: newEvent,
      },
    });
  };

  const handleSelectContactClicked = () => {
    setShowContactModal(true);
  };

  const handleClearContactClicked = () => {
    setNewEvent({
      ...newEvent,
      contactName: "",
      contactEmail: "",
      contactPhone: "",
    });
  };

  const hasFormChanged = () => {
    if (!oldEvent) return false;

    return JSON.stringify(oldEvent) !== JSON.stringify(newEvent);
  };

  const getDateTime = (date, time) => {
    if (!date) return "";

    return time ? date + "T" + time : date;
  };

  const isFormOk = () => {
    if (!newEvent.type) {
      return false;
    }

    if (!validateUtil.string.isValid(newEvent.header)) return false;

    if (
      validateUtil.string.isValid(newEvent.contactEmail) &&
      !validateUtil.email.isValid(newEvent.contactEmail)
    ) {
      return false;
    }

    if (newEvent.type.hasStart) {
      if (!newEvent.startDate && newEvent.startTime) return false;
    }

    if (newEvent.type.hasEnd) {
      if (!newEvent.endDate && newEvent.endTime) {
        return false;
      }
    }

    if (newEvent.type.hasStart && newEvent.type.hasEnd) {
      const start = getDateTime(newEvent.startDate, newEvent.startTime);
      const end = getDateTime(newEvent.endDate, newEvent.endTime);

      if (end) {
        if (start >= end) {
          return false;
        }
      }
    }

    return true;
  };

  const onSelectCustomerClose = (customer) => {
    setShowCustomerModal(false);
    if (!customer) return;

    const header = getHeader(customer, "");
    setNewEvent({
      ...newEvent,
      customer,
      header,
      contactName: "",
      contactEmail: "",
      contactPhone: "",
      place: "",
    });
  };

  const handleOpenClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.calenderEvents.updateFinished(
        params.calenderEventId,
        false
      );
      await refreshEvent(newEvent._id, types);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleCloseClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.calenderEvents.updateFinished(
        params.calenderEventId,
        true
      );
      navigate("./..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleSelectDeliveryLocationClicked = () => {
    setShowDeliveryLocationModal(true);
  };

  const onSelectDeliveryLocationClose = (location) => {
    setShowDeliveryLocationModal(false);
    if (!location) return;

    setNewEvent({
      ...newEvent,
      place: [
        location.address,
        location.postalCode + location.city,
        location.country,
      ].join("\n"),
    });
  };

  const onSelectContactClose = (contact) => {
    setShowContactModal(false);
    if (!contact) return;

    const header = getHeader(newEvent.customer, contact.name);
    setNewEvent({
      ...newEvent,
      header,
      contactName: contact.name,
      contactEmail: contact.email,
      contactPhone: contact.phone,
    });
  };

  const handleSendMeetingConfirmationEmailClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.calenderEvents.sendMeetingConfirmationEmail(
        newEvent._id
      );

      await refreshEvent(newEvent._id, types);

      messageCtx.showSuccess("Bekræftelses email sendt");
      //navigate("./..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const getHeader = (customer, name) => {
    const header = [];
    if (customer?.name) {
      header.push(customer.name);
    }

    if (name) {
      header.push(name);
    }

    return header.join(" - ");
  };

  if (!newEvent) return null;

  const setContactNameAdapter = (name) => {
    const header = getHeader(newEvent.customer, name);
    setNewEvent({ ...newEvent, header, contactName: name });
    //setContactName(name);
  };

  return (
    <div className="container page">
      <h1>Begivenhed</h1>
      {oldEvent && (
        <div className="space-children mt-3">
          <button
            className="btn btn-primary"
            onClick={handleCreateFromThisClicked}
          >
            Opret ny fra denne
          </button>

          {!newEvent.finished && (
            <button
              className="btn btn-success"
              onClick={handleCloseClicked}
              disabled={hasFormChanged()}
            >
              Afslut
            </button>
          )}
          {newEvent.finished && (
            <button
              className="btn btn-success"
              onClick={handleOpenClicked}
              disabled={hasFormChanged()}
            >
              Åben
            </button>
          )}
          {!newEvent.finished &&
            newEvent.type?.id === "1" && ( // type === meeting
              <button
                className="btn btn-success"
                onClick={handleSendMeetingConfirmationEmailClicked}
                disabled={
                  hasFormChanged() ||
                  !newEvent.contactEmail ||
                  newEvent.finished ||
                  !!newEvent.notifications?.meetingConfirmationAt ||
                  !!newEvent.notifications?.meetingReminderAt
                }
              >
                Send bekræftelse
              </button>
            )}
        </div>
      )}
      <div className="form-group mt-3">
        <label className="form-label">Kalender</label>
        <SelectInput
          items={calenders}
          nameExtractor={(c) => c.employee.name}
          valueExtractor={(c) => c.employee._id}
          value={newEvent.employeeId}
          onChange={(value) => setNewEvent({ ...newEvent, employeeId: value })}
          readonly={isReadonly}
        />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Type</label>
        <SelectInput
          items={[{ name: "Vælg...", id: "" }, ...types]}
          valueExtractor={(t) => t.id}
          nameExtractor={(t) => t.name}
          value={newEvent.type?.id || ""}
          onChange={(value) =>
            setNewEvent({
              ...newEvent,
              type: types.find((t) => t.id === value),
            })
          }
          readonly={isReadonly}
        />
      </div>
      {newEvent.type && (
        <div>
          <div className="form-group mt-3">
            <label className="form-label">Overskrift *</label>
            <TextInput
              value={newEvent.header}
              onChange={(value) => setNewEvent({ ...newEvent, header: value })}
              readonly={isReadonly}
            />
          </div>
          {newEvent.type.hasStart && (
            <div className="form-group mt-3">
              <label className="form-label">Start</label>
              <DateTimeInput
                value={{ date: newEvent.startDate, time: newEvent.startTime }}
                onChange={(value) =>
                  setNewEvent({
                    ...newEvent,
                    startDate: value.date,
                    startTime: value.time,
                  })
                }
                readonly={isReadonly}
              />
            </div>
          )}
          {newEvent.type.hasEnd && (
            <div className="form-group mt-3">
              <label className="form-label">Slut</label>
              <DateTimeInput
                value={{ date: newEvent.endDate, time: newEvent.endTime }}
                onChange={(value) =>
                  setNewEvent({
                    ...newEvent,
                    endDate: value.date,
                    endTime: value.time,
                  })
                }
                readonly={isReadonly}
              />
            </div>
          )}
          {newEvent.type.hasCustomer && (
            <div>
              <div className="form-group mt-3">
                <label className="form-label">Kunde</label>
                <div className="input-group">
                  <TextInput
                    value={newEvent.customer?.name || ""}
                    disabled
                    readonly={isReadonly}
                  />
                  {!isReadonly && (
                    <button
                      className="btn btn-primary py-0"
                      onClick={handleSelectCustomerClicked}
                    >
                      <FontAwesomeIcon icon={faEllipsisH} />
                    </button>
                  )}
                  {newEvent.customer && !isReadonly && (
                    <button
                      className="btn btn-secondary py-0"
                      onClick={handleClearCustomerClicked}
                    >
                      <FontAwesomeIcon icon={faXmark} />
                    </button>
                  )}
                </div>
              </div>
              {newEvent.customer && (
                <div className="form-group mt-3">
                  <div className="space-children">
                    <button
                      className="btn btn-primary"
                      onClick={() => setShowCustomerNotesModal(true)}
                    >
                      Noter
                    </button>
                    {newEvent.customer && (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => setShowCustomerInvoicesModal(true)}
                      >
                        Fakturaer
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
          {newEvent.type.hasContact && (
            <div className="form-group mt-3">
              <label className="form-label">Kontakt</label>
              <div className="input-group ">
                <TextInput
                  value={newEvent.contactName}
                  onChange={setContactNameAdapter}
                  readonly={isReadonly}
                  placeholder="Navn"
                />
                <TextInput
                  value={newEvent.contactEmail}
                  onChange={(value) =>
                    setNewEvent({ ...newEvent, contactEmail: value })
                  }
                  readonly={isReadonly}
                  placeholder="Email"
                />
                <TextInput
                  value={newEvent.contactPhone}
                  onChange={(value) =>
                    setNewEvent({ ...newEvent, contactPhone: value })
                  }
                  readonly={isReadonly}
                  placeholder="Tel"
                />
                {!isReadonly && newEvent.customer && (
                  <button
                    className="btn btn-primary py-0"
                    onClick={handleSelectContactClicked}
                  >
                    <FontAwesomeIcon icon={faEllipsisH} />
                  </button>
                )}
                {!isReadonly && newEvent.contactEmail && (
                  <button
                    className="btn btn-secondary py-0"
                    onClick={handleClearContactClicked}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                )}
              </div>
            </div>
          )}
          {newEvent.type.hasPlace && (
            <div className="form-group mt-3">
              <label className="form-label">Sted</label>
              <div className="input-group ">
                <TextareaInput
                  value={newEvent.place}
                  onChange={(value) =>
                    setNewEvent({ ...newEvent, place: value })
                  }
                  rows="5"
                  readonly={isReadonly}
                />

                {!isReadonly && newEvent.customer && (
                  <button
                    className="btn btn-primary"
                    onClick={handleSelectDeliveryLocationClicked}
                  >
                    <FontAwesomeIcon icon={faEllipsisH} />
                  </button>
                )}
              </div>
            </div>
          )}
          <div className="form-group mt-3">
            <label className="form-label">Note</label>
            <TextareaInput
              value={newEvent.note}
              onChange={(value) => setNewEvent({ ...newEvent, note: value })}
              rows="5"
              readonly={isReadonly}
            />
          </div>
          <div className="form-group mt-3">
            <label className="form-label">Høj prioritet</label>
            <SelectInput
              items={["Ja", "Nej"]}
              valueExtractor={(i) => i}
              nameExtractor={(i) => i}
              value={newEvent.important ? "Ja" : "Nej"}
              onChange={(value) =>
                setNewEvent({ ...newEvent, important: value === "Ja" })
              }
            />
          </div>
        </div>
      )}
      {!isReadonly && (
        <div className="space-children mt-3">
          {!oldEvent && (
            <button
              className="btn btn-primary"
              disabled={!isFormOk()}
              onClick={handleCreateClicked}
            >
              Gem
            </button>
          )}

          {params.calenderEventId && (
            <button
              className="btn btn-primary"
              disabled={!isFormOk() || !hasFormChanged()}
              onClick={handleUpdateClicked}
            >
              Opdater
            </button>
          )}
          {params.calenderEventId && (
            <button className="btn btn-secondary" onClick={handleDeleteClicked}>
              Slet
            </button>
          )}
        </div>
      )}
      <SelectCustomerModal
        customers={customers}
        show={showCustomerModal}
        onClose={onSelectCustomerClose}
      />
      <ChoiceModal
        title={oldEvent ? "Beginvhed opdateret" : "Begivenhed gemt"}
        text="Hvor vil du hen?"
        choices={["Til begivenheder", "Til begivenhed"]}
        nameExtractor={(c) => c}
        show={showGotoModal}
        onClose={onCloseGotoModal}
      />
      {newEvent.customer && (
        <SelectDeliveryLocationModal
          locations={[
            {
              address: newEvent.customer.address,
              postalCode: newEvent.customer.zip,
              city: newEvent.customer.city,
              country: newEvent.customer.country,
            },
            ...newEvent.customer?.deliveryLocations,
          ]}
          show={showDeliveryLocationModal}
          onClose={onSelectDeliveryLocationClose}
        />
      )}
      {newEvent.customer && (
        <SelectContactModal
          show={showContactModal}
          onClose={onSelectContactClose}
          contacts={[
            {
              customerContactNumber: "",
              name: "",
              email: newEvent.customer.email || "",
              phone: newEvent.customer.telephoneAndFaxNumber || "",
            },
            ...newEvent.customer.contacts.filter((c) => !!c.email),
          ]}
        />
      )}
      {newEvent.customer && (
        <CustomerNotesModal
          show={showCustomerNotesModal}
          setShow={setShowCustomerNotesModal}
          customerId={newEvent.customer._id}
        />
      )}
      {newEvent.customer && (
        <CustomerInvoicesModal
          show={showCustomerInvoicesModal}
          setShow={setShowCustomerInvoicesModal}
          customerNumber={newEvent.customer.customerNumber}
        />
      )}
    </div>
  );
};

export default CalenderEventPage;
