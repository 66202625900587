const util = {
  email: {
    isValid: (email) => {
      //eslint-disable-next-line
      var re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      return re.test(email);
    },
  },
  string: {
    isValid: (str, trim, minLength, maxLength) => {
      if (!str) return false;
      if (trim) {
        str = str.trim();
      }

      if (minLength !== undefined) {
        if (str.length < minLength) return false;
      }

      if (maxLength !== undefined) {
        if (str.length < maxLength) return false;
      }

      return true;
    },
    isPhone: (str) => {
      if (!str) return false;
      return str.trim().length === 8;
    },
    isInteger: (str, min, max) => {
      if (!/^\d+$/.test(str)) return false;

      const number = parseInt(str);

      if (min) {
        if (number < min) return false;
      }

      if (max) {
        if (number > max) return false;
      }

      return true;
    },
  },

  number: {
    isNumber: (val) => {
      return true;
    },
  },
};

export default util;
