import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import validateUtil from "../../../../../utils/validate.util";

import { useException } from "../../../../contexts/exception.context";
import { useMessage } from "../../../../contexts/message.context";
import { useAppState } from "../../../../contexts/appState.context";
import TextInput from "../../../../controls/text.input";
import TextareaInput from "../../../../controls/textarea.input";
import SelectInput from "../../../../controls/select.input";

const ReferencePage = () => {
  const navigate = useNavigate();
  const params = useParams();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [oldReference, setOldReference] = useState();
  const [newReference, setNewReference] = useState();

  const isReadonly = !appStateCtx.user.permissionIds.includes(
    "admin.references.edit"
  );

  const refresh = async () => {
    if (params.referenceId) {
      try {
        const reference =
          await appStateCtx.apiServerClient.employee.references.getById(
            params.referenceId
          );

        reference.type = reference.type || "Kunde";

        setOldReference(reference);
        setNewReference(JSON.parse(JSON.stringify(reference)));
      } catch (ex) {
        exceptionCtx.handleException(ex);
      }
    } else {
      setNewReference({
        title: "",
        text: "",
        from: "",
        type: "Kunde",
        enabled: false,
      });
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, [params.referenceId]);

  const handleUpdateClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.references.update(
        newReference._id,
        newReference.title,
        newReference.text,
        newReference.from,
        newReference.type,
        newReference.enabled
      );
      messageCtx.showSuccess("Reference opdateret");
      navigate("./..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleCreateClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.references.insert(
        newReference.title,
        newReference.text,
        newReference.from,
        newReference.type,
        newReference.enabled
      );
      messageCtx.showSuccess("Reference gemt");
      navigate("./..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleDeleteClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.references.delete(
        params.referenceId
      );
      messageCtx.showSuccess("Reference slettet");
      navigate("./..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const isFormOk = () => {
    if (!validateUtil.string.isValid(newReference.title)) return false;
    if (!validateUtil.string.isValid(newReference.text)) return false;
    if (!validateUtil.string.isValid(newReference.from)) return false;
    if (!validateUtil.string.isValid(newReference.type)) return false;

    return true;
  };

  const hasFormChanged = () => {
    return JSON.stringify(oldReference) !== JSON.stringify(newReference);
  };

  if (!newReference) return null;

  return (
    <div className="container page">
      <h1>Reference</h1>
      <div className="form-group mt-3">
        <label className="form-label">Titel</label>
        <TextInput
          value={newReference.title}
          readonly={isReadonly}
          onChange={(value) =>
            setNewReference({ ...newReference, title: value })
          }
        />
      </div>

      <div className="form-group mt-3">
        <label className="form-label">Tekst</label>
        <TextareaInput
          rows={7}
          readonly={isReadonly}
          value={newReference.text}
          onChange={(value) =>
            setNewReference({ ...newReference, text: value })
          }
        />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Fra</label>
        <TextInput
          value={newReference.from}
          readonly={isReadonly}
          onChange={(value) =>
            setNewReference({ ...newReference, from: value })
          }
        />
      </div>

      <div className="form-group mt-3">
        <label className="form-label">Type</label>
        <SelectInput
          items={["Kunde"]}
          nameExtractor={(i) => i}
          valueExtractor={(i) => i}
          value={newReference.type}
          readonly={isReadonly}
          onChange={(value) =>
            setNewReference({ ...newReference, type: value })
          }
        />
      </div>

      <div className="form-group mt-3">
        <label className="form-label">Vis</label>
        <SelectInput
          items={["Ja", "Nej"]}
          valueExtractor={(i) => i}
          nameExtractor={(i) => i}
          value={newReference.enabled ? "Ja" : "Nej"}
          readonly={isReadonly}
          onChange={(value) =>
            setNewReference({
              ...newReference,
              enabled: value === "Ja",
            })
          }
        />
      </div>

      {!isReadonly && (
        <div className="space-children mt-3">
          {!params.referenceId && (
            <button
              className="btn btn-primary"
              onClick={handleCreateClicked}
              disabled={!isFormOk()}
            >
              Gem
            </button>
          )}

          {params.referenceId && (
            <button
              className="btn btn-primary"
              onClick={handleUpdateClicked}
              disabled={!isFormOk() || !hasFormChanged()}
            >
              Opdater
            </button>
          )}

          {params.referenceId && (
            <button
              className="btn btn-secondary"
              onClick={handleDeleteClicked}
              disabled={!isFormOk()}
            >
              Slet
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default ReferencePage;
