import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisH,
  faImage,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

import FileViewerModal from "./fileViewer.modal";
import { useMessage } from "../contexts/message.context";

export default ({
  value,
  onChange,
  readonly,
  disabled,
  accept,
  allowEmpty,
  maxSize,
  showSeeImageButton,
}) => {
  const messageCtx = useMessage();
  const [showFileViewerModal, setShowFileViewerModal] = useState(false);

  const handleFileChanged = (e) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      if (maxSize && file.size > maxSize) {
        messageCtx.showError("Tamaño de archivo demasiado grande");
        return;
      }

      if (file.type.startsWith("image/")) {
        file.url = URL.createObjectURL(file);
        // var fr = new FileReader();
        // fr.onload = function () {
        //   file.url = fr.result;
        //   onChange(file);
        // };
        // fr.readAsDataURL(file);
      }

      onChange(file);
    }
  };

  const handleDeleteFileClicked = () => {
    onChange();
  };

  const handleShowFileClicked = () => {
    setShowFileViewerModal(true);
  };

  if (readonly) {
    return (
      <>
        {value && showSeeImageButton && (
          <div className="form-control-plaintext">
            <button className="btn btn-success" onClick={handleShowFileClicked}>
              <FontAwesomeIcon icon={faImage} />
            </button>
          </div>
        )}
        <FileViewerModal
          show={showFileViewerModal}
          setShow={setShowFileViewerModal}
          images={[value]}
        />
      </>
    );
  } else {
    return (
      <>
        <div className="input-group">
          <input
            className="form-control"
            value={value?.name || ""}
            disabled
          ></input>
          <label className="btn btn-primary py-0 d-flex align-items-center">
            <input
              disabled={disabled}
              type="file"
              style={{ display: "none" }}
              onChange={handleFileChanged}
              accept={accept}
              onClick={(e) => (e.target.value = "")}
            />
            <FontAwesomeIcon icon={faEllipsisH} />
          </label>
          {value && !(value instanceof File) && showSeeImageButton && (
            <button
              className="btn btn-success py-0"
              onClick={handleShowFileClicked}
            >
              <FontAwesomeIcon icon={faImage} />
            </button>
          )}
          {value && allowEmpty && (
            <button
              className="btn btn-secondary py-0"
              onClick={handleDeleteFileClicked}
            >
              <FontAwesomeIcon icon={faXmark} />
            </button>
          )}
        </div>
        <FileViewerModal
          show={showFileViewerModal}
          setShow={setShowFileViewerModal}
          images={[value]}
        />
      </>
    );
  }
};
