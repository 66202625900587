import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useException } from "../../../contexts/exception.context";
import { useMessage } from "../../../contexts/message.context";
import { useAppState } from "../../../contexts/appState.context";
import FileInput from "../../../controls/file.input";
import TextareaInput from "../../../controls/textarea.input";

const CustomerNotePage = () => {
  const navigate = useNavigate();
  const params = useParams();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [customer, setCustomer] = useState();

  const [oldNote, setOldNote] = useState();
  const [newNote, setNewNote] = useState();

  const isReadonly = !appStateCtx.user.permissionIds.includes(
    "admin.customers.notes.edit"
  );

  const handleOpenClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.customerNotes.updateFinished(
        params.customerNoteId,
        false
      );
      navigate("./..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleCloseClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.customerNotes.updateFinished(
        params.customerNoteId,
        true
      );
      navigate("./..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const refresh = async () => {
    try {
      const customer =
        await appStateCtx.apiServerClient.employee.customers.getByNumber(
          params.customerNumber
        );

      setCustomer(customer);

      if (params.customerNoteId) {
        const note =
          await appStateCtx.apiServerClient.employee.customerNotes.getById(
            params.customerNoteId
          );

        setOldNote(note);
        setNewNote(JSON.parse(JSON.stringify(note)));
      } else {
        setNewNote({ text: "", file: undefined });
      }
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, [params.customerNoteId]);

  const hasFormChanged = () => {
    return JSON.stringify(oldNote) !== JSON.stringify(newNote);
  };

  const isFormOk = () => {
    if (!newNote.text) return false;

    return true;
  };

  const handleCreateClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.customerNotes.insert(
        customer._id,
        newNote.text,
        newNote.file
      );
      messageCtx.showSuccess("Kunde note gemt");
      navigate("./..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleUpdateClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.customerNotes.update(
        params.customerNoteId,
        newNote.text,
        newNote.file
      );
      messageCtx.showSuccess("Kunde note opdateret");
      navigate("./..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleDeleteClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.customerNotes.delete(
        params.customerNoteId
      );
      messageCtx.showSuccess("Kunde note slettet");
      navigate("./..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  if (!newNote) return;

  return (
    <div className="container page">
      <h1>Kunde note</h1>
      {!isReadonly && oldNote && (
        <div className="space-children mt-3">
          {!oldNote.finished && (
            <button
              className="btn btn-success"
              onClick={handleCloseClicked}
              disabled={hasFormChanged()}
            >
              Afslut
            </button>
          )}
          {oldNote.finished && (
            <button
              className="btn btn-success"
              onClick={handleOpenClicked}
              disabled={hasFormChanged()}
            >
              Åben
            </button>
          )}
        </div>
      )}
      <div className="form-group mt-3">
        <label className="form-label">Text</label>
        <TextareaInput
          value={newNote.text}
          readonly={isReadonly}
          rows={5}
          onChange={(value) => setNewNote({ ...newNote, text: value })}
        />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Fil</label>

        <FileInput
          value={newNote.file}
          readonly={isReadonly}
          accept=".png, .jpeg, .jpg"
          onChange={(value) => setNewNote({ ...newNote, file: value })}
          allowEmpty
          showSeeImageButton
          maxSize={1024 * 1024 * 10}
        />
      </div>

      {!isReadonly && (
        <div className="space-children mt-3">
          {!params.customerNoteId && (
            <button
              className="btn btn-primary"
              onClick={handleCreateClicked}
              disabled={!isFormOk()}
            >
              Gem
            </button>
          )}
          {params.customerNoteId && (
            <button
              className="btn btn-primary"
              onClick={handleUpdateClicked}
              disabled={!isFormOk() || !hasFormChanged()}
            >
              Opdater
            </button>
          )}
          {params.customerNoteId && (
            <button className="btn btn-secondary" onClick={handleDeleteClicked}>
              Slet
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomerNotePage;
