import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import validateUtil from "../../../../../utils/validate.util";

import { useException } from "../../../../contexts/exception.context";
import { useAppState } from "../../../../contexts/appState.context";
import TextInput from "../../../../controls/text.input";
import SectioncheckboxesInput from "../../../../controls/sectioncheckboxes.input";
import { useMessage } from "../../../../contexts/message.context";

const RolePage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [oldRole, setOldRole] = useState();
  const [newRole, setNewRole] = useState();
  const [permissions, setPermissions] = useState();

  const isReadonly =
    !appStateCtx.user.permissionIds.includes("admin.roles.edit");

  const refresh = async () => {
    try {
      let permissions =
        await appStateCtx.apiServerClient.roles.getPermissions();

      let type;

      if (params.roleId) {
        const role = await appStateCtx.apiServerClient.roles.getById(
          params.roleId
        );
        setOldRole(role);
        setNewRole(JSON.parse(JSON.stringify(role)));
        type = role.type;
      } else {
        setOldRole();
        setNewRole({ name: "", permissionIds: [], type: "Employee" });
        type = "Employee";
      }

      permissions = permissions.filter((p) => p.userTypes.includes(type));

      setPermissions(permissions);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  const isFormOk = () => {
    if (!validateUtil.string.isValid(newRole.name, true, 1)) return false;

    return true;
  };

  const hasFormChanged = () => {
    return JSON.stringify(oldRole) !== JSON.stringify(newRole);
  };

  const handleDeleteClicked = async () => {
    try {
      await appStateCtx.apiServerClient.roles.delete(newRole._id);
      messageCtx.showSuccess("Rolle slettet");
      navigate("./..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleSaveClicked = async () => {
    try {
      await appStateCtx.apiServerClient.roles.update(
        newRole._id,
        newRole.name,
        newRole.permissionIds
      );
      messageCtx.showSuccess("Rolle opdateret");
      navigate("./..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleCreateClicked = async () => {
    try {
      await appStateCtx.apiServerClient.roles.insert(
        newRole.name,
        newRole.permissionIds
      );
      messageCtx.showSuccess("Rolle gemt");
      navigate("./..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  if (!newRole) return null;

  return (
    <div className="container page">
      <h1>Rolle</h1>
      <div>
        <label className="form-label">Navn</label>
        <TextInput
          value={newRole.name}
          onChange={(value) => setNewRole({ ...newRole, name: value })}
          readonly={isReadonly}
        />
      </div>
      <div className="mt-2">
        <label className="form-label">Rettigheder</label>
        <SectioncheckboxesInput
          readonly={isReadonly}
          values={newRole.permissionIds}
          onChange={(values) =>
            setNewRole({ ...newRole, permissionIds: values })
          }
          items={permissions}
          nameExtractor={(p) => p.name}
          valueExtractor={(p) => p.id}
          sectionNameExtractor={(p) => p.category}
          sectionValueExtractor={(p) => p.category}
        />
      </div>
      {!isReadonly && (
        <div className="space-children mt-3">
          {!oldRole && (
            <button
              className="btn btn-primary"
              onClick={handleCreateClicked}
              disabled={!isFormOk()}
            >
              Gem
            </button>
          )}
          {oldRole && (
            <button
              className="btn btn-primary"
              onClick={handleSaveClicked}
              disabled={!isFormOk() || !hasFormChanged()}
            >
              Opdater
            </button>
          )}
          {oldRole && newRole.type === "Employee" && (
            <button className="btn btn-secondary" onClick={handleDeleteClicked}>
              Slet
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default RolePage;
