import { useEffect, useState } from "react";

import { useAppState } from "../../contexts/appState.context";
import { useException } from "../../contexts/exception.context";
import MetadataControl from "../../controls/metadata.control";

import AnnouncementControl from "./announcement.control";

const AnnouncementsPage = () => {
  const appStateCtx = useAppState();
  const exceptionCtx = useException();

  const [announcements, setAnnouncements] = useState([]);

  const refresh = async () => {
    try {
      const announcements =
        await appStateCtx.apiServerClient.visitor.announcements.getAll();

      announcements.sort((a, b) => a.createdAt.localeCompare(b.createdAt) * -1);

      setAnnouncements(announcements);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  return (
    <section
      style={{
        paddingTop: "6rem",
        paddingBottom: "6rem",
      }}
    >
      <MetadataControl
        title="Capp2 - Nyheder"
        description="Se Capp2's nyheder"
      />
      <div className="container">
        <div className="row justify-content-center">
          <div className="text-center">
            <h1 className="mb-2">Nyheder</h1>
            <h2 className="mb-5">Seneste nyt fra Capp2</h2>
          </div>
        </div>
        <div className="row g-4 justify-content-start">
          {announcements.map((a) => (
            <AnnouncementControl announcement={a} key={a._id} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default AnnouncementsPage;
