import React, { useEffect, useState } from "react";

import { useAppState } from "../../../contexts/appState.context";
import { useException } from "../../../contexts/exception.context";
import InvoicesTable from "../../../controls/invoices.table";

const InvoicesPage = () => {
  const appStateCtx = useAppState();
  const exceptionCtx = useException();

  const [invoices, setInvoices] = useState([]);

  const refresh = async () => {
    try {
      const invoices =
        await appStateCtx.apiServerClient.customer.invoices.getAll();

      invoices.sort((a, b) => b.date.localeCompare(a.date));

      setInvoices(invoices);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  return (
    <div className="container page">
      <h1>Fakturaer</h1>

      <div className="mt-3">
        <InvoicesTable invoices={invoices} />
      </div>
    </div>
  );
};
export default InvoicesPage;
