import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import productUtil from "../../../utils/product.util";
import stringUtil from "../../../utils/string.util";
import settings from "../../../utils/settings";

import { useAppState } from "../../contexts/appState.context";
import SearchtextInput from "../../controls/searchtext.input";
import MetadataControl from "../../controls/metadata.control";

const DatasheetPage = () => {
  const appStateCtx = useAppState();

  const [productsWithDatasheets, setProductsWithDatasheets] = useState([]);
  const [query, setQuery] = useState("");

  useEffect(() => {
    const productsWithDatasheets = appStateCtx.products.filter((p) =>
      p.documents.some((d) => d.type === "Sikkerhedsdatablad")
    );

    setProductsWithDatasheets(productsWithDatasheets);
  }, [appStateCtx.products]);

  const query2 = query.trim();

  let products2 = productsWithDatasheets;

  if (query) {
    products2 = products2.filter((p) =>
      p.subProducts.some((s) =>
        stringUtil.includesCI(
          s.subProductNumber + "|" + s.name + "|" + p.tags,
          query2
        )
      )
    );
  }

  const groups = products2
    .filter((p) => p)
    .reduce((a, b) => {
      if (!a[b.productGroup.productGroupNumber]) {
        a[b.productGroup.productGroupNumber] = {
          name: b.productGroup.name,
          products: [],
        };
      }

      a[b.productGroup.productGroupNumber].products.push(b);
      return a;
    }, {});

  const groups2 = Object.values(groups).sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  return (
    <section className="container page">
      <MetadataControl
        title={`Capp2 - Sikkerhedsdatablade`}
        description="Hent sikkerhedsdatablade på Capp2's produkter"
      />
      <div className="row">
        <div className="col-md-12 col-lg-12">
          <h1 className="mb-5">Sikkerhedsdatablade</h1>
        </div>
      </div>
      <div className="row">
        <SearchtextInput
          name="productQuery"
          placeholder="Søg - nummer, navn, tags"
          value={query}
          onChange={setQuery}
        />

        {groups2.map((g) => {
          if (
            !g.products.some((p) =>
              p.documents.some((d) => d.type === "Sikkerhedsdatablad")
            )
          ) {
            return null;
          }

          return (
            <div key={g.products[0]._id}>
              <div className="display-6 display-bold my-5">{g.name}</div>
              <div className="row">
                {g.products.map((product) => {
                  if (!query || stringUtil.includesCI(product.name, query)) {
                    const document = product.documents.find(
                      (d) => d.type === "Sikkerhedsdatablad"
                    );
                    if (document) {
                      return (
                        <div
                          className="col-md-6 col-xl-4 mb-5"
                          key={product._id}
                        >
                          <div
                            className="card text-center backgroundcolor-gray h-100"
                            style={{ alignItems: "center" }}
                          >
                            <div className="card-body d-flex flex-column">
                              <h5 className="card-title display-bold">
                                {product.name}
                              </h5>

                              <div className="d-flex mt-2 align-items-end justify-content-center flex-grow-1">
                                {product.medias.length ? (
                                  <>
                                    <Link
                                      to={productUtil.getAbsoluteUrl(product)}
                                    >
                                      <img
                                        className="backgroundcolor-white"
                                        src={
                                          product.medias.length > 0
                                            ? product.medias[0].file.thumbUrl
                                            : settings.noImage.thumbUrl
                                        }
                                        style={{
                                          width: 200,
                                          height: 200,
                                          objectFit: "contain",
                                          padding: 20,
                                          border: "#bbb 1px solid",
                                        }}
                                        alt={product.name}
                                      />
                                    </Link>
                                  </>
                                ) : null}
                              </div>
                              <div>
                                <a
                                  href={document.file.url}
                                  target="_blank"
                                  className="btn btn-secondary btn-action mt-4"
                                  rel="noreferrer"
                                >
                                  Hent datablad
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  }
                  return null;
                })}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default DatasheetPage;
