import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import productUtil from "../../../../utils/product.util";

import { useException } from "../../../contexts/exception.context";
import { useMessage } from "../../../contexts/message.context";
import { useAppState } from "../../../contexts/appState.context";
import TextInput from "../../../controls/text.input";
import TextareaInput from "../../../controls/textarea.input";

const ProductReviewPage = () => {
  const navigate = useNavigate();
  const params = useParams();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [product, setProduct] = useState();
  const [oldReview, setOldReview] = useState();
  const [newReview, setNewReview] = useState();

  const isReadonly = !appStateCtx.user.permissionIds.includes(
    "admin.products.reviews.edit"
  );

  const refresh = async () => {
    try {
      const product =
        await appStateCtx.apiServerClient.employee.products.getByName(
          params.productGroupName,
          params.productName
        );

      setProduct(product);

      if (params.productReviewId) {
        const review =
          await appStateCtx.apiServerClient.employee.productReviews.getById(
            params.productReviewId
          );

        setOldReview(review);
        setNewReview(JSON.parse(JSON.stringify(review)));
      } else {
        setNewReview({ text: "", title: "", reviewer: "" });
      }
      // setText(review.text);
      // setTitle(review.title);
      // setReviewer(review.reviewer);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  const handleUpdateClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.productReviews.update(
        newReview._id,
        newReview.title,
        newReview.text,
        newReview.reviewer
      );

      navigate(productUtil.getAdminAbsoluteUrl(product, "anmeldelser"));
      messageCtx.showSuccess("Anmeldelse opdateret");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleCreateClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.productReviews.insert(
        product._id,
        newReview.title,
        newReview.text,
        newReview.reviewer
      );
      navigate(productUtil.getAdminAbsoluteUrl(product, "anmeldelser"));
      messageCtx.showSuccess("Anmeldelse gemt");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleDeleteClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.productReviews.delete(
        newReview._id
      );
      navigate(productUtil.getAdminAbsoluteUrl(product, "anmeldelser"));
      messageCtx.showSuccess("Anmeldelse slettet");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const hasFormChanged = () => {
    return JSON.stringify(oldReview) !== JSON.stringify(newReview);
  };

  const isFormOk = () => {
    if (!newReview.text) return false;
    if (!newReview.title) return false;
    if (!newReview.reviewer) return false;

    return true;
  };

  if (!product) return null;

  if (!newReview) return null;

  return (
    <div className="container page">
      <h1>{params.productName}: anmeldelse</h1>
      <div className="form-group mt-3">
        <label className="form-label">Titel</label>
        <TextInput
          value={newReview.title}
          onChange={(value) => setNewReview({ ...newReview, title: value })}
          readonly={isReadonly}
        />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Tekst</label>
        <TextareaInput
          value={newReview.text}
          rows={5}
          onChange={(value) => setNewReview({ ...newReview, text: value })}
          readonly={isReadonly}
        />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Anmelder</label>
        <TextInput
          value={newReview.reviewer}
          onChange={(value) => setNewReview({ ...newReview, reviewer: value })}
          readonly={isReadonly}
        />
      </div>
      {!isReadonly && (
        <div className="mt-3 space-children">
          {!params.productReviewId && (
            <button
              className="btn btn-primary"
              onClick={handleCreateClicked}
              disabled={!isFormOk()}
            >
              Gem
            </button>
          )}
          {params.productReviewId && (
            <button
              className="btn btn-primary"
              onClick={handleUpdateClicked}
              disabled={!isFormOk() || !hasFormChanged()}
            >
              Opdater
            </button>
          )}
          {params.productReviewId && (
            <button className="btn btn-secondary" onClick={handleDeleteClicked}>
              Slet
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default ProductReviewPage;
