import React, { useEffect, useState } from "react";

import { useException } from "../../contexts/exception.context";
import { useAppState } from "../../contexts/appState.context";
import ProductGroupControl from "../../controls/productGroup.control";
import MetadataControl from "../../controls/metadata.control";

const ProductGroupsPage = () => {
  const appStateCtx = useAppState();
  const exceptionCtx = useException();

  const [groups, setGroups] = useState([]);

  const refresh = async () => {
    try {
      const groups =
        await appStateCtx.apiServerClient.visitor.productGroups.getAll();

      groups.sort((a, b) => a.name.localeCompare(b.name));

      setGroups(groups);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  return (
    <section className="container page">
      <MetadataControl
        title="Capp2 - Produkt grupper"
        description="Se Capp2's produkt grupper."
      />
      <div className="row">
        <div className="col-12">
          <h1 className="mb-5">Grupper</h1>
        </div>
      </div>
      <div className="row">
        {groups.map((g) => (
          <ProductGroupControl key={g._id} group={g} />
        ))}
      </div>
    </section>
  );
};
export default ProductGroupsPage;
