import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import reportsUtil from "../../../../utils/reports.util";

import { useException } from "../../../contexts/exception.context";
import { useAppState } from "../../../contexts/appState.context";
import { useMessage } from "../../../contexts/message.context";
import OptionsControl from "../../../controls/options.control";

import getMenuItems from "./productMenuItems.data";
import SelectInput from "../../../controls/select.input";

const ProductReportsPage = () => {
  const params = useParams();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [types, setTypes] = useState([]);
  const [typeId, setTypeId] = useState("");
  const [product, setProduct] = useState();
  const [report, setReport] = useState();

  const refresh = async () => {
    try {
      const types =
        await appStateCtx.apiServerClient.employee.products.getReportTypes();

      const product =
        await appStateCtx.apiServerClient.employee.products.getByName(
          params.productGroupName,
          params.productName
        );
      setProduct(product);
      if (types.length) setTypeId(types[0].id.toString());
      setTypes(types);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  const handleCreateClicked = async () => {
    try {
      const report =
        await appStateCtx.apiServerClient.employee.products.generateReport(
          typeId,
          params.productGroupName,
          params.productName
        );

      messageCtx.showSuccess("Rapport genereret");
      setReport(report);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  if (!product) return null;

  const menuItems = getMenuItems(appStateCtx.user, product, "Rapporter");

  return (
    <div className="container page">
      <h1>{params.productName}: rapporter</h1>
      <div className="mt-4">
        <OptionsControl items={menuItems} />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Type</label>
        <SelectInput
          items={types}
          nameExtractor={(t) => t.name}
          valueExtractor={(t) => t.id}
          value={typeId}
          onChange={setTypeId}
        />
      </div>
      <div className="mt-3 space-children">
        <button className="btn btn-primary" onClick={handleCreateClicked}>
          Generer
        </button>
        {report && (
          <>
            <button
              className="btn btn-success"
              onClick={() => reportsUtil.generatePDF(report)}
            >
              PDF
            </button>
            <button
              className="btn btn-success ms-2"
              onClick={() => reportsUtil.generateXLSX(report)}
            >
              XLSX
            </button>
          </>
        )}
      </div>
      {report && <div>{reportsUtil.generateHtml(report)}</div>}
    </div>
  );
};

export default ProductReportsPage;
