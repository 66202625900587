import React from "react";
import { Link } from "react-router-dom";

import settings from "../../utils/settings";
import productUtil from "../../utils/product.util";

const getOverlay = (overlay) => {
  if (hasOverlay(overlay)) {
    return (
      <span
        className={`position-absolute ${getOverlayClassNames(
          overlay
        )} translate-middle badge rounded-pill`}
        style={{
          ...getOverlayBackgroundColor(overlay),
        }}
      >
        {getOverlayText(overlay)}
      </span>
    );
  }

  return null;
};

const hasOverlay = (overlay) => {
  const id = overlay?.type?.id;
  if (id === "none" || !id) return false;
  return true;
};

const getOverlayText = (overlay) => {
  const id = overlay?.type?.id;
  switch (id) {
    case "offer":
      return "Tilbud";
    case "novelty":
      return "Nyhed";
    default:
      return "";
  }
};

const getOverlayBackgroundColor = (overlay) => {
  const id = overlay?.type?.id;
  switch (id) {
    case "offer":
      return {
        backgroundColor: settings.colors.secondary,
      };
    case "novelty":
      return {
        backgroundColor: "green",
      };
    default:
      return {};
  }
};

const getOverlayClassNames = (overlay) => {
  const id = overlay?.position?.id;
  switch (id) {
    case "topLeft":
      return "top-0 start-0";
    case "topRight":
      return "top-0 start-100";
    case "bottomLeft":
      return "top-100 start-0";
    case "bottomRight":
      return "top-100 start-100";
    default:
      return "";
  }
};

const ProductControl = ({ product, onClick }) => {
  if (!product) return <div className="col" />;

  return (
    <div className="col-md-6 col-xl-4 mb-5">
      <div className="card backgroundcolor-gray h-100">
        <div className="card-body d-flex flex-column align-items-center">
          <h5 className="card-title display-bold text-center">
            {product.name}
          </h5>

          <div className="d-flex mt-2 align-items-end justify-content-center flex-grow-1">
            {/* {product.medias.length ? ( */}
            <div
              style={{
                width: 200,
                height: 200,
                position: "relative",
              }}
            >
              <Link to={productUtil.getAbsoluteUrl(product)} onClick={onClick}>
                <img
                  className="backgroundcolor-white"
                  src={
                    product.medias.length > 0
                      ? product.medias[0].file.thumbUrl
                      : settings.noImage.thumbUrl
                  }
                  style={{
                    width: 200,
                    height: 200,
                    objectFit: "contain",
                    padding: 20,
                    border: "#bbb 1px solid",
                  }}
                  alt={product.name}
                />
              </Link>
              {getOverlay(product.overlay1)}
              {getOverlay(product.overlay2)}
            </div>
            {/* ) : (
              <div style={{ width: 200, height: 200 }}></div>
            )} */}
          </div>
          <Link
            to={productUtil.getAbsoluteUrl(product)}
            className="btn btn-secondary btn-action mt-4"
            onClick={onClick}
          >
            Se mere
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProductControl;
