import { useState } from "react";
import { Modal } from "react-bootstrap";

import stringUtil from "../../utils/string.util";

import SearchtextInput from "./searchtext.input";
import TableControl from "./table.control";

export default ({ show, onClose, customers }) => {
  const [query, setQuery] = useState("");

  const query2 = query.trim();

  const filteredCustomers = !!query
    ? customers.filter((c) => stringUtil.includesCI(c.name, query2))
    : customers;

  const handleCustomerClicked = (customer) => {
    onClose(customer);
  };

  return (
    <Modal show={show} onHide={() => onClose()} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Vælg kunde</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SearchtextInput value={query} onChange={setQuery} placeholder="Søg" />
        <div className="mt-3">
          <TableControl
            columns={[
              { header: "Navn", type: "string", valueExtractor: (c) => c.name },
            ]}
            keyExtractor={(c) => c.customerNumber}
            rows={filteredCustomers}
            onRowClicked={handleCustomerClicked}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
