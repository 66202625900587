import React from "react";
import { Modal } from "react-bootstrap";

export default ({ show, onClose, title, text, choices, nameExtractor }) => {
  return (
    <Modal show={show} onHide={() => onClose()}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{text}</Modal.Body>
      <Modal.Footer>
        {choices.map((c) => {
          return (
            <button className="btn btn-primary" onClick={() => onClose(c)}>
              {nameExtractor(c)}
            </button>
          );
        })}
      </Modal.Footer>
    </Modal>
  );
};
