import { Modal } from "react-bootstrap";

import TableControl from "./table.control";

export default ({ show, onClose, contacts }) => {
  const handleContactClicked = (contact) => {
    onClose(contact);
  };

  return (
    <Modal show={show} onHide={() => onClose()} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Vælg kontakt person</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TableControl
          columns={[
            {
              header: "Navn",
              type: "string",
              valueExtractor: (c) => {
                const values = [];

                if (c.name) values.push(c.name);
                if (c.email) values.push(c.email);
                if (c.phone) values.push(c.phone);

                return (
                  <span>
                    {values.map((v) => (
                      <div>{v}</div>
                    ))}
                  </span>
                );
              },
            },
          ]}
          showColumns={false}
          rows={contacts}
          keyExtractor={(c) => c.customerContactNumber}
          onRowClicked={handleContactClicked}
        />
      </Modal.Body>
    </Modal>
  );
};
