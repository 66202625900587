import { useEffect, useState } from "react";

import { useAppState } from "../../contexts/appState.context";
import { useException } from "../../contexts/exception.context";
import MetadataControl from "../../controls/metadata.control";

import FocusProductControl from "./focusproduct.control";

const FocusProductsPage = () => {
  const appStateCtx = useAppState();
  const exceptionCtx = useException();

  const [focusProducts, setFocusProducts] = useState([]);

  const refresh = async () => {
    try {
      const products =
        await appStateCtx.apiServerClient.visitor.products.getAllFocusProducts();

      setFocusProducts(products);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  return (
    <section
      className="backgroundcolor-gray"
      style={{
        paddingTop: "6rem",
        paddingBottom: "6rem",
      }}
    >
      <MetadataControl
        title="Capp2 - Fokus produkter"
        description="Se Capp2's fokus produkter."
      />
      <div className="container">
        <div className="row justify-content-center text-center ">
          <h1 className="mb-2">Produkter i fokus</h1>
          <h2 className="mb-5">
            Her er listen over alle vores produkter der er i fokus
          </h2>
        </div>
        <div className="row g-4 justify-content-start">
          {focusProducts.map((p) => (
            <FocusProductControl product={p} key={p._id} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FocusProductsPage;
