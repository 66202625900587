import { Link } from "react-router-dom";

import settings2 from "../../../utils/settings";
import productUtil from "../../../utils/product.util";
import stringUtil from "../../../utils/string.util";
import settings from "../../../utils/settings";

const getFocusProductBackgroundColor = (p) => {
  //return "#e43f3f";
  switch (p.focus.reason) {
    // case "Hot":
    //   return "#a00";
    case "Bestseller":
      return settings2.colors.secondary;
    case "Nyhed":
      return settings2.colors.primary;
    case "Udsalg":
      return settings2.colors.yellow;
    default:
      return "#8E8E8E";
  }
};

const getFocusProductColor = (p) => {
  //return "#e43f3f";
  switch (p.focus.reason) {
    // case "Hot":
    //   return "#a00";
    case "Bestseller":
      return "#fff";
    case "Nyhed":
      return "#fff";
    case "Udsalg":
      return "#000";
    default:
      return "#fff";
  }
};

const FocusProductControl = ({ product }) => {
  return (
    <div className="col-12 col-lg-4">
      <div className="card h-100 p-4 pt-5 text-center d-flex border-radius-10">
        <Link to={productUtil.getAbsoluteUrl(product)}>
          <img
            src={
              product.medias.length > 0
                ? product.medias[0].file.thumbUrl
                : settings.noImage.thumbUrl
            }
            className="card-img-top"
            alt=""
            style={{
              maxHeight: 200,
              objectFit: "contain",
              maxWidth: "100%",
            }}
          />
        </Link>
        <h5 className="card-title display-bold mt-4">{product.name}</h5>
        <p
          className="card-text"
          dangerouslySetInnerHTML={{
            __html: stringUtil.textToHtml(product.focus.text),
          }}
        ></p>
        <div className="d-flex align-items-end justify-content-center flex-grow-1">
          <Link
            to={productUtil.getAbsoluteUrl(product)}
            className="btn btn-secondary item-btn btn-action"
          >
            Læs mere
          </Link>
        </div>

        {product.focus.reason && (
          <div
            className="btn-action"
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              padding: 8,
              backgroundColor: getFocusProductBackgroundColor(product),
              color: getFocusProductColor(product),
              borderBottomLeftRadius: 5,
            }}
          >
            {product.focus.reason}
          </div>
        )}
      </div>
    </div>
  );
};

export default FocusProductControl;
