import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useException } from "../../../contexts/exception.context";
import { useMessage } from "../../../contexts/message.context";
import { useAppState } from "../../../contexts/appState.context";
import OptionsControl from "../../../controls/options.control";
import TextInput from "../../../controls/text.input";
import FileInput from "../../../controls/file.input";
import CheckboxesInput from "../../../controls/checkboxes.input";

import getMenuItems from "./employeeMenuItems.data";

const EmployeePage = () => {
  const navigate = useNavigate();
  const params = useParams();

  const appStateCtx = useAppState();
  const messageCtx = useMessage();
  const exceptionCtx = useException();

  const [employees, setEmployees] = useState([]);
  const [roles, setRoles] = useState([]);
  const [customerGroups, setCustomerGroups] = useState([]);

  const [oldEmployee, setOldEmployee] = useState();
  const [newEmployee, setNewEmployee] = useState();

  const [isLoading, setIsLoading] = useState(true);

  const isReadonly = !appStateCtx.user.permissionIds.includes(
    "admin.employees.edit"
  );

  const refresh = async () => {
    try {
      const employeePromise =
        appStateCtx.apiServerClient.employee.employees.getByNumber(
          parseInt(params.employeeNumber)
        );
      const groupsPromise =
        appStateCtx.apiServerClient.employee.customerGroups.getAll();
      const rolesPromise =
        appStateCtx.apiServerClient.employee.employees.getRoles();
      const employeesPromise =
        appStateCtx.apiServerClient.employee.employees.getAll();

      const [employee, groups, roles, employees] = await Promise.all([
        employeePromise,
        groupsPromise,
        rolesPromise,
        employeesPromise,
      ]);

      groups.sort((a, b) => a.name.localeCompare(b.name));
      roles.sort((a, b) => a.name.localeCompare(b.name));
      employees.sort((a, b) => a.name.localeCompare(b.name));

      setEmployees(employees);
      setRoles(roles);
      setCustomerGroups(groups);

      //employee.calenders = employee.calenders.map((c) => c.employeeId);

      setOldEmployee(employee);
      setNewEmployee(JSON.parse(JSON.stringify(employee)));
    } catch (ex) {
      exceptionCtx.handleException(ex);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  const handleSendNewPasswordClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.employees.sendNewPassword(
        newEmployee._id
      );

      messageCtx.showSuccess("Medarbejder kodeord sendt");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleUpdateClicked = async (e, showEmployees) => {
    try {
      await appStateCtx.apiServerClient.employee.employees.update(
        newEmployee._id,
        newEmployee.position,
        newEmployee.customerGroups,
        newEmployee.roleIds,
        newEmployee.calenders,
        newEmployee.image
      );

      messageCtx.showSuccess("Medarbejder opdateret");
      if (showEmployees) navigate("./..");
      else refresh();
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const hasFormChanged = () => {
    return JSON.stringify(oldEmployee) !== JSON.stringify(newEmployee);
  };

  const isFormOk = () => {
    return true;
  };

  if (isLoading) return null;

  if (!newEmployee) return null;

  const menuItems = getMenuItems(appStateCtx.user, newEmployee, "Medarbejder");

  return (
    <div className="container page">
      <h1>Medarbejder</h1>

      <div className="mt-4">
        <OptionsControl items={menuItems} />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Navn</label>
        <TextInput value={newEmployee.name} readonly />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Email</label>
        <TextInput value={newEmployee.email} readonly />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Telefon</label>
        <TextInput value={newEmployee.phone} readonly />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Stilling</label>
        <TextInput
          value={newEmployee.position}
          onChange={(value) =>
            setNewEmployee({ ...newEmployee, position: value })
          }
          readonly={isReadonly}
        />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Billede</label>
        <FileInput
          value={newEmployee.image}
          onChange={(value) => setNewEmployee({ ...newEmployee, image: value })}
          accept=".png, .jpeg, .jpg"
          allowEmpty
          maxSize={1024 * 1024 * 2}
          readonly={isReadonly}
          showSeeImageButton
        />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Kunde grupper</label>
        <CheckboxesInput
          items={customerGroups}
          nameExtractor={(g) => g.name}
          valueExtractor={(g) => g.customerGroupNumber}
          readonly={isReadonly}
          values={newEmployee.customerGroups}
          onChange={(values) =>
            setNewEmployee({ ...newEmployee, customerGroups: values })
          }
        />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Roller</label>
        <CheckboxesInput
          items={roles}
          nameExtractor={(r) => r.name}
          valueExtractor={(r) => r._id}
          readonly={isReadonly}
          values={newEmployee.roleIds}
          onChange={(values) =>
            setNewEmployee({ ...newEmployee, roleIds: values })
          }
        />
      </div>

      <div className="form-group mt-3">
        <label className="form-label">Vis andres kalendere</label>
        <CheckboxesInput
          items={employees.filter((e) => e._id !== newEmployee._id)}
          nameExtractor={(e) => e?.name}
          valueExtractor={(e) => e._id}
          readonly={isReadonly}
          values={newEmployee.calenders.map((c) => c.employeeId)}
          onChange={(values) =>
            setNewEmployee({
              ...newEmployee,
              calenders: values.map((v) => ({
                employeeId: v,
                read: true,
                write: true,
              })),
            })
          }
        />
      </div>

      {!isReadonly && (
        <div className="space-children mt-3">
          <button
            className="btn btn-primary"
            onClick={(e) => handleUpdateClicked(e, false)}
            disabled={!isFormOk() || !hasFormChanged()}
          >
            Opdater
          </button>
          <button
            className="btn btn-primary"
            onClick={handleSendNewPasswordClicked}
            disabled={hasFormChanged()}
          >
            Send nyt kodeord
          </button>
        </div>
      )}
    </div>
  );
};
export default EmployeePage;
